<template>
  <div class="mt-10 sm:mt-0 channel-create-form__wrapper">
    <base-form v-bind="$attrs"
               :save-text="Object.keys(channel).length ? $t('Save Channel') : $t('Create Channel')"
               grid-classes="flex flex-col"
               layout="modal"
               @submit="submitChannelCreate">
      <div class="w-full">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Channel basics') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>

        <div class="flex">
          <base-avatar-upload
              :image="media_image_location"
              button-text="Select image"
              class="w-3/6 flex-col mr-4 md:mr-0 mt-4"
              @on-finish="model.media_image_id = $event"/>
          <div class="flex flex-col w-full justify-end my-4">
            <base-input v-model="model.name"
                        :label="$t('Name')"
                        :placeholder="$t('Name')"
                        :name="$t('Name')"
                        class="w-full"
                        id="organization"
                        rules="required">
            </base-input>
            <base-country-select v-model="selectedCountry"
                                 :all-countries="allCountries"
                                 :default-country-code="model.country_code || 'nl'"
                                 :name="$t('Country code')"
                                 :label="$t('Country code')"
                                 show-text
                                 rules="required"/>
          </div>
        </div>
      </div>
      <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white mt-14">{{ $t('Channel promotion') }}</p>
      <div class="w-full border-b border-gray-200 my-4"></div>
      <div class="flex flex-col lg:flex-row">
        <base-select v-model="model.promotion_type"
                     :options="promotionTypeOptions"
                     :label="$t('Promotion type')"
                     :placeholder="$t('Promotion type')"
                     classNames="w-full lg:mr-4"
                     :name="$t('Promotion type')"
                     id="promoType"
                     rules="required">
        </base-select>
        <base-select v-model="model.type"
                     :options="typeOptions"
                     :label="$t('Type')"
                     :placeholder="$t('Type')"
                     classNames="w-full"
                     :name="$t('Type')"
                     id="type"
                     rules="required">
        </base-select>
      </div>
      <div class="w-full flex flex-col lg:flex-row">
        <base-input v-model="model.promotion_base_price"
                    type="number"
                    step="0.1"
                    min="0"
                    :label="$t('Base Price')"
                    :placeholder="$t('Base Price')"
                    :name="$t('Base Price')"
                    class="w-full lg:mr-4"
                    id="basePrice">
          <template v-slot:prefix>
            {{ getLocaleCurrencySign }}
          </template>
        </base-input>
        <base-select v-model="model.implementation"
                     :options="channelExportTypesOptions"
                     :label="$t('Implementation')"
                     :placeholder="$t('Implementation')"
                     classNames="w-full"
                     :name="$t('Implementation')"
                     id="implementation"
                     rules="required">
        </base-select>
      </div>
    </base-form>
  </div>
</template>

<script>
import BaseCountrySelect from "@/components/form/BaseCountrySelect.vue";
import allCountries from '@/utils/all-countries';
import ChannelService from "@/modules/jobs/services/ChannelService";
import formatSelectOptionsMixin from "@/modules/jobs/mixins/formatSelectOptionsMixin";
import localeCurrencyMixin from "@/modules/jobs/mixins/localeCurrencyMixin";

export default {
  name: "ChannelCreateForm",
  components: {
    BaseCountrySelect
  },
  mixins: [formatSelectOptionsMixin, localeCurrencyMixin],
  props: {
    channel: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      model: {
        media_image_id: null,
        name: null,
        country_code: this.channel?.country_code || null,
        promotion_type: null,
        type: null,
        promotion_base_price: null,
        implementation: null
      },
      media_image_location: null,
      selectedCountry: {},
      promotionTypes: {},
      types: {},
      implementationTypes: {},
      allCountries
    }
  },
  computed: {
    promotionTypeOptions() {
      return this.formatOptionsData('promotionTypes')
    },
    typeOptions() {
      return this.formatOptionsData('types')
    },
    channelExportTypesOptions() {
      return this.formatOptionsData('implementationTypes')
    }
  },
  async mounted() {
    if (this.channel?.broker === null) {
      this.$router.back()
    }
    if (this.channel?.id) {
      this.initModel()
    }
    const res = await ChannelService.getChannelFields()
    this.promotionTypes = res.filters.find(f => f.field === 'promotion_type')?.options
    this.types = res.filters.find(f => f.field === 'type')?.options
    this.implementationTypes = res.filters.find(f => f.field === 'implementation')?.options
  },
  methods: {
    initModel() {
      this.model.media_image_id = this.channel.media_image_id
      this.model.name = this.channel.name
      this.model.promotion_type = this.channel.promotion_type
      this.model.type = this.channel.type
      this.model.implementation = this.channel.implementation
      this.media_image_location = this.channel.media_image_location
    },
    async submitChannelCreate() {
      let res
      let message = this.$t('Succesfully created a channel!')
      if (this.channel?.id) {
        message = this.$t('Succesfully edited the channel!')
        res = await ChannelService.editChannel(this.channel.id, this.model)
      } else {
        res = await ChannelService.createChannel(this.model)
      }

      if (res) {
        this.$success(message)
        this.$router.push({ path: `/settings/channel/${res.id}`, query: { title: res.name, tab: 'overview' } })
        this.$emit('changeTab', 'overview')
      }
    }
  },
  watch: {
    selectedCountry: {
      deep: true,
      handler(val) {
        if (val) {
          this.model.country_code = val.iso2
        }
      }
    }
  }
}
</script>
