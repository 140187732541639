<template>
  <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center mt-4">
    <p class="text-xl leading-6 font-medium text-gray-900">
      {{ $t('Account budget stopper') }}
    </p>
    <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
      <template v-if="+budget_stopper_copy !== 0">
        <span>
          {{ $formatPrice(budget_stopper_copy, {maximumFractionDigits: 2}) }}
        </span>
        <span class="ml-3 text-xl font-medium text-gray-500">
          {{ getLocaleCurrencyText }}
        </span>
      </template>
      <template v-else>
        <span>{{ $t('No limit') }}</span>
      </template>
    </div>
    <div class="mt-4">
        <base-button
            variant="primary"
            class="mt-3 py-3"
            size="sm"
            :disabled="editMode"
            @click="editMode = true"
        >
          <span class="whitespace-nowrap">
            {{ $t('Edit account budget stopper') }}
          </span>
        </base-button>
    </div>
    <div class="max-w-2xl mx-auto my-6 text-gray-700">
      {{ $t('account budget stopper info text') }}
    </div>
    <budget-change
        v-if="editMode"
        v-model="budget_stopper"
        min-value="0"
        :save-text="$t('Save')"
        :cancel-text="$t('Cancel')"
        :input-name="$t('Account budget stopper')"
        :input-placeholder="$t('Account budget stopper')"
        @cancel="cancelEdit"
        @submit="updateBudgetStopper"
    />
  </div>
</template>

<script>
import localeCurrencyMixin from "@/modules/jobs/mixins/localeCurrencyMixin.js";
import SettingsService from "@/modules/dashboard/services/SettingsService";
import BudgetCreate from '@/modules/dashboard/pages/budget-create.vue'
import BudgetChange from "@/modules/dashboard/components/BudgetChange.vue";

export default {
  name: "BudgetSettings",
  components: {
    BudgetChange,
    BudgetCreate
  },
  mixins: [localeCurrencyMixin],
  data() {
    return {
      budget_stopper: null,
      budget_stopper_copy: null,
      editMode: false,
    }
  },
  computed: {
    accountBudgetStopper() {
      return this.$store.state.settings.settings?.account_stopper_budget
    },
    selectedClient() {
      return this.$store.state.auth.selectedClient || {}
    },
    displayedRemainingBudgetWarning() {
      return this.$store.state.settings.displayedRemainingBudgetWarning
    },
  },
  watch: {
    accountBudgetStopper: {
      handler(val) {
        if (!val) {
          return
        }
        this.budget_stopper = val
        this.budget_stopper_copy = val
      },
      immediate: true
    }
  },
  methods: {
    cancelEdit() {
      this.budget_stopper = this.budget_stopper_copy
      this.editMode = false;
    },
    async updateBudgetStopper() {
      try {
        this.loading = true
        const res = await SettingsService.saveUtm({
          account_stopper_budget: this.budget_stopper
        })
        if (!res) {
          return
        }
        this.$store.commit('settings/setDisplayedRemainingBudgetWarning', {
          ...this.displayedRemainingBudgetWarning,
          [this.selectedClient.id]: false,
        })

        this.editMode = false
        this.budget_stopper_copy = this.budget_stopper
        this.$store.commit('settings/setSettings', res)

        this.$success(this.$t('The account budget stopper has been successfully updated'))
      } finally {
        this.loading = false
      }
    }
  },
}
</script>
