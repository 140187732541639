export const CampaignStrategies = {
  Result: 'result',
  Click: 'click',
  Pacing: 'pacing',
  ResultPacing: 'result_pacing',
}

export const CampaignStrategyLabels = {
  [CampaignStrategies.Result]: 'Result',
  [CampaignStrategies.Click]: 'Click',
  [CampaignStrategies.Pacing]: 'Pacing',
  [CampaignStrategies.ResultPacing]: 'Result with pacing',
}

export const campaignStrategyOptions = [
  {
    value: CampaignStrategies.Result,
    label: CampaignStrategyLabels[CampaignStrategies.Result]
  },
  {
    value: CampaignStrategies.ResultPacing,
    label: CampaignStrategyLabels[CampaignStrategies.ResultPacing]
  },
  {
    value: CampaignStrategies.Click,
    label: CampaignStrategyLabels[CampaignStrategies.Click],
    disabled: true,
  },
  {
    value: CampaignStrategies.Pacing,
    label: CampaignStrategyLabels[CampaignStrategies.Pacing],
    disabled: true,
  },
]

export function campaignSpentBudget(campaign) {
  const spend = parseFloat(campaign?.spend) || 0
  const spendDay = parseFloat(campaign?.spend_day) || parseFloat(campaign?.daily_spent_estimated) || 0
  return Math.ceil(spend + spendDay)
}
