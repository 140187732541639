<template>
  <div class="py-6 text-gray-700 text-sm flex justify-center">© {{ year }} {{ companyName }}. All rights
    reserved.
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      companyName: 'Jobmatix.com'
    }
  }
}
</script>
