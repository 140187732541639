<template>
  <div class="flex justify-end items-baseline mb-5"
       data-html2canvas-ignore="true"
  >
    <base-date-picker
        :modelValue="[from, till]"
        :key="fromDateKey"
        class="w-full"
        type="daterange"
        :shortcuts="shortcuts"
        :disabled-date="disabledDays"
        @update:modelValue="onChangeDates"/>
  </div>
</template>
<script>
import {
  addDays,
  addMonths,
  endOfMonth,
  startOfMonth,
  subMonths,
  subWeeks,
  subDays,
  endOfDay,
  startOfWeek,
  endOfWeek,
  parseISO,
  differenceInHours
} from "date-fns";
import { getDateWithoutTimezone } from "@/utils/dateUtils.js";

let now = new Date()
let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
today = getDateWithoutTimezone(today)
now = getDateWithoutTimezone(now)

export default {
  props: {
    from: {
      type: [Date, Object, String],
      default: subMonths(today, 1)
    },
    till: {
      type: [Date, Object, String],
      default: today,
    }
  },
  data() {
    return {
      tillDateKey: 0,
      fromDateKey: 1
    }
  },
  mounted() {
    this.trySetDatesFromStore()
  },
  computed: {
    shortcuts() {
      const app = this
      return [
        {
          text: this.$t('Today'),
          onClick() {
            let tomorrow = addDays(today, 1)
            app.formatDates(today, tomorrow)
          },
        },
        {
          text: this.$t('Yesterday'),
          onClick() {
            const from = subDays(today, 1)
            const to = getDateWithoutTimezone(endOfDay(from))
            app.formatDates(from, to)
          },
        },
        {
          text: this.$t('Last week'),
          onClick() {
            let weekStart = startOfWeek(today, { weekStartsOn: 1 })
            weekStart = getDateWithoutTimezone(weekStart)
            const lastWeekStart = subWeeks(weekStart, 1)
            const lastWeekEnd = endOfWeek(lastWeekStart, { weekStartsOn: 1 })
            app.formatDates(lastWeekStart, lastWeekEnd)
          },
        },
        {
          text: this.$t('Last 2 weeks'),
          onClick() {
            let weekStart = startOfWeek(today, { weekStartsOn: 1 })
            weekStart = getDateWithoutTimezone(weekStart)
            const twoWeeksStart = subWeeks(weekStart, 2)
            const twoWeeksEnd = endOfWeek(twoWeeksStart, { weekStartsOn: 1 })
            app.formatDates(twoWeeksStart, twoWeeksEnd)
          },
        },
        {
          text: this.$t('This month'),
          onClick() {
            const thisMonth = getDateWithoutTimezone(startOfMonth(today))
            app.formatDates(thisMonth)
          },
        },
        {
          text: this.$t('Last month'),
          onClick() {
            const lastMonth = getDateWithoutTimezone(subMonths(today, 1))
            const lastMonthFirstDay = startOfMonth(lastMonth)
            const lastMonthLastDay = endOfMonth(lastMonth)
            app.formatDates(lastMonthFirstDay, lastMonthLastDay)
          },
        },
        {
          text: this.$t('Last 3 months'),
          onClick() {
            const threeMonthsAgo = getDateWithoutTimezone(subMonths(today, 3))
            const lastMonthFirstDay = startOfMonth(threeMonthsAgo)
            app.formatDates(lastMonthFirstDay, today)
          },
        }
      ]
    },
    disabledDays() {
      return function disabledDate(time) {
        return time.getTime() > Date.now();
      }
    },
    tillDisabledDays() {
      return (time) => {
        const untillNow = time.getTime() > Date.now()
        const oneMonthAfterFrom = getDateWithoutTimezone(addMonths(new Date(this.from), 1))
        const beformeFromPlusMonth = time.getTime() > oneMonthAfterFrom
        return untillNow || beformeFromPlusMonth;
      }
    },
  },
  methods: {
    async onChangeDates(val) {
      const start = val[0]
      let end = val[1]
      if (start?.getTime() === end?.getTime()) {
        end = addDays(start, 1)
      }
      this.updateFromDate(start)
      this.updateTillDate(end)
      await this.$nextTick()
      this.updateDatesInStore()
    },
    updateFromDate(val) {
      this.$emit('update:from', val)
    },
    updateTillDate(val) {
      this.$emit('update:till', val)
    },
    trySetDatesFromStore() {
      const storeFrom = this.$store.state.settings.reportsDate.from
      const storeTill = this.$store.state.settings.reportsDate.till
      const storeRangeUpdatedAt = this.$store.state.settings.reportsDate.updated_at
      if (!storeRangeUpdatedAt) {
        this.updateDatesInStore()
        return
      }
      const hoursAgo = differenceInHours(new Date(), new Date(storeRangeUpdatedAt))
      if (hoursAgo > 12) {
        this.$emit('update:from', subMonths(today, 1))
        this.$emit('update:till', today)
        return
      }
      if (storeFrom && storeTill) {
        const from = new Date(storeFrom)
        const till = new Date(storeTill)
        if (from?.getTime() !== this.from?.getTime()) {
          this.$emit('update:from', from)
        }
        if (till?.getTime() !== this.till?.getTime()) {
          this.$emit('update:till', till)
        }
      }
    },
    onChangeTillDate(val) {
      if (val.getTime && this.till.getTime && val.getTime() === this.till.getTime()) {
        return
      }
      if (val === this.from) {
        let till = getDateWithoutTimezone(endOfDay(parseISO(val)))
        this.$emit('update:till', till)
        return
      }
      this.$emit('update:till', val)
    },
    formatDates(from, till = null) {
      if (!till) {
        till = today
      }
      this.updateFromDate(from)
      this.onChangeTillDate(till)
      this.updateCalendarKeys()
      this.$store.commit('settings/SET_REPORTS_DATE', {
        from: from.toString(),
        till: this.$store.state.settings.reportsDate?.till,
        updated_at: new Date().toString(),
      })
      this.$store.commit('settings/SET_REPORTS_DATE', {
        from: this.$store.state.settings.reportsDate?.from,
        till: till.toString(),
        updated_at: new Date().toString(),
      })
    },
    updateDatesInStore() {
      this.$store.commit('settings/SET_REPORTS_DATE', {
        from: this.from.toString(),
        till: this.till.toString(),
        updated_at: new Date().toString(),
      })
    },
    updateCalendarKeys() {
      this.fromDateKey = Math.random()
      this.tillDateKey = Math.random()
    },
  }
}
</script>
<style>
</style>
