import { format } from 'date-fns'
import store from "@/store/index.js";

export function getReportExportName({ from, till, reportName }) {
  let exportDate = ''

  if (from && till) {
    exportDate = `from${format(from, 'yyyy-MM-dd')}to${format(till, 'yyyy-MM-dd')}`
  }
  const client = store.state.auth.selectedClient
  let nameParts = [client.name]
  if (reportName) {
    nameParts.push(reportName)
  }
  if (exportDate) {
    nameParts.push(exportDate)
  }
  return nameParts.join('_')?.toLowerCase()
}
