<template>
  <BaseChart :chart-data="chartData" :loading="loading"/>
</template>
<script>
import BaseChart from "@/components/charts/echarts/BaseChart.vue";
import { buildComparedTooltip } from "@/components/charts/buildComparedTooltip.js";
import { kFormatter } from "@/components/charts/utils.js";

export default {
  components: {
    BaseChart,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => null,
    },
    loading: Boolean,
  },
  computed: {
    noData() {
      const series = this.options ? this.options.series : this.series
      return !this.loading && series.length === 0 || this.get(series, '[0].data.length') === 0;
    },
    chartData() {
      const vm = this
      let options = {
        xAxis: {
          data: this.labels,
          axisLabel: {
            overflow: 'truncate',
            lineOverflow: 'truncate',
            clickable: true,
            color: '#67758f',
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          formatter(params) {
            return buildComparedTooltip(params, vm);
          }
        },
        yAxis: [
          this.getYAxis(this.$t('Clicks & Applicants')),
          this.getYAxis(this.$t('Conversion & Cost')),
        ],
        grid: {
          left: 60,
          top: 40,
          right: 60,
          bottom: 45,
        },
        series: this.series
      }

      if (this.options) {
        options = {
          ...this.options
        }
      }
      if (this.noData) {
        options.title = {
          show: true,
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal',
            fontFamily: 'Inter var'
          },
          text: 'No data found',
          left: 'center',
          top: 'center'
        }
      } else {
        options.title = {
          show: false,
        }
      }

      return options

    }
  },
  methods: {
    getYAxis(name) {
      return {
        name,
        nameTextStyle: {
          color: '#67758f',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#e7e7ef',
          }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#67758f',
          formatter(value) {
            if (!isNaN(value)) {
              return kFormatter(value)
            }
            return value
          }
        },
        splitLine: {
          lineStyle: {
            color: '#e7e7ef'
          }
        },
      }
    }
  }
}
</script>
