<template>
  <el-image v-if="imageSrc"
            :src="imageSrc"
            :alt="name"
            :fit="fit"
            :class="{
                    'avatar-sm': size === 'sm',
                    'avatar-md': size === 'md',
                    'avatar-lg': size === 'lg',
                    'avatar-xl': size === 'xl',
                    'rounded-full': rounded,
                    'rounded': size==='sm' && !rounded,
                    'rounded-lg': size!=='sm' && !rounded,
                }"
            class="avatar border-2 border-white">

  </el-image>
  <div v-else
       :class="{
                'avatar-sm': size === 'sm',
                'avatar-md': size === 'md',
                'avatar-lg': size === 'lg',
                'avatar-xl': size === 'xl',
                'rounded-full': rounded,
                'rounded': size==='sm' && !rounded,
                'rounded-lg': size!=='sm' && !rounded,
                'mr-4': initials,
           }"
       class="avatar text-white text-sm bg-primary-600 border-2 border-primary-400 shadow flex items-center justify-center"
  >
    {{ initials }}
  </div>
</template>
<script>
import { ElImage, ElTooltip } from 'element-plus'

export default {
  name: 'base-avatar',
  components: {
    ElImage,
    ElTooltip
  },
  props: {
    name: [String, Number],
    size: String,
    src: String,
    fit: String,
    rounded: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    imageSrc() {
      if (this.src) {
        return this.src
      }
      return ''
    },
    initials() {
      let initials = this.name

      if (typeof this.name === "string") {
        initials = this.name.split(' ').map(word => word.charAt(0)).join('').toUpperCase()
      }
      return initials
    }
  }
}
</script>
<style scoped lang="scss">
.avatar {
  width: 32px;
  height: 32px;

}

.avatar-sm {
  width: 26px;
  height: 26px;
}

.avatar-md {
  width: 34px;
  height: 34px;
}

.avatar-lg {
  width: 50px;
  height: 50px;
}

.avatar-xl {
  @apply w-20 h-20;

  @screen sm {
    @apply w-20 h-20;
  }

  @screen md {
    @apply w-32 h-32;
  }

  @screen lg {
    @apply w-40 h-40;
  }
}
</style>
<style lang="scss">
.el-image.avatar img {
  @apply object-contain shadow border-0;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
</style>
