<template>
    <time>
        {{formattedDate}}
    </time>
</template>
<script>
  import { get } from 'lodash'
  import { format } from 'date-fns'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      },
      params: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      formattedDate() {
        const value = get(this.row, this.column.prop)
        if (!value) {
          return '- -'
        }
        return this.$formatDate(value, this.params.format)
      }
    },
  }
</script>
<style>
</style>
