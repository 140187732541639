<template>
  <base-input v-model="inputValue"
              v-bind="$attrs"
              :key="key"
              :ref="ref"
              :class="classNames"
              @keydown.enter.prevent="onEnterPress"
              @keydown.native.down.prevent="focusOnOption(0)"
  >
    <template v-if="$slots.prefix" v-slot:prefix>
      <slot name="prefix">
      </slot>
    </template>
    <template v-slot:suffix>
      <div class="flex items-center">
        <i v-if="loading" class="el-icon el-icon-loading mr-1"></i>
        <slot name="suffix">
        </slot>
      </div>
    </template>
  </base-input>
  <div class="relative w-full" :class="[ $attrs.rules ? '-mt-3' : 'mt-3' ]">
    <div class="search-results absolute z-10 w-full"
         v-if="showResults">
      <div v-for="(item, index) in searchResults"
           :key="item.name"
           tabindex="0"
           :ref="`result-${index}`"
           class="search-result__item"
           @keydown.down.prevent="onKeyArrowDown"
           @keydown.up.prevent="onKeyArrowUp"
           @click="navigateToItem(item)"
           @keydown.enter.prevent="navigateToItem(item)"
      >
        <div v-html="highlightResult(item.label || item)"></div>
      </div>
    </div>
  </div>

</template>

<script>
import { debounce, get } from "lodash";

export default {
  inheritAttrs: false,
  name: "BaseAutocomplete",
  props: {
    key: {
      type: String,
      default: '0'
    },
    ref: {
      type: String,
      default: 'input'
    },
    classNames: {
      type: String,
      default: null
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    inputValue: {
      type: String,
      default: null
    },
    itemsToExclude: {
      type: Array,
      default: () => []
    },
    fetchDataAction: {
      type: Function,
      default: () => ({})
    },
  },
  emits: ['onKeydownEnter'],
  data() {
    return {
      loading: false,
      showResults: false,
      searchResults: [],
      focusedOptionIndex: 0,
    }
  },
  mounted() {
    this.debouncedSearch = debounce(this.onSearchChange, 300)
  },
  computed: {
    resultsCount() {
      return this.searchResults.length
    },
  },
  methods: {
    onEnterPress(evt) {
      this.$emit('onKeydownEnter', evt.target.value)
    },
    focus() {
      this.$refs.input && this.$refs.input.focus()
    },
    closeResults() {
      this.showResults = false
    },
    highlightResult(name) {
      const characters = get(this.inputValue, 'length', 0)
      let query = (this.inputValue || '').toLowerCase()
      const characterStart = name.toLowerCase().indexOf(query)
      if (characterStart === -1) {
        return name
      }

      const searchedString = name.substr(characterStart, characters)
      return name.substr(0, characterStart) +
          `<span class="highlight">${searchedString}</span>` +
          name.substr(characterStart + characters, name.length)
    },
    onKeyArrowDown() {
      if (this.focusedOptionIndex < this.resultsCount - 1) {
        this.focusedOptionIndex++
      } else {
        this.focusedOptionIndex = 0
      }
      this.focusOnOption(this.focusedOptionIndex)
    },
    onKeyArrowUp() {
      if (this.focusedOptionIndex > 0) {
        this.focusedOptionIndex--
      } else {
        this.focusedOptionIndex = this.resultsCount - 1
      }
      this.focusOnOption(this.focusedOptionIndex)
    },
    focusOnOption(index) {
      if (!this.resultsCount) {
        return
      }
      this.$nextTick(() => {
        const ref = this.$refs[`result-${index}`]
        if (!ref) {
          return
        }
        ref.focus()
        if (this.focusedOptionIndex !== index) {
          this.focusedOptionIndex = index
        }
      })
    },
    async onSearchChange(query) {
      if (this.disableAutocomplete) {
        return
      }
      let searchResults = []

      if (!query) {
        this.searchResults = searchResults
        this.showResults = false
        return
      }

      try {
        this.loading = true

        const res = await this.fetchDataAction(query)

        if (!res) {
          return
        }

        Object.entries(res).forEach(([key, value]) => searchResults.push(value))

        if (this.itemsToExclude.length) {
          this.searchResults = searchResults.filter(r => !this.itemsToExclude.includes(r))
        } else {
          this.searchResults = searchResults
        }

        this.showResults = true
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    navigateToItem(item) {
      this.showResults = false
      this.searchResults = [item]
      this.$emit('onKeydownEnter', item)
      this.focus()
    }
  },
  watch: {
    inputValue(val, oldVal) {
      const firstResult = this.searchResults[0]

      if (val === firstResult || val === get(firstResult, 'label') || !oldVal) {
        return
      }

      this.debouncedSearch(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  .search-result__item {
    @apply py-1 text-sm;
  }
}
</style>
