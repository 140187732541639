import i18n from "@/i18n";
import { isMarketeer, isOwner } from "@/utils/roleUtils";

export default function hasOwnerRoleMiddleware(router, store) {
  router.beforeEach(async (to, from, next) => {
    const requiresRoleOwner = to.matched.some(record => record.meta.requiresOwnerRole);
    const blockedStatuses = ['BLOCKED', 'DELETED', 'ON-HOLD']

    if (to.path.includes('/login')) {
      return next();
    }

    if (blockedStatuses.includes(store.state.auth?.selectedClient?.status) && !to.path.includes('/contact-us') && !to.path.includes('/subscription-create')) {
      await window.app.$notifyDialog({
        type: 'warning',
        title: i18n.t('Your account has been blocked.'),
        description: i18n.t('Your account has been blocked, please contact the support team ( support@jobmatix.com ).'),
        wrapperClass: 'md:max-w-2xl lg:max-w-2xl',
        buttonText: i18n.t('Close')
      })

      return next('/contact-us');
    }

    if (!isOwner() && !isMarketeer() && (requiresRoleOwner || to.path.includes('/finance/general') || to.path.includes('/finance/mandates'))) {
      return next('/403');
    }

    return next();
  });
}
