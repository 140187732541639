<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'JobsLayout',
  data() {
    return {
      selectedOption: this.$route.path
    }
  }
}
</script>
