<template>
  <base-tooltip content="T" effect="light">
    <span>
      {{performancePercent}}
    </span>
    <template v-slot:content>
      <div class="flex flex-col space-y-1">
        <span>{{$t('Applicants')}} - {{kpis?.applicants}}</span>
        <span>{{$t('Clicks')}} - {{kpis?.clicks}}</span>
      </div>
    </template>
  </base-tooltip>
</template>
<script>
export default {
  props: {
    kpis: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    performancePercent() {
      const applicants = this.kpis?.applicants || 0
      const clicks = this.kpis?.clicks || 1
      const percent = applicants/clicks
      return percent ? this.$formatPercent(percent) : '- -'
    }
  }
}
</script>
<style>
</style>
