<template>
  <div>
    <router-link v-if="row && link"
                 :to="link"
                 tabindex="-1"
                 class="table-action-button"
    >
      <base-button variant="gray-light"
                   :tabindex="0"
                   size="icon"
      >
        <EyeIcon class="w-4 h-4"/>
      </base-button>
    </router-link>
    <base-button v-else
                 variant="gray-light"
                 :tabindex="0"
                 size="icon"
                 class="table-action-button"
                 @click="$emit('click', $event)"
    >
      <EyeIcon class="w-4 h-4"/>
    </base-button>

  </div>
</template>
<script>
  import { EyeIcon } from '@zhuowenli/vue-feather-icons'

  export default {
    name: 'TableViewButton',
    components: {
      EyeIcon,
    },
    props: {
      row: {
        type: Object,
        default: () => ({})
      },
      link: {
        type: String,
      },
    }
  }
</script>
<style>
  .cell .table-action-button:last-child {
    @apply mr-0;
  }
</style>
