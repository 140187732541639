<template>
  <div class="mt-10">
    <BaseDataTable :columns="columns"
                   :data="data"
                   :title="$t('Feeds')"
                   :show-pagination="false"
                   ref="channelFeeds"
    >
      <template v-slot:feed_url="{row}">
        <base-tooltip :content="row.feed_url">
          <div class="truncate">
            <a :href="row.feed_url">
              {{row.feed_url}}
            </a>
          </div>
        </base-tooltip>
      </template>
    </BaseDataTable>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Url'),
          prop: 'feed_url',
          maxWidth: 350,
          align: 'left'
        },
        {
          label: this.$t('Active'),
          prop: 'active',
          maxWidth: 150,
          component: 'Status'
        },
        {
          label: this.$t('Created At'),
          prop: 'created_at',
          maxWidth: 200,
          component: 'FormattedDate'
        },
      ]
    }
  }
}
</script>
<style>
</style>
