import axios from "axios";
import i18n from "@/i18n";

export default {
  async getChannelFields(params = {}) {
    try {
      return await axios.get('/channel/api-fields', params);
    } catch (e) {
      window.app.$error(i18n.t('An error occurred while updating the channel.'))
    }
  },

  async getChannel(channelId, params = {}) {
    try {
      return await axios.get(`/channel/${channelId}`, params);
    } catch (e) {
      window.app.$error(i18n.t('An error occurred while updating the channel.'))
    }
  },

  async getChannelKpi(channelId) {
    try {
      return await axios.get(`/channel/${channelId}/kpi`);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async createChannel(payload) {
    try {
      return await axios.post(`/channel/create`, payload);
    } catch (e) {
      window.app.$error(i18n.t('An error occurred while updating the channel.'))
    }
  },

  async editChannel(channelId, payload) {
    try {
      return await axios.put(`/channel/${channelId}`, payload);
    } catch (e) {
      console.warn(e)
    }
  },

  async pauseChannel(channelId, payload) {
    try {
      return await axios.put(`/channel/${channelId}/exclude`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Could not pause the channel. Please try again or contact us.'))
    }
  },
}

