<template>
  <div>
    <ProfileForm :data="$user"/>
  </div>
</template>
<script>
import ProfileForm from "@/modules/profile/components/ProfileForm.vue";

export default {
  components: {
    ProfileForm,
  },
}
</script>
<style>
</style>
