<template>
  <div class="grid grid-cols-2 gap-x-4 campaign-job-filters">
    <div v-for="field in fields"
         :key="field.label"
         class="col-span-2 md:col-span-1"
    >
      <base-input-tag v-if="[operators.LIKE.value, operators.NOT_LIKE.value].includes(field.operator)"
                      v-model:tags="field.value"
                      :label="field.label"
                      :placeholder="$t('Hit enter to add...')"
                      @update:tags="onFilterChange"
      >
      </base-input-tag>
      <base-input v-else-if="['string', 'date', 'int'].includes(getFilter(field).type)"
                  v-model="field.value"
                  :type="getInputType(getFilter(field).type)"
                  :label="field.label"
                  :placeholder="field.label"
                  @change="onFilterChange"
      >
      </base-input>
      <base-select v-else-if="getFilter(field).type === 'select'"
                   v-model="field.value"
                   :options="getFieldOptions(field)"
                   :key="getFieldOptions(field).length"
                   :label="field.label"
                   :placeholder="field.label"
                   :automatic-dropdown="true"
                   :clearable="true"
                   @change="onFilterChange"
      >
      </base-select>
      <base-select v-else-if="isRemoteSelect(field)"
                   v-model="field.value"
                   :options="remoteOptions"
                   :multiple="hasMultipleOptions(field)"
                   :remote-method="query => remoteMethod(query, field)"
                   :label="field.label"
                   :placeholder="field.label"
                   :clearable="true"
                   remote
                   filterable
                   @focus="onRemoteSelectFocus(field)"
                   @change="onFilterChange"
      >
      </base-select>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { operators } from "@/components/table/filters/operators.js";
import { isArray } from "lodash";

export default {
  props: {
    initialFilters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      operators,
      allFilters: [],
      fields: [
        {
          field: 'name',
          label: this.$t('Job Title'),
          operator: operators.LIKE.value,
          value: [],
        },
        {
          field: 'name',
          label: this.$t('Exclude Job Title'),
          operator: operators.NOT_LIKE.value,
          value: [],
        },
        {
          field: 'organisation_name',
          label: this.$t('Organisation'),
          operator: operators.IS.value,
          value: '',
        },
        {
          field: 'priority',
          label: this.$t('Priority'),
          operator: operators.IS.value,
          value: [],
        },
        {
          field: 'job_tags',
          label: this.$t('Job Tags'),
          operator: operators.IN.value,
          value: [],
        },
        {
          field: 'job_tags',
          label: this.$t('Exclude Job Tags'),
          operator: operators.NOT_IN.value,
          value: [],
        },
        {
          field: 'location_name',
          label: this.$t('Location'),
          operator: operators.LIKE.value,
          value: [],
        },
        {
          field: 'location_country',
          label: this.$t('Country'),
          operator: operators.IN.value,
          value: [],
        }
      ],
      remoteOptions: [],
    }
  },
  computed: {
    jobFilters() {
      let filters = this.fields
          .map(field => {
            return {
              field: field.field,
              operator: field.operator,
              value: field.value,
            }
          })
          .filter(field => {
            if (isArray(field.value)) {
              return field.value.length > 0
            }
            return field.value !== '' && field.value !== null
          })

      filters.push({
        field: 'status',
        value: "ACTIVE",
        operator: operators.IS.value,
      })
      return filters
    }
  },
  methods: {
    async getFilters() {
      try {
        const data = await axios.get(`/job/api-fields`)
        this.allFilters = data.filters
      } catch (e) {
        console.warn(e)
      }
    },
    getFilter(fieldConfig) {
      return this.allFilters.find(filter => filter.field === fieldConfig.field) || {}
    },
    getInputType(type) {
      if (type === 'string') {
        return 'text'
      }
      if (type === 'date') {
        return 'date'
      }
      if (type === 'int') {
        return 'number'
      }
      return type
    },
    getFieldOptions(field) {
      const filter = this.getFilter(field)
      const opts = filter.options
      if (opts) {
        return Object.keys(opts).map(key => {
          return {
            label: this.$t(opts[key]),
            value: key,
          }
        })
      }
      return []
    },
    isRemoteSelect(field) {
      const filter = this.getFilter(field)
      const { type } = filter
      const { operator } = field
      return type === 'multiselect' || (type === 'country' && operator !== operators.LIKE.value)
    },
    async remoteMethod(query, field) {
      const filter = this.getFilter(field)
      const results = await axios.post(`/type-ahead`, {
        type: filter.typeahead,
        query
      })
      this.remoteOptions = Object.keys(results).map(key => {
        return {
          label: results[key] || key,
          value: key,
        }
      })
    },
    hasMultipleOptions(field) {
      return [operators.IN.value, operators.NOT_IN.value].includes(field.operator)
    },
    async onRemoteSelectFocus(field) {
      if (!field.value || field.value?.length === 0) {
        await this.remoteMethod('', field)
      }
    },
    async onFilterChange() {
      await this.$nextTick()
      this.$emit('filter-change', this.jobFilters)
    },
    setInitialFilters(filters) {
      filters.forEach(filter => {
        const matchingFilter = this.fields.find(f => f.field === filter.field && f.operator === filter.operator)
        if (matchingFilter) {
          matchingFilter.value = filter.value
        }
      })
    }
  },
  mounted() {
    this.getFilters()
  },
  watch: {
    initialFilters: {
      handler(filters) {
        this.setInitialFilters(filters)
      },
      immediate: true
    }
  }
}
</script>
<style>
.campaign-job-filters .el-select__input {
  @apply ml-0;
}
</style>
