<template>
    <div class="truncate">
        <a v-if="value"
           :href="`mailto:${value}`"
           class="text-primary-500 hover:text-primary-900 hover:underline cursor-pointer truncate">
            {{value}}
        </a>
        <span v-else>- -</span>
    </div>
</template>
<script>
  import { get } from 'lodash'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      value() {
        return get(this.row, this.column.prop)
      },
    },
  }
</script>
