<template>
  <div>
    <div class="flex justify-between items-end">
      <div>
        <entity-select
            v-if="!jobId"
            v-model="selectedJob"
            :label="$t('Job')"
            url="/job"
            value-key="id"
            select-first-option
        />
      </div>

      <report-date-picker
          :key="dateKey"
          v-model:from="from"
          v-model:till="till"/>
    </div>

    <dashboard-reports :from="from"
                       :till="till"
                       :default-active-items="['kpi_applicants', 'kpi_spend']"
                       :extra-params="{
                         [`params[job_id]`]: selectedJob
                       }"
    />
    <div class="mt-4">
      <channels-table :from="from"
                      :till="till"
                      :extra-params="{
                         [`params[job_id]`]: selectedJob
                       }"
      />
    </div>
    <div class="mt-4">
      <campaigns-table :from="from"
                       :till="till"
                       :extra-params="{
                         [`params[job_id]`]: selectedJob
                       }"
      />
    </div>
  </div>
</template>
<script>
import { subMonths } from "date-fns";
import EntitySelect from "@/components/common/EntitySelect.vue";
import ReportDatePicker from "@/components/report/ReportDatePicker.vue";
import DashboardReports from "@/modules/dashboard/components/DashboardReports.vue";
import ChannelsTable from "@/modules/reports/components/ChannelsTable.vue";
import CampaignsTable from "@/modules/reports/components/CampaignsTable.vue";

export default {
  components: {
    ReportDatePicker,
    DashboardReports,
    EntitySelect,
    CampaignsTable,
    ChannelsTable,
  },
  props: {
    jobId: {
      type: String,
    }
  },
  data() {
    const reportsDate = this.$store.state.settings.reportsDate
    const from = reportsDate?.from ? new Date(reportsDate.from) : subMonths(new Date(), 1)
    const till = reportsDate?.till ? new Date(reportsDate.till) : new Date()
    return {
      loading: false,
      from,
      till,
      jobsData: [],
      selectedJob: this.$route.query.job || this.jobId || null,
      dateKey: 0
    }
  },
  mounted() {
    this.dateKey = Date.now()
  }
}
</script>
<style>
</style>
