<template>
  <div class="mt-4">
    <base-form v-bind="$attrs"
               :loading="loading"
               :save-text="$t('Save')"
               grid-classes="flex flex-col"
               layout="modal"
               @submit="submitFinancial">
      <div class="w-full">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Financial') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>

        <div class="mt-6">
          <base-input v-model="model.organisation_name"
                      :label="$t('Organization')"
                      :placeholder="$t('Organization')"
                      :name="$t('Organization')"
                      class="w-full"
                      id="organization"
                      rules="required">
          </base-input>
          <base-input v-model="model.attention"
                      :label="$t('Attention Of')"
                      :placeholder="$t('Attention Of')"
                      :name="$t('Attention Of')"
                      class="w-full"
                      id="tav"
                      rules="required">
          </base-input>
          <base-input v-model="model.email"
                      :label="$t('Email')"
                      :placeholder="$t('Email')"
                      :name="$t('Email')"
                      class="w-full"
                      id="email"
                      rules="required|email">
          </base-input>
          <div class="flex flex-col lg:flex-row lg:gap-4">
            <base-input v-model="model.po_number"
                        :label="$t('Purchase number')"
                        :placeholder="$t('Purchase number')"
                        :name="$t('Purchase number')"
                        class="w-full"
                        id="po">
            </base-input>
            <div class="w-full">
              <base-input v-model="model.vat_number"
                          :label="$t('VAT Number')"
                          :placeholder="$t('VAT Number')"
                          :name="$t('VAT Number')"
                          class="w-full"
                          id="vat">
              </base-input>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row">
            <base-input v-model="model.street"
                        :label="$t('Address')"
                        :placeholder="$t('Address')"
                        :name="$t('Address')"
                        class="w-full"
                        id="street"
                        rules="required">
            </base-input>
            <div class="lg:w-2/6 lg:ml-4">
              <base-input v-model="model.house_no"
                          :label="$t('House number')"
                          :placeholder="$t('House number')"
                          :name="$t('House number')"
                          id="house"
                          rules="required">
              </base-input>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row">
            <base-input v-model="model.zip_code"
                        :label="$t('Zip code')"
                        :placeholder="$t('Zip code')"
                        :name="$t('Zip code')"
                        class="w-full"
                        id="zipcode"
                        rules="required">
            </base-input>
            <div class="w-full lg:ml-4">
              <base-autocomplete :inputValue="model.city"
                                 :fetchDataAction="fetchCountriesData"
                                 @update:modelValue="onCityFieldChange($event)"
                                 @onKeydownEnter="onCityFieldChange($event)"
                                 :placeholder="$t('City')"
                                 :label="$t('City')"
                                 :name="$t('City')"
                                 classNames="w-full"
                                 rules="required">
              </base-autocomplete>
            </div>
          </div>
          <base-select v-model="model.country_code"
                       :options="allCountries"
                       :label="$t('Country')"
                       :placeholder="$t('Country')"
                       :name="$t('Country')"
                       classNames="w-full"
                       name="country"
                       id="country"
                       rules="required"
                       filterable>
          </base-select>
        </div>
      </div>
    </base-form>
  </div>
</template>

<script>
import FinancialService from "@/modules/dashboard/services/FinancialService";
import countriesMixin from "@/modules/jobs/mixins/countriesMixin";
import { get } from "lodash";

export default {
  name: "FinancialSettings",
  mixins: [countriesMixin],
  data() {
    return {
      model: {
        organisation_name: null,
        vat_number: null,
        email: null,
        po_number: null,
        attention: null,
        house_no: null,
        country_code: null,
        street: null,
        zip_code: null,
        city: null,
      },
      loading: false,
      financialSettings: {},
    }
  },
  async mounted() {
    this.loading = true
    const res = await FinancialService.getFinancialSettings()
    this.loading = false
    if (!res) {
      return
    }

    this.financialSettings = res
    this.initModel()
  },
  methods: {
    initModel() {
      this.model.organisation_name = this.financialSettings.organisation_name
      this.model.vat_number = this.financialSettings.vat_number
      this.model.email = this.financialSettings.email
      this.model.po_number = this.financialSettings.po_number
      this.model.attention = this.financialSettings.attention
      this.model.house_no = get(this.financialSettings, 'address.house_no', null)
      this.model.country_code = get(this.financialSettings, 'address.country_code', null)
      this.model.street = get(this.financialSettings, 'address.street', null)
      this.model.zip_code = get(this.financialSettings, 'address.zip_code', null)
      this.model.city = get(this.financialSettings, 'address.city', null)
    },
    async submitFinancial() {
      let res
      try {
        this.loading = true
        res = await FinancialService.saveFinancialSettings(this.model)
      } finally {
        this.loading = false
      }

      if (!res) {
        return
      }

      this.$success(this.$t('Settings saved'))
    }
  }
}
</script>
