<template>
  <div class="mb-4">
    <div class="sm:hidden">
      <select v-model="selectedOption" aria-label="Selected tab" class="form-select block w-full">
        <option v-for="option in items"
                :key="option.name"
                :value="option.path">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="flex overflow-x-auto">
        <router-link v-for="(route, index) in items"
                     :key="route.name"
                     :to="route.path"
                     :class="{'ml-4': index !== 0}"
                     :aria-current="$route.path === route.path"
                     class="navigation-item px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 focus:bg-gray-100
                     dark:text-gray-100 dark:hover:text-white dark:focus:text-white">
          {{ $t(route.name) }}
        </router-link>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'layout-tabs',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedOption: this.$route.path,
    }
  },
  watch: {
    '$route.path'(value) {
      if (this.selectedOption !== value) {
        this.selectedOption = value
      }
    },
    selectedOption(newVal) {
      if (newVal && newVal !== this.$route.path) {
        this.$router.push(newVal)
      }
    }
  }
}
</script>
<style>
</style>
