import { get } from "lodash";
import { kFormatter } from "@/components/charts/utils";

function getFormattedValue(value, seriesName, vm) {
  const percentageItems = ['Conversion']
  const priceItems = ['Spend', 'eCPC', 'eCPA']
  if (percentageItems.includes(seriesName)) {
    value = vm.$formatPercent(value / 100)
  } else if (priceItems.includes(seriesName)) {
    value = vm.$formatPrice(value)
  } else if (!isNaN(value)) {
    return kFormatter(value)
  }
  return value
}


export function buildComparedTooltip(params, vm) {
  const dataIndex = get(params, `[0].dataIndex`, 0)
  const title = vm.labels[dataIndex] || '--'
  let html = ``
  params.forEach(item => {
    let value = getFormattedValue(item.value, item.seriesName, vm)
    html += `<div class="flex items-end grid grid-cols-2">
                <div class="flex items-center col-span-1">
                    <div class="w-3 h-3 rounded-full mr-2" style="background-color: ${item.color}"></div>
                    <div class="text-sm leading-6">${item.seriesName}</div>
                </div>
                <div class="col-span-1 font-semibold text-right" style="min-width: 50px;">${value}</div>
              </div>
              `
  })
  return `<div class="flex flex-col">
            <div class="text-sm leading-6 mb-1">${title}</div>
            ${html}
          </div>`;

}
