<template>
  <div class="relative overflow-hidden flex flex-col items-center justify-center min-h-full md:-mt-10 lg:-mt-16 min-h-[500px]">
    <div class="mx-auto max-w-screen-xl px-4">
      <div class="text-center">
        <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Ooops,
          <br class="xl:hidden"/>
          <span class="text-primary-600">{{ $t('we have a problem') }}</span>
        </h2>
        <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          {{ $t(`It seems like you don't have access to view this page`) }}
        </p>
        <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <base-button variant="primary"
                       size="xl"
                       @click="$router.push('/')">
            {{ $t('Take me Home') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
