<template>
  <div class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center form-dialog z-50"
       @keydown.esc="onCancel"
  >
    <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-0"
                leave-to-class="opacity-100"
                appear
    >
      <div v-if="showModal"
           class="fixed inset-0 transition-opacity">
        <div @click="onCancel"
             class="absolute inset-0 bg-gray-500 opacity-75">
        </div>
      </div>
    </transition>

    <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                leave-active-class="ease-out duration-200"
                leave-class="opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                appear
                @after-enter="onAfterEnter"
                @after-leave="onAfterLeave"
    >
      <div v-if="showModal"
           class="relative dialog-inner bg-white rounded-lg px-4 pt-5 pb-4 overflow-y-auto max-h-screen shadow-xl transform transition-all sm:w-full sm:p-6"
           :class="{
                    'sm:max-w-xl': size === 'sm',
                    'sm:max-w-4xl': size === 'default',
                    'sm:max-w-6xl': size === 'lg',
                    'sm:max-w-7xl': size === 'xl',
                    'show-arrows': showArrows
                 }"
           role="dialog"
           aria-modal="true"
           v-loading="loading"
           aria-labelledby="modal-headline">
        <template v-if="showArrows">
          <base-button :disabled="previousDisabled"
                       variant="white"
                       rounded
                       size="sm"
                       type="button"
                       class="left-arrow absolute rounded-full"
                       @click="$emit('prev-click')"
          >
            <ChevronLeftIcon class="h-8"/>
          </base-button>
          <base-button :disabled="nextDisabled"
                       rounded
                       variant="white"
                       size="sm"
                       type="button"
                       class="right-arrow absolute rounded-full"
                       @click="$emit('next-click')"
          >
            <ChevronRightIcon class="h-8"/>
          </base-button>
        </template>
        <h3 v-if="title" class="text-lg leading-6 font-medium text-gray-900 text-center" id="modal-headline">
          {{ title }}
        </h3>
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button type="button"
                  class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                  @click="onCancel">
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { ChevronLeftIcon, ChevronRightIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "FormDialog",
  inheritAttrs: false,
  components: {
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  props: {
    open: Boolean,
    loading: Boolean,
    appendToBody: Boolean,
    title: String,
    confirmText: {
      type: String,
      default: i18n.t('Save'),
    },
    cancelText: {
      type: String,
      default: i18n.t('Cancel'),
    },
    size: {
      type: String,
      default: 'default'
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
    previousDisabled: {
      type: Boolean,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close'],
  data() {
    return {
      showModal: this.open
    }
  },
  methods: {
    onDelete() {
      this.showModal = false
    },
    onCancel() {
      this.showModal = false
    },
    onAfterLeave() {
      this.$emit('close')
      this.$emit('update:open', false)
    },
    onAfterEnter() {
      if (!this.$refs.cancel) {
        return
      }
      this.$refs.cancel.focus()
    }
  },
  watch: {
    open(value) {
      this.showModal = value
    }
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    const firstInput = document.querySelector('.form-dialog input')
    if (firstInput) {
      firstInput.focus()
    }
    document.addEventListener("keyup", event => {
      if (event.code === 'Escape' && this.showModal) {
        this.onCancel()
      }
    })
  },
  unmounted() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody) {
      this.$el?.parentNode?.removeChild(this.$el);
    }
  }
}
</script>
<style scoped lang="scss">
.left-arrow {
  left: -60px;
  top: calc(50% - 25px);
}

.right-arrow {
  right: -60px;
  top: calc(50% - 25px);
}

.dialog-inner.show-arrows {
  @apply overflow-y-visible;
}
</style>
