<template>
  <div class="entity-select-wrapper">
    <base-select v-bind="$attrs"
                 :modelValue="modelValue"
                 :remote="true"
                 :clearable="true"
                 :remote-method="getData"
                 :options="mappedOptions"
                 :placeholder="$t(`Search for ${entity}s...`)"
                 filterable
    >
      <template v-slot:extra>
        <div class="flex justify-center items-center pt-1"
             v-if="lastPage > currentPage"
        >
          <base-button variant="primary-link"
                       :loading="loading"
                       @click="loadMore">
            {{$t('View More')}}
          </base-button>
        </div>
      </template>
    </base-select>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'entity-select',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    selectFirstOption: {
      type: Boolean,
      default: false,
    },
    labelFormat: {
      type: Function,
    }
  },
  data() {
    return {
      lastPage: 1,
      currentPage: 1,
      query: '',
      loading: false,
      options: [],
    }
  },
  computed: {
    mappedOptions() {
      return this.options.map(option => {
        let finalOption = {
          value: option[this.valueKey],
          label: option[this.labelKey],
        }
        if (this.labelFormat) {
          finalOption.label = this.labelFormat(option)
        }
        return finalOption
      })
    },
    entity() {
      const parts = this.url.split('/')
      let entity = ''
      if (parts.length) {
        entity = parts[parts.length - 1]
      }
      return entity
    }
  },
  methods: {
    loadMore() {
      this.currentPage++
      this.getData(this.query)
    },
    async getData(query) {
      if (query !== this.query) {
        this.currentPage = 1
      } else {
        this.currentPage++
      }
      this.query = query

      if (!this.url && this.data) {
        this.options = this.data
        return
      }
      try {

        this.loading = true
        const searchFilter = {
          field: "search",
          operator: "LIKE",
          value: query
        }
        const requestData = {
          filters: [
            { field: "status", operator: "IS", value: "ACTIVE" },
          ],
          sort: {
            page: this.currentPage,
            limit: 25,
            order: {}
          }
        }
        if (query) {
          requestData.filters.push(searchFilter)
        }
        const { data, pagination } = await axios.post(this.url, requestData)
        this.lastPage = pagination.last_page
        if (this.currentPage === 1) {
          this.options = data
        } else {
          this.options = this.options.concat(data)
        }
      } finally {
        this.loading = false
      }
    },
    onSelectFirstOption() {
      if (this.options.length) {
        this.$emit('update:modelValue', this.options[0][this.valueKey])
      }
    }
  },
  watch: {
    url(value) {
      if (!value) {
        return
      }
      this.getData()
    },
  },
  async mounted() {
    await this.getData()
    if (this.selectFirstOption && !this.modelValue) {
      this.onSelectFirstOption()
    }
  },
}
</script>

<style>
.entity-select-wrapper .base-select-inner {
  min-width: 250px;
}
</style>
