import en from 'date-fns/locale/en-US'
import { parseISO, format } from 'date-fns'

export const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy'
export const DEFAULT_DATE_TIME_FORMAT = 'dd-MM-yyyy HH:mm'
export const DATE_TIME = 'MMM dd, yyyy'
export const DATE_TIME_2 = 'd MMM yyyy'

export function formatDate(date, dateFormat = DEFAULT_DATE_FORMAT) {
  if (!date) {
    return '- -'
  }
  let dateToFormat = date
  if (typeof date === 'string') {
    dateToFormat = parseISO(date)
  }
  return format(dateToFormat, dateFormat, { locale: en })
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$formatDate = formatDate
  }
}
