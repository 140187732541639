export default {
  computed: {
    getLocaleCurrencySign() {
      // TODO get it from the client when available
      return '€'
    },
    getLocaleCurrencyText() {
      // TODO get it from the client when available
      return 'EUR'
    }
  }
}
