import JobsLayout from "./layout/JobsLayout.vue";
import JobCreate from "./pages/job-create.vue";
import JobDetails from "./pages/job-details.vue";
import JobList from "./pages/jobs.vue";
import SponsoredJobs from "./pages/sponsored-jobs.vue";
import CampaignList from "./pages/campaigns.vue";
import CampaignCreate from "./pages/campaign-create.vue";
import CampaignsLayout from "./layout/CampaignsLayout.vue";

export default [
  {
    path: '/jobs',
    name: 'Jobs',
    redirect: '/jobs/all',
    component: JobsLayout,
    meta: {
      requiresClient: true,
    },
    children: [
      {
        path: 'all',
        name: 'All Jobs',
        title: 'All Jobs',
        description: 'Check out information about your jobs, their progress and performance over time',
        component: JobList,
        meta: {
          hideBreadCrumb: true,
        }
      },
      {
        path: 'sponsored-jobs',
        name: 'Sponsored Jobs',
        title: 'Sponsored Jobs',
        description: 'Check out information about your sponsored jobs, their progress and performance over time',
        component: SponsoredJobs,
      },
      {
        path: 'nonsponsored-jobs',
        name: 'Non-sponsored jobs',
        title: 'Non-sponsored jobs',
        description: 'Check out information about your non-sponsored jobs, their progress and performance over time',
        component: SponsoredJobs,
      },
      {
        path: 'job-create',
        name: 'Create Job',
        title: 'Job create',
        description: 'Create a new job',
        component: JobCreate,
      },
      {
        path: ':id',
        name: 'Job Details',
        description: 'Job Details',
        component: JobDetails
      },
    ]
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    redirect: '/campaigns/all',
    component: CampaignsLayout,
    meta: {
      requiresClient: true,
      requiresOwnerRole: true
    },
    children: [
      {
        path: 'all',
        name: 'All Campaigns',
        title: 'All Campaigns',
        description: 'Check out information about your campaigns',
        component: CampaignList,
        meta: {
          hideBreadCrumb: true,
        }
      },
      {
        path: 'campaign-create',
        name: 'Create campaign',
        title: 'Campaign create',
        description: 'Create a campaign',
        component: CampaignCreate
      },
      {
        path: ':id',
        name: 'Campaign Details',
        description: 'Campaign Details',
        component: () => import(/* webpackChunkName: "campaigns" */ './pages/campaign-details.vue')
      },
    ]
  },
]
