<template>
  <job-create-form />
</template>

<script>
import JobCreateForm from "@/modules/jobs/components/JobCreateForm.vue";

export default {
  name: "job-create",
  components: {
    JobCreateForm
  }
}
</script>
