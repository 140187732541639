<template>
  <div>
    <router-link v-if="openEntityInNewTab && link"
                 :key="`edit-${row.id}`"
                 :to="link"
                 class="table-action-button"
                 tabindex="-1"
    >
      <base-button variant="primary-light"
                   :tabindex="0"
                   size="icon"
      >
        <Edit2Icon class="w-4 h-4"/>
      </base-button>
    </router-link>
    <base-button v-else
                 :tabindex="0"
                 variant="primary-light"
                 size="icon"
                 class="table-action-button"
                 @click="$emit('click', $event)">
      <Edit2Icon class="w-4 h-4"/>
    </base-button>

  </div>
</template>
<script>
import { Edit2Icon } from '@zhuowenli/vue-feather-icons'

export default {
  name: 'TableEditButton',
  components: {
    Edit2Icon,
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: String,
    },
    openEntityInNewTab: Boolean,
  }
}
</script>
