import store from "@/store/index.js";
import { NL_LOCALE } from "@/i18n.js";

function formatPrice(value, options = {}) {
  if (value === null || value === undefined) {
    return '- -'
  }

  let formatter
  let locale = NL_LOCALE
  if (store) {
    locale = store.state?.auth?.user?.active_locale
  }
  if (options.currencyDisplay === false) {
    formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: options.maximumFractionDigits || 2,
      minimumFractionDigits: options.maximumFractionDigits !== undefined ? options.maximumFractionDigits : 2,
    })
  } else {
    const fractionDigits = options.maximumFractionDigits !== undefined ? options.maximumFractionDigits : 2
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    })
  }

  return formatter.format(value)
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$formatPrice = formatPrice
  }
}
