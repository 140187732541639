<template>
  <div>
    <div>
      <img class="h-8 mx-auto w-auto" src="/img/logo.svg" alt="Workflow"/>
      <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900 text-center">
        {{ $t('Reset Password') }}
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w text-center">
        {{ $t('Enter your new password below') }}
      </p>
    </div>
    <div class="mt-8">
      <div class="mt-6 relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center text-sm leading-5">
        </div>
      </div>
      <div class="mt-6">
        <VeeForm v-slot="{ handleSubmit, errors }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <BaseInput v-model="model.password"
                       :label="$t('New Password')"
                       :name="$t('New Password')"
                       :placeholder="$t('New Password')"
                       rules="required|min:8"
                       id="password"
                       type="password"
            />
            <BaseInput v-model="model.password_confirm"
                       :label="$t('Confirm Password')"
                       :name="$t('Confirm Password')"
                       :placeholder="$t('Confirm Password')"
                       :rules="`required|confirmed:@${$t('New Password')}`"
                       id="password_confirm"
                       type="password"
            />
            <div class="flex justify-end text-sm leading-5">
              <router-link to="/login">
                {{ $t('Back to login') }}
              </router-link>
            </div>
            <div class="mt-6">
              <BaseButton block
                          type="submit"
                          :disabled="Object.keys(errors).length > 0"
                          :loading="loading">
                {{ $t('Reset Password') }}
              </BaseButton>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from '@/modules/auth/services/AuthService.js';

export default {
  data() {
    return {
      model: {
        email: '',
        token: '',
        password: null,
        password_confirm: null,
      },
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true

        this.model = {
          ...this.model,
          ...this.$route.query,
        }

        await AuthService.resetPassword(this.model)
        this.$success(this.$t('The password it was reset!'))
        await this.$router.push({ name: 'Login' })
      } catch (e) {
        if (e.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
