<template>
    <base-phone-link :value="value"/>
</template>
<script>
  import { get } from 'lodash'
  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      value() {
        return get(this.row, this.column.prop)
      }
    },
  }
</script>
<style>
</style>
