<template>
  <BaseNoPermissions v-if="!$isAdmin" />
  <div v-else class="mt-4">
    <base-form :can-submit-form="false"
               grid-classes="flex flex-col"
               layout="modal">
      <div class="w-full">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Tracking settings') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>

        <div class="mt-2">
          <p class="text-xl font-semibold my-2">{{$t('How to add a Jobmatix measurement pixel')}}</p>
          <p class="text-gray-600">{{$t('To make optimal use of the Jobmatix tooling you want to add a measurement pixel related to your account on your career page. With this “pixel” the tool is able to measure completed applications on your careersite or ATS. When measuring this KPI, you’re able to see the effectiveness of your ads, what their average cost per application is, and the quality of your bought traffic per media channel.')}}</p>
          <p class="font-semibold my-2">{{$t('Check our docs here:')}}
            <a href="https://support.jobboost.io/article/42-how-to-add-a-jobboostio-measurement-pixel">https://support.jobboost.io/article/42-how-to-add-a-jobboostio-measurement-pixel</a>
          </p>
        </div>
        <div>
          <base-button v-if="!trackingPixelToken"
                       variant="primary"
                       @click="onCreatePixel">
            {{ $t('Create pixel') }}
          </base-button>

          <div v-if="trackingPixelToken" class="mt-4">
              <div class="relative bg-whiteSmoke border-l-2 rounded p-2 pr-8 lg:pr-10 border-primary-500 h-full text-gray-700 font-mono text-sm bg-0 border-0 outline-none ring-0 w-full resize-none break-all cursor-pointer pixel-textarea"
                   :value="imageTag"
                   @click="copyPixelToClipboard">
                  {{ imageTag }}
                    <base-tooltip :content="$t('Click to copy pixel tag')">
                      <copy-icon size="1.2x" class="cursor-pointer absolute my-auto top-0 bottom-0 right-0 text-primary-800 mr-2 xl:mr-4 focus:outline-none"/>
                    </base-tooltip>
                </div>
            <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white my-4">{{ $t('Events') }}</p>
            <BaseDataTable :columns="columns"
                           :url="`/setting/token/${trackingPixelToken}/events`"
                           perPage="15"
                           tableColumnsKey="settings-tracking-table"
            >
              <template v-slot:created_at="{row}">
                {{ $formatDate(row.created_at, 'yyyy-MM-dd HH:mm:ss') }}
              </template>
              <template v-slot:event="{row}">
                {{ row.event }}
              </template>
              <template v-slot:refer="{row}">
                <a v-if="row.refer" :href="row.refer" target="_blank" rel="noopener" class="cursor-pointer">
                  {{ row.refer }}
                </a>
                <p v-else>- -</p>
              </template>
            </BaseDataTable>
          </div>
        </div>
      </div>
    </base-form>
  </div>
</template>

<script>
import SettingsService from "../services/SettingsService";
import { CopyIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "TrackingSettings",
  components: {
    CopyIcon
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Date'),
          prop: 'created_at',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Event'),
          prop: 'event',
          minWidth: 120,
          align: 'left'
        },
        {
          label: this.$t('User Agent'),
          prop: 'user_agent',
          minWidth: 120,
          align: 'left'
        },
        {
          label: this.$t('Refer'),
          prop: 'refer',
          minWidth: 120,
          align: 'left'
        },
        {
          label: this.$t('IP'),
          prop: 'ip',
          minWidth: 120,
          align: 'left'
        }
      ]
    },
    trackingPixelToken() {
      return this.$store.state.auth.selectedClient?.tokens[0]?.token
    },
    imageTag() {
      return `<img src="https://track.jobboost.io/collect/${this.trackingPixelToken}/collect.png?event=applicant" width="1" height="1"/><br>`
    }
  },
  methods: {
    async onCreatePixel() {
      const res = await SettingsService.createPixelToken()
      if (!res) {
        return
      }
      this.$router.go(0)
    },
    copyPixelToClipboard() {
      this.$copyToClipboard(this.imageTag, 'Tracking pixel tag copied to clipboard.');
    }
  }
}
</script>
<style lang="scss" scoped>
.pixel-textarea {
  box-shadow: none;
  word-break: break-word;
}
</style>
