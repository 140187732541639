<template>
  <div class="flex flex-wrap items-center lg:mb-4 table-filters">

    <div v-if="showPopover"
         class="fixed inset-0 w-screen h-screen z-0"
         @click="showPopover = false">

    </div>
    <el-popover trigger="manual"
                :visible="showPopover"
                placement="bottom-start"
                popper-class="filter-popover"
                ref="popover"
    >
      <template v-slot:reference>
        <base-button
            :disabled="!fieldOptions.length"
            variant="primary-link"
            class="mb-1"
            size="xxs"
            @click="showPopover = !showPopover"
        >
          <div class="flex items-center text-sm px-2">
            <plus-icon></plus-icon>
            {{ $t('Add filter') }}
          </div>
        </base-button>
      </template>

      <base-form layout="vertical"
                 class="w-full h-full -mt-6"
                 :save-text="$t('Save')"
                 :show-cancel="true"
                 v-click-outside="onClickOutside"
                 @cancel="showPopover = false"
                 :key="formKey"
                 @submit="onSubmit"
      >
        <div v-if="fieldOptions.length"
             class="col-span-6 md:col-span-2">
          <base-select v-model="model.field"
                       :automatic-dropdown="true"
                       :options="fieldOptions"
                       :label="$t('Field')"
                       :placeholder="$t('Field')"
                       name="field"
                       id="field"
                       ref="fieldSelect"
                       rules="required"
                       @update:modelValue="onFieldChange"
          >
          </base-select>
        </div>
        <div class="col-span-6 md:col-span-2"
             :key="operatorOptions.length">
          <base-select v-model="model.operator"
                       :options="translatedOperatorOptions"
                       :label="$t('Operator')"
                       :placeholder="$t('Operator')"
                       :automatic-dropdown="true"
                       name="operator"
                       id="operator"
                       rules="required">
          </base-select>
        </div>
        <div class="col-span-6 md:col-span-2">
          <template v-if="model.operator === operators.BETWEEN.value">
            <base-input v-model="model.value"
                        :type="getInputType(selectedField.type)"
                        :label="$t('Min')"
                        :placeholder="$t('Min')"
                        name="min"
                        id="min"
                        rules="required">
            </base-input>
            <base-input v-model="model.maxValue"
                        :type="getInputType(selectedField.type)"
                        :label="$t('Max')"
                        :placeholder="$t('Max')"
                        name="max"
                        id="max"
                        rules="required">
            </base-input>
          </template>
          <base-input-tag v-else-if="model.operator === operators.LIKE.value && model.field !== 'search'"
                          v-model:tags="model.value"
                          :key="selectedFieldOptions.length"
                          :label="$t('Value')"
                          :placeholder="$t('Hit enter to add...')"
                          name="value"
                          id="value4">
          </base-input-tag>
          <base-input v-else-if="['string', 'date', 'int'].includes(selectedField.type) && model.operator !== operators.BETWEEN.value"
                      v-model="model.value"
                      :type="getInputType(selectedField.type)"
                      :label="$t('Value')"
                      :placeholder="$t('Value')"
                      name="value"
                      id="value"
                      rules="required">
          </base-input>
          <base-select v-else-if="selectedField.type === 'select'"
                       v-model="model.value"
                       :options="selectedFieldOptions"
                       :key="selectedFieldOptions.length"
                       :label="$t('Value')"
                       :placeholder="$t('Value')"
                       :automatic-dropdown="true"
                       name="value"
                       id="value1"
                       rules="required">
          </base-select>

          <base-select v-else-if="isRemoteSelect"
                       v-model="model.value"
                       :options="remoteOptions"
                       :multiple="hasMultipleOptions"
                       :remote-method="remoteMethod"
                       :key="selectedFieldOptions.length"
                       :label="$t('Value')"
                       :placeholder="$t('Value')"
                       remote
                       filterable
                       name="value"
                       id="value2"
                       rules="required"
                       @focus="onRemoteSelectFocus"
          >
          </base-select>

          <base-switch
              v-model="model.value"
              value-type="string"
              v-if="['boolean'].includes(selectedField.type)"
              class="flex flex-col"
              :label="$t('Value')"/>
        </div>
      </base-form>
    </el-popover>
    <status-badge v-for="filter in visibleFilters"
                  :key="filter.field"
                  :status="filter.label"
                  class="flex mb-1 ml-1"
                  @click="onEditFilter(filter)"
    >
      <div class="group relative">
        <div class="group-hover:opacity-0">
          {{filter.label}} {{filter.operator}}
          <template v-if="filter.value && !filter.maxValue">
            {{ getFilterPrettyValue(filter) }}
          </template>
          <template v-if="filter.maxValue">
            {{$t('Min:')}} {{filter.value}}  {{$t('Max:')}} {{filter.maxValue}}
          </template>
        </div>
        <div class="hidden absolute top-0 h-full w-full justify-center items-center group-hover:flex">
          {{ $t('Edit') }}
        </div>
      </div>
      <XIcon @click.prevent.stop="removeFilter(filter)" class="h-5 hover:text-gray-800 cursor-pointer ml-2"></XIcon>
    </status-badge>
    <el-popover trigger="click"
                placement="bottom-start"
                popper-class="save-popover"
                ref="savePopover"
    >
      <template v-slot:reference>
        <base-button variant="green-link"
                     class="mb-1"
                     size="xxs"
                     :disabled="!activeFilters.length">
          <span class="text-sm px-3">{{ $t('Save as preset') }}</span>
        </base-button>
      </template>
      <base-form layout="vertical"
                 class="w-full h-full -mt-6"
                 :save-text="$t('Save')"
                 @submit="onPresetSubmit"
      >
        <base-input v-model="filterName"
                    :label="$t('Preset Name')"
                    :placeholder="$t('Preset Name')"
                    class="col-span-6"
                    name="preset-name"
                    ref="presetNameInput"
                    id="preset-name"
                    rules="required">
        </base-input>
      </base-form>
    </el-popover>
  </div>
</template>

<script>
import axios from 'axios'
import { PlusIcon, XIcon } from '@zhuowenli/vue-feather-icons'
import { ElPopover } from 'element-plus'
import { cloneDeep, isArray } from 'lodash';
import SavedTableFilters from "./SavedTableFilters.vue";
import { operators } from "@/components/table/filters/operators.js";
export default {
  name: 'table-filters',
  components: {
    XIcon,
    PlusIcon,
    ElPopover,
    SavedTableFilters,
  },
  props: {
    url: String,
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      model: {
        field: '',
        operator: '',
        value: '',
        maxValue: '',
        label: '',
      },
      formKey: 0,
      showPopover: false,
      filterName: '',
      operators,
      selectedFilter: null,
      allOperators: {
        boolean: [operators.IS, operators.IS_NOT],
        string: [operators.IS, operators.IS_NOT, operators.LIKE],
        country: [operators.IS, operators.IS_NOT, operators.IN, operators.NOT_IN],
        location_country: [operators.IS, operators.IS_NOT, operators.IN, operators.NOT_IN],
        select: [operators.IS, operators.IS_NOT],
        status: [operators.IS, operators.IS_NOT],
        date: [operators.LTE, operators.GTE, operators.BETWEEN],
        int: [operators.IS, operators.IS_NOT, operators.LTE, operators.GTE, operators.BETWEEN],
        multiselect: [operators.IS, operators.IS_NOT, operators.IN, operators.NOT_IN],
      },
      activeFilters: this.modelValue || [],
      allFilters: [],
      remoteOptions: []
    }
  },
  computed: {
    isRemoteSelect() {
      const { type } = this.selectedField
      const { operator } = this.model
      return type === 'multiselect' || (type === 'country' && operator !== operators.LIKE.value)
    },
    visibleFilters() {
      return this.activeFilters?.filter(f => f.visible !== false)
    },
    fieldOptions() {
      return this.allFilters
          .filter(f => !f.hideInTable)
          .map(f => {
        return {
          label: this.$t(f.label),
          value: f.field,
        }
      })
    },
    selectedField() {
      return this.allFilters.find(f => f.field === this.model.field) || { type: 'string' }
    },
    operatorOptions() {
      let type = this.selectedField ? this.selectedField.type : this.allOperators.string
      if (this.selectedField.field === 'status') {
        return this.allOperators.status
      }
      return this.allOperators[type] || []
    },
    translatedOperatorOptions() {
      return this.operatorOptions.map(el => ({
        ...el,
        label: this.$t(el.label),
      }))
    },
    selectedFieldOptions() {
      const opts = this.selectedField.options
      if (opts) {
        return Object.keys(opts).map(key => {
          return {
            label: this.$t(opts[key]),
            value: key,
          }
        })
      }
      return []
    },
    hasMultipleOptions() {
      return [operators.IN.value, operators.NOT_IN.value].includes(this.model.operator)
    },
    entity() {
      return this.url.replaceAll('/', '')
    }
  },
  methods: {
    async getFilters() {
      try {
        const url = this.url.split('/')
        const data = await axios.get(`${url[1]}/api-fields`)
        this.allFilters = data.filters
      } catch (e) {
        console.warn(e)
      }
    },
    async onRemoteSelectFocus() {
      if (!this.model.value) {
        await this.remoteMethod('')
      }
    },
    async remoteMethod(query) {
      const results = await axios.post(`/type-ahead`, {
        type: this.selectedField.typeahead,
        query
      })
      this.remoteOptions = Object.keys(results).map(key => {
        return {
          label: results[key] || key,
          value: key,
        }
      })
    },
    getInputType(type) {
      if (type === 'string') {
        return 'text'
      }
      if (type === 'date') {
        return 'date'
      }
      if (type === 'int') {
        return 'number'
      }
      return type
    },
    onSubmit() {
      const data = {
        ...this.model,
      }
      this.addOrUpdateFilter(data)

      this.model = {
        field: '',
        operator: '',
        value: '',
        maxValue: '',
        label: '',
      }
      this.formKey = Date.now()
      this.saveFiltersToStore()
      this.$emit('update:modelValue', this.activeFilters)
      this.showPopover = false
    },
    addOrUpdateFilter(filter) {
      if (!filter.id) {
        filter.id = new Date().getTime()
      }
      if (this.selectedFilter) {
        const index = this.activeFilters.findIndex(f => f.id === filter.id || f.field === filter.field )
        if (index !== -1) {
          this.activeFilters[index] = filter
        }
        this.selectedFilter = null
      } else {
        this.activeFilters.push(filter)
      }
    },
    onPresetSubmit() {
      this.$store.commit('filter/ADD_FILTER', {
        entity: this.entity,
        filter: {
          name: this.filterName,
          value: cloneDeep(this.activeFilters)
        },
      })
      this.$refs.savePopover.hide()
      this.$success(this.$t(`Filter ${this.filterName} saved successfully`))
    },
    onClickOutside() {
      debugger
      this.showPopover = false
    },
    onFieldChange() {
      this.model.label = this.selectedField.label

      if (this.selectedField.type === 'boolean') {
        this.model.value = 'true'
      }

      if (this.operatorOptions.find(o => o.value === 'LIKE')) {
        this.$nextTick(() => {this.model.operator = 'LIKE'})
      } else if (this.operatorOptions.find(o => o.value === '>=')) {
        this.$nextTick(() => {this.model.operator = '>='})
      }
    },
    saveFiltersToStore() {
      this.$store.commit('filter/SET_ACTIVE_FILTERS', {
        page: this.$route.path,
        filters: this.activeFilters
      })
    },
    removeFilter(filter) {
      const filterIndex = this.activeFilters.findIndex(f => f.field === filter.field && f.value === filter.value)
      if (filterIndex !== -1) {
        this.activeFilters.splice(filterIndex, 1)
        this.$emit('update:modelValue', this.activeFilters)
        this.saveFiltersToStore()
      }
    },
    setFilter(filter) {
      this.activeFilters = cloneDeep(filter)
      this.$emit('update:modelValue', this.activeFilters)
      this.saveFiltersToStore()
    },
    getFilterPrettyValue(filter) {
      if (isArray(filter.value)) {
        return filter.value.join(', ')
      }
      return this.$t(filter.value)
    },
    async onEditFilter(filter) {
      this.model = {
        ...this.model,
        ...filter,
      }
      this.selectedFilter = {
        ...filter,
      }
      this.showPopover = true
      await this.$nextTick()
      this.model.value = filter.value
    },
  },
  async created() {
    await this.getFilters()
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.activeFilters = val
      }
    },
    'model.operator'(operator) {
      if ([operators.NOT_IN.value, operators.IN.value].includes(operator)) {
        this.model.value = []
      } else {
        this.model.value = ''
      }
    },
    showPopover(val){
      if (!val) {
        this.model.value = ''
      }
    }
  }
}
</script>

<style lang="scss">
.save-popover.el-popover.el-popper {
  @apply p-0;
  min-width: 350px;
}
.filter-popover.el-popover.el-popper {
  @apply p-0;
  min-width: 700px;
}
.filter-popover {
  .country-select__wrapper.el-select.country-select {
    @apply w-full;
  }
  .selected-flag {
    @apply w-8;
  }
}
</style>
