<template>
  <div class="base-select" :class="classNames">
    <label :for="label || $slots.label"
           class="block text-sm font-medium leading-5 text-gray-700">
      <slot name="label">
        {{ label }}
        <span v-if="rules && rules.includes('required')"
              class="text-gray-500">
              *
        </span>
      </slot>
      <span v-if="labelInfo" class="block text-xs text-gray-400 flex items-center mb-2">
          <info-icon size="1.3x" class="text-black mr-2 w-8 sm:w-auto"></info-icon>
        {{labelInfo}}
      </span>
    </label>
    <base-input-error :error="errorMessage"
                      :show-tooltip="inlineErrors">
      <el-select v-bind="$attrs"
                 v-model="inputValue"
                 :multiple="multiple"
                 :disabled="readonly"
                 :multiple-limit="multipleLimit"
                 :remote="remote"
                 :remote-method="remote ? remoteMethod : undefined"
                 ref="select"
                 class="entity-select base-select-inner w-full"
                 :class="{ 'select-error': errorMessage }"
      >
        <slot>
          <el-option v-for="option in options"
                     :value="option.value"
                     :label="option.label || option.value"
                     :key="option.value"
                     :disabled="option.disabled"
          >
            <div class="flex items-center">
              <component v-if="option.icon" :is="option.icon" size="1.3x" class="mr-2"/>
              <span class="truncate">{{ option.label || option.value }}</span>
            </div>
          </el-option>
        </slot>
        <slot name="extra"></slot>
      </el-select>
    </base-input-error>
  </div>
</template>
<script>
import { ElSelect, ElOption } from 'element-plus'
import { useField } from "vee-validate";
import { InfoIcon, UserPlusIcon } from '@zhuowenli/vue-feather-icons'

export default {
  inheritAttrs: false,
  components: {
    ElSelect,
    ElOption,
    InfoIcon,
    UserPlusIcon
  },
  props: {
    label: String,
    classNames: String,
    modelValue: [String, Number, Array],
    labelInfo: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
    inlineErrors: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object, Array],
      default: ''
    },
    remote: {
      type: Boolean
    },
    remoteMethod: {
      type: Function,
    }
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const name = props.name || props.label
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.rules, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (value === undefined) {
          value = null
        }
      },
    },
  },
  methods: {
    initForceSelect() {
      const input = this.$el.querySelector('.entity-select .el-input')
      input.addEventListener('keydown', event => {
        const selectRef = this.$refs.select
        if (!selectRef || event.key !== 'Tab') {
          return
        }
        const option = selectRef.options[selectRef.hoverIndex]
        if (option) {
          selectRef.handleOptionSelect(option);
        }
      })
    },
    focus() {
      this.$refs.select.focus()
    }
  },
  mounted() {
    this.initForceSelect()
  },
  watch: {
    modelValue(val) {
      if (val !== this.inputValue) {
        this.inputValue = val
      }
    }
  }
}
</script>
<style>
.el-select-dropdown .el-scrollbar__wrap--hidden-default {
  @apply overflow-auto;
}
</style>
