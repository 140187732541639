import i18n from "@/i18n";

function copyToClipboard(value, message = 'Text copied to clipboard.') {
  const el = document.createElement('textarea');

  el.value = value;

  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  window.app.$success(i18n.t(message))
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$copyToClipboard = copyToClipboard
  }
}
