import axios from 'axios';
import i18n from "@/i18n";
import config from "@/modules/common/config.js";
import { validatePhoneRequest } from "@/utils/phoneUtils.js";

export default {

  setToken(token) {
    localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  async login(data, params) {
    let result = await axios.post('/auth/login', data, {
      params,
    });
    let { token } = result;
    this.setToken(token);
    return result;
  },

  async globalLogin(data) {
    return axios.post(`${config.ROOT_API_URL}/global/login`, data);
  },

  async createClient(data) {
    try {
      data = validatePhoneRequest(data)
      return await axios.post('/client', data);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async register(data, params) {
    const requestData = {
      email: data.email,
      given_name: data.given_name,
      family_name: data.family_name,
      password: data.password,
      password_confirmation: data.password_confirmation,
      'g-recaptcha-response': data.gRecaptchaResponse,
    }
    const requestParams = {
      from: params.from,
      token: params.invitation,
    }
    let result = await axios.post('/auth/register', requestData, {
      params: requestParams,
    });
    let { plainTextToken } = result;
    this.setToken(plainTextToken);
    return result;
  },

  logout() {
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
  },

  async resetPassword(data) {
    return await axios.post('/auth/password-reset', data);
  },

  async forgotPassword(data) {
    return axios.post('/auth/password-forgot', data);
  },
}
