<template>
  <div>
    <base-tabs
        v-model="settingTab"
        :getItemClass="getItemClass"
        class="w-full mb-4 md:mb-0"
        :items="tabs"
    />
    <organization-settings v-if="settingTab === 'organization'"/>
    <team-settings v-if="settingTab === 'team'"/>
    <feed-settings v-if="settingTab === 'feeds'"/>
    <tracking-settings v-if="settingTab === 'tracking pixel'"/>
    <utm-settings v-if="settingTab === 'UTM'"/>
    <router-view :key="settingTab" v-if="settingTab === 'channel'"/>

  </div>
</template>

<script>
import OrganizationSettings from "@/modules/dashboard/components/OrganizationSettings.vue";
import TeamSettings from "@/modules/dashboard/components/TeamSettings.vue";
import FeedSettings from "@/modules/dashboard/components/FeedSettings.vue";
import TrackingSettings from "@/modules/dashboard/components/TrackingSettings.vue";
import UtmSettings from "@/modules/dashboard/components/UtmSettings.vue";
import i18n from "@/i18n.js";

export default {
  name: "settings.vue",
  components: {
    OrganizationSettings,
    TeamSettings,
    FeedSettings,
    TrackingSettings,
    UtmSettings
  },
  childPaths: [
    {
      name: i18n.t('Organization Settings'),
      description: i18n.t('Organization general settings'),
      path: '/settings/organization'
    },
    {
      name: i18n.t('Team Settings'),
      description: i18n.t('Manage your team users'),
      path: '/settings/team'
    },
    {
      name: i18n.t('Feed Settings'),
      description: i18n.t('Manage your feeds'),
      path: '/settings/feeds'
    },
  ],
  data() {
    return {
      settingTab: ''
    }
  },
  computed: {
    channelTab() {
      return {
        route: '/settings/channel/all',
        title: this.$t('Channels')
      }
    },
    adminTabs() {
      return ['feeds', 'tracking pixel', 'UTM', this.channelTab]
    },
    tabs() {
      const baseTabs = ['organization', 'team']
      if (this.$isAdmin) {
        return baseTabs.concat(this.adminTabs)
      }
      return baseTabs;
    }
  },
  methods: {
    getItemClass(item) {
      const isAdminItem = this.adminTabs.includes(item)
      return isAdminItem ? 'bg-red-50' : ''
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        if (val.path.indexOf('settings') === -1) {
          return
        }
        const paramId = val.params.id
        const isChannelRoute = val.path.includes('channel')

        if (paramId || isChannelRoute) {
          this.settingTab = isChannelRoute ? 'channel' : paramId
        } else {
          this.settingTab = 'organization'
        }
      }
    },
    settingTab: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }

        if (val === 'subscriptions') {
          this.$router.push('/subscription-create')
          return
        }

        if (val === 'channel' && route.path.includes('channel')) {
          return
        }

        this.$router.push({
          path: `/settings/${val}`
        })
      }
    }
  }
}
</script>
