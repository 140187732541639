<template>
  <div>
    <div class="p-4 lg:px-6">
      <div class="mx-auto">
        <div>
          <div>
            <nav class="sm:hidden">
              <a href="#"
                 class="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
                <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400 dark:text-gray-100" fill="currentColor"
                     viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
                {{$t('Back')}}
              </a>
            </nav>
            <nav v-if="matchedRoutes.length > 1"
                 class="hidden sm:flex items-center text-sm leading-5 font-medium">
              <template v-for="(route, index) in matchedRoutes" :key="route.name">
                <router-link :to="route"
                             class="first-letter:uppercase breadcrumb-link text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out dark:text-gray-100">
                  {{ getBreadcrumbName(route) }}
                </router-link>
                <svg :key="route.name + 'arrow'"
                     v-if="index !== matchedRoutes.length - 1"
                     class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400"
                     fill="currentColor"
                     viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
              </template>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    matchedRoutes() {
      let matchedRoutes = this.$route.matched.filter(r => !r?.meta?.hideBreadCrumb)
      if (this.$route.query.title) {
        matchedRoutes.map(route => {
          if (route.name === this.$route.name) {
            route.query = this.$route.query
          }
        })
      }

      return matchedRoutes
    },
    showLogout() {
      return this.$route.path.includes('profile')
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    getBreadcrumbName(route) {
      const paramRoutes = ['Settings item', 'Finance item']
      if (paramRoutes.includes(route.name) && this.$route?.params?.id) {
        return this.$t(this.$route.params?.id?.toString())
      }
      if (route?.query?.title) {
        return this.$t(this.$route.query.title)
      }
      return this.$t(route.name)
    }
  }
}
</script>
<style>
.breadcrumb-link.router-link-exact-active {
  @apply text-primary-600;
}
</style>
