<template>
  <div>
    <label :for="label || $slots.label"
           class="block text-sm font-medium leading-5 text-gray-700">
      <slot name="label">
        {{ label }}
        <span v-if="rules && rules.includes('required')"
              class="text-gray-500">
              *
        </span>
      </slot>
      <span v-if="labelInfo" class="block text-xs text-gray-400 flex items-center mb-2">
          <info-icon size="1.3x" class="text-black mr-2"></info-icon>
        {{labelInfo}}
      </span>
    </label>
    <base-input-error :error="errorMessage"
                      :show-tooltip="inlineErrors">
      <textarea ref="redactor"
                :name="name || label"
                :placeholder="placeholder"
                :value="inputValue"
      />
    </base-input-error>
  </div>
</template>
<script>
import '@/assets/vendor/redactor/scss/redactor.scss'
import '@/assets/vendor/redactor/scss/variables.scss'
import '@/assets/vendor/redactor/scss/icons.scss'
import { useField } from "vee-validate";
import { InfoIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: 'html-editor',
  redactor: false,
  components: {
    InfoIcon
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: String,
    labelInfo: String,
    rules: {
      type: [String, Object, Array],
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    inlineErrors: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({
        plugins: ['alignment', 'fontcolor', 'fontsize', 'imagemanager', 'definedlinks', 'variable', 'icons'],
        imageResizable: true,
        imagePosition: true,
        breakline: true,
      }),
    }
  },
  setup(props) {
    const name = props.name

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.rules, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  data() {
    return {
      iconList: [
        'user',
        'star-off',
        'goods',
        'warning-outline',
        'setting',
        'check',
        'close',
        'picture-outline',
        'download',
        'right',
        'back',
        'play',
        'view',
        'document-add',
        'paperclip',
        'search',
        'monitor',
        'mobile',
        'data-line',
        'collection-tag',
        'receiving',
        'office-building',
        'wallet',
        'present',
        'bank-card',
        'news',
        'price-tag',
        'discount',
        'set-up',
        'chat-line-square',
        'message',
        'link',
        'position',
        'location-outline',
        'unlock',
      ]
    }
  },
  watch: {
    errorMessage(val) {
      const redactor = this.$el.querySelector('.redactor-box')

      if (val) {
        redactor.classList.add('form-input-error')
      } else {
        redactor.classList.remove('form-input-error')
      }
    },
    modelValue: {
      immediate: true,
      handler(val) {
        this.inputValue = val
        if (!this.redactor) {
          return
        }
        if (this.redactor.editor.isFocus()){
          return
        }
        this.redactor.source.setCode(val)
      }
    },
  },
  async mounted() {
    await import('@/assets/vendor/redactor/redactor.js')
    await Promise.all([
      import('@/assets/vendor/redactor/plugins/imagemanager/imagemanager.js'),
      import('@/assets/vendor/redactor/plugins/definedlinks/definedlinks.js'),
      import('@/assets/vendor/redactor/plugins/alignment/alignment.js'),
      import('@/assets/vendor/redactor/plugins/variable/variable.js'),
      import('@/assets/vendor/redactor/plugins/fontsize/fontsize.js'),
      import('@/assets/vendor/redactor/plugins/fontcolor/fontcolor.js'),
    ])
    this.init()
  },
  methods: {
    init() {
      try {
        let callbacks = {
          changed: html => {
            this.handleInput(html)
            return html
          }
        };

        let target = document.querySelector('#scrollable-layer')
        this.config.callbacks = callbacks
        let finalConfig = {
          ...this.config,
          icons: this.generateIcons(),
          toolbarFixedTarget: target,
        };
        // in case the target doesn't exist, delete it
        if (!target) {
          delete finalConfig.toolbarFixedTarget
        }
        let app = window.$R(this.$refs.redactor, finalConfig);
        // set instance
        this.redactor = app;
        this.$parent.redactor = app;
      } catch (e) {
        console.warn('Redactor init error', e)
      }
    },
    generateIcons() {
      return this.iconList.map((icon, index) => {
        return [
          `<i class="el-icon-${icon}" data-index="${index}"></i>`,
          `<i class="el-icon-${icon}"></i>`
        ]
      })
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleInput(val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
