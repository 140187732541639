<template>
  <div>
    <base-input v-bind="$attrs"
                v-model="inputValue"
                ref="input"
                @blur="onBlur"
                @keydown.enter.prevent="onEnter"
    >
    </base-input>
    <status-badge v-for="tag in tagsToDisplay"
                  :key="tag"
                  :status="tag"
                  :close-icon="true"
                  class="flex mb-1 ml-1"
                  @delete="deleteTag(tag)"
    >
    </status-badge>
  </div>
</template>
<script>
import uniq from 'lodash/uniq'
export default {
  props: {
    tags: {
      type: Array,
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  computed: {
    tagsToDisplay() {
      if (Array.isArray(this.tags)) {
        return this.tags
      }
      if (!this.tags) {
        return []
      }
      return [this.tags]
    }
  },
  methods: {
    async onEnter() {
      if (!this.inputValue) {
        return
      }
      const tags = this.tagsToDisplay || [];
      let newTags = [...tags, this.inputValue]
      newTags = uniq(newTags)
      this.$emit('update:tags', newTags)
      await this.$nextTick()
      this.$refs.input.inputValue = ''
    },
    async onBlur() {
      await this.onEnter();
      this.$emit('blur')
    },
    deleteTag(tag) {
      const tags = this.tagsToDisplay || [];
      let newTags = tags.filter(t => t !== tag)
      this.$emit('update:tags', newTags)
    }
  }
}
</script>
<style>
</style>
