<template>
  <div
      class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:-mt-56 dialog-wrapper"
      @keydown.esc="onClose"
      v-bind="$attrs"
  >
    <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-0"
                leave-to-class="opacity-100"
                appear
    >
      <div v-if="showModal"
           class="fixed inset-0 top-0 transition-opacity">
        <div @click="onClose"
             class="absolute inset-0 bg-gray-500 opacity-75">
        </div>
      </div>
    </transition>

    <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                leave-active-class="ease-out duration-200"
                leave-class="opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                appear
                @after-enter="onAfterEnter"
                @after-leave="onCloseFinished"
    >
      <div v-if="showModal"
           class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 max-h-screen overflow-y-auto"
           role="dialog"
           aria-modal="true"
           aria-labelledby="modal-headline">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button type="button"
                  class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                  @click="onClose">
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div>
          <slot></slot>
        </div>
        <div class="el-dialog__footer flex justify-center bg-gray-100 border-t border-grey-light"
             v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "BaseDialog",
  inheritAttrs: false,
  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: i18n.t('Delete Information'),
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: i18n.t('Are you sure ? This will permanently remove the information you are about to delete.'),
    },
    deleteText: {
      type: String,
      default: i18n.t('Delete'),
    },
    cancelText: {
      type: String,
      default: i18n.t('Cancel'),
    },
    type: {
      type: String,
      default: 'danger'
    },
  },
  emits: ['update:modelValue', 'delete'],
  data() {
    return {
      showModal: this.modelValue
    }
  },
  methods: {
    onDelete() {
      this.showModal = false
      this.$emit('delete')
    },
    onClose() {
      this.showModal = false
    },
    onCloseFinished() {
      this.$emit('update:modelValue')
    },
    onAfterEnter() {
      if (!this.$refs.cancel) {
        return
      }
      this.$refs.cancel.focus()
    }
  },
  watch: {
    modelValue(value) {
      this.showModal = value
    }
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    document.addEventListener("keyup", event => {
      if (event.code === 'Escape' && this.modelValue) {
        this.onClose()
      }
    })
  },
  unmounted() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody) {
      this.$el?.parentNode?.removeChild(this.$el);
    }
  }
}
</script>
