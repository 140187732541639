<template>
  <div>
    <div class="flex flex-col md:flex-row items-center w-full details-header__wrapper">
      <base-tabs v-model="channelTab" class="w-full mb-4 md:mb-0" :items="tabItems"/>
    </div>
    <template v-if="get(channel, 'id')">
      <div class="flex items-center w-full md:w-auto mt-6 mb-10">
        <div class="flex items-center">
          <div
              v-if="$isAdmin"
              class="relative flex flex-col -space-y-px"
          >
            <base-button size="sm"
                         variant="primary-link"
                         @click="toggleChannelActivity(channel)">
              <base-tooltip :content="channel.excluded_id ? $t('Enable') : $t('Pause')">
                <play-circle-icon v-if="channel.excluded_id" class="text-green-500" size="2x"/>
                <pause-circle-icon v-else class="text-yellow-500" size="2x"/>
              </base-tooltip>
            </base-button>
          </div>
        </div>
        <span class="text-xl mr-4">{{ channel.name }}</span>
        <base-tooltip :content="channel.type" effect="light">
          <book-open-icon
              size="1.5x"
              v-if="channel.type === CHANNEL_TYPES.JOB_BOARD"
              class="cursor-pointer"/>
          <search-icon
              v-else-if="channel.type === CHANNEL_TYPES.SEARCH_ENGINE"
              size="1.5x"
              class="cursor-pointer"/>
          <instagram-icon
              v-else-if="channel.type === CHANNEL_TYPES.SOCIAL_MEDIA"
              size="1.5x"
              class="cursor-pointer"/>
          <arrow-up-icon
              v-else
              size="1.5x"
              class="cursor-pointer"/>
        </base-tooltip>
      </div>
      <div v-if="channelTab === 'overview'" class="flex flex-col lg:flex-row justify-between py-2">
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Effective cost per click')">
                  <span>{{ $t('CPC') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ calculateEcpc }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Effective cost for an applicant')">
                  <span>{{ $t('CPA') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ calculateEcpa }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Click through ratio')">
                  <span>{{ $t('CTR') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ calculateCtr }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Total spent so far')">
                  <span>{{ $t('Spent') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ $formatPrice(get(kpi, 'spend')) || '- -' }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
      </div>
      <template v-if="channelTab === 'overview'">
        <div class="flex flex-col lg:flex-row justify-between py-2">
          <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
            <template v-slot:content>
              <div class="xl:flex-1 w-full flex flex-col justify-center">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                  {{ $t('Views') }}
                </dt>
                <dd class="flex items-baseline justify-start">
                  <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ get(kpi, 'views') }}
                  </div>
                </dd>
              </div>
            </template>
          </base-stats-card>
          <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
            <template v-slot:content>
              <div class="xl:flex-1 w-full flex flex-col justify-center">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                  {{ $t('Free') }}
                </dt>
                <dd class="flex items-baseline justify-start">
                  <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ get(kpi, 'free') }}
                  </div>
                </dd>
              </div>
            </template>
          </base-stats-card>
          <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
            <template v-slot:content>
              <div class="xl:flex-1 w-full flex flex-col justify-center">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                  {{ $t('Clicks') }}
                </dt>
                <dd class="flex items-baseline justify-start">
                  <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ get(kpi, 'clicks') }}
                  </div>
                </dd>
              </div>
            </template>
          </base-stats-card>
          <base-stats-card class="h-auto w-full">
            <template v-slot:content>
              <div class="xl:flex-1 w-full flex flex-col justify-center">
                <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                  {{ $t('Applicants') }}
                </dt>
                <dd class="flex items-baseline justify-start">
                  <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ get(kpi, 'applicants') }}
                  </div>
                </dd>
              </div>
            </template>
          </base-stats-card>
        </div>
        <ChannelFeeds v-if="channel.feeds" :data="channel.feeds"/>
      </template>
      <div v-if="channelTab === 'edit'">
        <channel-create-form :channel="channel" @changeTab="changeTab"/>
      </div>
      <div v-if="channelTab === 'reports'">
        <channel-reports :channel-id="channel.id"/>
      </div>
    </template>
    <div v-else
         v-loading="loading"
         element-loading-background="rgba(0,0,0,0)"
         class="h-20 mt-4"></div>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio.vue";
import ChannelService from "@/modules/jobs/services/ChannelService";
import { CHANNEL_TYPES } from '@/enum/tableEnums'
import { ArrowUpIcon, InstagramIcon, SearchIcon, BookOpenIcon, PlayCircleIcon, PauseCircleIcon } from '@zhuowenli/vue-feather-icons'
import ChannelCreateForm from "@/modules/jobs/components/ChannelCreateForm.vue";
import { get } from "lodash";
import channelSubmitMixin from "../mixins/channelSubmitMixin";
import ChannelFeeds from "@/modules/jobs/components/ChannelFeeds.vue";
import ChannelReports from "@/modules/reports/pages/channel-reports.vue";

export default {
  name: "channel-details",
  components: {
    SearchIcon,
    ArrowUpIcon,
    BookOpenIcon,
    InstagramIcon,
    PlayCircleIcon,
    PauseCircleIcon,
    BaseRadio,
    ChannelFeeds,
    ChannelReports,
    ChannelCreateForm,
  },
  mixins: [channelSubmitMixin],
  data() {
    return {
      loading: false,
      channel: null,
      kpi: null,
      channelTab: this.$route.query.tab || 'overview',
      CHANNEL_TYPES
    }
  },
  computed: {
    tabItems() {
      let items = ['overview', 'edit']
      if (!this.channel?.client_id) {
        items =  ['overview']
      }
      items.push('reports')
      return items
    },
    calculateEcpc() {
      const result = get(this.kpi, 'spend') / get(this.kpi, 'clicks')
      return result && result !== Infinity ? this.$formatPrice(result) : '- -'
    },
    calculateEcpa() {
      const result = get(this.kpi, 'spend') / get(this.kpi, 'applicants')
      return result && result !== Infinity ? this.$formatPrice(result) : '- -'
    },
    calculateCtr() {
      const result = get(this.kpi, 'applicants') / get(this.kpi, 'clicks')
      return result && result !== Infinity ? this.$formatPercent(result.toFixed(2)) : '- -'
    }
  },
  methods: {
    changeTab(val) {
      this.channelTab = val
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        if (val.path.indexOf('channel') === -1) {
          return
        }

        const channelId = val.params.id

        if (channelId) {
          try {
            this.loading = true
            const [channel, kpi] = await Promise.all([
              ChannelService.getChannel(channelId),
              ChannelService.getChannelKpi(channelId)
            ])

            this.channel = channel
            this.kpi = kpi
          } finally {
            this.loading = false
          }
        }

        if (!this.channel.client_id && this.channelTab === 'edit') {
          let query = {
            tab: 'overview'
          }
          if (this.$route.query.title) {
            query.title = this.$route.query.title
          }
          this.channelTab = 'overview'
        }
      }
    },
    channelTab(val) {
      let query = {
        tab: val
      }

      if (this.$route.query.title) {
        query.title = this.$route.query.title
      }

      this.$router.push({
        query: query
      })
    }
  }
}
</script>
