export function replaceLinkVariables(url, variables = {}) {
  // Use a regular expression to match the variables in square brackets. E.g [variable]
  const pattern = /\[(\w+)\]/g;

  return url.replace(pattern, (match, variable) => {
    if (variable in variables) {
      return variables[variable];
    }

    // Return the original variable if it's not present in the variables object
    return match;
  });
}
