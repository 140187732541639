<template>
  <div>
    <div id="job-performance-chart"
         class="pb-6 mt-4 bg-white shadow-card px-4 py-6 rounded-lg relative">
      <report-actions-dropdown
          :export-name="exportName"
          export-id="job-performance-chart"
      />
      <div class="flex justify-between items-start mb-6 mx-14">
        <base-tabs v-model="activeTab"
                   :items="tabItems"
                   data-html2canvas-ignore="true"
                   @update:modelValue="onActiveTabChanged"
        />
        <slot name="actions" />
      </div>
      <report v-if="activeTab === $t('Analytics')"
              key="analytics"
              :query="clickReport"
              v-slot="{data, loading}">
        <div class="mb-6 mx-14 grid grid-cols-3 xl:flex">
          <div v-for="(item, index) in totals(data)"
               class="px-4 py-5 bg-white border-r border-b border-t-4 overflow-hidden sm:p-6 max-w-sm flex-1 border-gray-200 cursor-pointer"
               :class="{'border-l': index === 0}"
               @click="activeReportItems[item.prop] = !activeReportItems[item.prop]"
               :style="{borderTopColor: activeReportItems[item.prop] ? item.color : ''}"
          >
            <dt class="text-sm font-medium text-gray-500 truncate pb-1 leading-4">
              {{ item.label }}
            </dt>
            <dd class="flex items-end text-2xl font-semibold text-gray-900">
              <span class="pt-3 leading-none">{{ formatValue(item) }}</span>
            </dd>
          </div>
        </div>

        <styled-base-chart
            v-bind="getAnalyticsData(data)"
            :loading="loading"
            class="mt-4 w-full h-96"
        />
      </report>
      <report v-else-if="activeTab === $t('Job progress')"
              key="spend"
              :query="jobsVsSpendReport"
              v-slot="{data, loading}">
        <styled-base-chart
            :loading="loading"
            :options="getScatterData(data)"
            class="mt-4 w-full h-96"
        />
      </report>
    </div>
  </div>
</template>
<script>
import { parseISO, format } from 'date-fns'
import { sortBy, get } from 'lodash'
import Report from "@/components/report/Report.vue";
import StyledBaseChart from "@/components/charts/StyledBaseChart.vue";
import ReportActionsDropdown from "@/modules/dashboard/components/ReportActionsDropdown.vue";
import { kFormatter } from "@/components/charts/utils.js";
import { getReportExportName } from "@/modules/reports/utils/getReportExportName.js";

const colors = {
  clicks: '#3B82F6',
  applicants: '#7C3AED',
  conversion: '#DB2777',
  spend: '#10B981',
  cpc: '#EA580C',
  cpa: '#F59E0B'
}
export default {
  name: 'DashboardReports',
  components: {
    Report,
    StyledBaseChart,
    ReportActionsDropdown,
  },
  props: {
    from: {
      type: [String, Date],
      default: ''
    },
    till: {
      type: [String, Date],
      default: ''
    },
    extraParams: {
      type: Object,
      default: () => ({})
    },
    defaultActiveItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeTab: this.$t('Analytics'),
      tabItems: [this.$t('Analytics'), this.$t('Export')],
      activeReportItems: {
        kpi_total: true,
        kpi_applicants: this.hasItem('kpi_applicants') || false,
        kpi_spend: this.hasItem('kpi_spend') || false,
        kpi_ctr: this.hasItem('kpi_ctr') || false,
        kpi_cpc: this.hasItem('kpi_cpc') || false,
        kpi_cpa: this.hasItem('kpi_cpa') || false,
      },
      jobsChartData: {
        datasets: []
      },
      analyticsData: {}
    }
  },
  computed: {
    exportName() {
      return getReportExportName({
        from: this.from,
        till: this.till,
        reportName: this.activeTab,
      })
    },
    formattedRange() {
      return {
        date_from: format(this.from, 'yyyy-MM-dd'),
        date_till: format(this.till, 'yyyy-MM-dd'),
        date_range: 'custom'
      }
    },
    clickReport() {
      let baseReport = {
        type: 'days',
        compare: true,
        'params[date_interval]': 'day',
        ...this.formattedRange,
      }
      if (this.extraParams) {
        baseReport = {
          ...baseReport,
          ...this.extraParams
        }
      }
      return baseReport
    },
    jobsVsSpendReport() {
      let baseReport = {
        type: 'jobs',
        ...this.formattedRange,
      }
      baseReport = {
        ...baseReport,
        ...this.extraParams
      }
      return baseReport
    },
  },
  methods: {
    formatValue(item) {
      const kFormatting = [this.$t('Clicks'), this.$t('Applicants')]
      if (kFormatting.includes(item.label)) {
        return kFormatter(item.value)
      }
      return item.value
    },
    hasItem(item) {
      return this.defaultActiveItems.indexOf(item) !== -1
    },
    async onActiveTabChanged(tab) {
      if (tab === this.$t('Export')) {
        await this.$router.push('/profile/export')
      }
    },
    totals(data) {
      let totalClicks = data?.total?.clicks || 0
      let totalApplicants = data?.total?.applicants || 0
      let totalSpend = data?.total?.spend || 0
      let conversion = data?.avg?.ctr || 0
      let totalCPC = data?.avg?.ecpc || 0
      let totalCPA = data?.avg?.ecpa || 0
      return [
        {
          label: this.$t('Clicks'),
          value: totalClicks,
          comparedChange: data?.compared?.clicks || 0,
          color: colors.clicks,
          prop: 'kpi_total',
        },
        {
          label: this.$t('Applicants'),
          value: totalApplicants,
          comparedChange: data?.compared?.applicants || 0,
          color: colors.applicants,
          prop: 'kpi_applicants',
        },
        {
          label: this.$t('Spent'),
          value: this.$formatPrice(totalSpend, {
            maximumFractionDigits: 0,
          }),
          comparedChange: data?.compared?.spend || 0,
          color: colors.spend,
          prop: 'kpi_spend',
        },
        {
          label: this.$t('Conversion'),
          value: this.$formatPercent(conversion / 100),
          comparedChange: data?.compared?.ctr || 0,
          color: colors.conversion,
          prop: 'kpi_ctr',
        },
        {
          label: this.$t('CPC'),
          value: this.$formatPrice(totalCPC),
          comparedChange: data?.compared?.ecpc || 0,
          color: colors.cpc,
          prop: 'kpi_cpc',
        },
        {
          label: this.$t('CPA'),
          value: this.$formatPrice(totalCPA),
          comparedChange: data?.compared?.ecpa || 0,
          color: colors.cpa,
          prop: 'kpi_cpa',
        }
      ]
    },
    getScatterData(data) {
      data = sortBy(data?.jobs || [], 'date')
      let series = [
        {
          symbolSize: 20,
          type: 'scatter',
          emphasis: {
            focus: 'series',
          },
          name: 'Spent',
          data: data.map(item => ({
            value: [item.spend, item.applicants],
            job: item.job,
            job_id: item.job_id,
          }))
        }
      ]
      const vm = this
      return {
        xAxis: {
          name: this.$t('Spent'),
          axisLabel: {
            interval: 0,
            overflow: 'truncate',
            lineOverflow: 'truncate',
            clickable: true,
            color: '#67758f',
            formatter(value) {
              return vm.$formatPrice(value)
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          trigger: 'item',
          enterable: true,
          formatter(params) {
            let [spent, applicants] = params.value
            const { job, job_id } = params.data
            spent = vm.$formatPrice(spent)
            window.onTooltipLinkClick = function () {
              return vm.$router.push(`/jobs/${job_id}`)
            }
            return `
            <div>
              <div class="text-sm mb-2 w-48 truncate">
                <span onclick='onTooltipLinkClick()'
                      class="font-medium text-primary-600 hover:text-primary-500 hover:underline cursor-pointer">
                    ${job}
                </span>
              </div>
              <div class="flex items-center">
              <div class="mr-5">
                <dt class="text-sm font-medium text-gray-800 truncate">Applicants</dt>
                <dd class="mt-1 text-2xl font-semibold text-gray-700">${applicants}</dd>
              </div>
              <div>
                <dt class="text-sm font-medium text-gray-900 truncate">Spend</dt>
                <dd class="mt-1 text-2xl font-semibold text-gray-700">${spent}</dd></div>
              </div>
             </div>`
          },
        },
        yAxis: {
          name: this.$t('Applicants'),
          nameLocation: 'middle',
          nameTextStyle: {
            padding: [15, 0]
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#67758f',
          },
          splitLine: {
            lineStyle: {
              color: '#e7e7ef'
            }
          },
        },
        grid: {
          left: 40,
          top: 20,
          right: 10,
          bottom: 45,
        },
        series,
      }
    },
    getAnalyticsData(data) {
      const comparedData = sortBy(data?.compared_results?.days || [], 'date')
      data = sortBy(data?.days || [], 'date')
      const series = []
      const defaultSeries = {
        type: 'line',
        showSymbol: false,
        emphasis: {
          focus: 'series',
        },
      }

      function mapItemData(key) {
        return data.map((item, index) => {
          let value = get(item, key) || 0
          let oldValue = get(comparedData, `[${index}].${key}`) || 0
          let newValue = value || 1
          let comparedValue = (newValue - oldValue) / (oldValue || 1) * 100
          return {
            value,
            oldValue,
            comparedValue,
          }
        })
      }

      if (this.activeReportItems.kpi_total) {
        series.push({
          ...defaultSeries,
          name: 'Clicks',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: colors.clicks,
          },
          data: mapItemData('clicks'),
        })
      }
      if (this.activeReportItems.kpi_applicants) {
        series.push({
          ...defaultSeries,
          name: 'Applicants',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: colors.applicants,
          },
          data: mapItemData('applicants'),
        })
      }
      if (this.activeReportItems.kpi_ctr) {
        series.push({
          ...defaultSeries,
          name: 'Conversion',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: colors.conversion,
          },
          data: mapItemData('ctr'),
          yAxisIndex: 1,
        })
      }
      if (this.activeReportItems.kpi_spend) {
        series.push({
          ...defaultSeries,
          name: 'Spend',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: colors.spend,
          },
          data: mapItemData('spend'),
          yAxisIndex: 1,
        })
      }
      if (this.activeReportItems.kpi_cpc) {
        series.push({
          ...defaultSeries,
          name: 'eCPC',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: colors.cpc,
          },
          data: mapItemData('ecpc'),
          yAxisIndex: 1,
        })
      }
      if (this.activeReportItems.kpi_cpa) {
        series.push({
          ...defaultSeries,
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: colors.cpa,
          },
          name: 'eCPA',
          data: mapItemData('ecpa'),
          yAxisIndex: 1,
        })
      }
      this.analyticsData = {
        labels: data.map(item => format(parseISO(item.date), 'MMM dd')),
        series,
      }
      return this.analyticsData
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
