<template>
  <div class="flex items-center" v-bind="$attrs">
    <input :id="cbId"
           :disabled="$attrs.disabled"
           v-model="modelValue"
           :class="{'cursor-not-allowed text-gray-300 bg-gray-200': $attrs.disabled}"
           class="form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out"
           type="checkbox"
           @change="$evt => $emit('update:modelValue', $evt.target.checked)"/>
    <label :for="cbId"
           class="ml-2 mt-1 block text-sm leading-5 text-gray-900 select-none"
           :class="{
              'cursor-not-allowed text-gray-400': $attrs.disabled,
              'cursor-pointer': !$attrs.disabled
           }"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <base-popover v-if="infoText.length" :parent-entity-id="`popover-input-${$attrs.id}`">
      <template v-slot:body>
        <div class="w-48 p-3 text-sm font-medium leading-5 text-gray-700 dark:text-gray-200">
          {{infoText}}
        </div>
      </template>
      <template v-slot:reference>
        <help-circle-icon size="1.1x" class="text-black ml-2 cursor-help"/>
      </template>
    </base-popover>
  </div>
</template>
<script>
import BasePopover from '@/components/common/BasePopover.vue'
import { HelpCircleIcon } from '@zhuowenli/vue-feather-icons'

export default {
  components: { BasePopover, HelpCircleIcon },
  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, String, Number],
      description: 'Switch value'
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: ''
    },
    infoText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cbId: '',
    }
  },
  mounted() {
    this.cbId = Math.random().toString(16).slice(2);
  }
}
</script>
<style>
</style>
