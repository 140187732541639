<template>
  <div class="absolute right-0 top-0 card-actions-dropdown"
       data-html2canvas-ignore="true">
    <base-dropdown :options="options" @option-click="handleOptionClick" dropdownClass="w-56">
      <template v-slot:trigger>
        <button class="mt-5 mr-2 text-gray-600 hover:text-blue-500 p-2">
          <more-vertical-icon class="w-4 h-4"></more-vertical-icon>
        </button>
      </template>
    </base-dropdown>
  </div>
</template>
<script>
import { MoreVerticalIcon } from "@zhuowenli/vue-feather-icons";

export default {
  name: 'report-actions-dropdown',
  components: {
    MoreVerticalIcon,
  },
  props: {
    exportId: {
      type: String,
      default: ''
    },
    exportName: {
      type: String,
      default: 'chart'
    }
  },
  data() {
    const exportToPNG = 'Export as PNG'
    return {
      exportToPNG,
      options: [exportToPNG]
    }
  },
  methods: {
    async handleOptionClick(option) {
      if (option === this.exportToPNG) {
        await this.$exportToPng(`#${this.exportId}`, this.exportName)
      }
    },
  }
}
</script>
<style>
</style>
