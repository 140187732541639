<template>
  <div class="flex items-center md:mr-5 relative group">
    <base-avatar :name="name"
                 :src="avatarImage"
                 size="xl">
    </base-avatar>

    <base-button class="absolute top-0 right-0 mr-6 mt-2 hidden group-hover:block"
                 variant="danger"
                 icon
                 @click="removeFile"
    >
      <Trash2Icon class="w-4 h-4"/>
    </base-button>
    <base-upload-button accept="image/*"
                        classNames="mt-4"
                        @input="onFileUpdate">
      {{ $t(buttonText) }}
    </base-upload-button>
  </div>
</template>

<script>
import axios from 'axios'
import { get } from 'lodash'
import { Trash2Icon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "BaseAvatarUpload",
  components: {
    Trash2Icon,
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Change'
    }
  },
  data() {
    return {
      loading: false,
      photo_url: ''
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    avatarImage() {
      return this.photo_url || this.image
    }
  },
  methods: {
    async onFileUpdate(evt) {
      const files = get(evt, 'target.files', [])

      if (!files.length) {
        return
      }

      let file = files[0]
      this.photo_url = URL.createObjectURL(file)
      await this.uploadAvatarImage(file)
    },
    removeFile() {
      this.photo_url = null
      this.$emit('on-finish', null)
    },
    async uploadAvatarImage(value) {
      let requestDataa = new FormData();
      requestDataa.append('image', value);

      this.loading = true
      try {
        let uploadResult = await axios.post(`/image/upload`, requestDataa, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        let media_image_id = get(uploadResult, 'id', null)

        this.$emit('on-finish', media_image_id)

        this.$success(this.$t('Avatar successfully uploaded!'));
      } catch (error) {
        if (!error.handled) {
          this.$error(this.$t('Something went wrong! Please try again or contact us.'));
        }

      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
