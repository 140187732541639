import JobService from "@/modules/jobs/services/JobService.js";
import { capitalize, isObject } from "lodash";

export default {
  methods: {
    async onSubmit(row, field, fieldValue) {
      let payload

      if (row[field] === fieldValue) {
        return
      }

      payload = {
        [field]: fieldValue
      }

      const res = await JobService.editJobField(row.id, payload)

      if (res) {
        this.$nextTick(() => {
          isObject(this[field]) ? row[field] = fieldValue : this[field] = fieldValue
        })
        this.$success(this.$t(`${capitalize(field)} updated`))

        row[field] = fieldValue

        const popoverRef = this.$refs[`popover-${field}-${row.id}`]
        popoverRef && popoverRef.hidePreview()

        this.circleArrowKey = Math.random()
      } else {
        isObject(this[field]) ? this[field][row.id] = row[field] : this[field] = row[field]
      }
    }
  }
}
