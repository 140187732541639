import GeoService from "@/modules/jobs/services/GeoService";
import axios from "axios";

export default {
  data() {
    return {
      countries: [],
    }
  },
  computed: {
    allCountries() {
      return this.countries
    },
  },
  methods: {
    async fetchCountriesData(query, countries) {
      if (query.length < 1) {
        return
      }

      const language = this.$i18n.locale
      return await GeoService.getCountries({
        location: query,
        language,
        countries,
        type: 'city',
      })
    },
    onCityFieldChange(val, field = 'city') {
      if (val?.label) {
        this.model[field] = val.label
        this.model.lat = val.lat
        this.model.lon = val.lon
      } else {
        this.model[field] = val
        this.model.lat = null
        this.model.lon = null
      }
    },
    async getAllCountries() {
      if (this.countries.length > 0) {
        return
      }
      const data = await axios.post('/type-ahead', {
        type: "location_country",
        query: '',
      })
      for(let key in data) {
        this.countries.push({
          value: key,
          label: data[key]
        })
      }
    }
  },
  async created() {
    await this.getAllCountries()
  }
}
