<template>
  <div class="relative overflow-hidden inline-block cursor-pointer" :class="classNames">
    <base-button :variant="variant">
      <slot></slot>
    </base-button>
    <input v-bind="$attrs"
           type="file"
           name="myfile"
           class="absolute left-0 top-0 right-0 bottom-0 opacity-0 shadow-none"
    />
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: 'white'
    },
    classNames: {
      type: String,
      default: ''
    }
  }
}
</script>
