<template>
  <BaseDataTable
      ref="teamTable"
      :columns="teamTableColumns"
      :show-pagination="false"
      :local-sort="true"
      tableColumnsKey="settings-team-table"
      actions="add,delete"
      url="/setting/users"
      actionType="get"
      @data-fetch="onTableFetchData"
      @add="onAddClick"
  >
    <template v-slot:verified_at="{row}">
      <base-badge :type="row.verified_at ? 'success' : 'danger'">
        {{ row.verified_at ? $t('Verified') : $t('Not verified') }}
      </base-badge>
    </template>
    <template v-slot:pivot.role="{row}">
      <base-popover :parent-entity-id="`popover-team-${row.id}`"
                    :ref="`popover-team-${row.id}`">
        <template v-slot:body>
          <div class="w-32">
            <base-radio v-for="data in UserTypes"
                        v-model="teamRole[row.id]"
                        :key="`radio-teamRole-${row.id}`"
                        :value="data"
                        name="teamRole"
                        :active="data === teamRole[row.id]"
                        @update:modelValue="$evt => onSubmit(row, $evt)"
                        inline>
              {{ $t(data).toLowerCase() }}
            </base-radio>
          </div>
        </template>
        <template v-slot:reference>
          <home-icon
              size="1.5x"
              v-if="row.pivot.role === UserTypes.Owner"
              class="m-auto text-primary-500 cursor-pointer"/>
          <book-open-icon
              v-else-if="row.pivot.role === UserTypes.ReadOnly"
              size="1.5x"
              class="m-auto text-primary-500 cursor-pointer"/>
          <pen-tool-icon
              v-else
              size="1.5x"
              class="m-auto text-primary-500 cursor-pointer"/>
        </template>
      </base-popover>
    </template>
    <template v-if="createMode" v-slot:default>
      <base-form v-bind="$attrs"
                 :save-text="$t('Create')"
                 :cancel-text="$t('Cancel')"
                 grid-classes="flex flex-col"
                 layout="modal"
                 @cancel="createMode = !createMode"
                 @submit="submitUser"
                 show-cancel>
        <div class="w-full">
          <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Invite a new teammate') }}</p>
          <div class="w-full border-b border-gray-200 my-4"></div>
          <div class="flex flex-col lg:flex-row">
            <base-input v-model="model.email"
                        :label="$t('Email')"
                        :placeholder="$t('Email')"
                        :name="$t('Email')"
                        class="w-full"
                        id="email"
                        rules="required|email">
            </base-input>
            <div class="lg:ml-4 w-full">
              <base-input v-model="model.given_name"
                          :label="$t('Name')"
                          :placeholder="$t('Name')"
                          :name="$t('Name')"
                          class="w-full"
                          id="name"
                          rules="required">
              </base-input>
            </div>
          </div>
          <base-select v-model="model.role"
                       :options="roleOptions"
                       :label="$t('Role')"
                       :placeholder="$t('Role')"
                       :name="$t('Role')"
                       id="role"
                       rules="required">
          </base-select>
        </div>
      </base-form>
    </template>
    <template v-slot:extra-actions-before="{row}">
      <base-tooltip v-if="!row.verified_at"
                    :content="$t('Resend invitation')"
                    effect="light">
        <base-button variant="white"
                     @click="resendInvitation(row.id)"
                     size="xs">
          <send-icon />
        </base-button>
      </base-tooltip>
    </template>
  </BaseDataTable>
</template>

<script>
import BasePopover from "@/components/common/BasePopover.vue";
import BaseRadio from "@/components/form/BaseRadio.vue";
import { BookOpenIcon, HomeIcon, PenToolIcon, MessageSquareIcon, SendIcon } from '@zhuowenli/vue-feather-icons'
import SettingsService from "@/modules/dashboard/services/SettingsService";
import formatSelectOptionsMixin from "@/modules/jobs/mixins/formatSelectOptionsMixin.js";
import { UserTypes } from '@/enum/userEnums'

export default {
  name: "TeamSettings",
  components: {
    BasePopover,
    BaseRadio,
    BookOpenIcon,
    HomeIcon,
    PenToolIcon,
    SendIcon
  },
  mixins: [formatSelectOptionsMixin],
  data() {
    return {
      model: {},
      teamRole: {},
      createMode: false,
      UserTypes
    }
  },
  computed: {
    teamTableColumns() {
      return [
        {
          label: this.$t('Name'),
          prop: 'given_name',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Email'),
          prop: 'email',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Status'),
          prop: 'verified_at',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Role'),
          prop: 'pivot.role',
          width: 20,
          align: 'left'
        }
      ]
    },
    roleOptions() {
      return this.formatOptionsData(UserTypes, 'value')
    }
  },
  mounted() {
    this.initModel()
  },
  methods: {
    initModel() {
      this.model = {
        email: null,
        given_name: null,
        role: 'READ-ONLY'
      }
    },
    onTableFetchData(data) {
      data.forEach(data => {
        this.teamRole[data.id] = data.pivot.role
      })
    },
    async onSubmit(row, role) {
      let oldValue = row.pivot.role
      const payload = {
        role: role
      }
      const res = await SettingsService.editTeamRoleSettings(row.id, payload)
      if (res) {
        this.teamRole[row.id] = role
        row.pivot.role = role
        const popoverRef = this.$refs[`popover-team-${row.id}`]
        popoverRef && popoverRef.hidePreview()
        this.$success(this.$t('Succesfully edited team role!'))
      } else {
        this.teamRole[row.id] = oldValue
      }
    },
    onAddClick() {
      this.createMode = !this.createMode
    },
    async submitUser() {
      const res = await SettingsService.addNewTeamUserSettings(this.model)

      if (res) {
        this.$success(this.$t('Successfully created a new team member.'))
        this.createMode = !this.createMode
        const tableRef = this.$refs.teamTable
        tableRef && tableRef.refresh()
        this.initModel()
      }
    },
    async resendInvitation(userId) {
      const res = await SettingsService.resendInvitationTeamMemberSettings(userId)

      if (res) {
        this.$success('Invitation has been sent again')
      }
    }
  }
}
</script>
