<template>
  <div :class="$attrs.class">
    <label v-if="label || $slots.label"
           :for="$attrs.id"
           class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-200 flex items-center flex-wrap">
      <slot name="label">
        {{ label }}
        <span v-if="rules && rules.includes('required')" class="text-gray-500">
            *
        </span>
        <base-popover v-if="infoText.length" :parent-entity-id="`popover-input-${$attrs.id}`">
          <template v-slot:body>
            <div class="w-48 p-3">
              {{infoText}}
            </div>
          </template>
          <template v-slot:reference>
            <help-circle-icon size="1.1x" class="text-black ml-1 cursor-help"/>
          </template>
        </base-popover>
      </slot>
      <span v-if="labelInfo" class="min-w-full flex items-center justify-between text-xs text-gray-400 flex items-center mb-1">
        <span class="flex items-center">
          <info-icon size="1.3x" class="text-black mr-2"></info-icon>
          {{labelInfo}}
        </span>
        <span v-if="maxLength" class="whitespace-nowrap ml-2" :class="{ 'text-red-500' : inputValue?.length >= maxLength }">
          {{inputValue?.length || 0}} / {{maxLength}}
        </span>
      </span>
    </label>

    <slot>
      <div class="relative rounded-md" :class="{ 'mt-1' : showErrors }">
        <span v-if="$slots.prefix"
              class="absolute inset-y-0 left-0 pl-3 flex items-center"
              :class="{'mb-5': !inlineErrors}"
        >
            <span class="text-gray-500 sm:text-sm sm:leading-5">
                <slot name="prefix"></slot>
            </span>
        </span>
        <base-input-error
            :show-errors="showErrors"
            :error="errorMessage"
            :show-tooltip="inlineErrors"
            :help="help"
        >

          <textarea v-if="type === 'textarea'"
                    v-bind="$attrs"
                    :value="inputValue"
                    :key="key"
                    :name="name || label"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                    :class="{
                  'form-input-error': errorMessage,
                  'pl-8': $slots.prefix,
                  'pr-12': hasSuffix,
                  'pr-24': inlineDropdown,
                  'bg-gray-100 dark:bg-gray-700 cursor-not-allowed': $attrs.disabled,
                  'dark:bg-gray-800': !$attrs.disabled && $attrs.readonly === undefined,
                  'cursor-not-allowed bg-gray-100 dark:bg-gray-700 focus:shadow-none focus:border-transparent': $attrs.readonly !== undefined,
                 }"
                    ref="input"
                    class="form-input" />

          <input v-else
                 v-bind="$attrs"
                 :value="inputValue"
                 v-cleave="maskOptions"
                 :type="type"
                 :key="key"
                 :name="name || label"
                 @focus="onFocus"
                 @blur="onBlur"
                 @input="onInput"
                 :class="{
                   [inputClass]: inputClass,
                  'form-input-error': errorMessage,
                  'pl-8': $slots.prefix,
                  'pr-8': hasSuffix,
                  'pr-12': hasSuffix && type === 'number',
                  'pr-24': inlineDropdown,
                  'bg-gray-100 dark:bg-gray-700 cursor-not-allowed': $attrs.disabled,
                  'dark:bg-gray-800': !$attrs.disabled && $attrs.readonly === undefined,
                  'cursor-not-allowed bg-gray-100 dark:bg-gray-700 focus:shadow-none focus:border-transparent': $attrs.readonly !== undefined,
                 }"
                 ref="input"
                 class="form-input"/>
          {{formattedValue}}

        </base-input-error>
        <div v-if="hasSuffix && !errorMessage"
             class="absolute inset-y-0 right-0 pr-3 flex items-center z-10"
             :class="{'mb-5': !inlineErrors}"
        >
                        <span class="text-gray-500 sm:text-sm sm:leading-5">
                            <slot name="suffix"></slot>
                        </span>
          <XCircleIcon v-if="modelValue && clearable"
                       class="w-4 h-4 text-gray-400 cursor-pointer"
                       @click="clearInput"
          />
        </div>

        <div v-if="$slots['prefix-icon']"
             class="absolute inset-y-0 left-0 pl-3 flex items-center">
          <slot name="prefix"/>
        </div>
        <div v-if="$slots['suffix-icon'] || clearable"
             class="absolute inset-y-0 right-0 pr-3 flex items-center">
          <slot name="suffix"></slot>
        </div>

        <div v-if="errorMessage"
             class="absolute inset-y-0 right-0 pb-5 pr-3 flex items-center pointer-events-none z-10">
          <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import { XCircleIcon, InfoIcon, HelpCircleIcon } from '@zhuowenli/vue-feather-icons'
import { useField } from "vee-validate";
import BasePopover from "@/components/common/BasePopover.vue";

export default {
  inheritAttrs: false,
  components: {
    BasePopover,
    HelpCircleIcon,
    XCircleIcon,
    InfoIcon
  },
  props: {
    key: {
      type: String,
      default: '0'
    },
    maxLength: {
      type: [Number, String],
      default: ''
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: [String, Number],
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    labelInfo: {
      type: String,
      default: '',
    },
    infoText: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    inlineErrors: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object, Array],
      default: ''
    },
    formatCurrency: {
      type: Boolean,
      default: false
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const name = props.name || props.label
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.rules, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  data() {
    return {
      error: '',
      formattedValue: '',
    }
  },
  computed: {
    hasSuffix() {
      return this.$slots.suffix || (this.clearable && this.modelValue)
    },
    inlineDropdown() {
      return this.$attrs['inline-dropdown']
    },
    maskOptions() {
      if (this.formatCurrency) {
        return {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }
      }

      return {
        disabled: true
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    onFocus() {
      if (this.type !== 'number') return
      if (parseFloat(this.modelValue) === 0) {
        this.$emit('update:modelValue', '')
      }
    },
    onBlur() {
      this.handleBlur()
      if (this.type !== 'number') {
        return
      }
      if (this.modelValue === '') {
        this.$emit('update:modelValue', 0)
      }
      this.handleBlur()
    },
    onInput(evt) {
      this.$emit('update:modelValue', evt.target.value || null)
      this.handleChange(evt)
    },
    clearInput() {
      this.$emit('update:modelValue', '')
      this.$emit('change', '')
    },
  },
  watch: {
    modelValue(val, oldVal) {
      if (val && this.maxLength && val.length > this.maxLength) {
        this.$emit('update:modelValue', oldVal)
        this.inputValue = oldVal
        return
      }

      if (val !== this.inputValue) {
        this.inputValue = val
      }
    }
  }
}
</script>
