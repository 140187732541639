<template>
  <div class="mt-4">
    <base-form :save-text="$t('Create')"
               :can-submit-form="!maxClientsCreated"
               :fixed-footer="maxClientsCreated"
               grid-classes="flex flex-col"
               @submit="submit"
               layout="modal">
      <div class="w-full mb-2">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Create a new client') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>
      </div>

      <p v-if="maxClientsCreated" class="text-xl text-gray-600 mb-2">{{$t('You have created a maximum number of clients')}}</p>
      <div v-else>
        <div class="mt-4 flex flex-col md:flex-row">
          <base-input v-model="model.name"
                      class="md:mr-4 w-full"
                      :label="$t('Company Name')"
                      :placeholder="$t('Company Name')"
                      :name="$t('Company Name')"
                      id="name"
                      rules="required">
          </base-input>
        </div>

        <base-phone-input :label="$t('Company Phone')"
                          v-model="model.phone"
                          rules="required"
                          :phone-country="''"
                          @input-data="updatePhoneData"/>
      </div>
    </base-form>
  </div>
</template>

<script>
import { get } from "lodash";
import AuthService from "@/modules/auth/services/AuthService.js";

export default {
  name: "client-create",
  data() {
    return {
      model: {
        type: "EMPLOYER",
        name: null,
        phone: null,
        phone_country: null,
      }
    }
  },
  computed: {
    maxClientsCreated() {
      return this.$store.state.auth.allClients.length >= 5
    }
  },
  methods: {
    updatePhoneData(value) {
      this.model.phone_country = get(value, 'activeCountry.iso2', '')
      this.model.phone = get(value, 'number', '')
    },
    async submit() {
      const res = await AuthService.createClient(this.model)

      if (!res) {
        return
      }
      await this.$store.dispatch('auth/refreshProfile', true)
      const totalClients = this.$store.state.auth.allClients.length

      if (totalClients === 0) {
        await this.$notifyDialog({
          title: this.$t('Welcome to Jobmatix.com!'),
          description: this.$t('Welcome to Jobmatix.com!\n\nCongratulations on'),
          buttonText: this.$t('Next Step'),
          wrapperClass: 'md:max-w-2xl lg:max-w-2xl'
        })
      } else if (totalClients > 0) {
        await this.$notifyDialog({
          title: this.$t('Welcome to Jobmatix.com!'),
          description: this.$t('Successfully created new client'),
          buttonText: this.$t('Next Step'),
        })
      }

      this.$router.push('/welcome')
    }
  }
}
</script>

<style scoped>

</style>
