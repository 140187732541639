import store from "@/store/index.js";
import { NL_LOCALE } from "@/i18n.js";

function formatPercent(value, maximumFractionDigits = 2) {
  if (value === null || value === undefined) {
    return '- -'
  }
  let locale = NL_LOCALE
  if (store) {
    locale = store.state?.auth?.user?.active_locale
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits,
  })
  return formatter.format(value)
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$formatPercent = formatPercent
  }
}
