<template>
  <div v-if="defaultFilters.length || customFilters.length"
       class="relative inline-block text-left table-filters">
    <base-tooltip :content="$t('Saved filters')">
      <span class="rounded-md shadow-sm">
        <button @click="toggleDropdown"
                class="inline-flex justify-center w-full border border-gray-300 px-4 filter-button bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
          <svg width="20" height="20" viewBox="0 0 20 20" aria-labelledby="filter"
               role="presentation" class="fill-current text-80">
          <path fill-rule="evenodd"
                d="M.293 5.707A1 1 0 0 1 0 4.999V1A1 1 0 0 1 1 0h18a1 1 0 0 1 1 1v4a1 1 0 0 1-.293.707L13 12.413v2.585a1 1 0 0 1-.293.708l-4 4c-.63.629-1.707.183-1.707-.708v-6.585L.293 5.707zM2 2v2.585l6.707 6.707a1 1 0 0 1 .293.707v4.585l2-2V12a1 1 0 0 1 .293-.707L18 4.585V2H2z"/>
          </svg>
          <svg class="h-5 w-5"
               fill="currentColor"
               viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </button>
      </span>
    </base-tooltip>
    <div v-if="showDropdown"
         class="fixed inset-0 w-screen h-screen z-0"
         @click="hideDropdown">
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
    >
      <div v-if="showDropdown"
           class="origin-top-left absolute left-0 mt-2 filters-dropdown rounded-md shadow-lg z-30">
        <div class="rounded-md bg-white shadow-xs overflow-hidden relative filters-dropdown__content">
          <template v-if="defaultFilters.length">
            <div class="px-4 text-gray-700 font-medium py-2 text-sm">{{ $t('Default Filters')}}</div>
            <div v-for="(filter, index) in defaultFilters"
                 :key="filter.name + index"
                 tabindex="0"
                 @click="onFilterClick(filter)"
                 :class="{'mb-1': index === defaultFilters.length - 1 }"
                 class="filter-item py-1 text-gray-700 border-b px-4 hover:bg-primary-100 cursor-pointer flex justify-between items-center"
            >
              <span>{{ $t(filter.name) }}</span>
              <base-button v-if="!filter.noDelete"
                           variant="danger-link"
                           class="hidden delete-button"
                           @click.stop="removeFilter(filter)">
                {{ $t('Delete') }}
              </base-button>
            </div>
          </template>
          <template v-if="customFilters.length">
            <div class="px-4 text-gray-700 font-medium py-2 text-sm">{{ $t('Saved Filters')}}</div>
            <div v-for="(filter, index) in customFilters"
                 :key="filter.name + index"
                 tabindex="0"
                 @click="onFilterClick(filter)"
                 class="filter-item py-1 text-gray-700 border-b px-4 hover:bg-primary-100 cursor-pointer flex justify-between items-center"
            >
              <span>{{ filter.name }}</span>
              <base-button v-if="!filter.noDelete"
                           variant="danger-link"
                           class="hidden delete-button"
                           @click.stop="removeFilter(filter)">
                {{ $t('Delete') }}
              </base-button>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    entity: String,
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    savedFilters() {
      return this.$store.state.filter.savedFilters[this.entity] || []
    },
    defaultFilters() {
      return this.savedFilters.filter(f => f.noDelete)
    },
    customFilters() {
      return this.savedFilters.filter(f => !f.noDelete)
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    hideDropdown() {
      this.showDropdown = false
    },
    onFilterClick(filter) {
      this.$emit('change', filter.value)
      this.showDropdown = false
    },
    removeFilter(filter) {
      this.$store.commit('filter/REMOVE_FILTER', {
        entity: this.entity,
        filter
      })
    }
  }
}
</script>
<style lang="scss">
.table-filters {
  .filters-dropdown {
    width: 300px;
  }

  .filters-dropdown__content {
    max-height: 50vh;
    @apply overflow-y-auto;
  }

  .filter-button {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .filter-item {
    height: 40px;
  }

  .filter-item:hover {
    .delete-button {
      @apply flex;
    }
  }
}
</style>
