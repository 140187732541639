<template>
  <div>
    <form id="payment-form">
      <slot name="card-element">
        <div id="card-element"></div>
      </slot>
      <slot name="card-errors">
        <div class="text-red-500 text-xs italic min-h-1" id="card-errors" role="alert">
        </div>
      </slot>
      <button ref="submitButtonRef" type="submit"></button>
    </form>
  </div>
</template>

<script>
import config from '@/modules/common/config'

export const STRIPE_CHECKOUT_URL = 'https://js.stripe.com';
export default {
  props: {
    pk: {
      type: String,
      default: config.STRIPE_KEY,
    },
    model: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      stripe: null,
      elements: null,
      card: null,
      stripeScript: null
    }
  },
  computed: {
    style() {
      return {
        base: {
          color: '#2c2c2c',
          lineHeight: '48px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#DE2937',
          iconColor: '#DE2937'
        }
      };
    },
    form() {
      return document.getElementById('payment-form');
    },
    billableDetails() {
      return {
        name: `${this.$store.state.auth.user.given_name} ${this.$store.state.auth.user.family_name}`,
        email: this.$store.state.auth.user.email,
        address_city: this.model.city,
        address_country: this.model.country_code,
        address_line1: this.model.street,
        address_zip: this.model.zip_code,
      };
    }
  },
  methods: {
    loadStripeCheckout(pk, version = 'v3', callback) {
      let e = document.createElement('script');
      e.src = `${STRIPE_CHECKOUT_URL}/${version}`;
      e.type = 'text/javascript';
      this.stripeScript = document.getElementsByTagName('head')[0].appendChild(e);
      e.addEventListener('load', callback);
    },
    submit() {
      this.$refs.submitButtonRef.click();
    },
    async createToken() {
      const res = await this.stripe.createToken(this.card, this.billableDetails);

      return {
        setup: res.token,
        error: res.error
      }
    },
  },
  beforeUnmount() {
    this.card.destroy();
    if (this.stripeScript) {
      document.head.removeChild(this.stripeScript);
    }
  },
  mounted() {
    this.loadStripeCheckout(this.pk, 'v3', () => {
      this.stripe = window.Stripe(this.pk);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', { style: this.style });
      this.card.mount('#card-element');

      this.card.addEventListener('change', ({ error }) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });

      this.form.addEventListener('submit', async (event) => {
        try {
          this.$emit('loading', true);
          event.preventDefault();
          const { setup, error } = await this.createToken();

          if (error) {
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = error.message;
            console.warn(error);
            this.$emit('handleError', error);
          } else {
            this.$emit('paymentMethodCreated', setup);
          }
        } catch (error) {
          console.error(error);
          this.$emit('error 2', error);
        } finally {
          this.$emit('loading', false);
        }
      });
    });
  }
}
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 3rem;
  line-height: 3rem;
  padding: 0 12px;

  @apply rounded border;
  background-color: white;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
