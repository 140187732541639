<template>
  <channel-create-form />
</template>

<script>
import ChannelCreateForm from "@/modules/jobs/components/ChannelCreateForm.vue";

export default {
  name: "channel-create",
  components: {
    ChannelCreateForm
  }
}
</script>
