<template>
  <div class="bg-white shadow-card overflow-hidden">
    <div v-if="title" class="px-2 py-3 sm:px-6 border-t border-b border-gray-200">
      <h3 class="leading-6 font-medium text-gray-900">
        {{ data[title] }}
      </h3>
    </div>
    <div>
      <dl>
        <div v-for="(item, index) in fields"
             :key="index"
             class="bg-white px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 even:bg-gray-100">
          <dt v-if="data?.[index]" class="text-sm font-medium text-gray-500">
            {{item}}
          </dt>
          <dd v-if="data?.[index] && index.includes('budget_daily') ||  index.includes('budget_stopper')" class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{$formatPrice(data?.[index])}}
          </dd>
          <dd v-else-if="data?.[index]" class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ data?.[index] }}
          </dd>
        </div>
        <slot name="extraFields"/>
      </dl>
    </div>
  </div>
</template>
<script>

export default {
  name: 'InfoCard',
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: () => null
    }
  }
}
</script>
