export const UserTypes = {
  Owner: 'OWNER',
  Marketeer: 'MARKETEER',
  ReadOnly: 'READ-ONLY',
};

export const USER_STATUSES = {
  INACTIVE: 'INACTIVE',
  BLOCKED: 'BLOCKED',
  VERIFIED: 'VERIFIED',
  NEW: 'NEW',
};
