import axios from "axios";
import i18n from "@/i18n";

export default {
  async getFinancialSettings() {
    try {
      return await axios.get('/setting/financial');
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getMandates() {
    try {
      return await axios.get('/mandates');
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async saveFinancialSettings(payload) {
    try {
      return await axios.put('/setting/financial', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async saveSepa(payload) {
    try {
      return await axios.post('/mandates/sepa', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async changePaymentType(paymentType) {
    try {
      return await axios.put('/mandates', {
        type: paymentType
      });
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async saveCreditCard(payload) {
    try {
      return await axios.post('/mandates/credit-card', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getMediaBudget(payload) {
    try {
      return await axios.post('/media-budget', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async depositMediaBudget(payload) {
    try {
      return await axios.post('/media-budget/deposit', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async checkoutMediaBudget(token, redirectUrl) {
    try {
      return await axios.post(`/cart/${token}/checkout`, {
        url : redirectUrl
      });
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async verifyCheckoutMediaBudget(token, redirectUrl) {
    try {
      return await axios.get(`/cart/${token}/verify`, {
        url : redirectUrl
      });
    } catch (e) {
      window.app.$error(i18n.t('Payment failed, please try again.'))
    }
  },

  async getSubscriptionList() {
    try {
      return await axios.get('/subscriptions');
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async subscribe(payload) {
    try {
      return await axios.post('/subscriptions/subscribe', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getConfiguration() {
    try {
      return await axios.get('/configuration');
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },
}
