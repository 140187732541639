import { Envs } from '@/modules/common/config'
import SettingsService from "@/modules/dashboard/services/SettingsService"
import DashboardService from "@/modules/dashboard/services/DashboardService"

const types = {
  SET_SAVED_COLUMNS: 'SET_SAVED_COLUMNS',
  SET_TABLE_SETTINGS: 'SET_TABLE_SETTINGS',
  SET_ENV: 'SET_ENV',
  SET_REPORTS_DATE: 'SET_REPORTS_DATE',
};

const state = {
  savedColumns: {},
  tableSettings: {},
  env: import.meta.env.MODE || Envs.DEV,
  reportsDate: {},
  settings: {},
  dashboardKpi: {},
  displayedRemainingBudgetWarning: {},
};

const mutations = {
  setSettings(state, value) {
    state.settings = value;
  },
  setDashboardKpi(state, value) {
    state.dashboardKpi = value;
  },
  setDisplayedRemainingBudgetWarning(state, value) {
    state.displayedRemainingBudgetWarning = value;
  },
  [types.SET_SAVED_COLUMNS]: (state, value) => {
    const { table, columns } = value
    state.savedColumns[table] = columns
  },
  [types.SET_TABLE_SETTINGS]: (state, value) => {
    const { table, setting, val } = value
    if (!state.tableSettings[table]) {
      state.tableSettings = {
        [table] : {
          [setting] : val
        }
      }
    }
    state.tableSettings[table][setting] = val
  },
  [types.SET_ENV]: (state, value) => {
    state.env = value
  },
  [types.SET_REPORTS_DATE]: (state, value) => {
    state.reportsDate = value
  }
};
const actions = {
  async getSettings({ commit }) {
    try {
      const res = await SettingsService.getSettings()
      commit('setSettings', res)
    } catch (e) {
      console.warn(e)
    }
  },
  async getDashboardKpi({ commit }) {
    try {
      const res = await DashboardService.getDashboardKpis()
      commit('setDashboardKpi', res)
    } catch (e) {
      console.warn(e)
    }
  },
};
const getters = {
  accountBudgetStopper: state => state.settings?.account_stopper_budget || 0,
  spentCurrentMonth: state => state.dashboardKpi?.media_budget_spend || 0,
  remainingBudget: (state, getters) => {
    const budgetStopper = getters.accountBudgetStopper
    if (!budgetStopper) {
      return 1
    }
    return budgetStopper - getters.spentCurrentMonth
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
