<template>
  <div class="date-picker__wrapper">
    <el-date-picker
        v-bind="$attrs"
        v-model="model"
        :type="type"
        :clearable="clearable"
        :shortcuts="shortcuts"
        :placeholder="placeholder"
        :format="format"
        :picker-options="{
            firstDayOfWeek: firstDayOfWeek,
          }"
        :disabled-date="disabledDate"
        popper-class="date-picker__wrapper"
    />
  </div>
</template>

<script>
import { ElDatePicker } from 'element-plus'

export default {
  name: "BaseDatePicker",
  inheritAttrs: false,
  components: {
    ElDatePicker
  },
  props: {
    modelValue: [String, Number, Date],
    placeholder: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: 'DD-MM-YYYY'
    },
    valueFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    name: {
      type: [String, Object, Array],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    shortcuts: {
      type: Array,
      default: () => ([]),
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
    type: {
      type: String,
      default: 'date'
    },
    firstDayOfWeek: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
}
</script>

<style lang="scss">
</style>
