<template>
  <div>
    <div v-if="jobLoading"
         v-loading="jobLoading"
         class="h-screen w-full"
    />
    <div v-if="get(job, 'id')">
      <div class="flex flex-col w-full details-header__wrapper">
        <base-tabs v-model="jobTab" class="w-full mb-4 md:mb-0" :items="jobTabs"/>
      </div>
      <div class="flex items-center w-full md:w-auto mt-6">
        <div class="flex items-center">
          <base-popover :parent-entity-id="`popover-status-${this.job.id}`"
                        :ref="`popover-status-${this.job.id}`"
                        :class="{
                          'pointer-events-none': $isReadOnly
                        }"
                        trigger="click">
            <template v-slot:body>
              <div class="w-40">
                <base-radio v-for="item in JOB_STATUSES"
                            v-model="status"
                            :key="`radio-status-${job.id}`"
                            :value="item"
                            :disabled="item === JOB_STATUSES.DRAFT"
                            name="status"
                            :active="item === status"
                            @update:modelValue="$evt => onSubmit(job, 'status', $evt)"
                            inline>
              <span class="capitalize">
                {{ $t(item).toLowerCase() }}
              </span>
                </base-radio>
              </div>
            </template>
            <template v-slot:reference>
              <base-button variant="primary-link" size="xxs" class="pr-4">
                <div class="w-5 h-5 m-auto rounded-full"
                     :class="{
                        'bg-green-400' : job.status === JOB_STATUSES.ACTIVE,
                        'bg-yellow-400' : job.status === JOB_STATUSES.FINISHED,
                        'bg-gray-400' : job.status === JOB_STATUSES.DRAFT,
                      }"/>
              </base-button>
            </template>
          </base-popover>
        </div>
        <div class="w-full flex items-center justify-between">
          <span class="text-xl">{{ job.name }}</span>
          <div class="flex items-center space-x-3">
            <base-tooltip v-if="!$isReadOnly" :content="$t('Clone job')">
              <router-link :to="`/jobs/job-create?copy=${this.job.id}`">
                <base-button variant="white">
                  <copy-icon size="1.5x"/>
                </base-button>
              </router-link>
            </base-tooltip>
            <base-tooltip :content="$t('External job url')">
              <a :href="job.job_url" target="_blank" rel="noopener">
                <base-button variant="white">
                  <external-link-icon size="1.5x"/>
                </base-button>
              </a>
            </base-tooltip>
          </div>
        </div>
      </div>

      <div class="mt-2 mb-6 flex flex-col lg:flex-row">
      <span v-if="job.reference"
            class="text-gray-900 text-sm font-medium flex items-start cursor-pointer hover-buttons whitespace-nowrap"
            @click="$copyToClipboard(job.reference)">
        <clipboard-icon size="1.2x" class="mr-1 hover details-icon"></clipboard-icon>
        {{ job.reference }}
      </span>
        <div class="flex items-start my-3 lg:my-0 lg:ml-4">
          <home-icon size="1.2x" class="mr-1 details-icon"/>
          {{job.organisation_name}}
        </div>
        <span v-if="job.location_city" class="text-gray-900 text-sm font-medium flex items-start mb-3 lg:mb-0 whitespace-nowrap"
              :class="[ job.reference ? 'lg:mx-4' : 'lg:mr-6' ]">
        <map-pin-icon size="1.2x" class="mr-1 details-icon"></map-pin-icon>
        {{ job.location_city }}
      </span>
        <div class="flex items-start min-h-10">
          <JobTags class="text-sm w-full"
                   :max-tags="3"
                   :tags="get(job, 'job_tags', [])"
                   :tagInputRef="`tag-input-0`"
                   :items-to-exclude="addedTags"
                   :placeholder="$t('Tag name...')"
                   @delete="onDeleteTag($event, job)"
                   @onKeydownEnter="onAddTag($event, job)"/>
        </div>
      </div>
      <div v-if="jobTab === 'overview'" class="flex flex-col lg:flex-row justify-between py-2">
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Effective cost per click')">
                  <span>{{ $t('CPC') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ calculateEcpc }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full" v-if="jobTab === 'overview'">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Effective cost for an applicant')">
                  <span>{{ $t('CPA') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ calculateEcpa }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Click through ratio')">
                  <span>{{ $t('CTR') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ calculateCtr }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                <base-tooltip :content="$t('Total spent so far')">
                  <span>{{ $t('Spent') }}</span>
                </base-tooltip>
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ $formatPrice(get(kpi, 'spend')) || '- -' }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
      </div>
      <div v-if="jobTab === 'overview'" class="flex flex-col lg:flex-row justify-between py-2">
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                {{ $t('Views') }}
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ get(kpi, 'views') }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                {{ $t('Free') }}
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ get(kpi, 'free') }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto mb-4 lg:mb-0 lg:mr-4 w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                {{ $t('Clicks') }}
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ get(kpi, 'clicks') }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
        <base-stats-card class="h-auto w-full">
          <template v-slot:content>
            <div class="xl:flex-1 w-full flex flex-col justify-center">
              <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
                {{ $t('Applicants') }}
              </dt>
              <dd class="flex items-baseline justify-start">
                <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                  {{ get(kpi, 'applicants') }}
                </div>
              </dd>
            </div>
          </template>
        </base-stats-card>
      </div>

      <div v-if="jobTab === 'overview'">

        <p class="text-xl mt-10 text-gray-500">{{ $t('Campaigns') }}</p>
        <div class="w-full border-b border-gray-300 my-4"></div>
        <campaigns
            :initial-filters="campaginInitialFilter"
            :url="`/job/${job?.id}/campaign`"
            actions="view,search"
            action-type="get"
            table-columns-key="jobs-detail-campaigns-table"/>

        <p class="text-xl mt-4 text-gray-500">{{ $t('Applicants') }}</p>
        <div class="w-full border-b border-gray-300 my-4"></div>
        <BaseDataTable :columns="applicantsTableColumns"
                       tableColumnsKey="jobs-detail-applicants-table"
                       :initial-filters="initialFilter"
                       actions="search"
                       url="/applicant"
                       ref="applicantsTable"
        >
          <template v-slot:candidate.candidate_phone="{row}">
            <base-tooltip :content="row.candidate.candidate_phone" effect="light">
              <a class="flex items-center truncate" :href="`tel:${row.candidate.candidate_phone}`">
                {{ row.candidate.candidate_phone }}
              </a>
            </base-tooltip>
          </template>
          <template v-slot:candidate.candidate_email="{row}">
            <base-tooltip :content="row.candidate.candidate_email" effect="light">
              <a class="flex items-center truncate" :href="`mailto:${row.candidate.candidate_email}`">
                {{ row.candidate.candidate_email }}
              </a>
            </base-tooltip>
          </template>
          <template v-slot:created_at="{row}">
            {{ $formatDate(row.created_at) }}
          </template>
        </BaseDataTable>
      </div>

      <div v-if="jobTab === 'edit'">
        <job-create-form
            :job="job"
            :loading="jobLoading"
            @changeTab="changeTab"/>
      </div>
      <div v-if="jobTab === 'reports'">
        <job-reports :job-id="job.id"/>
      </div>
    </div>
  </div>
</template>
<script>
import BaseRadio from "@/components/form/BaseRadio.vue";
import BasePopover from "@/components/common/BasePopover.vue";
import JobCreateForm from "@/modules/jobs/components/JobCreateForm.vue";
import JobTags from "@/modules/jobs/components/JobTags.vue";
import JobService from "@/modules/jobs/services/JobService.js";
import jobTagsMixin from "@/modules/jobs/mixins/jobTagsMixin.js";
import jobSubmitFieldMixin from "@/modules/jobs/mixins/jobSubmitFieldMixin.js";
import { JOB_STATUSES } from '@/enum/tableEnums'
import { MapPinIcon, ClipboardIcon, CopyIcon, ExternalLinkIcon, HomeIcon } from '@zhuowenli/vue-feather-icons'
import { get } from 'lodash'
import Campaigns from "@/modules/jobs/pages/campaigns.vue";
import JobReports from "@/modules/reports/pages/job-reports.vue";

export default {
  components: {
    Campaigns,
    BaseRadio,
    JobTags,
    JobReports,
    BasePopover,
    JobCreateForm,
    MapPinIcon,
    ClipboardIcon,
    ExternalLinkIcon,
    CopyIcon,
    HomeIcon
  },
  mixins: [jobTagsMixin, jobSubmitFieldMixin],
  data() {
    return {
      job: null,
      kpi: null,
      status: null,
      jobTab: this.$route.query.tab || 'overview',
      JOB_STATUSES,
      jobLoading: false,
    }
  },
  computed: {
    jobTabs() {
      return this.$isReadOnly ? ['overview', 'reports'] : ['overview', 'edit', 'reports']
    },
    filterTags() {
      return this.$store.state.settings.tableSettings['jobs-table']?.filterTagAutocomplete ?? true
    },
    addedTags() {
      return this.filterTags ? get(this.job, 'job_tags', []) : []
    },
    calculateEcpc() {
      const result = get(this.kpi, 'spend') / get(this.kpi, 'clicks')
      return result && result !== Infinity ? this.$formatPrice(result) : '- -'
    },
    calculateEcpa() {
      const result = get(this.kpi, 'spend') / get(this.kpi, 'applicants')
      return result && result !== Infinity ? this.$formatPrice(result) : '- -'
    },
    calculateCtr() {
      const result = get(this.kpi, 'applicants') / get(this.kpi, 'clicks')
      return result && result !== Infinity ? this.$formatPercent(result.toFixed(2)) : '- -'
    },
    initialFilter() {
      return [
        {
          field: 'job_id',
          operator: 'IS',
          value: this.job.id
        }
      ]
    },
    campaginInitialFilter() {
      return [
        {
          field: 'status',
          operator: 'IS',
          value: 'ACTIVE'
        }
      ]
    },
    applicantsTableColumns() {
      return [
        {
          label: this.$t('Candidate name'),
          prop: 'candidate.candidate_name',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Email'),
          prop: 'candidate.candidate_email',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Candidate phone'),
          prop: 'candidate.candidate_phone',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Apply date'),
          prop: 'created_at',
          minWidth: 120,
          sortable: true,
          align: 'left'
        }
      ]
    }
  },
  methods: {
    get,
    changeTab(val) {
      this.jobTab = val
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        if (val.path.indexOf('jobs') === -1) {
          return
        }

        const jobId = val.params.id

        if (jobId) {
          try {
            this.jobLoading = true
            this.job = await JobService.getJob(jobId)
            this.jobLoading = false
            this.kpi = await JobService.getJobKpi(jobId)
            this.status = this.job.status
          } finally {
            this.jobLoading = false
          }
        }
      }
    },
    jobTab(val) {
      this.$router.push({
        query: {
          tab: val,
          title: this.$route.query.title
        }
      })
    }
  }
}
</script>
<style lang="scss">
.details-header__wrapper {
  .base-select {
    @apply w-28;
  }
}
.details-icon {
  --size: 18px;
  min-width: var(--size);
  min-height: var(--size);
  width: var(--size);
  height: var(--size);
  margin-top: 2px;
}

.hover-buttons {
  &:hover {
    svg.hover {
      @apply text-primary-500;
    }
  }
}
</style>
