<template>
  <div class="-mt-10">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedOption: '',
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        if (val.path.indexOf('reports') === -1) {
          return
        }
        const parts = val.path.split('/')
        const lastPart = parts[parts.length - 1]
        if (lastPart) {
          this.selectedOption = lastPart
        }
      }
    },
    selectedOption: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }

        this.$router.push({
          path: `/reports/${val}`
        })
      }
    }
  }

}
</script>
