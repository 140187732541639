<template>
  <div class="flex flex-col">
    <label :for="label || $slots.label"
           class="block text-sm font-medium leading-5 text-gray-700">
      <slot name="label">
        {{ label }}
        <span v-if="rules && rules.includes('required')"
              class="text-gray-500">
              *
        </span>
      </slot>
      <span v-if="labelInfo" class="block text-xs text-gray-400 flex items-center mb-2">
          <info-icon size="1.3x" class="text-black mr-2"></info-icon>
        {{labelInfo}}
      </span>
    </label>
    <div class="flex flex-wrap" v-bind="$attrs">
      <div v-for="(option, index) in options"
           class="base-amount__option mr-2 mt-2"
           :class="option.class">
        <base-button
            :key="index"
            :id="`amount-${option.label}`"
            :variant="selectedOption?.toString() === (option?.value?.toString() ?? option.label.toString()) ? this.activeBudgetVariant : 'secondary'"
            class="text-sm font-medium min-w-28"
            @click.prevent="onSelectAmount(option)">
          {{ getTextLabel(option) }}
        </base-button>
      </div>
    </div>
  </div>
  <base-input-error :error="errorMessage"
                    :show-tooltip="inlineErrors">
    <input v-model="selectedOption"
           :key="key"
           :name="name || label"
           ref="input"
           class="hidden"/>
  </base-input-error>
</template>
<script>
import localeCurrencyMixin from "@/modules/jobs/mixins/localeCurrencyMixin.js";
import { useField } from "vee-validate";
import { InfoIcon } from '@zhuowenli/vue-feather-icons'

export default {
  inheritAttrs: false,
  mixins: [localeCurrencyMixin],
  emits: ['onChangeOption'],
  components: {
    InfoIcon
  },
  props: {
    modelValue: [String, Number, Array],
    options: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    key: {
      type: String,
      default: '0'
    },
    monthlyJobBudget: {
      type: Number,
      default: 0,
    },
    rules: {
      type: [String, Object, Array],
      default: 'required'
    },
    inlineErrors: {
      type: Boolean,
      default: false,
    },
    labelInfo: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const name = props.name || props.label
    const {
      value: selectedOption,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.rules, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      selectedOption,
      meta,
    };
  },
  computed: {
    activeBudgetVariant() {
      if (this.monthlyJobBudget === 0) {
        return 'primary'
      }
      if (this.monthlyJobBudget < 1) {
        return 'danger'
      }
      return this.monthlyJobBudget < 2 ? 'yellow' : 'green'
    },
  },
  methods: {
    onSelectAmount(option) {
      this.selectedOption = option.value ?? option.label
      this.$emit('onChangeOption', option)
    },
    getTextLabel(option) {
      const label = option.label ?? option.value;
      return isNaN(parseInt(label)) ? label : this.getLocaleCurrencySign + label
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.selectedOption = this.option[0].value ?? this.option[0].label
          return
        }

        const amount = Number(val).toFixed(0)
        const findOption = this.options.find(o => {
          const value = o.value ?? o.label;
          return value.toString() === amount?.toString()
        }) || ''

        const findOptionValue = (findOption.value ?? findOption.label);

        if (typeof findOptionValue !== 'undefined') {
          this.selectedOption = amount
        } else {
          this.selectedOption = this.$t('Other')
          this.$emit('onChangeOption', {
            label: this.$t('Other')
          })
        }
      }
    }
  }
}
</script>
