<template>
  <div class="flex flex-col bg-gray-50 dark:bg-gray-800 h-screen">
    <div class="w-full z-50 shadow">
      <EnvironmentChange hide-buttons />
      <AnnouncementBanners />
    </div>
    <div class="flex-col-grow">
      <DashboardNavbar />
        <div class="overflow-y-auto relative flex-col-grow mx-auto w-full sm:px-6 lg:px-8 bg-gray-50" id="scrollable-layer">
          <DashboardPageHeader/>
          <div class="flex-col-grow">
            <RouterView />
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import DashboardPageHeader from "@/modules/dashboard/components/DashboardPageHeader.vue";
import DashboardNavbar from "@/modules/dashboard/components/DashboardNavbar.vue";
import EnvironmentChange from "@/components/EnvironmentChange.vue";
import AnnouncementBanners from '@/components/AnnouncementBanners.vue'

export default {
  components: {
    AnnouncementBanners,
    DashboardNavbar,
    DashboardPageHeader,
    EnvironmentChange,
  },
  computed: {
    theme() {
      return this.$store.state.theme?.theme
    },
    selectedClient() {
      return this.$store.state.auth.selectedClient || {}
    },
    remainingBudget() {
      return this.$store.getters['settings/remainingBudget']
    },
    displayedRemainingBudgetWarning() {
      return this.$store.state.settings.displayedRemainingBudgetWarning
    },
  },
  watch: {
    async remainingBudget(val) {
      if (val > 0 || this.displayedRemainingBudgetWarning?.[this.selectedClient.id]) {
        return
      }
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Your account budget has been depleted and your campaigns have been paused until the start of the next month. Increase the account budget to reactivate the campaigns.'),
        deleteText: this.$t('Increase budget'),
        type: 'primary',
      })
      this.$store.commit('settings/setDisplayedRemainingBudgetWarning', {
        ...this.displayedRemainingBudgetWarning,
        [this.selectedClient.id]: true,
      })

      if (!confirmed) {
        return
      }

      await this.$router.push('/finance/account%20budget%20stopper')
    },
  },
  methods: {
    initFeedbackScript() {
      try {
        const exceptionEmails = ['exenzo', 'jobboost', 'binarcode']
        const isExceptionEmail = exceptionEmails.some(email => this.$user.email.includes(email))
        if (!isExceptionEmail || window.Userback){
          return
        }
        window.Userback = window.Userback || {};
        Userback.access_token = '32156|58575|VKX4kvzvC8Sq8kxvLCuV9fsTM';
        (function (d) {
          var exceptions = ['localhost']
          var isException = exceptions.some(host => window.location.host.includes(host))
          if (isException) {
            return
          }
          var s = d.createElement('script');
          s.async = true;
          s.src = 'https://static.userback.io/widget/v1.js';
          (d.head || d.body).appendChild(s);
        })(document);
      } catch (err) {
        console.warn(err)
      }
    }
  },
  async created() {
    const selectedUser = this.$store.state.auth.user
    const selectedClient = this.$store.state.auth.selectedClient
    this.initFeedbackScript()
    if (window.Beacon) {
      window.Beacon('init', '3d65091d-4d6f-45eb-b585-5300104eb41f')
      window.Beacon('identify', {
        name: selectedUser?.given_name,
        email: selectedUser?.email,
        company: selectedClient?.name
      });
    }
    await this.$store.dispatch('auth/refreshProfile', true)
    this.$store.commit('filter/MERGE_DEFAULT_FILTERS')
    await this.$store.dispatch('settings/getSettings')
    await this.$store.dispatch('settings/getDashboardKpi')
  },
  beforeUnmount() {
    window.Beacon('destroy')
  },
}
</script>
