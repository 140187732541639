import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

export function initSentry(app, router) {
  Sentry.init({
    app,
    dsn: "https://b4bd220c6fe74fa29e579c1d9c0f537f@o20540.ingest.sentry.io/5857108",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["app.stg.jobboost.io", "app.jobboost.io", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
