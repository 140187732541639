<template>
  <BaseNoPermissions v-if="$isReadOnly" />
  <div v-else>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'CampaignLayout',
  data() {
    return {
      selectedOption: this.$route.path,
    }
  }
}
</script>
