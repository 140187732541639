<template>
  <button type="button"
          class="close-button"
          aria-label="Close"
  >
    <i
        class="close-icon el-icon el-icon-close"
        :class="{ 'icon-small': size === 'small' }"
    />
  </button>
</template>
<script>
export default {
  name: 'close-button',
  props: {
    size: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
.close-button {
  @apply p-1 rounded leading-none hover:bg-gray-100;

  .close-icon {
    @apply text-2xl font-semibold text-gray-500;
  }

  .icon-small {
    @apply text-lg font-semibold text-gray-500;
  }
}
</style>
