<template>
  <div v-if="$isDevEnv() && !isClosed"
       class="py-2 flex justify-center items-center"
       :class="{
          'bg-blue-100': currentEnv === Envs.DEV,
          'bg-green-100': currentEnv === Envs.STG,
          'bg-red-100': currentEnv === Envs.PROD,
       }"
  >
    <div>
      {{$t('You are running on')}}
      <span class="capitalize font-bold"
            :class="{
                'text-blue-500': currentEnv === Envs.DEV,
                'text-green-500': currentEnv === Envs.STG,
                'text-red-500': currentEnv === Envs.PROD,
            }"
      >{{currentEnv}}</span>
      {{$t('environment')}}
    </div>
    <template v-if="!hideButtons">
      <template v-if="currentEnv === Envs.DEV">
        <base-button class="ml-5" variant="green" @click="setEnvUrl(Envs.STG)">{{$t('Switch to Staging')}}</base-button>
      </template>
      <template v-if="currentEnv === Envs.STG">
        <base-button class="ml-5" variant="danger" @click="setEnvUrl(Envs.PROD)">{{$t('Switch to Production')}}</base-button>
      </template>
      <template v-if="currentEnv === Envs.PROD">
        <base-button class="ml-5" variant="primary" @click="setEnvUrl(Envs.DEV)">{{$t('Switch to Development')}}</base-button>
      </template>
    </template>
    <close-button
        class="absolute right-5"
        :class="{
          'top-3': !hideButtons,
          'top-1.5': hideButtons,
        }"
        size="small"
        @click="isClosed = true"
    />
  </div>
</template>
<script>
import { setEnvUrl } from "@/modules/common/apiConfig";
import CloseButton from "@/components/common/CloseButton.vue";
import { Envs } from '@/modules/common/config'

export default {
  components: {
    CloseButton
  },
  props: {
    hideButtons: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isClosed: false,
      Envs
    }
  },
  computed: {
    currentEnv() {
      return this.$store.state.settings.env
    },
  },
  methods: {
    setEnvUrl,
  },
}
</script>
