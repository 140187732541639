export const PAYMENT_TYPES = {
  SEPA: 'SEPA',
  CREDIT_CARD: 'CREDIT-CARD',
  BANK_TRANSFER: 'BANK-TRANSFER',
};

export const CLIENT_PAYMENT_TYPES = {
  USAGE: 'USAGE',
  MEDIA_BUDGET: 'MEDIA-BUDGET',
}
