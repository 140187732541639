<template>
  <base-badge :type="badgeType"
              class="whitespace-nowrap status-badge flex items-center relative cursor-pointer"
              :class="{
                        'capitalize' : capitalize,
                        'pointer-events-none': $isReadOnly
                      }"
              @click="$emit('delete')">
    <slot>
      <div v-if="closeIcon" class="hidden delete-text text-red-400 absolute inset-0 flex justify-center items-center">
        {{ formattedStatus.length < 6 ? $t('Del') : $t('Delete') }}
      </div>
      <div :class="{ 'status-text' : closeIcon }">
        {{ $t(formattedStatus) }}
      </div>
    </slot>
  </base-badge>
</template>
<script>
import BaseBadge from './BaseBadge.vue';
const badgeTypes = {
  More: 'more',
  Less: 'less',
}
export default {
  name: 'status-badge',
  components: {
    BaseBadge
  },
  props: {
    status: {
      type: [String, Number, Boolean],
      required: true
    },
    type: String,
    closeIcon: Boolean,
    capitalize: {
      type: Boolean,
      default: true
    }
  },
  emits: ['delete'],
  data() {
    return {
      successStates: ['active', 'paid', 'sent', 'notice', 'success', 'new', 'yes', 'enabled', 'posted', 'add', 'add tag', 'add city', 'add title'],
      dangerStates: ['blocked', 'inactive', 'emergency', 'critical', 'error', 'rejected', 'no', 'disabled', 'high'],
      grayStatuses: ['recurring', 'low'],
    }
  },
  computed: {
    badgeType() {
      if (this.type === badgeTypes.More || this.type === badgeTypes.Less) {
        return 'default'
      }
      if (typeof this.status !== 'string') {
        return 'info'
      }
      if (this.successStates.includes(this.status.toLowerCase())) {
        return 'success'
      }
      if (this.dangerStates.includes(this.status.toLowerCase())) {
        return 'danger'
      }
      if (this.grayStatuses.includes(this.status.toLowerCase())) {
        return 'default'
      }
      return 'info'
    },
    formattedStatus() {
      if (this.status === true) {
        return this.$t('yes')
      } else if (this.status === false) {
        return this.$t('no')
      }
      return this.status
    }
  }
}
</script>
<style scoped lang="scss">
.status-badge {
  min-width: auto;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;

  &:hover {
    .delete-text {
      display: flex;
    }

    .status-text {
      visibility: hidden;
    }
 }
}
</style>
