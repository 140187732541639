import { defineRule, configure } from 'vee-validate';
import * as rules from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import i18n, { DEFAULT_LOCALE, NL_LOCALE } from "@/i18n.js";

export default {
  install() {
    configure({
      generateMessage: localize({
        [DEFAULT_LOCALE]: en,
        [NL_LOCALE]: nl,
      })
    })
    Object.keys(rules).forEach(rule => {
      defineRule(rule, rules[rule]);
    });

    defineRule('min_val', (value, [min]) => {
      if(!value && String(value) !== '0') return true;
      return Number(value) >= Number(min) ? true : i18n.t('This field must be at least {min}', { min });
    })

    defineRule('confirmed', (value, [target]) => {
      if (value === target) {
        return true;
      }
      return i18n.t('Passwords must match');
    });

    defineRule('email', value => {
      if (!value || /(^$)|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return true
      }
      return i18n.t('This field must be a valid email');
    });

    defineRule('url', value => {
      if (!/(^$)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(value)) {
        return i18n.t('This field must be a valid url');
      }
      return true;
    });
  }
}
