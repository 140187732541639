<template>
  <div>
    <layout-tabs :items="items"></layout-tabs>

    <div class="py-4">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedOption: this.$route.path,
      items: [
        {
          name: 'Edit profile',
          path: '/profile/edit',
        },
        {
          name: 'Export data',
          path: '/profile/export',
        },
      ]
    }
  }
}
</script>
