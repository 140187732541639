import { UserTypes } from "@/enum/userEnums.js";

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $user() {
          return this.$store.state.auth.user
        },
        $client() {
          return this.$store.state.auth.selectedClient
        },
        $isOwner() {
          return this.$store.state.auth?.user?.role === UserTypes.Owner
        },
        $isAdmin() {
          return this.$store.state.auth?.user?.admin === true
        },
        $isMarketeer() {
          return this.$store.state.auth?.user?.role === UserTypes.Marketeer
        },
        $isReadOnly() {
          return this.$store.state.auth?.user?.role === UserTypes.ReadOnly
        },
        $canCreateCampaigns() {
          return this.$isAdmin || this.$isOwner || this.$isMarketeer
        },
      }
    })
  }
}
