<template>
  <report key="jobs"
          :query="reportQuery"
          v-slot="{data, loading}">
    <BaseDataTable
        :title="$t('Jobs')"
        :class="$attrs.class"
        :columns="jobsColumns"
        :local-pagination="true"
        :local-search="true"
        :local-sort="true"
        :default-sort="{'kpi_spend': 'desc'}"
        :per-page="10"
        :data="data.jobs || []"
        actions="search"
        tableColumnsKey="report-jobs"
    >
      <template v-slot:job="{row}">
        <div :title="row.job" class="truncate">
          <router-link :to="`/jobs/${row.job_id}?tab=overview`" class="hover:underline">
            <span class="text-gray-700 font-normal w-10">
              {{ row.job }}
            </span>
          </router-link>
        </div>
      </template>
    </BaseDataTable>
  </report>
</template>
<script>
import format from "date-fns/format";
import Report from "@/components/report/Report.vue";

export default {
  components: {
    Report,
  },
  props: {
    from: {
      type: [String, Date],
      default: ''
    },
    till: {
      type: [String, Date],
      default: ''
    },
    extraParams: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formattedRange() {
      return {
        date_from: format(this.from, 'yyyy-MM-dd'),
        date_till: format(this.till, 'yyyy-MM-dd'),
        date_range: 'custom'
      }
    },
    reportQuery() {
      let baseReport = {
        type: 'jobs',
        'params[date_interval]': 'day',
        buckets: 500,
        ...this.formattedRange,
      }
      if (this.extraParams) {
        baseReport = {
          ...baseReport,
          ...this.extraParams
        }
      }
      return baseReport
    },
    jobsColumns() {
      return [
        {
          label: this.$t('Title'),
          prop: 'job',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('Clicks'),
          prop: 'clicks',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('Applicants'),
          prop: 'applicants',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('Spent'),
          prop: 'spend',
          component: 'FormattedPrice',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('CPA'),
          prop: 'ecpa',
          component: 'FormattedPrice',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('CPC'),
          prop: 'ecpc',
          component: 'FormattedPrice',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('Location'),
          prop: 'location_name',
          maxWidth: 150,
          sortable: true,
        },
        {
          label: this.$t('CR'),
          prop: 'ctr',
          labelInfo: this.$t('Conversion Rate'),
          component: 'FormattedPercent',
          maxWidth: 120,
          sortable: true,
        },
      ]
    }
  }
}
</script>
