import DashboardLayout from "@/modules/dashboard/layout/DashboardLayout.vue";
import Welcome from './pages/welcome.vue'
import BudgetCreate from './pages/budget-create.vue'
import Settings from './pages/settings.vue'
import Finance from './pages/finance.vue'
import Support from './pages/support.vue'
import ContactUs from './pages/contact-us.vue'
import NotFound from '@/modules/auth/pages/404.vue'
import NotAuthorized from '@/modules/auth/pages/403.vue'
import FinanceLayout from "./layout/FinanceLayout.vue";
import ClientCreate from "./pages/client-create.vue";
import SubcriptionCreate from "./pages/subscription-create.vue";
import ChannelLayout from "@/modules/jobs/layout/ChannelLayout.vue";
import ChannelsList from "@/modules/jobs/pages/channel.vue";
import ChannelDetails from "@/modules/jobs/pages/channel-details.vue";
import ChannelCreate from "@/modules/jobs/pages/channel-create.vue";

function getAllModuleRoutes() {
  const files = import.meta.globEager('../**/routes.js')
  const exceptions = ['auth', 'dashboard'] // we don't want to include these modules here
  let fileKeys = Object.keys(files)
  fileKeys = fileKeys.filter(pathName => {
    return exceptions.every(exception => !pathName.includes(exception))
  })
  const flatRoutes = []
  fileKeys.forEach(key => {
    flatRoutes.push(...files[key].default)
  })
  return flatRoutes
}

const allModuleRoutes = getAllModuleRoutes()
export default [
  {
    path: '/',
    name: 'Home',
    title: 'Home',
    redirect: '/welcome',
    component: DashboardLayout,
    children: [
      {
        path: 'welcome',
        name: 'Welcome page',
        title: 'Welcome',
        description: 'Find the most relevant information for you in the application dashboard.',
        component: Welcome,
        meta: {
          requiresClient: true,
          hideBreadCrumb: true,
        }
      },
      {
        path: 'settings',
        name: 'Settings',
        title: 'Settings',
        description: 'App settings page.',
        component: Settings,
        meta: {
          requiresClient: true,
          requiresOwnerRole: true
        },
        children: [
          {
            path: ':id',
            name: 'Settings item',
            component: Settings
          },
          {
            path: 'channel',
            name: 'Channel',
            redirect: '/settings/channel/all',
            component: ChannelLayout,
            children: [
              {
                path: 'all',
                name: 'All Channels',
                title: 'All Channels',
                description: 'Check out information about your channel',
                component: ChannelsList,
                meta: {
                  hideBreadCrumb: true,
                }
              },
              {
                path: 'channel-create',
                name: 'Create Channel',
                title: 'Create Channel',
                description: 'Create a channel',
                component: ChannelCreate,
              },
              {
                path: ':id',
                name: 'Channel Details',
                description: 'Channel Details',
                component: ChannelDetails
              },
            ]
          },
        ]
      },
      {
        path: 'finance',
        name: 'Finance',
        title: 'Finance',
        description: 'Finance settings page.',
        redirect: '/finance/general',
        component: FinanceLayout,
        meta: {
          requiresOwnerRole: true,
          requiresClient: true
        },
        children: [
          {
            path: 'budget-create',
            name: 'Create media budget',
            title: 'Create media budget',
            description: 'Create a new media budget',
            component: BudgetCreate,
          },
          {
            path: ':id',
            name: 'Finance item',
            component: Finance
          }
        ]
      },
      {
        path: 'client-create',
        name: 'Create Client',
        title: 'Create Client',
        description: 'Create a new client',
        component: ClientCreate
      },
      {
        path: 'subscription-create',
        name: 'Create Subscription',
        title: 'Create Subscription',
        description: 'Create a new subscription',
        component: SubcriptionCreate,
        meta: {
          requiresClient: true
        },
      },
      {
        path: 'support',
        name: 'Support',
        title: 'Support',
        description: 'Find the most relevant information for you in the help center.',
        component: Support
      },
      {
        path: 'contact-us',
        name: 'Contact Us',
        title: 'Contact Us',
        description: 'Contact us if you have any questions',
        component: ContactUs,
      },
      ...allModuleRoutes,
      {
        path: '/403',
        name: 'Not Authorized',
        component: NotAuthorized
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        component: NotFound
      },
    ]
  }
]
