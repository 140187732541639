<template>
  <base-select :model-value="selectedClient.id"
               :options="clientValues"
               @update:modelValue="onClientChange"
               :placeholder="$t('Select Organisation')"
  />
</template>

<script>
export default {
  name: 'organization-select',
  computed:{
    selectedClient() {
      return this.allClients.find(c => c.active) || {}
    },
    allClients() {
      return this.$store.state.auth.allClients
    },
    clientValues() {
      return this.allClients.map(c => {
        return {
          value: c.id,
          label: c.name,
        }
      })
    }
  },
  methods: {
    async onClientChange(clientId) {
      if (clientId.startsWith('/')) {
        await this.$router.push(clientId)
      } else {
        await this.$store.dispatch('auth/switchClient', clientId)
        if (this.$route.path === '/welcome') {
          await this.$router.go(0)
        } else {
          await this.$router.push('/welcome')
        }
      }
    }
  },
}
</script>

<style>

</style>
