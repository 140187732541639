import store from "@/store";

function extractTextInBrackets(text) {
  return text.replace(/^.*\(([^)]+)\).*$/, "$1");
}

function removeBracketedText(text) {
  return text.replace(/ *\([^)]*\) */g, "");
}

// countryName example: Belgium (België) -> it comes from the BE
export function getCountryNameByLocale(countryName) {
  const { active_locale } = store?.state?.auth?.user
  return active_locale === 'nl-NL' ? extractTextInBrackets(countryName) : removeBracketedText(countryName)
}
