<template>
  <div class="relative inline-block text-left manage-columns"
       ref="el"
       :class="{
          'less-data': data?.length < 10
       }"
  >
    <base-tooltip :content="$t('Manage Columns')">
      <span class="rounded-md">
        <button @click="toggleDropdown"
                class="inline-flex items-center justify-start w-full bg-transparent text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
            <svg data-v-7d0c7aee="" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="h-6 w-6 feather feather-columns">
                <path data-v-7d0c7aee=""
                      d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18">
                </path>
            </svg>
            <svg class="h-5 w-5"
                 fill="currentColor"
                 viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </button>
      </span>
    </base-tooltip>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
    >
      <div v-if="showDropdown"
           class="origin-top-right absolute right-0 mt-1 columns-dropdown rounded-md shadow-lg z-10"
           :style="dropdownStyle"
      >
        <div class="rounded-md bg-white shadow-xs overflow-hidden relative">
          <div v-for="(column, index) in columnsData"
               :key="index"
               tabindex="0"
               class="h-10 py-1 text-gray-700 border-b px-4 hover:bg-primary-100 cursor-pointer flex justify-between items-center">
            <base-checkbox v-model="column.checked"
                           :label="column.label"
                           @input="updateColumn(column)">
              <template v-slot:label>
                <div class="w-32 finline-flex text-sm text-gray-900 font-medium capitalize">
                  {{ column.label }}
                </div>
              </template>
            </base-checkbox>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useElementBounding } from "@/composables/useElementBoundingRect.js";

export default {
  name: 'manage-columns',
  components: {},
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    savedColumns: {
      type: Array,
      default: () => [],
    },
    tableKey: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    }
  },
  setup() {
    const el = ref(null)
    const data = useElementBounding(el)
    return {
      el,
      top: data.top,
    }
  },
  data() {
    return {
      showDropdown: false,
      columnsData: []
    }
  },
  computed: {
    dropdownStyle() {
      let top = this.top
      let offset = 30
      top = top + offset
      return {
        top: `${top}px`,
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    updateColumn(column) {
      const columnToUpdate = this.savedColumns.find(c => c.prop === column.prop)
      if (columnToUpdate) {
        columnToUpdate.checked = !columnToUpdate.checked
      } else {
        column.checked = !column.checked
        column.order = this.savedColumns.length + 1
        this.savedColumns.push(column)
      }
      this.$store.commit('settings/SET_SAVED_COLUMNS', {
        table: this.tableKey,
        columns: this.savedColumns
      })
      this.$emit('update-columns', this.savedColumns)
    },
    initData() {
      this.columnsData = this.columns.map(column => {
        let savedColumn = this.savedColumns.find(el => el.prop === column.prop)
        if (column.hide !== undefined && !savedColumn.checked) {
          column.checked = !column.hide
          return column
        }
        column.checked = savedColumn?.checked
        return column
      })
    }
  },
  watch: {
    savedColumns() {
      this.initData()
    },
  },
  mounted() {
    this.initData()
  }
}
</script>
<style scoped>
.columns-dropdown {
  @apply fixed mt-2;
  max-height: 300px;
  overflow-y: auto;
  right: 50px;
}
</style>
