import axios from 'axios'
import config from './config'
import store from '@/store'
import AuthService from '@/modules/auth/services/AuthService'
import { currentLocale } from '@/i18n'

export const statusCodesToHandle = [400, 403, 422];

export function setEnvUrl(env = config.Envs.DEV) {
  if (!config.API_HOSTS[env]) {
    return
  }
  axios.defaults.timeout = env === config.Envs.PROD ? 10000 : 20000
  axios.defaults.baseURL = config.API_HOSTS[env]
  store.commit('settings/SET_ENV', env)
}

setEnvUrl(store.state.settings.env || 'staging')

export function successInterceptor(response) {
  return response.data;
}

function isUnauthorized(status) {
  const isOnSecuredRoute = window.app.$route.matched.some(record => record.meta.requiresClient)
  return status === 401 && isOnSecuredRoute
}

export async function errorInterceptor(error) {
  if (axios.isCancel(error)) {
    console.log('Request canceled', error.message);
    error.handled = true
    return
  }
  if (!error.response) {
    console.log(error)
    return
  }
  const { status } = error.response;
  let errors = '';

  if (statusCodesToHandle.includes(status)) {
    errors = mapErrors(error.response.data);

    if (window.app.$notifications.state.length === 0) {
      window.app.$notify({ type: 'danger', message: errors, once: true });
      error.handled = true
    }
  }

  error.errors = errors;

  if (isUnauthorized(status)) {

    AuthService.logout();
    await window.app.$router.push('/login');

    return Promise.reject(error);
  }
  return Promise.reject(error);
}

export function requestInterceptor(config) {
  const token = AuthService.getToken();
  let locale = currentLocale
  if (store) {
    locale = store.state?.auth?.user?.active_locale
  }
  if (!config.headers['Authorization'] && token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  config.headers.locale = locale
  return config;
}

function mapErrors(data) {
  let errors = '';

  if (!data.errors && data.message || data.messages) {
    return data.message || data.messages[0]
  }
  if (Array.isArray(data.errors)) {
    const hasStringErrors = typeof data.errors[0] === 'string'

    if (hasStringErrors) {
      return data.errors[0]
    }

    data.errors = data.errors[0]
  }
  for (let err in data.errors) {
    let errorRow = data.errors[err].join('</br>');
    if (!errorRow.endsWith('</br>')) {
      errorRow += '</br>';
    }
    errors += errorRow;
  }
  return errors;
}

axios.interceptors.response.use(successInterceptor, errorInterceptor);
axios.interceptors.request.use(requestInterceptor);
