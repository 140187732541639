import ChannelService from "@/modules/jobs/services/ChannelService";

export default {
  methods: {
    async toggleChannelActivity(row) {
      if (row.broker === null) {
        return
      }
      const payload = {
        active: row.excluded_id ? 't' : 'f'
      }
      const res = await ChannelService.pauseChannel(row.id, payload)
      if (res) {
        row.excluded_id = res.excluded_id
        this.$success(this.$t(`Channel updated`))
      }
    }
  }
}
