<template>
  <div>
    <a v-if="entityAddress"
       :href="locationLink"
       target="_blank"
       rel="noopener"
       class="text-primary-500 hover:text-primary-900 hover:underline cursor-pointer"
    >
      <address class="address-field truncate">
        {{ formattedAddress }}
      </address>
    </a>
    <span v-else>- -</span>
  </div>
</template>
<script>
  export default {
    props: {
      row: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        entityAddress: false
      }
    },
    computed: {
      formattedAddress() {
        const entityAddress = this.entityAddress

        const address_1 = entityAddress.address_1 || ''
        const address_2 = entityAddress.address_2 || ''
        const number = entityAddress.number || ''
        const city = entityAddress.city || ''
        const state = entityAddress.state || ''
        const country = entityAddress.country || ''

        let address = `${address_1} ${address_2} ${number}`.trim()

        if ( city ) {
          address += `, ${city}`
        }
        if ( state ) {
          address += `, ${state}`
        }
        if ( country ) {
          address += `, ${country}`
        }
        return address.trim()
      },
      locationLink() {
        return `https://www.google.com/maps/search/?api=1&query=${this.formattedAddress}`
      }
    },
    methods: {
      getFirstAddress() {
        return this.get(this.row, 'relationships.addresses[0]', false)
      },
      tryToGetPrimaryAddress() {
        let entityAddress = this.row?.relationships?.addresses.find(address => address?.primary)
        if ( !entityAddress ) {
          entityAddress = this.getFirstAddress(this.row)
        }
        return entityAddress
      },
      getEntityAddress() {
        this.entityAddress = this.tryToGetPrimaryAddress()
      }
    },
    mounted() {
      this.getEntityAddress()
    }
  }
</script>
