<template>
  <BaseNoPermissions v-if="!$isAdmin" />
  <BaseDataTable
      v-else
      :columns="feedTableColumns"
      :show-pagination="false"
      :local-sort="true"
      tableColumnsKey="settings-feed-table"
      actions="add,delete"
      url="/setting/feeds"
      actionType="get"
      ref="feedTable"
      @add="createMode = !createMode"
  >
    <template #type="{row}">
      <base-badge>
        {{ row.type }}
      </base-badge>
    </template>
    <template #feed_url="{row}">
      <a :href="row.feed_url" target="_blank" rel="noopener">
        {{ row.feed_url }}
      </a>
    </template>
    <template #status="{row}">
      <base-badge :type="row.status.toLowerCase()">
        {{ row.status }}
      </base-badge>
    </template>
    <template #extra-actions-before="{row}">
      <base-tooltip
          v-if="$isAdmin"
          :content="$t('Reload feed')">
        <base-button variant="primary-light"
                     :tabindex="0"
                     :loading="row.feedLoading"
                     size="icon"
                     class="hidden md:flex table-action-button"
                     @click="reloadFeed(row)">
          <RefreshCwIcon class="w-4 h-4"/>
        </base-button>
      </base-tooltip>
    </template>
    <template v-if="createMode" #default>
      <base-form
          :save-text="$t('Create')"
          :cancel-text="$t('Cancel')"
          grid-classes="flex flex-col"
          layout="modal"
          @cancel="createMode = !createMode"
          @submit="submitFeed"
          show-cancel
      >
        <div class="w-full">
          <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Import new feed') }}</p>
          <div class="w-full border-b border-gray-200 my-4"></div>
          <div class="flex flex-col">
            <base-input v-model="model.feed_url"
                        :label="$t('Feed URL')"
                        :label-info="$t('Example') + ': (http://your-url.nl/feed.xml)'"
                        :placeholder="$t('Feed URL')"
                        :name="$t('Feed URL')"
                        class="w-full"
                        id="email"
                        rules="required">
            </base-input>
            <base-select v-model="model.type"
                         :options="typeOptions"
                         :label="$t('Implementation')"
                         :placeholder="$t('Implementation')"
                         :name="$t('Implementation')"
                         id="type"
                         rules="required">
            </base-select>
          </div>
        </div>
      </base-form>
    </template>
  </BaseDataTable>
</template>

<script>
import { FEED_TYPES } from '@/enum/tableEnums'
import formatSelectOptionsMixin from "@/modules/jobs/mixins/formatSelectOptionsMixin.js";
import SettingsService from "@/modules/dashboard/services/SettingsService";
import { RefreshCwIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "FeedSettings",
  mixins: [formatSelectOptionsMixin],
  components: {
    RefreshCwIcon
  },
  data() {
    return {
      model: {
        feed_url: null,
        type: null
      },
      createMode: false,
      FEED_TYPES
    }
  },
  computed: {
    feedTableColumns() {
      return [
        {
          label: this.$t('Url'),
          prop: 'feed_url',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Type'),
          prop: 'type',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Status'),
          prop: 'status',
          minWidth: 120,
          sortable: true,
          align: 'left'
        }
      ]
    },
    typeOptions() {
      return this.formatOptionsData(FEED_TYPES)
    }
  },
  methods: {
    async reloadFeed(row) {
      try {
        row.feedLoading = true
        await SettingsService.reloadFeed(row.id)
        this.$success(this.$t('Feed reloaded successfully'))
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong while reloading the feed.'))
      } finally {
        row.feedLoading = false
      }
    },
    async submitFeed() {
      const res = await SettingsService.addFeedSettings(this.model)

      if (res) {
        this.$success(this.$t('Successfully imported a new feed.'))
        this.createMode = !this.createMode
        this.model.feed_url = null
        this.model.type = null
        const tableRef = this.$refs.feedTable
        tableRef && tableRef.refresh()
      }
    }
  }
}
</script>
