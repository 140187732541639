import { createApp } from 'vue'
import store from './store'
import i18n from "./i18n";
import App from './App.vue'
import authMiddleware from "./modules/auth/middleware/authMiddleware";
import hasClientAndSubscriptionMiddleware from "./modules/auth/middleware/hasClientAndSubscriptionMiddleware";
import hasOwnerRoleMiddleware from "./modules/auth/middleware/hasOwnerRoleMiddleware";
import router from './router'
import globalPlugins from "./plugins/globalPlugins";
import globalComponents from "./plugins/globalComponents";

authMiddleware(router)
hasClientAndSubscriptionMiddleware(router, store)
hasOwnerRoleMiddleware(router, store)

const app = createApp(App)
  .use(store)
  .use(i18n.i18n)
  .use(i18n)
  .use(globalComponents)
  .use(globalPlugins)

initSentry(app, router)

app.use(router);
const mountedApp = app.mount('#app')
window.app = mountedApp


import './modules/common/apiConfig'
import './assets/index.scss'
import 'element-plus/lib/theme-chalk/base.css'
import { initSentry } from "@/plugins/initSentry.js";
export default app;
