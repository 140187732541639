import axios from "axios";
import { validatePhoneRequest } from "@/utils/phoneUtils.js";

export default  {

  async getProfile() {
    return axios.get(`/profile`);
  },

  async updateProfile(data) {
    data = validatePhoneRequest(data)
    return axios.put(`/profile`, data);
  },

  async patchProfile(data) {
    return axios.put(`/profile/patch`, data);
  },

  async changePassword(data) {
    return axios.put(`/profile/password`, data)
  },

  async changeLocale(data) {
    return axios.put(`/profile/locale`, data);
  },

  async changeOrganization(data) {
    return axios.post(`/profile/switch`, data)
  },

  async updateNotificationPreferences(data) {
    return axios.put(`/profile/notifications`, data)
  },
}
