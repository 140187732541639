<template>
  <div class="flex-col-grow">
    <EnvironmentChange />
    <div class="flex-col-grow items-center bg-gray-50">
      <img class="h-8 w-auto my-12" src="/img/logo.png" alt="Logo"/>
      <div class="bg-white shadow-xl rounded-md flex justify-center">
        <div class="hidden lg:flex lg:m-16 max-w-lg justify-center items-center left-side">
          <img src="/img/auth-background.svg"
               alt="auth-background"/>
        </div>
        <div class="flex flex-col justify-between px-6 lg:px-20 xl:px-24 lg:border-l lg:border-gray-200">
          <div class="flex-1 flex flex-col justify-center w-full max-w-lg right-side py-8 px-6">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <base-copyright/>
    </div>
  </div>
</template>
<script>
import EnvironmentChange from "@/components/EnvironmentChange.vue";

export default {
  components: {
    EnvironmentChange
  },
}
</script>
<style scoped lang="scss">
.left-side,
.right-side {
  min-width: 380px;
  @screen lg {
    min-width: 380px;
  }
}
</style>
