<template>
  <div>
    <div class="max-w-7xl mx-auto">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
        <p class="text-xl text-gray-500 sm:text-center">Start building for free, then add a site plan to go live. Account plans unlock additional features.</p>
      </div>
      <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
        <div v-for="(product, value, index) in products" :key="index"
             :class="[ isCurrentSubscription(value) ? 'border-2 border-primary-500 transform scale-105' : 'border' ]"
             class="border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
        >
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900 capitalize">{{value}}</h2>
            <p class="mt-4 text-sm text-gray-500">{{getLicenseProduct(product).description}}</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">{{$formatPrice(getLicenseProduct(product)?.price, {maximumFractionDigits: 0})}}</span>
              <span class="text-base font-medium text-gray-500">/mo</span>
            </p>
            <base-button variant="primary"
                         class="mt-6"
                         block
                         :disabled="isCurrentSubscription(value)"
                         @click="onBuyProduct(value)">
              <span v-if="!isCurrentSubscription(value)"
                    class="capitalize">{{$t('Buy')}}&nbsp{{value}}</span>
              <span v-else>{{$t('Current')}}</span>
            </base-button>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="mb-2 text-xs font-medium text-gray-900 tracking-wide uppercase">Addons</h3>
            <base-checkbox v-if="value === 'flex'"
                           v-model="selectedAddons[value].free"
                           :label="$t('Addons')">
              <template v-slot:label>
                <div class="w-32 finline-flex text-sm text-gray-900 font-medium capitalize">
                  {{$t('Free')}}
                </div>
              </template>
            </base-checkbox>
            <base-checkbox v-model="selectedAddons[value].careerpage"
                           :label="$t('Addons')">
              <template v-slot:label>
                <div class="w-32 finline-flex text-sm text-gray-900 font-medium capitalize">
                  {{$t('Careerpage')}}
                </div>
              </template>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinancialService from "../services/FinancialService";

export default {
  name: "subscription-create",
  data() {
    return {
      selectedAddons: {
        flex: {
          careerpage: false,
          free: false
        },
        premium: {
          careerpage: false
        },
        enterprise: {
          careerpage: false
        }
      },
      products: null,
      addons: null,
    }
  },
  async mounted() {
    const res = await FinancialService.getConfiguration()
    if (!res) {
      return
    }
    this.products = res.products.adengine
    this.addons = res.products.addons
  },
  computed: {
    userHasPaymentMethod() {
      return this.$store.state.auth.selectedClient?.automatic?.id
    }
  },
  methods: {
    isCurrentSubscription(product) {
      return this.$store.state.auth.userSubscriptions.find(s => s.type === 'license')?.product === product
    },
    getLicenseProduct(product) {
      return product.find(p => p.type === 'license')
    },
    async onBuyProduct(product) {
      const payload = {
        adengine: product,
        addons: []
      }
      if (this.selectedAddons[product].careerpage) {
        payload.addons.push('careerpage')
      }
      if (this.selectedAddons[product].free) {
        payload.addons.push('free')
      }

      if (product !== 'flex' && !this.userHasPaymentMethod) {
        this.$store.commit('auth/SET_STORED_SUBSCRIPTION', payload)
        this.$router.push('/finance/payment%20methods')
        await this.$notifyDialog({
          type: 'info',
          title: this.$t('Please add a payment method first'),
          description: this.$t('Add a payment card or a bank account'),
          buttonText: this.$t('Continue'),
        })
        return
      }

      const res = await FinancialService.subscribe(payload)

      if (!res) {
        return
      }

      const redirectUrl = window.location.origin + '/subscription-create?verify=' + res.token
      const checkoutRes = await FinancialService.checkoutMediaBudget(res.token, redirectUrl)
      if (product === 'flex' && checkoutRes.payment_url) {
        window.open(checkoutRes.payment_url)
      } else {
        this.$store.commit('auth/SET_STORED_SUBSCRIPTION', {})
        this.$router.push('/welcome')
      }
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        if (val.query.verify) {
          const res = await FinancialService.verifyCheckoutMediaBudget(val.query.verify)

          if (res) {
            this.$success(this.$t('Payment successfull'))
          }

          await this.$router.replace({
            path: val.path
          })
        }
      }
    }
  }
}
</script>
