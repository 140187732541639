<template>
  <BaseNoPermissions v-if="!$isAdmin" />
  <div v-else class="mt-4 mandate-settings__wrapper">
    <base-form v-if="!showEditForm"
               :can-submit-form="false"
               :loading="loading"
               grid-classes="flex flex-col"
               layout="modal">
      <div class="w-full mb-6">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Payment types') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>
      </div>

      <div v-for="item in mandates"
           class="py-4 border-2 border-gray-100 rounded-lg xl:w-3/6 shadow-lg my-1"
           :class="{ 'border-green-300' : activePayment[item.id] }"
           :key="`payment-type-${item.id}`">
        <div class="flex flex-col items-start ml-6">
          <div class="flex items-center mb-2">
            <span class="text-xl">{{ item.type }}</span>
            <base-popover v-if="item?.information" :parent-entity-id="`popover-mandate-${item.id}`">
              <template v-slot:body>
                <div class="w-88">
                  <info-card
                      :key="`info-card-${item.id}`"
                      :data="item?.information"
                      :fields="mandateFields(item.type)"/>
                </div>
              </template>
              <template v-slot:reference>
                <info-icon size="1x" class="text-black ml-2 cursor-help"></info-icon>
              </template>
            </base-popover>
          </div>
          <span v-if="item?.information?.iban_number" class="italic">{{ item?.information?.iban_number }}</span>
          <div v-if="item?.information?.last4"
               class="flex items-center">
            •••• •••• •••• &nbsp
            <span class="italic">{{ item?.information?.last4 }}</span>
          </div>
          <span class="text-gray-600 mt-2">{{
              $t('Automatic payment (After € 25.00 spent or 30 days, whichever comes first)')
            }}
          </span>
          <div class="mt-3">
            <base-button v-if="item.type !== PAYMENT_TYPES['BANK-TRANSFER']"
                         size="sm"
                         variant="primary"
                         @click="openEditMode(item.type)">
              {{ $t('Manage') }}
            </base-button>
            <base-button v-if="!activePayment[item.id]"
                         size="xxs"
                         class="text-sm text-primary-500 p-0"
                         :class="{ 'ml-4' : item.type !== PAYMENT_TYPES['BANK-TRANSFER'] }"
                         variant="link"
                         @click="updatePaymentType(item.id)">
              {{ $t('Make default') }}
            </base-button>
          </div>
        </div>
      </div>

      <div v-if="canCreateNewMandate" class="mb-2 flex flex-col items-start">
        <span v-if="!mandates.length" class="text-xl text-gray-600 mb-2">
          {{ $t('You don\'t have a payment method yet.') }}
        </span>
        <base-button variant="primary"
                     class="mt-4"
                     @click="onCreateClick">
          <span v-if="!mandates.length">{{ $t('Create payment method') }}</span>
          <span v-else>{{ $t('Add new payment method') }}</span>
        </base-button>
      </div>
    </base-form>

    <base-form v-else
               :save-text="$t('Save')"
               :cancel-text="$t('Cancel')"
               @cancel="showEditForm = !showEditForm"
               show-cancel
               grid-classes="flex flex-col"
               layout="modal"
               @submit="submitMandate"
               :hide-overflow="false">
      <p v-if="createMode" class="text-xl font-medium leading-6 text-gray-900 dark:text-white">
        {{ $t('Add new payment type') }}</p>
      <p v-else class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Edit payment type') }}</p>
      <div class="w-full border-b border-gray-200 my-4"></div>
      <div class="w-full">
        <base-select
            v-if="createMode"
            v-model="typeToEdit"
            :options="paymentOptions"
            :label="$t('Payment type')"
            :placeholder="$t('Payment type')"
            :name="$t('Payment type')"
            @update:modelValue="openEditMode"
            id="type"
            inline-errors>
        </base-select>

        <div class="mt-4" :key="inputsWrapperKey">
          <base-input v-if="sepaEditing"
                      v-model="model.iban_number"
                      :label="$t('IBAN Number')"
                      :placeholder="$t('IBAN Number')"
                      :name="$t('IBAN Number')"
                      class="w-full"
                      id="iban"
                      maxlength="18"
                      rules="required|length:18">
          </base-input>
          <div v-else>
            <label class="text-sm font-medium leading-5 text-gray-700 dark:text-gray-200">{{ $t('Credit card') }}
              *</label>
            <StripeInput
                ref="stripeRef"
                :model="model"
                @handleError="stripeError"
                @paymentMethodCreated="paymentMethodCreated">
            </StripeInput>
          </div>
          <base-input v-model="model.attention"
                      :label="$t('Attn')"
                      :placeholder="$t('Attn')"
                      :name="$t('Attn')"
                      class="w-full"
                      id="tav"
                      rules="required">
          </base-input>
          <base-select v-model="model.country_code"
                       :options="allCountries"
                       :label="$t('Country')"
                       :placeholder="$t('Country')"
                       :name="$t('Country')"
                       classNames="w-full"
                       name="country"
                       id="country"
                       rules="required"
                       filterable>
          </base-select>
          <div class="flex flex-col lg:flex-row">
            <base-input v-model="model.street"
                        :label="$t('Address')"
                        :placeholder="$t('Address')"
                        :name="$t('Address')"
                        class="w-full"
                        id="street"
                        rules="required">
            </base-input>
            <div class="lg:w-2/6 lg:ml-4">
              <base-input v-model="model.house_no"
                          :label="$t('House number')"
                          :placeholder="$t('House number')"
                          :name="$t('House number')"
                          id="house"
                          rules="required">
              </base-input>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row">
            <base-input v-model="model.zip_code"
                        :label="$t('Zip code')"
                        :placeholder="$t('Zip code')"
                        :name="$t('Zip code')"
                        class="w-full"
                        id="zipcode"
                        rules="required">
            </base-input>
            <div class="w-full lg:ml-4">
              <base-autocomplete :inputValue="model.city"
                                 :fetchDataAction="fetchCountriesData"
                                 @update:modelValue="onCityFieldChange($event)"
                                 @onKeydownEnter="onCityFieldChange($event)"
                                 :placeholder="$t('City')"
                                 :label="$t('City')"
                                 :name="$t('City')"
                                 classNames="w-full"
                                 rules="required">
              </base-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </base-form>
  </div>
</template>

<script>
import FinancialService from "../services/FinancialService";
import countriesMixin from "@/modules/jobs/mixins/countriesMixin";
import { cloneDeep } from 'lodash';
import StripeInput from "@/modules/payment/StripeInput.vue";
import formatSelectOptionsMixin from "@/modules/jobs/mixins/formatSelectOptionsMixin.js";
import { PAYMENT_TYPES } from '@/enum/paymentEnums'
import BasePopover from "@/components/common/BasePopover.vue";
import { InfoIcon } from '@zhuowenli/vue-feather-icons'
import InfoCard from "@/components/common/InfoCard.vue";

export default {
  name: "MandateSettings",
  mixins: [countriesMixin, formatSelectOptionsMixin],
  components: {
    BasePopover,
    InfoCard,
    InfoIcon,
    StripeInput
  },
  data() {
    return {
      model: {},
      mandates: [],
      typeToEdit: PAYMENT_TYPES.CREDIT_CARD,
      showEditForm: false,
      createMode: false,
      loading: false,
      activePayment: {},
      stripe: null,
      inputsWrapperKey: 0,
      PAYMENT_TYPES
    }
  },
  async mounted() {
    await this.initMandatesData()
  },
  computed: {
    canCreateNewMandate() {
      const sepa = this.mandates.find(m => m.type === PAYMENT_TYPES.SEPA)
      const creditCard = this.mandates.find(m => m.type === PAYMENT_TYPES.CREDIT_CARD)
      return !(sepa && creditCard);
    },
    sepaEditing() {
      return this.typeToEdit === PAYMENT_TYPES.SEPA
    },
    creditCardEditing() {
      return this.typeToEdit === PAYMENT_TYPES.CREDIT_CARD
    },
    paymentOptions() {
      return this.formatOptionsData(PAYMENT_TYPES)
          .filter(p => p.label !== PAYMENT_TYPES.BANK_TRANSFER && p.label !== PAYMENT_TYPES.SEPA)
    }
  },
  methods: {
    mandateFields(type) {
      let fields = {
        iban_number: 'IBAN Number',
        last4: 'Card last four numbers',
        attention: 'T.A.V',
        city: 'City',
        country_code: 'Country code',
        house_no: 'House number',
        street: 'Street',
        zip_code: 'ZIP Code',
      }
      if (type === 'SEPA') {
        delete fields.last4
      } else {
        delete fields.iban_number
      }
      return fields
    },
    async refreshMandates() {
      await this.initMandatesData()
      this.showEditForm = !this.showEditForm
    },
    stripeError(error) {
      this.$error(error.message)
    },
    async paymentMethodCreated(data) {
      this.stripe = data;
      const payload = this.model
      payload.stripeToken = data.id
      const res = await FinancialService.saveCreditCard(this.model)

      if (!res) {
        return
      }

      this.$success(this.$t(`Payment method saved successfully`))
      await this.refreshMandates()
    },
    async updatePaymentType(id) {
      const type = this.mandates.find(m => m.id === id).type
      const res = await FinancialService.changePaymentType(type)

      if (!res) {
        return
      }

      this.$success(this.$t(`Payment type changed successfully`))

      Object.keys(this.activePayment).forEach(p => {
        this.activePayment[p] = false
      })
      this.activePayment[id] = type
    },
    async initMandatesData() {
      this.loading = true
      const res = await FinancialService.getMandates()
      if (!res) {
        return
      }
      this.mandates = []
      this.activePayment = {}
      Object.keys(res).forEach(item => {
        if (res[item]) {
          this.mandates.push(res[item])
        }
      });
      const usedPayment = this.$store.state.auth.selectedClient?.automatic
      this.activePayment[usedPayment.id] = true
      this.loading = false
    },
    async onCreateClick() {
      if (!this.$store.state.auth.selectedClient.financial.organisation_name) {
        this.$emit('changeTab', 'general')
        await this.$notifyDialog({
          type: 'info',
          title: this.$t('Please add general information about your organisation first'),
          buttonText: this.$t('Continue'),
        })
      }
      this.openEditMode(PAYMENT_TYPES.CREDIT_CARD)
      this.createMode = true
    },
    openEditMode(type) {
      this.createMode = false
      const selectedType = cloneDeep(this.mandates).find(m => m.type === type)
      if (selectedType) {
        this.model = selectedType?.information
      } else {
        this.model = {}
      }

      this.typeToEdit = type
      this.showEditForm = true
      this.inputsWrapperKey = Math.random()
    },
    async submitMandate() {
      let res

      if (this.sepaEditing) {
        res = await FinancialService.saveSepa(this.model)
      } else if (this.creditCardEditing) {
        await this.$refs.stripeRef.submit();
        return
      }

      if (!res) {
        return
      }

      this.$success(this.$t(`Payment method saved successfully`))
      await this.refreshMandates()
    }
  }
}
</script>
