<template>
    <span :class="{
             'rounded-none': !rounded,
             'rounded-full': rounded,
             'block w-full': block,
             'inline-flex': !block,
             'shadow-sm': !variant.includes('link')
          }"
    >
        <button v-bind="$attrs"
                :type="$attrs.type || 'button'"
                :disabled="disabled || loading"
                ref="button"
                class="items-center justify-center border border-transparent font-medium focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150"
                :class="{
                  'rounded-none': !rounded,
                  'rounded-full': rounded,
                  'text-white bg-primary-600 hover:bg-primary-500 focus:border-primary-700 active:bg-primary-700': variant === 'primary',
                  'text-white bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700': variant === 'success',
                  'text-primary-600 hover:text-primary-900': variant === 'primary-link',
                  'text-green-600 hover:text-green-900': variant === 'green-link',
                  'text-gray-600 hover:text-gray-900': variant === 'gray-link',
                  'text-gray-600 hover:text-gray-800 bg-gray-100 hover:bg-gray-300 hover:shadow-md': variant === 'gray-light',
                  'text-primary-600 hover:text-primary-800 bg-primary-100 hover:bg-primary-300 hover:shadow-md': variant === 'primary-light',
                  'text-green-600 hover:text-green-800 bg-green-100 hover:bg-green-300 hover:shadow-md': variant === 'green-light',
                  'text-red-600 hover:text-red-800 bg-red-100 hover:bg-red-300 hover:shadow-md': variant === 'danger-light',
                  'text-white bg-red-600 hover:bg-red-500 focus:border-red-700 active:bg-red-700': variant === 'danger',
                  'text-white bg-yellow-400 hover:bg-yellow-500 focus:border-yellow-700 active:bg-yellow-700': variant === 'yellow',
                  'text-white bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700': variant === 'green',
                  'text-red-600 hover:text-red-900': variant === 'danger-link',
                  'text-primary-700 bg-primary-100 hover:bg-primary-50 focus:border-primary-300 active:bg-primary-200': variant === 'secondary',
                  'text-red-700 border-red-300 hover:bg-red-50 focus:border-red-300 active:bg-red-400': variant === 'danger-secondary',
                  'border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50': variant === 'white',
                  'text-xs leading-4': size === 'xxs',
                  'text-xs px-2.5 py-1.5 leading-4': size === 'xs',
                  'text-sm px-3 py-2 leading-4': size === 'sm',
                  'text-sm px-4 py-2 leading-5': size === 'md',
                  'text-base px-6 py-2 leading-6': size === 'lg',
                  'text-base px-8 py-3 leading-6': size === 'xl',
                  'icon-button': size === 'icon',
                  'opacity-50 cursor-not-allowed': disabled || loading,
                  'inline-flex': !block,
                  'w-full flex justify-center': block,
                }"
        >
        <div v-if="loading"
             class="absolute flex w-full items-center justify-center">
            <loader-icon class="spin-animation"
                         :class="{
                           'h-4 w-4': size === 'xs',
                           'h-5 w-5': size === 'sm',
                           'h-5 w-5': !['xs', 'sm'].includes(size),
                         }"/>
        </div>

        <div class="flex flex-wrap items-center"
             :class="{'opacity-0': loading}"
        >
            <slot></slot>
        </div>
    </button>
    </span>
</template>
<script>
import { LoaderIcon } from '@zhuowenli/vue-feather-icons'

export default {
  inheritAttrs: false,
  components: {
    LoaderIcon,
  },
  props: {
    block: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary' // primary|secondary|danger|danger-secondary|white
    },
    size: {
      type: String,
      default: 'md' // xs|sm|md|lg|xl
    }
  },
  methods: {
    focus() {
      if (!this.$refs.button) {
        return
      }
      this.$refs.button.focus()
    }
  },
}
</script>
<style lang="scss" scoped>
.icon-button {
  padding: 8px;
  @screen md {
    padding: 6px;
  }
}
</style>
