import ReportsLayout from "@/modules/reports/layout/ReportsLayout.vue";
import DashboardReports from './pages/dashboard-reports.vue'
import JobReports from './pages/job-reports.vue'
import CampaignReports from './pages/campaign-reports.vue'
import ChannelReports from './pages/channel-reports.vue'

export default [
  {
    path: '/reports',
    name: 'Reports',
    redirect: '/reports/dashboard',
    component: ReportsLayout,
    meta: {
      requiresClient: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard Reports',
        title: 'Dashboard Reports',
        description: 'See Dashboard Reports and overall analytics of the platform',
        component: DashboardReports,
      },
      {
        path: 'jobs',
        name: 'Job Reports',
        title: 'Job Reports',
        description: 'Explore job performance and kpis over time',
        component: JobReports,
      },
      {
        path: 'campaigns',
        name: 'Campaign Reports',
        title: 'Campaign Reports',
        description: 'Explore campaign performance and kpis over time',
        component: CampaignReports,
      },
      {
        path: 'channels',
        name: 'Channel Reports',
        title: 'Channel Reports',
        description: 'Explore channel performance and kpis over time',
        component: ChannelReports,
      },
    ]
  },
]
