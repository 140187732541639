export const Envs = {
  STG: 'staging',
  PROD: 'production',
  DEV: 'development',
}

const API_HOSTS = {
  [Envs.STG]: 'https://app.stg.jobboost.io/api/v1',
  [Envs.PROD]: 'https://app.jobboost.io/api/v1',
  [Envs.DEV]: 'https://app.dev.jobboost.io/api/v1',
}

const RECAPTCHA_KEY = '6LeRJY4aAAAAAJVjpEzXgUqp-bIAo2ayxkApwDDa'
const STRIPE_KEY = 'pk_test_R6owCNNzIklpcDNm1eB5OTuz'
const STRIPE_PROD_KEY = 'pk_live_pSbSylS6UdXDHC6VkpBTyLDK'
const mode = import.meta.env.MODE
const isProduction = mode === Envs.PROD

export default {
  Envs,
  API_HOSTS,
  RECAPTCHA_KEY,
  STRIPE_KEY: isProduction ? STRIPE_PROD_KEY : STRIPE_KEY,
};
