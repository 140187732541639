export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: ''
};

export const JOB_STATUSES = {
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  DRAFT: 'DRAFT',
};

export const PRIORITIES = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const CAMPAIGN_STATUSES = {
  ACTIVE: 'ACTIVE',
  PAUSE: 'PAUSE',
  DRAFT: 'DRAFT',
};

export const CAMPAIGN_TYPE = {
  BOOST: 'BOOST',
  MONTH: 'MONTH',
  'ONE-TIME': 'ONE-TIME',
};

export const CHANNEL_STATUSES = {
  ACTIVE: 'ACTIVE',
  NEW: 'NEW',
  STOPPED: 'STOPPED',
  PAUSE: 'PAUSE',
};

export const CHANNEL_TYPES = {
  JOB_BOARD: 'JOB-BOARD',
  SEARCH_ENGINE: 'SEARCH-ENGINE',
  SOCIAL_MEDIA: 'SOCIAL-MEDIA',
  VERTICAL: 'VERTICAL',
};

export const FEED_TYPES = {
  "JOB-BOOST": 'JOB-BOOST',
  RECRUITEE: 'RECRUITEE',
  INDEED: 'INDEED',
  OTYS: 'OTYS',
};
