<template>
  <div v-if="banners.length">
    <div
      v-for="(banner, index) in banners"
      :class="bannerBgColors[banner.type]"
    >
      <div
          v-if="banner.condition && !banner.hide"
          class="flex justify-center py-2 relative text-blue-500/90 px-20"
      >
        <div class="shrink-0 mt-0.5">
          <component
              :is="bannerIcon[banner.type]"
              class="w-5"
          />
        </div>
        <div class="text-center text-sm banner px-4" v-html="$t(banner.content)" />
        <close-button
            size="small"
            class="absolute right-5 cursor-pointer"
            @click="hideBanner(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconAnnouncement from '@/components/icons/IconAnnouncement.vue'
import CloseButton from "@/components/common/CloseButton.vue";
import { useStorage } from '@vueuse/core'
import { onMounted } from 'vue'
import { isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns'
import { today } from '@/utils/dateUtils'

const bannerBgColors = {
  Announcement: 'bg-blue-100'
}

const bannerIcon = {
  Announcement: IconAnnouncement
}

const bannersArr = [
  {
    type: 'Announcement',
    condition: isFirstDayOfMonth(today),
    content: 'Every first day of the month, our data indexation takes place. During the indexation of today, it is possible the data in the Jobmatix tool is not correct. We aim to have the correct data back at 3pm today.'
  },
  {
    type: 'Announcement',
    condition: isLastDayOfMonth(today),
    content: 'Every first day of the month, our data indexation takes place. Tomorrow, during this indexation, it is possible that the data in the Jobmatix tool is not correct. We would advice you to either collect data today or tomorrow again after 3pm.'
  },
]

export default {
  name: "AnnouncementBanners",
  components: {
    IconAnnouncement,
    CloseButton
  },
  setup() {
    let banners = useStorage('banners', [...bannersArr])
    onMounted(() => {
      bannersArr.forEach(el => {
        const banner = banners.value.find(banner => banner.content === el.content);
        if(!banner) {
          banners.value.push(el);
          return
        }
        banner.condition = el.condition;
      })
    })
    return { bannerIcon, bannerBgColors, banners }
  },
  methods: {
    hideBanner(index) {
      this.banners[index].hide = true
    },
  },
}
</script>

<style>
.banner a {
  @apply font-semibold hover:underline
}
</style>
