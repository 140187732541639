import axios from "axios";
import i18n from "@/i18n";

export default {
  async getCampaign(campaignId) {
    try {
      return await axios.get(`/campaign/${campaignId}`, {
        params: {
          with: 'kpis'
        }
      });
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async createCampaign(payload) {
    try {
      return await axios.post(`/campaign/create`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async editCampaign(campaignId, payload) {
    try {
      return await axios.put(`/campaign/${campaignId}`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async editCampaignField(campaignId, payload) {
    try {
      return await axios.put(`/campaign/${campaignId}/field`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getAvailableCampaignChannels(campaignId, params = {}) {
    try {
      return await axios.post(`/campaign/${campaignId}/channels`, params);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getCampaignChannels(campaignId, params = {}) {
    try {
      return await axios.get(`/campaign/${campaignId}/channel`, params);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async editCampaignChannels(campaignId, payload) {
    if (!Object.keys(payload.channels).length) {
      return
    }
    try {
      const res = await axios.put(`/campaign/${campaignId}/channel`, payload);
      window.app.$success(i18n.t(`Campaign channels updated`))
      return res
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },
  async excludeJobFromCampaign(campaignId, payload) {
    try {
      return await axios.put(`/campaign/${campaignId}/exclude`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async refreshIndeedCampaign(campaignId) {
    try {
      const data = await axios.get(`/campaign/${campaignId}/refresh-indeed`);
      window.app.$success(i18n.t(`Campaign was successfully refreshed`))
      return data
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  }
}

