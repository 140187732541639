<template>
  <base-form :save-text="saveText"
             :cancel-text="cancelText"
             grid-classes="flex flex-col"
             class="max-w-2xl mx-auto"
             layout="modal"
             :loading="loading"
             :show-cancel="showCancel"
             @cancel="$emit('cancel')"
             @submit="$emit('submit')"
  >
    <div class="w-full">
      <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ title }}</p>
      <div v-if="title" class="w-full border-b border-gray-200 my-4"></div>
      <div class="flex flex-col lg:flex-col">
        <base-amount-option
            v-model="budget"
            :name="$t('Budget')"
            :label="inputName"
            :options="amountOptions"
            rules="required"
            @onChangeOption="onChangeBudgetStopper"
        />
        <div class="lg:w-3/6">
          <base-input v-show="showBudgetInput"
                      ref="stopperInput"
                      v-model="budget"
                      type="number"
                      :min="minValue"
                      class="lg:mr-4"
                      :name="inputName"
                      :placeholder="inputPlaceholder"
                      id="mediaBudget"
                      :rules="`required|min_value:${minValue}`"
                      has-suffix>
            <template v-slot:prefix>
              {{ getLocaleCurrencySign }}
            </template>
          </base-input>
        </div>
      </div>
    </div>
  </base-form>
</template>

<script>
import BaseAmountOption from "@/modules/jobs/components/BaseAmountOption.vue";
import localeCurrencyMixin from "@/modules/jobs/mixins/localeCurrencyMixin.js";
import i18n from "@/i18n";

export default {
  name: "BudgetChange",
  inheritAttrs: false,
  components: {
    BaseAmountOption,
  },
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    saveText: {
      type: String,
      default: i18n.t('Deposit')
    },
    minValue: {
      type: String,
      default: '500'
    },
    cancelText: {
      type: String,
      default: i18n.t('Cancel')
    },
    inputName: {
      type: String,
      default: i18n.t('Budget field')
    },
    inputPlaceholder: {
      type: String,
      default: i18n.t('Budget')
    },
    title: {
      type: String,
      default: ''
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [localeCurrencyMixin],
  data() {
    return {
      budget: null,
      showBudgetInput: false,
    }
  },
  computed: {
    amountOptions() {
      let options = [
        {
          label: '500',
          value: 500
        },
        {
          label: '1.000',
          value: 1000
        },
        {
          label: '2.500',
          value: 2500
        },
        {
          label: '5.000',
          value: 5000
        },
        {
          label: '10.000',
          value: 10000
        },
        {
          label: this.$t('Other')
        },
        {
          label: this.$t('No limit'),
          value: 0
        },
      ]
      if (!this.$isOwner) {
        const newOption = {
          label: '15.000',
          value: 15000
        }
        options.splice(options.length - 1, 0, newOption)
      }
      return options
    },
  },
  watch: {
    budget(val) {
      if (val !== this.modelValue) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    onChangeBudgetStopper(val) {
      const findOption = this.amountOptions.find(o => {
        const value = o.value ?? o.label
        return value.toString() === this.budget?.toString()
      })
      this.showBudgetInput = val.label === this.$t('Other');

      if (val.label === this.$t('Other') && this.$refs.stopperInput) {
        this.$nextTick(() => {
          this.$refs.stopperInput.focus()
        })
      }

      if (val.label === this.$t('Other') && findOption?.label) {
        this.budget = this.amountOptions[0].value
      } else if (val.label !== this.$t('Other')) {
        this.budget = val.value
      }
    },
  },
  created() {
    this.budget = this.modelValue
  }
}
</script>
