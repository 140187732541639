import axios from "axios";
import i18n from "@/i18n";

export default {
  async getJobs(params) {
    try {
      return await axios.post(`/job`, params);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getJob(jobId) {
    try {
      return await axios.get(`/job/${jobId}`);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getJobKpi(jobId) {
    try {
      return await axios.get(`/job/${jobId}/kpi`);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async createJob(payload) {
    try {
      return await axios.post(`/job/create`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async editJob(jobId, payload) {
    try {
      return await axios.put(`/job/${jobId}`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async editJobField(jobId, payload) {
    try {
      return await axios.put(`/job/${jobId}/field`, payload);
    } catch (e) {
      console.warn(e)
    }
  },

  async editTags(jobId, payload) {
    try {
      return await axios.put(`/job/${jobId}/tag`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async typeAhead(payload) {
    try {
      return await axios.post(`/type-ahead`, payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  }
}

