<template>
  <div class="data-table">
    <div v-if="showTopTotalRows && totalRowsBeforeFilter" class="footer-left flex flex-col mb-4">
      <div>
        <span class="text-xl font-semibold text-gray-500 lowercase">
          <span class="text-primary-500"> {{ selectedRowsCount }} {{ $t('selected') }} </span>
          / {{ totalRowsBeforeFilter }} {{ $t('total') }} {{ entity }}{{ totalRowsBeforeFilter > 1 ? 's' : '' }}
        </span>
        <slot name="headerInfo" />
      </div>
      <span class="text-sm text-gray-500">
        {{ $t('Only selected vacancies are sponsored') }}
      </span>
    </div>
    <div v-if="showAction('search') || showAction('refresh') || showAction('add') || title"
         class="w-full flex flex-wrap lg:flex-nowrap flex-col md:flex-row md:items-center"
         :class="{
                'justify-start': !title && !showAction('filters'),
                'md:items-baseline': showAction('search') && !showAction('filters') && $slots['extra-buttons'],
                'justify-between items-center': title && showAction('filters') || title && showAction('search'),
                'mb-2': !showAction('search')}"
    >
      <div v-if="title" class="text-lg font-medium leading-6 text-gray-900">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <saved-table-filters v-if="showAction('filters')"
                           :entity="entity"
                           class="md:mr-2"
                           @change="setFilter"
      >
      </saved-table-filters>
      <div class="flex">
        <base-input v-if="showAction('search')"
                    v-model="filters.search"
                    :placeholder="$t('Search...')"
                    class="search-input"
                    clearable
                    @update:modelValue="onLocalSearch"
                    @change="refresh"
        >
          <template v-slot:prefix>
            <SearchIcon class="w-4 h-4 text-gray-400"/>
          </template>
        </base-input>
        <div v-if="$slots['additional-actions']" class="mx-2">
          <slot name="additional-actions"/>
        </div>
        <div v-if="showAction('refresh')"
             class="ml-2 mt-1">
          <base-button
              :title="$t('Refresh data')"
              variant="white"
              size="sm"
              @click="refresh"
          >
            <RefreshCwIcon class="w-5 h-5 text-primary-500"/>
          </base-button>
        </div>
      </div>
      <div v-if="showAction('add') || showAction('filters') || $slots['extra-buttons']"
           class="flex flex-wrap xl:flex-nowrap w-full items-center mb-4 lg:mb-0"
           :class="[
               !showAction('filters') && !showAction('search') ? 'justify-end': 'justify-between',
               showAction('add') && !showAction('filters') && showAction('search') ? 'flex-row-reverse' : ''
           ]">
        <table-filters v-if="showAction('filters')"
                       v-model="dynamicFilters"
                       @update:modelValue="refresh(true)"
                       :url="url"
                       ref="tableFilters"
        />
        <div class="flex flex-end">
          <div v-if="showAction('add')"
               class="add-button flex justify-end lg:mb-4">
            <base-button
                :title="$t('Add')"
                variant="primary"
                size="sm"
                @click="$emit('add', $event)"
            >
              <PlusIcon class="w-4 h-4 text-white"/>
              <span v-if="addText" class="ml-1">{{ addText }}</span>
            </base-button>
          </div>
          <slot name="extra-buttons">
          </slot>
        </div>
      </div>
    </div>
    <slot
        name="default"
        :data="tableData"
    >
      <base-table :data="tableData"
                  :loading="loading || dataLoading"
                  :default-sort="defaultSort"
                  :local-sort="localSort"
                  v-bind="$attrs"
                  v-on="listeners"
                  ref="table"
      >
        <template v-slot:empty>
          <slot v-if="!loading" name="empty"/>
        </template>
        <template v-slot:thead-infos>
          <slot name="thead-infos"/>
        </template>
        <template v-slot:header="{row}">
          <slot name="header" :row="row"/>
        </template>
        <template v-slot:footer="{row}">
          <slot name="footer" :row="row"/>
        </template>
        <template v-slot:subtotal="{row}">
          <slot name="subtotal" :row="row"/>
        </template>
        <template v-slot:default>
          <base-table-column v-for="(column, index) in columnsToDisplay"
                             :key="`${column.prop}-${tableKey}`"
                             v-bind="column"
                             :sortable="isColumnSortable(column)">
            <template v-slot:header="{column, index}">
              <slot :name="`${column.prop}-header`"
                    :column="column"
                    :index="index">
                {{ column.label }}
              </slot>
            </template>
            <template v-slot="{row, index}">
              <slot :name="column.prop" :row="row" :column="column" :index="index">
                <template v-if="column.component">
                  <component :is="column.component"
                             :column="column"
                             :params="column.params || {}"
                             :row="row">
                  </component>
                </template>
                <div v-else class="truncate" :title="get(row, column.prop, '')">
                  <span :tabindex="-1">{{ getCellValue(row, column) }}</span>
                </div>
              </slot>
            </template>
          </base-table-column>
          <base-table-column v-if="actions && showActionsColumn || $slots['extra-actions']"
                             classes="actions-header"
                             rowClasses="table-actions"
                             :width="actionsColumnWidth"
                             :maxWidth="actionsColumnWidth"
          >
            <template v-slot:header>
              <manage-columns
                  :columns="columns"
                  :table-key="tableColumnsKey"
                  :saved-columns="savedColumns"
                  :data="tableData"
                  @update-columns="updateColumns"/>
            </template>
            <template v-slot="{row, index}">
              <div class="flex items-center justify-start space-x-2">
                <slot name="extra-actions-before" :row="row" :index="index"></slot>
                <table-view-button v-if="showAction('view')"
                                   @click="$emit('view', row, index)">
                  {{ $t('View') }}
                </table-view-button>
                <table-edit-button v-if="showAction('edit')"
                                   @click="$emit('edit', row, index)">
                  {{ $t('Edit') }}
                </table-edit-button>
                <table-delete-button v-if="showAction('delete')"
                                     @click="onDelete(row, index)">
                  {{ $t('Delete') }}
                </table-delete-button>
                <slot name="extra-actions" :row="row" :index="index"></slot>
              </div>
            </template>
          </base-table-column>
        </template>

        <template v-slot:summary="{column}">
          <slot :name="`${column.prop}_summary`">
          </slot>
        </template>
      </base-table>
    </slot>
    <div v-if="showPagination"
         data-html2canvas-ignore="true"
         class="w-full flex justify-between mt-5 table-pagination">
      <div class="footer-left flex items-center">
        <span v-if="totalRowsBeforeFilter && pagination.total !== totalRowsBeforeFilter"
              class="text-sm text-gray-500 px-2">
          {{ pagination.total }} {{ $t('of') }} {{ totalRowsBeforeFilter }} {{ $t('total') }}
          <span>{{ `${entity}s` }}</span>
        </span>
        <slot v-else name="footer-left"></slot>
      </div>
      <el-pagination
          v-model:currentPage="pagination.current_page"
          :page-sizes="perPageOptions"
          :page-size="+pagination.per_page"
          :pager-count="5"
          :total="pagination.total"
          layout="total, slot, prev, pager, next, jumper"
          @current-change="refresh"
      >
        <el-select class="select-default"
                   size="mini"
                   @change="onPerPageChange"
                   v-model="pagination.per_page">
          <el-option v-for="value in perPageOptions"
                     class="select-default"
                     :key="value"
                     :label="value"
                     :value="value">
          </el-option>
        </el-select>
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Fuse from 'fuse.js'
import { FilePlusIcon, FilterIcon, PlusIcon, RefreshCwIcon, SearchIcon } from '@zhuowenli/vue-feather-icons'
import { ElOption, ElPagination, ElSelect, ElTooltip } from 'element-plus'
import { get, cloneDeep, isEqual } from 'lodash'
import axios from 'axios'
import PhoneLink from './cells/PhoneLink.vue'
import EntityLink from './cells/EntityLink.vue'
import AddressLink from './cells/AddressLink.vue'
import PrimaryAddressLink from './cells/PrimaryAddressLink.vue'
import TableFilters from "@/components/table/filters/TableFilters.vue";
import ManageColumns from "@/components/table/ManageColumns.vue";
import FormattedDate from './cells/FormattedDate.vue'
import FormattedPrice from './cells/FormattedPrice.vue'
import Status from './cells/Status.vue'
import EmailLink from './cells/EmailLink.vue'
import SavedTableFilters from "@/components/table/filters/SavedTableFilters.vue";
import TableViewButton from "@/components/table/buttons/TableViewButton.vue";
import TableEditButton from "@/components/table/buttons/TableEditButton.vue";
import TableDeleteButton from "@/components/table/buttons/TableDeleteButton.vue";
import Sortable from "sortablejs";
import orderBy from "lodash/orderBy.js";
import { SORT_DIRECTION } from "@/enum/tableEnums.js";
import FormattedPercent from "@/components/table/cells/FormattedPercent.vue";

export default {
  emits: ['data-fetch', 'pagination-fetch', 'delete', 'view', 'edit', 'on-change-filters', 'add', 'force-pagination'],
  inheritAttrs: false,
  components: {
    ManageColumns,
    Status,
    PhoneLink,
    AddressLink,
    SearchIcon,
    FilterIcon,
    PlusIcon,
    EmailLink,
    EntityLink,
    FilePlusIcon,
    RefreshCwIcon,
    TableFilters,
    FormattedDate,
    FormattedPrice,
    FormattedPercent,
    PrimaryAddressLink,
    SavedTableFilters,
    TableViewButton,
    TableEditButton,
    TableDeleteButton,
    ElTooltip,
    ElSelect,
    ElOption,
    ElPagination,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showTopTotalRows: {
      type: Boolean,
      default: false,
    },
    draggableColumns: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    urlQuery: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tableColumnsKey: {
      type: String,
      default: '',
    },
    deleteAction: {
      type: Function,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showActionsColumn: {
      type: Boolean,
      default: true,
    },
    importModel: {
      type: String,
      default: 'accounts',
    },
    perPage: {
      type: [String, Number],
      default: 25,
    },
    excludedRows: {
      type: Number,
      default: 0,
    },
    exportModel: {
      type: String,
      default: 'AccountBalances',
    },
    actionType: {
      type: String,
      default: 'post',
    },
    entity: {
      type: String,
      default: '',
    },
    defaultSort: {
      type: Object,
      default: () => ({}),
    },
    localSort: {
      type: Boolean,
      default: false,
    },
    initialFilters: {
      type: Array,
      default: () => ([]),
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    dataMapFunction: {
      type: [Function, Object],
    },
    onDeleteConfirmMessage: {
      type: String,
      default: 'Are you sure you want to delete this row? The data will be removed on our servers. This action cannot be undone.',
    },
    localPagination: {
      type: Boolean,
    },
    localSearch: {
      type: Boolean,
    },
    addText: {
      type: String,
    }
  },
  data() {
    const initialFilters = this.$store.state.filter.activeFilters[this.$route.path] || []
    const searchValue = this.getSearchValue(initialFilters)
    return {
      tableKey: 0,
      savedColumns: [],
      initialTableData: this.data,
      tableData: this.data,
      loading: false,
      perPageOptions: [5, 10, 15, 20, 25, 50],
      pagination: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: this.perPage || 25,
        to: 5,
        total: this.data.length || 5,
      },
      totalRowsBeforeFilter: null,
      filters: {
        search: searchValue,
      },
      fuseSearch: {},
      dynamicFilters: initialFilters || [],
      sort: this.defaultSort || { 'name': 'desc' },
    }
  },
  computed: {
    selectedRowsCount() {
      let total = this.pagination.total - this.excludedRows
      if (total < 0) {
        return 0
      }
      return total
    },
    actionsArray() {
      return this.actions.split(',').map(action => action.trim().toLowerCase())
    },
    listeners() {
      return {
        ...this.$attrs,
        sort: this.onSort
      }
    },
    actionsColumnWidth() {
      return '1%'
    },
    columnsToDisplay() {
      const columns = this.savedColumns.filter(c => c.checked)
      return orderBy(columns, ['order'], ['asc'])
    }
  },
  methods: {
    get,
    initSortable() {
      if (!this.draggableColumns) {
        return
      }
      const table = this.$el.querySelector('.table__header-wrapper thead tr')
      if (!table) {
        return
      }
      Sortable.create(table, {
        group: 'description',
        filter: '.visible-columns__header',
        fallbackOnBody: true,
        swapThreshold: 0.10,
        animation: 150,
        onStart: function (evt) {
          if (!evt.oldIndex) {
            evt.preventDefault()
            return
          }
          return evt.oldIndex;
        },
        onEnd: async ({ newIndex, oldIndex }) => {
          let clonedColumns = cloneDeep(this.columnsToDisplay)
          const targetRow = clonedColumns.splice(oldIndex, 1)[0]
          clonedColumns.splice(newIndex, 0, targetRow);
          clonedColumns = clonedColumns.map((col, index) => {
            col.order = index
            return col
          })
          this.savedColumns = this.savedColumns.map(col => {
            const matchingCol = clonedColumns.find(c => c.prop === col.prop)
            if (matchingCol) {
              col.order = matchingCol.order
            }
            return col
          })
          this.$store.commit('settings/SET_SAVED_COLUMNS', {
            table: this.tableColumnsKey,
            columns: this.savedColumns
          })
          this.updateColumns(this.savedColumns)
        },
      })
    },
    onSort(column) {
      if (!column.prop) {
        return
      }
      column.toggleSort()
      this.sort = {
        [column.prop]: column.sortDirection
      }
      if (column.sortDirection === SORT_DIRECTION.NONE) {
        this.sort = {}
      }
      if (this.localSort) {
        this.$nextTick(() => {
          this.sortLocalData({
            sort: column.sortDirection !== SORT_DIRECTION.NONE ? column.prop : '',
            sortOrder: column.sortDirection,
          })
        })
        return
      }
      this.refresh()
    },
    isColumnSortable(column) {
      return column.sortable
    },
    getSearchValue(filters) {
      return filters?.find(f => f.field === 'search')?.value || ''
    },
    setFilter(filter) {
      if (!this.$refs.tableFilters) {
        return
      }
      this.$refs.tableFilters.setFilter(filter)
    },
    showAction(action) {
      if (!action) {
        return false
      }
      return this.actionsArray.includes(action)
    },
    async goToLastPage() {
      if (this.pagination.current_page === this.pagination.last_page) {
        return
      }
      this.pagination.current_page = this.pagination.last_page
      await this.refresh()
    },
    async goToNextPage() {
      if (this.pagination.current_page >= this.pagination.last_page) {
        return
      }
      this.pagination.current_page++
      await this.refresh()
    },
    onPerPageChange() {
      this.pagination.current_page = 1
      this.handleLocalPagination(this.pagination.current_page)
      this.refresh()
    },
    async refresh(fromFilterUpdate = false) {
      if (this.localPagination) {
        return
      }
      if (!this.url) {
        this.$emit('force-pagination', this.pagination.per_page)
        return
      }
      try {
        this.loading = true

        if (!this.filters.search) {
          this.dynamicFilters.find((f, index) => {
            if (f.field === 'search') {
              this.dynamicFilters.splice(index, 1)
            }
          })
        }

        let requestData = {
          filters: this.dynamicFilters,
          sort: {
            page: this.pagination.current_page,
            limit: this.pagination.per_page,
            perPage: this.pagination.per_page,
            order: {
              ...this.sort,
            }
          },
          ...this.urlParams
        }

        if (typeof fromFilterUpdate === 'boolean' && fromFilterUpdate) {
          this.filters.search = this.getSearchValue(requestData.filters)
        }

        if (this.filters.search) {
          const searchFilter = {
            field: "search",
            operator: "LIKE",
            value: this.filters.search
          }

          const searchField = requestData.filters.find(f => f.field === 'search')

          if (searchField) {
            searchField.value = this.filters.search
          } else {
            requestData.filters.push(searchFilter)
          }
        } else {
          const searchFieldIndex = requestData.filters.findIndex(f => f.field === 'search')
          if (searchFieldIndex !== -1) {
            requestData.filters.splice(searchFieldIndex, 1)
          }
        }

        let data
        let pagination
        const fullUrl = this.url + this.urlQuery
        const actionType = this.actionType

        if (actionType === 'get') {
          requestData = this.getRequestParams(requestData)
        }

        if (this.showPagination) {
          const res = await axios[actionType](fullUrl, requestData)
          data = res.data
          pagination = res.pagination
        } else {
          data = await axios[actionType](fullUrl, requestData)
        }
        if (!this.totalRowsBeforeFilter && this.showPagination) {
          this.totalRowsBeforeFilter = pagination.total
        }
        if (this.showPagination) {
          this.pagination = {
            ...pagination,
            current_page: pagination.page,
            per_page: this.pagination.per_page,
          }
        }
        if (this.dataMapFunction) {
          this.tableData = this.dataMapFunction(data)
        } else {
          this.tableData = data
        }
        this.$emit('data-fetch', this.tableData, this.filters.search, this.pagination)
        this.$emit('on-change-filters', requestData.filters)
        this.$emit('pagination-fetch', pagination)
      } catch (err) {
        this.$error(this.$t('Could not fetch the table data'))
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    getRequestParams(requestData) {
      const params = {}
      Object.keys(requestData).forEach(key => {
        const isObject = typeof requestData[key] === 'object' && !Array.isArray(requestData[key])
        if (isObject) {
          Object.assign(params, requestData[key])
        } else {
          params[key] = requestData[key];
        }
      })
      return { params }
    },
    async onDelete(row, index) {
      try {
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Delete row'),
          description: this.$t(this.onDeleteConfirmMessage)
        })

        if (!confirmed) {
          return
        }

        if (this.deleteAction) {
          await this.deleteAction(row, index)
          return
        }

        await axios.delete(`${this.url}/${row.id}`)

        if (index === undefined || index < 0) {
          return
        }
        this.tableData.splice(index, 1)

        this.$success('Row deleted successfully')
      } catch (err) {
        console.warn(err)
        this.$error('Could not delete the specified row')
      }
      this.$emit('delete', row)
    },
    async getById(id) {
      if (!this.showAction('edit')) {
        return
      }
      const { data } = await axios.get(`${this.url}/${id}`)
      this.$emit('edit', data)
    },
    updateColumns(value) {
      this.savedColumns = cloneDeep(value)
      this.tableKey = Math.random()
    },
    initSavedColumns() {
      let visibleTableColumns = this.$store.state.settings.savedColumns[this.tableColumnsKey];
      let allColumns = this.columns.map((item, index) => {
        item.order = index
        item.checked = !item.hide
        return item
      })

      function findColIndex(columns, column) {
        return columns.findIndex(c => c.name === column.name && c.prop === column.prop)
      }

      if (visibleTableColumns) {
        visibleTableColumns.forEach(column => {
          let matchingColIndex = findColIndex(allColumns, column)
          if (matchingColIndex === -1) {
            return
          }
          const matchingColumn = allColumns[matchingColIndex]
          allColumns[matchingColIndex] = {
            ...matchingColumn,
            ...column,
            maxWidth: matchingColumn?.maxWidth,
            width: matchingColumn?.width,
          }
        })
      }

      this.savedColumns = allColumns
    },
    handleLocalPagination(value, searchedData, shouldSort = true) {
      if (!this.localPagination) {
        return
      }
      let allData = searchedData || this.initialTableData
      this.pagination.total = allData.length
      const skip = (value - 1) * this.pagination.per_page

      if (this.localSort && Object.keys(this.sort).length && shouldSort) {
        const sortProp = Object.keys(this.sort)[0]
        const sortDirection = this.sort[sortProp]

        let data = cloneDeep(allData)
        data = orderBy(data, [item => this.get(item, sortProp) || ''], [sortDirection])
        this.tableData = data.slice(skip, this.pagination.per_page + skip)
      } else {
        this.tableData = allData.slice(skip, this.pagination.per_page + skip)
      }
    },
    sortLocalData(params, paginate = true) {
      let tableData = this.initialTableData?.length ? this.initialTableData : this.tableData
      if (this.filters.search) {
        tableData = this.tableData
      }
      let data = cloneDeep(tableData)
      if (params.sort) {
        data = orderBy(data, [item => this.get(item, params.sort) || ''], [params.sortOrder])
      }
      this.sort = {
        [params.sort]: params.sortOrder
      }
      this.tableData = data
      if (paginate) {
        this.handleLocalPagination(this.pagination.current_page, this.tableData, false)
      }
      return data
    },
    onLocalSearch(query) {
      if (!this.localSearch) {
        return
      }
      try {
        if (!query) {
          this.tableData = cloneDeep(this.initialTableData)
          this.handleLocalPagination(1, this.tableData)
          return
        }
        const result = this.fuseSearch.search(query)
        this.tableData = result.map(row => {
          return {
            ...row.item,
            matches: row.matches,
          }
        })
        this.handleLocalPagination(1, this.tableData)
      } catch (err) {
        console.warn(err)
      }
    },
    initLocalData() {
      const keys = this.columns.map(c => c.prop)
      this.fuseSearch = new Fuse(this.initialTableData, {
        minMatchCharLength: 2,
        threshold: 0.2,
        includeMatches: true,
        keys,
      })
      if (!this.localSort || !this.defaultSort) {
        return
      }
      const defaultSort = Object.keys(this.defaultSort)[0]
      const sortDirection = this.defaultSort[defaultSort]
      this.$nextTick(() => {
        this.sortLocalData({
          sort: defaultSort,
          sortOrder: sortDirection,
        })
      })
    },
    getCellValue(row, column) {
      const data = get(row, column.prop)
      if (data === undefined || data === null || data === '') {
        return '- -'
      }
      return data
    }
  },
  mounted() {
    this.refresh()
    document.addEventListener('keyup', event => {
      if (event.target && event.target.nodeName === 'INPUT') {
        return
      }
      if (event.code === 'Equal' || event.key === '+' || event.key === '=') {
        this.$emit('add', event)
      }
    })
    this.initSavedColumns()
    this.initSortable()
    this.initLocalData()
  },
  watch: {
    'pagination.current_page': {
      immediate: true,
      handler(value) {
        this.handleLocalPagination(value)
      }
    },
    'initialFilters': {
      immediate: true,
      handler(val) {
        if (isEqual(this.dynamicFilters, val)) {
          return
        }
        if (!this.filters.search) {
          this.filters.search = this.getSearchValue(val)
        }
        if (this.dynamicFilters?.length === 0) {
          this.dynamicFilters = cloneDeep(val)
        }

        // Skip data refresh if component is not mounted
        if (!this.$el) {
          return
        }
        this.refresh()
      },
      filters(val) {
        const searchValue = this.getSearchValue(val)
        if (this.filters.search !== searchValue) {
          this.filters.search = searchValue
        }
      }
    },
    '$route.query.id': {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.getById(value)
        }
      },
    },
    data(value) {
      this.tableData = cloneDeep(value)
      this.initialTableData = cloneDeep(value)
      this.handleLocalPagination(this.pagination.current_page)
      this.initLocalData()
    },
    url() {
      this.refresh()
    },
    urlParams: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    urlQuery() {
      this.refresh()
    }
  },
}
</script>
<style>
.search-input {
  min-width: 250px;
}

.add-button {
  margin-top: 6px;
}
</style>
