<template>
  <base-tabs
      v-model="financeTab"
      class="w-full mb-4 md:mb-0"
      :getItemClass="getItemClass"
      :items="tabItems"
  />
  <financial-settings
      v-if="financeTab === 'general'"
      @changeTab="financeTab = $event"
  />
  <budget-settings v-if="financeTab === 'media budget'"/>
  <budget-stopper v-if="financeTab === 'account budget stopper'"/>
  <mandate-settings
      v-if="financeTab === 'payment methods'"
      @changeTab="financeTab = $event"/>
</template>

<script>
import FinancialSettings from "@/modules/dashboard/components/FinancialSettings.vue";
import BudgetSettings from "@/modules/dashboard/components/BudgetSettings.vue";
import BudgetStopper from "@/modules/dashboard/components/BudgetStopper.vue";
import MandateSettings from "@/modules/dashboard/components/MandateSettings.vue";
import FinancialService from "../services/FinancialService";

export default {
  name: "finance",
  components: {
    BudgetSettings,
    FinancialSettings,
    MandateSettings,
    BudgetStopper
  },
  childPaths: [
    {
      name: 'Financial Settings',
      description: 'Financial general settings',
      path: '/finance/general'
    },
  ],
  data() {
    return {
      financeTab: ''
    }
  },
  methods: {
    getItemClass(item) {
      const isAdminItem = this.adminTabs.includes(item)
      return isAdminItem ? 'bg-red-50' : ''
    }
  },
  computed: {
    adminTabs() {
      return ['payment methods']
    },
    tabItems() {
      if (!this.$isOwner) {
        return []
      }
      const ownerTabs = ['general', 'media budget', 'account budget stopper']
      if (this.$isAdmin) {
        return ownerTabs.concat(this.adminTabs)
      }
      return ownerTabs
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(val) {
        if (val.query.verify) {
          const res = await FinancialService.verifyCheckoutMediaBudget(val.query.token)

          if (res) {
            this.$success(this.$t('Payment successful'))
          }

          await this.$router.replace({
            path: val.path
          })
        }
        if (val.path.indexOf('finance') === -1) {
          return
        }
        const paramId = val.params.id
        if (!paramId) {
          return
        }
        this.financeTab = paramId
      }
    },
    financeTab: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }

        this.$router.push({
          path: `/finance/${val}`,
          query: this.$route.query
        })
      }
    }
  }
}
</script>
