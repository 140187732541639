import AuthService from "@/modules/auth/services/AuthService.js";
import UserService from "@/modules/auth/services/UserService.js";
import FinancialService from "../../dashboard/services/FinancialService";
import { differenceInDays } from 'date-fns'
import { USER_STATUSES } from "@/enum/userEnums.js";
import { changeLocale } from "@/i18n.js";

const types = {
  SET_USER: 'SET_USER',
  SET_CLIENT: 'SET_CLIENT',
  SET_PROFILE_OBJECT: 'SET_PROFILE_OBJECT',
  SET_ALL_CLIENTS: 'SET_ALL_CLIENTS',
  SET_SPEND_STATS: 'SET_SPEND_STATS',
  SET_USER_SUBSCRIPTIONS: 'SET_SPEND_STATS',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  SET_STORED_SUBSCRIPTION: 'SET_STORED_SUBSCRIPTION',
};
const state = {
  user: {},
  spendStats: {},
  allClients: [],
  selectedClient: {},
  userSubscriptions: [],
  storedSubscription: {},
  isLoggedIn: false,
  profileObject: null,
};

const mutations = {
  [types.SET_USER]: (state, value) => {
    state.user = value;
  },
  [types.SET_CLIENT]: (state, value) => {
    state.selectedClient = value;
  },
  [types.SET_ALL_CLIENTS]: (state, value) => {
    state.allClients = value;
  },
  [types.SET_SPEND_STATS]: (state, value) => {
    state.spendStats = value;
  },
  [types.SET_USER_SUBSCRIPTIONS]: (state, value) => {
    state.userSubscriptions = value;
  },
  [types.SET_IS_LOGGED_IN]: (state, value) => {
    state.isLoggedIn = value;
  },
  [types.SET_STORED_SUBSCRIPTION]: (state, value) => {
    state.storedSubscription = value;
  },
  [types.SET_PROFILE_OBJECT]: (state, value) => {
    state.profileObject = value
  }
};

const actions = {
  async login({ commit, dispatch }, data) {
    try {
      await AuthService.login(data);
      commit(types.SET_IS_LOGGED_IN, true);
    } catch (err) {
      if (![400, 401, 422].includes(err.response.status)) {
        window.app.$error('An error occurred during login. Please try again or contact us')
      }
      throw err
    }
  },
  async register({ commit, dispatch }, { data, params }) {
    try {
      const res = await AuthService.register(data, params)
      debugger
      localStorage.setItem('token', res.token);
      commit(types.SET_IS_LOGGED_IN, true);
      return {}
    } catch (err) {
      console.warn(err)
      throw err
    }
  },
  logout({ commit }) {
    commit(types.SET_IS_LOGGED_IN, false);
    commit('settings/SET_REPORTS_DATE', {}, { root: true });
    commit('settings/setDisplayedRemainingBudgetWarning', {}, { root: true });
    commit('settings/setSettings', {}, { root: true });
    AuthService.logout();
  },
  async refreshProfile({ commit, state }, forceRefresh = true) {
    try {
      if (!forceRefresh && state.profileObject) {
        return state.profileObject
      }
      let subscriptions
      const res = await UserService.getProfile();
      if (res.client) {
        subscriptions = await FinancialService.getSubscriptionList()
      }
      commit(types.SET_PROFILE_OBJECT, res);
      commit(types.SET_USER, res.user);
      commit(types.SET_CLIENT, res.client);
      commit(types.SET_ALL_CLIENTS, res.clients);
      commit(types.SET_SPEND_STATS, res.spend);
      commit(types.SET_USER_SUBSCRIPTIONS, subscriptions);
      changeLocale(res.user?.active_locale)
      return res;
    } catch (err) {
      console.warn(err)
    }
  },
  async switchClient({ commit, state, dispatch }, clientId) {
    await UserService.changeOrganization({
      client_id: clientId,
    })
    await dispatch('refreshProfile', true)
  }
};
const getters = {
  user: state => state.user,
  selectedClient: state => state.selectedClient,
  permissions: state => {
    return []
  },
  activeSubscription: (state, getters) => {
    const { subscription, status } = state?.selectedClient || {}
    const validStatuses = [USER_STATUSES.VERIFIED, USER_STATUSES.NEW]
    if (subscription && validStatuses.includes(status)) {
      return true
    }

    return getters.trialDaysLeft > 0
  },
  isTrial: state => {
    const subscription = state.selectedClient?.subscription
    if (subscription) {
      return false
    }
    return !!state.selectedClient?.trial_period
  },
  trialDaysLeft: (state, getters) => {
    if (!getters.isTrial) {
      return 0
    }
    const trialPeriod = state.selectedClient?.trial_period
    return differenceInDays(new Date(trialPeriod), new Date())
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
