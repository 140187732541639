import { USER_STATUSES } from '@/enum/userEnums'
import { $notifyDialog } from "@/components/common/modal/modalPlugin.js";
import i18n from "@/i18n.js";

export default function hasClientAndSubscriptionMiddleware(router, store, app) {
  router.beforeEach(async (to, from, next) => {
    const loginPage = to.path.startsWith('/login')
    if (loginPage) {
      return next();
    }
    const requiresAuth = to.matched.some(record => record.meta?.requiresClient);
    const profile = await store.dispatch('auth/refreshProfile', false)
    const selectedClient = store.state.auth.selectedClient
    const hasSubscription = store.getters['auth/activeSubscription']
    const inactive = selectedClient?.status === USER_STATUSES.INACTIVE
    const isVerified = selectedClient?.status === USER_STATUSES.VERIFIED

    if (!requiresAuth) {
      return next();
    }

    if (!isVerified && !hasSubscription) {
      notifyAboutStatus()
    }

    if (!profile?.client) {
      return next('/client-create');
    } else if (!hasSubscription) {
      return next({
        path: '/contact-us',
        query: {
          inactive: inactive,
        }
      });
    }

    return next();
  });

  function notifyAboutStatus() {
    const accountStatus = store.state.auth.selectedClient?.status || 'Inactive'
    const message = `
            ${i18n.t(`Your account is `)} <b>${accountStatus}</b> <br/> ${i18n.t('please')} <a href='mailto:support@jobmatix.com' class='cursor-pointer'>  ${i18n.t('contact us')} </a>
          .`
    $notifyDialog({
      type: 'warning',
      title: message,
      buttonText: i18n.t('Continue'),
    }).then(() => {
      console.log('Notification triggered')
    })
  }
}
