import AuthLayout from "../auth/layout/AuthLayout.vue";
import Login from './pages/login.vue'
import ForgotPassword from './pages/forgot-password.vue'
import ResetPassword from './pages/reset-password.vue'
import i18n from "@/i18n";

function hasToken() {
  return localStorage.getItem('token')
}

export default [
  {
    path: '/',
    components: {
      default: AuthLayout
    },
    redirect: hasToken() ? '/welcome' : '/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword,
      },
      {
        path: '/password/reset',
        name: 'Reset Password',
        component: ResetPassword
      },
    ]
  },
]
