import { createApp } from "vue";

export default {
  install(Vue) {
    let app = createApp({
      methods: {
        can(permissions) {
          // TODO Handle user permissions here if needed
          return true
        }
      }
    });
    Vue.provide('$can', app.can)
  }
}
