<template>
  <div>
    <div class="block">
      <nav class="flex overflow-x-auto flex-wrap">
        <span v-for="item in items"
              :key="item"
              :class="{
                'bg-primary-100 text-primary-900 dark:bg-primary-900 dark:text-primary-200': isActive(item),
                [getItemClass(item)]: true,
              }"
              :aria-current="isActive(item)"
              @click="onChange(item)"
              class="navigation-item flex items-center justify-center px-3 py-2 font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 focus:bg-gray-100 cursor-pointer
              dark:text-gray-200 mr-4">
          {{ capitalize($t(item.title || item)) }}
        </span>
      </nav>
    </div>
  </div>
</template>
<script>
import { capitalize } from 'lodash'
export default {
  props: {
    modelValue: {
      type: String,
    },
    items: {
      type: Array,
      default: () => []
    },
    getItemClass: {
      type: Function,
      default: () => '',
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      activeItem: this.modelValue
    }
  },
  methods: {
    capitalize,
    onChange(item) {
      this.activeItem = item
      if (item.route) {
        this.$router.push(item.route)
        return
      }
      this.$emit('update:modelValue', item)
    },
    isActive(item) {
      if (item.route) {
        return this.$route.path === item.route
      }
      return item === this.activeItem
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        if (val) {
          this.activeItem = val
        }
      }
    }
  }
}
</script>
