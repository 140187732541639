<template>
    <status-badge :status="value"/>
</template>
<script>
  import { get }  from 'lodash'
  import StatusBadge from "@/components/common/StatusBadge.vue";

  export default {
    components: {
      StatusBadge
    },
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      value() {
        const value = get(this.row, this.column.prop)
        if (value === 1 || value === true || value === '1') {
          return this.$t('Yes')
        }
        if (value === null || value === 0 || value === false || value === '0') {
          return this.$t('No')
        }
        if (value === undefined) {
          return 'N/A'
        }
        return value
      }
    },
  }
</script>
<style>
</style>
