<template>
  <slot></slot>
  <div class="flex items-center"
       :class="wrapperClass">
    <JobTags v-show="modelValue.show"
             v-bind="$attrs"
             class="text-sm w-full"
             :max-tags="maxTags"
             :tags="modelValue.fields"
             :hide-add-input="modelValue.fields?.length >= maxTags"
             :capitalize="false"
             :disable-autocomplete="disableAutocomplete"
             :tagInputRef="tagInputRef"
             :ref="tagInputRef"
             :fetch-data-action="fetchDataAction"
             @delete="onDeleteField($event)"
             @onKeydownEnter="onAddField($event)"
    />
  </div>
</template>

<script>
import JobTags from "@/modules/jobs/components/JobTags.vue";

export default {
  name: "MultiFieldInput",
  emits: ['update:modelValue'],
  inheritAttrs: false,
  components: {
    JobTags
  },
  props: {
    modelValue: {
      deep: true,
      type: Object,
      default: () => ({})
    },
    tagInputRef: {
      type: String,
      default: 'tagInputRef'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    minLength: {
      type: Number,
      default: 5
    },
    maxTags: {
      type: Number,
      default: 5,
    },
    mapLabel: {
      type: Function,
    },
    fetchDataAction: {
      type: Function,
    },
    disableAutocomplete: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onAddField(evt) {
      let label = this.mapLabel ? this.mapLabel(evt) : evt;
      if (typeof evt === 'string') {
        label = evt
      }
      if (label.length < this.minLength) {
        this.$error(this.$t('Optional field must have minimum 2 characters.'));
        return
      }
      this.modelValue.fields.push(label)
    },
    onDeleteField(evt) {
      const label = this.mapLabel ? this.mapLabel(evt) : evt;
      this.modelValue.fields.splice(this.modelValue.fields.indexOf(label), 1)
    },
  },
  watch: {
    'modelValue': {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.show) {
          this.$refs[this.tagInputRef] && this.$refs[this.tagInputRef].showTagInput()
        }
      }
    }
  }
}
</script>
