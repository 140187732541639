<template>
  <base-button variant="white"
               class="cancel-button"
               v-bind="$attrs">
    <slot>
      {{ $t('Cancel') }}
    </slot>
  </base-button>
</template>
<script>
export default {
  inheritAttrs: false,
}
</script>
<style>
.cancel-button {
  min-width: 120px;
}
</style>
