<template>
  <BaseNoPermissions v-if="!$isAdmin" />
  <div v-else class="mt-4">
    <base-form @submit="submitForm"
               :loading="loading"
               grid-classes="flex flex-col"
               layout="modal">
      <div class="w-full">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('UTM settings') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>

        <div class="mt-6">
          <base-switch
              v-model="model.utm_active"
              :label="$t('Active')"/>
          <div class="flex flex-col md:flex-row mt-4">
            <base-input v-model="model.utm_source"
                        :label="$t('UTM Source')"
                        class="w-full md:mr-4"
                        id="utmSource"/>
            <base-input v-model="model.utm_camaign"
                        :label="$t('UTM Campaign')"
                        class="w-full"
                        id="utmCampaign"/>
          </div>
          <div class="flex flex-col md:flex-row">
            <base-input v-model="model.utm_medium_free"
                        :label="$t('UTM Medium free')"
                        class="w-full md:mr-4"
                        id="utmFree"/>
            <base-input v-model="model.utm_medium_cpc"
                        :label="$t('UTM Medium CPC')"
                        class="w-full"
                        id="utmCpc"/>
          </div>
          <div class="flex flex-col md:flex-row">
            <base-input v-model="model.utm_content"
                        :label="$t('UTM Content')"
                        class="w-full md:mr-4"
                        id="utmContent"/>
            <base-input v-model="model.utm_term"
                        :label="$t('UTM Term')"
                        class="w-full"
                        id="utmTerm"/>
          </div>
        </div>
      </div>
    </base-form>
  </div>
</template>

<script>
import SettingsService from "../services/SettingsService";

export default {
  name: "UTMSettings",
  data() {
    return {
      model: {
        utm_active: false,
        utm_source: null,
        utm_camaign: null,
        utm_medium_free: null,
        utm_medium_cpc: null,
        utm_content: null,
        utm_term: null,
      },
      loading: false
    }
  },
  computed: {
    utmSettings() {
      return this.$store.state.settings.settings?.utm
    },
  },
  watch: {
    utmSettings: {
      handler(val) {
        if (!val) {
          return
        }
        this.initModel(val)
      },
      immediate: true
    }
  },
  methods: {
    initModel(data) {
      this.model.utm_active = data.utm_active
      this.model.utm_source = data.utm_source
      this.model.utm_camaign = data.utm_camaign
      this.model.utm_term = data.utm_term
      this.model.utm_content = data.utm_content
      this.model.utm_medium_cpc = data.utm_medium_cpc
      this.model.utm_medium_free = data.utm_medium_free
    },
    async submitForm() {
      let res
      try {
        this.loading = true
        res = await SettingsService.saveUtm(this.model)
      } finally {
        this.loading = false
      }
      if (!res) {
        return
      }
      this.$success(this.$t('Successfully updated targets'))
    }
  }
}
</script>
