<template>
  <div class="w-screen flex-col-grow h-screen">
    <notifications/>
    <RouterView />
  </div>
</template>
<script>
import bus from "@/components/charts/echarts/bus.js";

export default {
  mounted() {
    this.$store.dispatch('theme/setDefaultPreferences')
    window.addEventListener('resize', () => {
      window.requestAnimationFrame(() => {
        bus.$emit('resize')
      })
    })
  },
}
</script>
<style lang="scss">
</style>
