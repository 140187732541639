<template>
  <div>
    <report-date-picker
        :key="dateKey"
        v-model:from="from"
        v-model:till="till"
        @update:till="onChangeTillDate"/>

    <dashboard-reports :from="from"
                       :till="till"/>

    <div class="mt-4"></div>
    <div class="mt-4">
      <channels-table :from="from" :till="till"/>
    </div>
    <div class="mt-4">
      <campaigns-table :from="from" :till="till"/>
    </div>
    <div class="mt-4">
      <jobs-table :from="from" :till="till"/>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { format, subMonths } from "date-fns";
import DashboardReports from "@/modules/dashboard/components/DashboardReports.vue";
import ReportDatePicker from "@/components/report/ReportDatePicker.vue";
import JobsTable from "@/modules/reports/components/JobsTable.vue";
import CampaignsTable from "@/modules/reports/components/CampaignsTable.vue";
import ChannelsTable from "@/modules/reports/components/ChannelsTable.vue";

export default {
  components: {
    JobsTable,
    CampaignsTable,
    ChannelsTable,
    ReportDatePicker,
    DashboardReports,
  },
  data() {
    const reportsDate = this.$store.state.settings.reportsDate
    const from = reportsDate?.from ? new Date(reportsDate.from) : subMonths(new Date(), 1)
    const till = reportsDate?.till ? new Date(reportsDate.till) : new Date()
    return {
      loading: false,
      from,
      till,
      dateKey: 0
    }
  },
  methods: {
    async onChangeTillDate() {
      await this.debouncedRefreshData()
    },
    async refreshData() {
      await this.initData({
        date_from: format(this.from, 'yyyy-MM-dd'),
        date_till: format(this.till, 'yyyy-MM-dd'),
        date_range: 'custom'
      })
    },
    async initData(params) {
      this.loading = false
    }
  },
  created() {
    this.debouncedRefreshData = debounce(this.refreshData, 100)
  },
  mounted() {
    this.dateKey = Date.now()
  }
}
</script>
<style>
</style>
