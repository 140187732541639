import * as echarts from 'echarts/core';
import {
  GridComponent,
  DataZoomComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
} from 'echarts/components';
import {
  BarChart,
  LineChart,
  ScatterChart,
  PieChart,
} from 'echarts/charts';
import {
  SVGRenderer
} from 'echarts/renderers';

echarts.use(
  [
    GridComponent,
    LineChart,
    ScatterChart,
    BarChart,
    PieChart,
    SVGRenderer,
    DataZoomComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
  ]
);

export default echarts
