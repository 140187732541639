<template>
  <div id="welcome-page">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <dl
      class="mb-10 grid grid-cols-1 gap-x-5 gap-y-10 sm:grid-cols-3"
      :class="{
          'sm:grid-cols-3': !$isReadOnly,
          'sm:grid-cols-2': $isReadOnly
        }"
    >
      <base-stats-card id="jobs-stats-card"
                       :loading="loading">
        <template v-slot:icon>
          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
          </svg>
        </template>
        <template v-slot:content>
          <report-actions-dropdown
              :export-name="exportName"
              export-id="jobs-stats-card"
          />
          <router-link to="/jobs/all">
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2 hover:underline hover:text-blue-500">
              {{ $t('All Jobs') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ get(kpi, 'jobs_active', '- -') }}
              </div>
            </dd>
          </router-link>
          <router-link to="/jobs/sponsored-jobs">
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2 hover:underline hover:text-blue-500">
              {{ $t('Sponsored jobs') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ get(kpi, 'sponsored_jobs', '- -') }}
              </div>
            </dd>
          </router-link>
          <router-link v-if="$isDevEnv()" to="/jobs/nonsponsored-jobs">
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2 hover:underline hover:text-blue-500">
              {{ $t('Non-sponsored jobs') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ get(kpi, 'jobs_active', '- -') - get(kpi, 'sponsored_jobs', '- -') }}
              </div>
            </dd>
          </router-link>
        </template>

        <template v-slot:footer>
          <router-link to="/jobs/all">
            <base-button variant="primary">
              {{ $t('All Jobs') }}
            </base-button>
          </router-link>
        </template>
      </base-stats-card>

      <base-stats-card id="jobs-stats-card"
                       :loading="loading">
        <template v-slot:icon>
          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
          </svg>
        </template>
        <template v-slot:content>
          <report-actions-dropdown
              :export-name="exportName"
              export-id="jobs-stats-card"
          />
          <div>
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2">
              {{ $t('All Campaigns') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ get(kpi, 'campaigns_active', '- -') }}
              </div>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2">
              {{ $t('Campaigns budget') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ $formatPrice(get(kpi, 'campaigns_budget', 0)) }}
              </div>
            </dd>
          </div>
        </template>

        <template v-slot:footer>
          <base-button
              v-if="$canCreateCampaigns"
              variant="primary"
              @click="$router.push('/campaigns/all')"
          >
            {{ $t('All Campaigns') }}
          </base-button>
        </template>
      </base-stats-card>
      <base-stats-card
          v-if="!$isReadOnly"
          id="budget-stats-card"
          :loading="loading"
      >
        <template v-slot:icon>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="coins"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-6 w-6 text-white">
            <path fill="currentColor"
                  d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"
                  class=""></path>
          </svg>
        </template>
        <template v-slot:content>
          <report-actions-dropdown
              :export-name="exportName"
              export-id="budget-stats-card"
          />
          <div v-if="isMediaBudgetEnabled">
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2">
              {{ $t('Media budget deposited') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ $formatPrice(get(kpi, 'media_budget', 0)) }}
              </div>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2">
              {{ $t('Spent this month') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ $formatPrice(spentCurrentMonth) }}
              </div>
            </dd>
          </div>
          <div v-if="isMediaBudgetEnabled">
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2">
              {{ $t('Media budget available') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ $formatPrice(get(kpi, 'media_budget_available', 0)) }}
              </div>
            </dd>
          </div>
          <router-link v-if="!$isReadOnly && accountBudgetStopper" to="/finance/account%20budget%20stopper">
            <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate leading-4 pb-2">
              {{ $t('Remaining account budget') }}
            </dt>
            <dd class="flex items-baseline justify-center">
              <div class="text-2xl font-semibold text-gray-900 dark:text-gray-100 leading-6 pb-2">
                {{ $formatPrice(remainingBudget) }}
              </div>
            </dd>
          </router-link>

        </template>
        <template v-slot:footer>
          <base-button
              v-if="$isOwner"
              variant="primary"
              @click="$router.push('/finance')"
          >
            {{ $t('More finance') }}
          </base-button>
        </template>

      </base-stats-card>
    </dl>

    <dashboard-reports :from="from" :till="till">
      <template #actions>
        <report-date-picker
            :key="dateKey"
            class="mb-0"
            v-model:from="from"
            v-model:till="till"
            @update:till="onChangeTillDate"
        />
      </template>
    </dashboard-reports>

    <div class="mt-10 pb-4">
      <template v-if="false">
        <base-tabs v-model="jobsTab" :items="tabItems" class="mb-4"/>
        <jobs v-if="jobsTab === $t('Your Jobs')"
              :per-page="10"
              :createCampaignButton="false"
              actions="view,search"
              @pagination-fetch="onTableFetchData"
        >
          <template v-slot:empty>
            <div class="flex justify-evenly my-10">
              <router-link to="/jobs/job-create">
                <base-button variant="primary">
                  {{ $t('Add a job') }}
                </base-button>
              </router-link>
              <router-link to="/settings/feeds">
                <base-button variant="primary">
                  {{ $t('Add a feed') }}
                </base-button>
              </router-link>
            </div>
          </template>
        </jobs>
      </template>
    </div>
  </div>
</template>

<script>
import { get, debounce } from 'lodash'
import { subMonths, format } from "date-fns";
import BaseStatsCard from "@/components/common/BaseStatsCard.vue";
import DashboardService from "@/modules/dashboard/services/DashboardService.js";
import DashboardReports from "@/modules/dashboard/components/DashboardReports.vue";
import ReportDatePicker from "@/components/report/ReportDatePicker.vue";
import Jobs from '@/modules/jobs/pages/jobs.vue'
import axios from "axios";
import ReportActionsDropdown from "@/modules/dashboard/components/ReportActionsDropdown.vue";
import { getReportExportName } from "@/modules/reports/utils/getReportExportName.js";

export default {
  name: 'Welcome',
  components: {
    Jobs,
    BaseStatsCard,
    DashboardReports,
    ReportDatePicker,
    ReportActionsDropdown,
  },
  data() {
    const reportsDate = this.$store.state.settings.reportsDate
    const from = reportsDate?.from ? new Date(reportsDate.from) : subMonths(new Date(), 1)
    const till = reportsDate?.till ? new Date(reportsDate.till) : new Date()
    return {
      jobsTab: this.$t('Your Jobs'),
      kpi: {},
      overPerfJobs: [],
      underPerfJobs: [],
      jobCount: null,
      loading: false,
      from,
      till,
      dateKey: 0,
      cancelSource1: null,
      cancelSource2: null,
      cancelSource3: null,
    }
  },
  created() {
    this.debouncedRefreshData = debounce(this.refreshData, 100)
  },
  async mounted() {
    this.loading = true
    await this.initData({
      date_from: format(this.from, 'yyyy-MM-dd'),
      date_till: format(this.till, 'yyyy-MM-dd'),
      date_range: 'custom'
    })
    this.dateKey = Date.now()
  },
  computed: {
    kpi() {
      return this.$store.state.settings?.dashboardKpi
    },
    accountBudgetStopper() {
      return this.$store.getters['settings/accountBudgetStopper']
    },
    remainingBudget() {
      return this.$store.getters['settings/remainingBudget']
    },
    spentCurrentMonth() {
      return this.$store.getters['settings/spentCurrentMonth']
    },
    isMediaBudgetEnabled() {
      return this.$store.state.auth?.selectedClient?.payment_type === 'MEDIA-BUDGET'
    },
    tabItems() {
      return [this.$t('Your Jobs')]
    },
    exportName() {
      return getReportExportName({
        from: this.from,
        till: this.till,
        reportName: this.$t('job_stats'),
      })
    },
  },
  methods: {
    get,
    async onChangeTillDate() {
      await this.debouncedRefreshData()
    },
    async initData(params) {
      const overJobsParams = {
        ...params,
        type: 'jobs',
        sort: 'desc',
        limit: 10
      }
      const underJobsParams = {
        ...overJobsParams,
        sort: 'asc',
      }
      this.cancelSource1 = axios.CancelToken.source()
      this.cancelSource2 = axios.CancelToken.source()
      this.cancelSource3 = axios.CancelToken.source()
      const [overJobs, underJobs] = await Promise.all([
        await DashboardService.getPerformingJobs(overJobsParams, this.cancelSource2),
        await DashboardService.getPerformingJobs(underJobsParams, this.cancelSource3),
      ])

      this.loading = false
      if (!overJobs || !underJobs) {
        return
      }
      this.overPerfJobs = overJobs.results || []
      this.underPerfJobs = underJobs.results || []
    },
    goToJobPage(row, tab) {
      this.$router.push({path: `/jobs/${row.id}`, query: {title: row.name, tab: tab}})
    },
    onTableFetchData(data) {
      this.jobCount = data.total
    },
    async refreshData() {
      await this.initData({
        date_from: format(this.from, 'yyyy-MM-dd'),
        date_till: format(this.till, 'yyyy-MM-dd'),
        date_range: 'custom'
      })
    }
  },
  beforeUnmount() {
    this.cancelSource1?.cancel()
    this.cancelSource2?.cancel()
    this.cancelSource3?.cancel()
  },
  watch: {
    async from(val) {
      if (!val) {
        return
      }
      await this.debouncedRefreshData()
    }
  }
}
</script>
<style lang="scss" scoped>
.date-picker__wrapper {
  @apply flex justify-end;
}
</style>
