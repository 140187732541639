export const operators = {
  IS: {
    label: 'is',
    value: 'IS'
  },
  IS_NOT: {
    label: 'is not',
    value: 'ISNOT'
  },
  IN: {
    label: 'in',
    value: 'IN'
  },
  NOT_IN: {
    label: 'not in',
    value: 'NOTIN'
  },
  LIKE: {
    label: 'like',
    value: 'LIKE'
  },
  NOT_LIKE: {
    label: 'like',
    value: 'NOTLIKE'
  },
  LTE: {
    label: 'less than or equal',
    value: '<='
  },
  GTE: {
    label: 'greater than or equal',
    value: '>='
  },
  BETWEEN: {
    label: 'between',
    value: '><'
  }
}
