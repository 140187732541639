<template>
  <div class="lang-switcher">
    <img class="w-10 my-auto cursor-pointer absolute lang-switcher__img"
         :src="selectedLang?.flag"
         :alt="`flag-${selectedLang?.flag}`"/>
    <el-select v-model="locale"
               @change="setSelectedLang"
               class="lang-switcher__select"
               popper-class="lang-switcher__popper">
      <el-option v-for="item in langOptions"
                 :key="item.value"
                 class="lang-switcher__option"
                 :label="item.label"
                 :value="item.value">
        <div class="lang-switcher__option">
          <img class="w-10 my-auto cursor-pointer lang-switcher__option-svg"
               :src="item.flag"
               :alt="`flag-${item.iso}`">
          <span class="lang-switcher__option-label text-navy">{{ $t(item.label) }}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { ElSelect, ElOption } from 'element-plus'
import { useI18n } from 'vue-i18n'
import UserService from "@/modules/auth/services/UserService.js";

const langOptions = [
  {
    label: 'English',
    value: 'en-GB',
    iso: 'en',
    flag: 'https://raw.githubusercontent.com/behdad/region-flags/gh-pages/png/GB.png'
  },
  {
    label: 'Dutch',
    value: 'nl-NL',
    iso: 'nl',
    flag: 'https://raw.githubusercontent.com/behdad/region-flags/gh-pages/png/NL.png'
  }
]

export default {
  name: 'LangSwitcher',
  components: {
    ElSelect,
    ElOption,
  },
  setup() {
    const { locale } = useI18n()
    return { locale }
  },
  data() {
    return {
      langOptions,
    }
  },
  computed: {
    selectedLang() {
      return langOptions.find(l => l.value === this.locale) || {}
    }
  },
  methods: {
    async setSelectedLang() {
      const activeLocale = this.$i18n?.locale

      if (this.$user.active_locale === activeLocale) {
        return
      }
      try {
        await UserService.patchProfile({
          active_locale: activeLocale
        });
        await this.$store.dispatch('auth/refreshProfile', true)
      } catch (err) {
        console.warn(err)
      }
    }
  },
}
</script>

<style lang="scss">
.lang-switcher {
  position: relative;
  display: flex;
  width: 62px;
  align-items: center;
  margin-right: -10px 0;
}

.lang-switcher__img {
  position: absolute;
  width: 30px;
  height: auto;
  left: 0;
}

.lang-switcher__select {
  cursor: pointer;

  .el-input__inner {
    visibility: hidden;
    padding-right: 0;
  }
}

.lang-switcher__option {
  color: inherit;
  display: flex;

  &.selected .lang-switcher__option-label {
    @apply text-primary-500;
  }

  &:hover {
    @apply text-primary-500;
  }
}

.lang-switcher__option-svg {
  width: 25px;
  height: auto;
  margin-right: 8px;
}
</style>

