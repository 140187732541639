<template>
  <jobs
      actions="view,search,edit"
      :initial-filters="initialFilters"
  />
</template>

<script>
import Jobs from "@/modules/jobs/pages/jobs.vue";

export default {
  name: 'sponsored-jobs',
  components: {
    Jobs,
  },
  data() {
    return {
      baseFilter: {
        field: 'status',
        operator: 'IS',
        value: 'ACTIVE',
      }
    }
  },
  computed: {
    sponsoredType() {
      return this.$route.name === 'Sponsored Jobs'
    },
    initialFilters() {
      return this.sponsoredType ? [ this.baseFilter,
        {
          field: 'campaigns_count',
          operator: '>=',
          value: '1',
          visible: false
        }
      ] : [ this.baseFilter,
        {
          field: 'campaigns_count',
          operator: 'IS',
          value: '0',
          visible: false
        }
      ]
    },
  }
}
</script>
