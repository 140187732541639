<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'ChannelLayout',
  data() {
    return {
      selectedOption: this.$route.path,
    }
  }
}
</script>
