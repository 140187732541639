<template>
  <div class="truncate">
    <template v-if="value">
      <router-link :to="composeRoute">
        {{ value }}
      </router-link>
    </template>
    <template v-else>- -</template>
  </div>
</template>
<script>
  import { get } from 'lodash'

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      value() {
        return get(this.row, this.column.prop)
      },
      pathQuery() {
        return this.column.entityKey ? get(this.row, this.column.entityKey) : this.row.id
      },
      link() {
        return this.column.redirectTo
      },
      composeRoute() {
        return this.link.replace('{ID}', this.pathQuery)
      }
    },
  }
</script>
