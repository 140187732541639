import uniqBy from 'lodash/uniqBy'

const types = {
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SET_ACTIVE_FILTERS: 'SET_ACTIVE_FILTERS',
  SET_CAMPAIGN_CREATE_FILTERS: 'SET_CAMPAIGN_CREATE_FILTERS',
  MERGE_DEFAULT_FILTERS: 'MERGE_DEFAULT_FILTERS'
};
const activeFilter =  {
  field: "status",
  label: "Status",
  operator: "IS",
  value: "ACTIVE"
}

function getActiveFilter() {
  return {
    ...activeFilter
  }
}

const activeJobsFilter = {
  name: 'Active jobs',
  noDelete: true,
  value: [
    getActiveFilter(),
  ]
}
const activeChannelsFilter = {
  name: 'Dutch channels',
  noDelete: true,
  value: [
    {
      field: "country_code",
      label: "Country",
      operator: "IS",
      value: "NL"
    }
  ]
}
const ownChannelsFilter = {
  name: 'Own channels',
  noDelete: true,
  value: [
    {
      field: "broker",
      label: "Broker",
      operator: "IS",
      value: "false"
    }
  ]
}
const brokerChannelsFilter = {
  name: 'Broker channels',
  noDelete: true,
  value: [
    {
      field: "broker",
      label: "Broker",
      operator: "IS",
      value: "true"
    },
  ]
}
const boostCampaignFilter = {
  name: 'Boost campaigns',
  noDelete: true,
  value: [
    {
      field: "type",
      label: "Type",
      operator: "IS",
      value: "BOOST"
    },
    getActiveFilter(),
  ]
}
const monthCampaignFilter = {
  name: 'Month campaigns',
  noDelete: true,
  value: [
    {
      field: "type",
      label: "Type",
      operator: "IS",
      value: "MONTH"
    },
    getActiveFilter(),
  ]
}
const oneTimeCampaignFilter = {
  name: 'One-Time campaigns',
  noDelete: true,
  value: [
    {
      field: "type",
      label: "Type",
      operator: "IS",
      value: "ONE-TIME"
    },
    getActiveFilter()
  ]
}

const defaultFilters = {
  job: [
    activeJobsFilter
  ],
  channel: [
    activeChannelsFilter,
    ownChannelsFilter,
    brokerChannelsFilter
  ],
  campaign: [
    boostCampaignFilter,
    monthCampaignFilter,
    oneTimeCampaignFilter
  ]
}

const state = {
  savedFilters: {
    job: [
      ...defaultFilters.job
    ],
    channel: [
      ...defaultFilters.channel
    ],
    campaign: [
      ...defaultFilters.campaign
    ]
  },
  activeFilters: {},
  campaignCreateFilters: []
};

const mutations = {
  [types.ADD_FILTER]: (state, value) => {
    const { entity, filter } = value
    if (state.savedFilters[entity]) {
      state.savedFilters[entity].push(filter)
    } else {
      state.savedFilters[entity] = [filter]
    }
  },
  [types.REMOVE_FILTER]: (state, value) => {
    const { entity, filter } = value
    const entityValues = state.savedFilters[entity]
    if (!entityValues || !filter.name) {
      return
    }
    const filterIndex = entityValues.findIndex(f => f.name === filter.name)
    if (filterIndex !== -1) {
      state.savedFilters[entity].splice(filterIndex, 1)
    }
  },
  [types.SET_ACTIVE_FILTERS]: (state, value) => {
    const { page, filters } = value
    state.activeFilters[page] = filters
  },
  [types.SET_CAMPAIGN_CREATE_FILTERS]: (state, value) => {
    state.campaignCreateFilters = value
  },
  [types.MERGE_DEFAULT_FILTERS]: (state) => {
    const savedFilters = state.savedFilters
    let mergedFilters = {}
    for(let key in savedFilters) {
      const filterValues = savedFilters[key]
      const defaultFilterValues = defaultFilters[key]
      let mergedFilterValues = [...defaultFilterValues, ...filterValues]
      mergedFilters[key] = uniqBy(mergedFilterValues, 'name')
    }
    state.savedFilters = mergedFilters
  }
};
const actions = {};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
