<template>
  <div></div>
</template>
<script>
import echarts from './init'
import bus from "@/components/charts/echarts/bus.js";

export default {
  props: {
    chartData: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingConfig: {
        text: 'Loading...',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        zlevel: 0,
        fontSize: 12,
        showSpinner: true,
        spinnerRadius: 20,
        lineWidth: 3,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontFamily: 'Inter var'
      }
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el);
    if (this.chartData) {
      this.chart.setOption(this.chartData);
    }
    bus.$on('resize', () => {
      this.chart.resize()
    });
  },
  watch: {
    chartData(value) {
      if (!value) {
        return
      }
      this.chart.setOption(value, {
        replaceMerge: ['series']
      });
    },
    loading(value) {
      if (value) {
        this.chart.showLoading('default', this.loadingConfig);
      } else {
        this.chart.hideLoading();
      }
    }
  }
}

</script>
