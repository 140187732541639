<template>
  <slot :data="reportData" :loading="loading"></slot>
</template>
<script>
import axios from 'axios'

export default {
  props: {
    query: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      reportData: {},
      loading: false,
      cancelSource: null,
    }
  },
  methods: {
    sanitizeQuery(query) {
      const newQuery = {}
      for(let key in query) {
        const value = query[key]
        if (value !== null && value !== undefined && value !== '') {
          newQuery[key] = value
        }
      }
      return newQuery
    },
    tryCancelRequest() {
      if (this.cancelSource) {
        this.cancelSource.cancel()
      }
    }
  },
  beforeUnmount() {
    this.tryCancelRequest()
  },
  watch: {
    query: {
      immediate: true,
      deep: true,
      async handler(query) {
        if (!query) {
          return
        }
        this.tryCancelRequest()
        try {
          this.cancelSource = axios.CancelToken.source()
          this.loading = true
          const data = await axios.get(`/report/elastic`, {
            cancelToken: this.cancelSource.token,
            params: this.sanitizeQuery(query)
          })
          if (data) {
            this.reportData = data
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
<style>
</style>
