import html2canvas from "html2canvas";

export function downloadFile({ fileContent, fileName, extension = 'png', shouldEncode = true }) {
  let content = window.URL.createObjectURL(new Blob([fileContent]));
  if (shouldEncode) {
    content = encodeURI(fileContent);
  }
  const link = document.createElement('a');
  link.setAttribute('href', content);
  link.setAttribute('download', `${fileName}.${extension}`)
  document.body.appendChild(link);

  link.click();
}

async function handleExport(elementSelector, exportName = new Date().toISOString()) {
  try {
    const element = document.querySelector(elementSelector)
    if (!element) {
      throw new Error('Could not export the html to png')
    }
    const currentScroll = window.scrollY
    window.scrollTo(0, 0)
    const canvas = await html2canvas(element, {
      scale: 4,
      scrollY: 0,
      onclone: (el) => {
        const svgElements = el.querySelector(elementSelector).getElementsByTagName("svg");
        Array.from(svgElements).forEach((svgElement) => {
          const bBox = svgElement.getBBox();
          svgElement.setAttribute("width", Math.round(bBox.width));
          svgElement.setAttribute("height", Math.round(bBox.height));
        });
      },
    })
    window.scrollTo(0, currentScroll)
    const image = canvas.toDataURL()
    downloadFile({
      fileContent: image,
      fileName: exportName,
    })
  } catch (err) {
    throw new Error('Could not export the html to png')
  }
}

export default {
  install(Vue) {
    Vue.config.globalProperties.$exportToPng = handleExport
  }
}
