import axios from "axios";
import i18n from "@/i18n";

export default {
  async getDashboardKpis() {
    try {
      const [cachedData, liveData] = await Promise.all([
        axios.get('/dashboard'),
        axios.get('/dashboard/live')
      ])
      return {
        ...cachedData,
        ...liveData,
      }
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getPerformingJobs(params, cancelSource) {
    try {
      return await axios.get('/report/actuals', {
        params,
        cancelToken: cancelSource?.token,
      });
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },
}
