<template>
  <div class="grid gap-5 lg:gap-3 2xl:gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
    <div class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
         v-for="channel in channels"
         :key="channel.id">
      <div class="w-full flex items-center justify-between p-6 space-x-6">
        <div class="flex-1 w-full min-w-full truncate">
          <div class="flex w-full min-w-full items-center justify-between space-x-3">
            <router-link :to="`/settings/channel/${channel.id}?tab=overview`" class="truncate">
              <base-tooltip :content="channel.name">
                <h3 class="text-gray-900 text-sm font-medium truncate">
                  {{ channel.name }}
                </h3>
              </base-tooltip>
            </router-link>
            <span v-if="channel.broker !== null"
                  class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                {{ $t('Own') }}
              </span>
            <span v-if="channel.broker === null"
                  class="flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">
                {{ $t('Broker') }}
              </span>
          </div>
          <div class="flex items-center mt-1">
            <p class="text-gray-500 text-sm truncate">
              {{ channel.promotion_type }}
            </p>
            <base-tooltip :content="channel.type">
              <book-open-icon
                  size="1.3x"
                  v-if="channel.type === CHANNEL_TYPES.JOB_BOARD"
                  class="ml-3 text-black cursor-pointer"/>
              <search-icon
                  v-else-if="channel.type === CHANNEL_TYPES.SEARCH_ENGINE"
                  size="1.3x"
                  class="ml-3 text-black cursor-pointer"/>
              <instagram-icon
                  v-else-if="channel.type === CHANNEL_TYPES.SOCIAL_MEDIA"
                  size="1.3x"
                  class="ml-3 text-black cursor-pointer"/>
              <arrow-up-icon
                  v-else
                  size="1.3x"
                  class="ml-3 text-black cursor-pointer"/>
            </base-tooltip>
          </div>
        </div>
        <router-link :to="`/settings/channel/${channel.id}?tab=overview`"
                     class="max-w-36">
          <img v-if="channel.media_image_location"
               :src="channel.media_image_location"
               :alt="`${channel.name} image`"
               class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"/>
        </router-link>
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div class="w-0 flex-1 flex">
            <router-link :to="`/settings/channel/${channel.id}?tab=overview`"
               class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <EyeIcon class="w-5 h-5 text-gray-400"/>
              <span class="ml-3">{{ $t('View') }}</span>
            </router-link>
          </div>
          <div v-if="channel.broker !== null && $isAdmin"
               class="w-0 flex-1 flex"
               @click="$emit('toggleActivity', channel)"
          >
            <base-tooltip :content="channel.excluded_id ? $t('Enable') : $t('Pause')"
                          :class="{
                            'text-yellow-500 hover:text-yellow-700': !channel.excluded_id,
                            'text-green-500 hover:text-green-700': channel.excluded_id,
                          }"
                          class="w-full flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg cursor-pointer"
            >
              <div class="flex items-center">
                <play-circle-icon v-if="channel.excluded_id" class="w-5 h-5"/>
                <pause-circle-icon v-else class="w-5 h-5"/>
                <span v-if="channel.excluded_id" class="ml-3">
                  {{ $t('Enable') }}
                </span>
                <span v-else class="ml-3">
                  {{ $t('Pause') }}
                </span>
              </div>
            </base-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BookOpenIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  SearchIcon,
  InstagramIcon,
  ArrowUpIcon,
  EyeIcon,
} from '@zhuowenli/vue-feather-icons'
import { CHANNEL_TYPES } from '@/enum/tableEnums'

export default {
  name: "ChannelsGridView",
  components: {
    ArrowUpIcon,
    BookOpenIcon,
    PlayCircleIcon,
    PauseCircleIcon,
    SearchIcon,
    EyeIcon,
    InstagramIcon
  },
  props: {
    channels: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      CHANNEL_TYPES
    }
  }
}
</script>
