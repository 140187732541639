<template>
  <campaign-create-form />
</template>

<script>
import CampaignCreateForm from "@/modules/jobs/components/CampaignCreateForm.vue";

export default {
  name: "campaign-create",
  components: {
    CampaignCreateForm
  }
}
</script>
