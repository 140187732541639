<template>
  <div :class="params.classes || ''">
    {{ formattedPrice }}
  </div>
</template>
<script>
import { get } from 'lodash'

export default {
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    row: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formattedPrice() {
      const value = get(this.row, this.column.prop)
      if (value === undefined || value === null) {
        return '- -'
      }
      return this.$formatPrice(value, this.params)
    }
  },
}
</script>
<style>
</style>
