<template>
  <BaseDataTable :columns="columns"
                 :tableColumnsKey="tableColumnsKey"
                 :initial-filters="initialFilters"
                 :actions="filteredActions"
                 :url="url"
                 :action-type="actionType"
                 :add-text="$t('New Campaign')"
                 entity="campaign"
                 ref="campaignsTable"
                 @data-fetch="onTableFetchData"
                 @add="$router.push('/campaigns/campaign-create')"
                 @view="row => goToCampaignPage(row, 'overview')"
                 @edit="row => goToCampaignPage(row, 'edit')"
  >
    <template v-slot:status="{row}">
      <base-popover :parent-entity-id="`popover-status-${row.id}`"
                    :ref="`popover-status-${row.id}`">
        <template v-slot:body>
          <div class="w-32">
            <base-radio v-for="data in CAMPAIGN_STATUSES"
                        v-model="status[row.id]"
                        name="status"
                        :key="`radio-status-${row.id}`"
                        :value="data"
                        :active="data === status[row.id]"
                        :disabled="status[row.id] === 'FINISHED'"
                        @update:modelValue="$evt => onSubmit(row, 'status', $evt)"
                        inline>
              {{ $t(data).toLowerCase() }}
            </base-radio>
          </div>
        </template>
        <template v-slot:reference>
          <base-button variant="primary-link">
            <div class="w-4 h-4 m-auto rounded-full"
                 :class="{
                  'bg-green-400' : row.status === CAMPAIGN_STATUSES.ACTIVE,
                  'bg-yellow-400' : row.status === CAMPAIGN_STATUSES.FINISHED,
                  'bg-gray-400' : row.status === CAMPAIGN_STATUSES.DRAFT,
                  'bg-red-400' : row.status === CAMPAIGN_STATUSES.PAUSE,
                 }"
            />
          </base-button>
        </template>
      </base-popover>
    </template>
    <template v-slot:strategy="{row}">
      {{ CampaignStrategyLabels[row.strategy] ? $t(CampaignStrategyLabels[row.strategy]) : $t('Custom') }}
    </template>
    <template v-slot:progress="{row}">
      <el-progress :stroke-width="10" :percentage="Math.round(row.progress)" />
    </template>
    <template v-slot:type="{row}">
      <base-tooltip :content="row.type" effect="light">
        <trending-up-icon
            size="1.5x"
            v-if="row.type === CAMPAIGN_TYPE.BOOST"
            class="m-auto cursor-pointer"/>
        <calendar-icon
            v-else-if="row.type === CAMPAIGN_TYPE.MONTH"
            size="1.5x"
            class="m-auto cursor-pointer"/>
        <arrow-up-right-icon
            v-else
            size="1.5x"
            class="m-auto cursor-pointer"/>
      </base-tooltip>
    </template>
    <template v-slot:name="{row}">
      <div :title="row.name" class="truncate">
        <router-link :to="campaignPath(row)" class="hover:underline">
          <span class="text-gray-700 font-normal w-10">{{ row.name }}</span>
        </router-link>
      </div>
    </template>
    <template v-slot:budget_daily="{row}">
      <base-popover :parent-entity-id="`popover-budget_daily-${row.id}`"
                    :ref="`popover-budget_daily-${row.id}`"
                    @after-enter="focusBudget('budget_daily', row)">
        <template v-slot:body>
          <div class="flex justify-center w-56 py-3">
            <base-input :ref="`budget_daily-${row.id}`"
                        :modelValue="getNumber(row.budget_daily)"
                        type="number"
                        @blur="debouncedSubmit(row, 'budget_daily', $event.target.value)"
                        @keydown.enter="debouncedSubmit(row, 'budget_daily', $event.target.value)"
                        :show-errors="false"
                        id="budgetDaily" />
          </div>
        </template>
        <template v-slot:reference>
          {{ $formatPrice(row.budget_daily, { maximumFractionDigits: 0 }) }}
        </template>
      </base-popover>
    </template>
    <template v-slot:budget_stopper="{row}">
      <base-popover :parent-entity-id="`popover-budget_stopper-${row.id}`"
                    :ref="`popover-budget_stopper-${row.id}`"
                    @after-enter="focusBudget('budget_stopper', row)">
        <template v-slot:body>
          <div class="flex justify-center w-56 py-3">
            <base-input
                id="budgetStopper"
                type="number"
                :show-errors="false"
                :min="campaignSpentBudget(row)"
                :ref="`budget_stopper-${row.id}`"
                :modelValue="getNumber(row.budget_stopper)"
                @blur="debouncedSubmit(row, 'budget_stopper', $event.target.value)"
                @keydown.enter="debouncedSubmit(row, 'budget_stopper', $event.target.value)"
            />
          </div>
        </template>
        <template v-slot:reference>
            {{ $formatPrice(row.budget_stopper, { maximumFractionDigits: 0 }) }}
        </template>
      </base-popover>
    </template>
  </BaseDataTable>
</template>

<script>
import { debounce, capitalize } from 'lodash'
import BaseRadio from "@/components/form/BaseRadio.vue";
import BasePopover from "@/components/common/BasePopover.vue";
import { CAMPAIGN_STATUSES, CAMPAIGN_TYPE } from '@/enum/tableEnums'
import CampaignService from "@/modules/jobs/services/CampaignService"
import { ElSlider, ElTooltip, ElProgress } from 'element-plus'
import { TrendingUpIcon, CalendarIcon, ArrowUpRightIcon } from '@zhuowenli/vue-feather-icons'
import { DEFAULT_DATE_TIME_FORMAT } from "@/plugins/dateFormatPlugin.js";
import { campaignSpentBudget, CampaignStrategyLabels } from '@/modules/jobs/enums/CampaignStrategies.js'
import localeCurrencyMixin from "@/modules/jobs/mixins/localeCurrencyMixin.js";

export default {
  name: "campaigns",
  components: {
    ElProgress,
    BaseRadio,
    BasePopover,
    ElSlider,
    ElTooltip,
    TrendingUpIcon,
    CalendarIcon,
    ArrowUpRightIcon
  },
  mixins: [localeCurrencyMixin],
  props: {
    initialFilters: {
      type: Array,
      default: () => [
        {
          field: 'status',
          operator: 'IS',
          value: 'ACTIVE',
        }
      ]
    },
    tableColumnsKey: {
      type: String,
      default: 'campaigns-table'
    },
    actions: {
      type: String,
      default: 'view,search,filters,add,edit'
    },
    url: {
      type: String,
      default: '/campaign',
    },
    actionType: {
      type: String,
      default: 'post',
    },
  },
  data() {
    return {
      CampaignStrategyLabels,
      CAMPAIGN_STATUSES,
      CAMPAIGN_TYPE,
      status: {},
      debouncedSubmit: () => {}
    }
  },
  computed: {
    filteredActions() {
      if (!this.$canCreateCampaigns) {
        let actions = this.actions.replace(',add', '')
        actions = actions.replace(',edit', '')
        return actions
      }
      return this.actions
    },
    columns() {
      return [
        {
          label: this.$t('Status'),
          prop: 'status',
          maxWidth: 80,
          align: 'left'
        },
        {
          label: this.$t('Progress'),
          prop: 'progress',
          maxWidth: 320,
          align: 'left'
        },
        {
          label: this.$t('Strategy'),
          prop: 'strategy',
          maxWidth: 120,
          align: 'left'
        },
        {
          label: this.$t('Title'),
          prop: 'name',
          maxWidth: 200,
          sortable: true,
          align: 'left'
        },
        // {
        //   label: this.$t('Campaign priority'),
        //   prop: 'priority',
        //   maxWidth: 120,
        //   align: 'left',
        // },
        {
          label: this.$t('Active Jobs'),
          prop: 'active_jobs',
          maxWidth: 200,
          align: 'left'
        },
        {
          label: this.$t('Budget stopper'),
          prop: 'budget_stopper',
          maxWidth: 120,
          align: 'left',
        },
        {
          label: this.$t('Clicks'),
          prop: 'clicks',
          maxWidth: 120,
          align: 'left'
        },
        {
          label: this.$t('Applicants'),
          prop: 'applicants',
          maxWidth: 100,
          align: 'left'
        },
        {
          label: this.$t('Spent'),
          prop: 'spend',
          maxWidth: 120,
          align: 'left',
          component: 'FormattedPrice'
        },
        {
          label: this.$t('CPA'),
          prop: 'cpa',
          maxWidth: 120,
          align: 'left',
          component: 'FormattedPrice'
        },
        {
          label: this.$t('Target CPA'),
          prop: 'target_cpa',
          maxWidth: 120,
          align: 'left',
          component: 'FormattedPrice',
          hide: true,
        },
        {
          label: this.$t('Created At'),
          prop: 'created_at',
          maxWidth: 160,
          component: 'FormattedDate',
          hide: true,
          params: {
            format: DEFAULT_DATE_TIME_FORMAT,
          },
        }
      ]
    }
  },
  created() {
    this.debouncedSubmit = debounce(this.onSubmit, 500)
  },
  methods: {
    campaignSpentBudget,
    campaignPath(campaign) {
      if (!campaign.job_id) {
        return `/campaigns/${campaign.id}?tab=overview`
      } else {
        return `/campaigns/${campaign.id}?tab=overview&job=${campaign.job_id}`
      }
    },
    getNumber(strNumber) {
      return Math.round(+strNumber)
    },
    goToCampaignPage(row, tab) {
      let path = {
        path: `/campaigns/${row.id}`,
        query: {
          title: row.name,
          tab: tab
        }
      }

      if (row.type === 'BOOST') {
        path.query.job = row.job_id
      }

      this.$router.push(path)
    },
    onTableFetchData(data) {
      data.forEach(data => {
        this.status[data.id] = data.status
      })
    },
    async onSubmit(row, field, fieldValue) {
      let oldValue = row[field]
      if (oldValue?.toString() === fieldValue?.toString() || +oldValue === +fieldValue) {
        return
      }

      if (field === 'budget_stopper' && fieldValue < this.campaignSpentBudget(row)) {
        return this.$error(this.$t('The budget stopper cannot be set lower than {amount}', {
          amount: this.$formatPrice(this.campaignSpentBudget(row))
        }))
      }

      row[field] = fieldValue
      const res = await CampaignService.editCampaignField(row.id, {
        [field]: fieldValue,
      })

      if (res) {
        const fieldName = capitalize(field).replaceAll('_', ' ')
        this.$success(`${fieldName} ${this.$t('has been updated')}`)
      } else {
        row[field] = oldValue
        this.status[row.id] = oldValue
      }
      const popoverRef = this.$refs[`popover-${field}-${row.id}`]
      popoverRef && popoverRef.hidePreview()
    },
    focusBudget(field, row) {
      let name = `${field}-${row.id}`
      this.$nextTick(() => {
        const ref = this.$refs[name]
        if (!ref) {
          return
        }
        ref.focus()
      })
    }
  },
}
</script>
