<script>
import { SORT_DIRECTION } from '@/enum/tableEnums'

export default {
  name: 'BaseTableColumn',
  props: {
    sortable: Boolean,
    firstSort: {
      type: String,
      default: 'asc'
    },
    order: Number,
    label: String,
    labelInfo: String,
    align: String,
    prop: String,
    width: [String, Number],
    minWidth: [String, Number],
    maxWidth: [String, Number],
    rowClasses: [String, Object, Array],
    classes: [String, Object, Array],
    smallCell: Boolean,
    background: String,
    redirectTo: String,
    entityKey: String,
  },
  inject: ['addColumn', 'removeColumn'],
  data() {
    return {
      sortDirection: '',
    }
  },
  computed: {
    styles() {
      const styles = {}
      if (this.minWidth) {
        styles.minWidth = this.parseStyleProperty(this.minWidth)
      }
      if (this.width) {
        styles.width = this.parseStyleProperty(this.width)
      }
      if (this.maxWidth) {
        styles.maxWidth = this.parseStyleProperty(this.maxWidth)
      }
      if (this.background) {
        styles.background = this.parseStyleProperty(this.background)
      }
      return styles
    }

  },
  mounted() {
    this.addColumn(this);
  },
  unmounted() {
    this.$el?.parentNode?.removeChild(this.$el)
    this.removeColumn(this)
  },
  render() {
    // https://github.com/vuejs/vue-next/issues/1997#issuecomment-682838443
    return null
  },
  methods: {
    toggleSort(direction = null) {
      if (direction) {
        this.sortDirection = direction
        return
      }

      if (!this.sortable) {
        return
      }

      if (this.sortDirection === SORT_DIRECTION.ASC) {
        this.sortDirection = this.firstSort === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.NONE
      } else if (this.sortDirection === SORT_DIRECTION.DESC) {
        this.sortDirection = this.firstSort === SORT_DIRECTION.ASC ? SORT_DIRECTION.NONE : SORT_DIRECTION.ASC
      } else {
        this.sortDirection = this.firstSort || 'asc'
      }
    },
    parseStyleProperty(property) {
      let result = ''

      if (typeof property === 'number') {
        result = `${property}px`
      } else {
        result = property
      }
      return result
    },
  }
}
</script>
