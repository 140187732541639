import { createI18n } from 'vue-i18n'
import { setLocale } from '@vee-validate/i18n';
import en from './locales/en.json'
import nl from './locales/nl.json'
import elNlLocale from 'element-plus/lib/locale/lang/nl'
import elEnLocale from 'element-plus/lib/locale/lang/en'

export const DEFAULT_LOCALE = 'en-GB'
export const NL_LOCALE = 'nl-NL'

export const currentLocale = localStorage.getItem('locale') || DEFAULT_LOCALE


export const elementLocales = {
  [DEFAULT_LOCALE]: elEnLocale,
  [NL_LOCALE]: elNlLocale
}

const i18n = createI18n({
  locale: currentLocale,
  fallbackLocale: currentLocale,
  warnHtmlInMessage: 'off',
  messages: {
    [DEFAULT_LOCALE]: en,
    [NL_LOCALE]: nl,
  }
})

export function changeLocale(locale) {
  if (!locale) {
    locale = DEFAULT_LOCALE
  }
  if (locale === currentLocale) {
    return
  }
  i18n.global.locale = locale
  setLocale(locale)
  localStorage.setItem('locale', locale)
}

export default {
  i18n,
  install(Vue) {
    Vue.config.globalProperties.$t = i18n.global.t
    Vue.config.globalProperties.$i18n = i18n
  },
  t: i18n.global.t
}
