import { cancelTopProgress } from '@/modules/common/progressbar.js';

let loginRoute = {
  path: '/login'
};

function redirectToLogin(next) {
  cancelTopProgress();
  return next(loginRoute);
}

/**
 * Middleware to check if user has the correct right to access a certain page.
 * Checks are performed based on route meta fields `meta: { requiresAuth: true }`.
 * @param {object} router Vue router instance
 */
export default function authMiddleware(router) {
  router.beforeEach(async (to, from, next) => {
    const loginPage = to.path.startsWith('/login')
    const registerPage = to.path.startsWith('/register')
    const forgotPassPage = to.path.startsWith('/forgot-password')
    const resetPasswordPage = to.path.startsWith('/password/reset')

    if (loginPage || registerPage || forgotPassPage || resetPasswordPage) {
      return next();
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    const token = localStorage.getItem('token');

    if (!token) {
      return redirectToLogin(next);
    }

    if (!requiresAuth) {
      return next();
    }
    return next();
  });
}
