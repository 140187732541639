<template>
  <div>
    <div>
      <h2 class="mt-6 text-3xl leading-9 font-semibold text-gray-900 text-center">
        {{ $t('Sign in to your account') }}
      </h2>
    </div>
    <div class="mt-4">
      <div class="mt-6 relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center text-sm leading-5">
        </div>
      </div>
      <div class="mt-6">
        <VeeForm v-slot="{ handleSubmit, errors }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <base-input v-model="model.email"
                       :label="$t('Email address')"
                       :placeholder="$t('Email address')"
                       :name="$t('Email address')"
                       rules="required|email"
                       id="email"
                       type="email"
            />
            <BaseInput v-model="model.password"
                       :label="$t('Password')"
                       :placeholder="$t('Password')"
                       :name="$t('Password')"
                       rules="required"
                       id="password"
                       type="password"
            />
            <div class="flex justify-end text-sm leading-5">
              <router-link to="/forgot-password">
                {{ $t('Forgot your password?') }}
              </router-link>
            </div>
            <div class="mt-6">
              <BaseButton :disabled="Object.keys(errors).length > 0"
                          type="submit"
                          block
                          :loading="loading">
                {{ $t('Sign in') }}
              </BaseButton>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      loading: false,
      model: {
        email: '',
        password: '',
        rememberMe: false,
      },
      organizations: []
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await this.$store.dispatch('auth/login', this.model)
        await this.$router.push('/welcome')

      } catch (err) {
        console.log(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('An error occurred while trying to login'))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
