<template>
  <BaseNoPermissions v-if="!$isAdmin" />
  <BaseDataTable v-else
                 :columns="columns"
                 :tableColumnsKey="tableKey"
                 :default-sort="{'created_at': 'desc'}"
                 actions="view,search,filters"
                 entity="channel"
                 url="/channel"
                 ref="campaignsTable"
                 @data-fetch="onTableFetchData"
                 @view="row => goToChannelPage(row, 'overview')"
                 @add="$router.push('/settings/channel/channel-create')"
  >
    <template v-if="gridView" v-slot:default>
      <channels-grid-view :channels="channels" @toggleActivity="toggleChannelActivity"/>
    </template>
    <template v-slot:status="{row}">
      <div class="flex items-center">
        <base-popover :parent-entity-id="`popover-status-${row.id}`"
                      :ref="`popover-status-${row.id}`">
          <template v-slot:body>
            <div class="px-3 py-2 bg-white">
              {{ row.excluded_id ? $t('Channel is Inactive') : $t('Channel is Active') }}
            </div>
          </template>

          <template v-slot:reference>
            <div class="w-4 h-4 m-auto rounded-full"
                 :class="{
                  'bg-green-400' : !row.excluded_id,
                  'bg-red-400' : row.excluded_id,
           }"
            />
          </template>
        </base-popover>
      </div>
    </template>
    <template v-slot:promotion_type="{row}">
      <div class="capitalize">
        {{ row.promotion_type.toLowerCase() }}
      </div>
    </template>
    <template v-slot:broker="{row}">
      <base-badge :type="row.broker ? 'success' : 'danger'">
        {{ row.broker ? $t('Yes') : $t('No') }}
      </base-badge>
    </template>
    <template v-slot:name="{row}">
      <div :title="row.name" class="truncate">
        <router-link :to="`/settings/channel/${row.id}`" class="hover:underline">
          <span class="text-gray-700 font-normal w-10">{{ row.name }}</span>
        </router-link>
      </div>
    </template>
    <template v-slot:extra-buttons>
      <div class="add-button flex items-start order-first">
        <base-tooltip :content="gridView ? $t('List view') : $t('Grid view')">
          <base-button variant="white"
                       @click="toggleGridView"
                       class="mr-2"
                       size="sm">
            <list-icon v-if="gridView" size="1.5x"/>
            <grid-icon v-else size="1.5x"/>
          </base-button>
        </base-tooltip>
      </div>
    </template>
    <template v-slot:extra-actions="{row}">
      <table-edit-button v-if="row.client_id"
                         @click="goToChannelPage(row, 'edit')">
      </table-edit-button>
      <base-tooltip v-if="row.broker !== null && $isAdmin"
                    :content="row.excluded_id ? $t('Enable') : $t('Pause')">
        <base-button :variant="row.excluded_id ? 'green-light' : 'danger-light'"
                     :tabindex="0"
                     size="icon"
                     class="hidden md:flex table-action-button"
                     @click="toggleChannelActivity(row)">
          <play-circle-icon v-if="row.excluded_id" class="text-green-500"/>
          <pause-circle-icon v-else class="text-red-500"/>
        </base-button>
      </base-tooltip>
    </template>
  </BaseDataTable>
</template>

<script>
import {
  ArrowUpIcon,
  InstagramIcon,
  SearchIcon,
  BookOpenIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  GridIcon,
  ListIcon
} from '@zhuowenli/vue-feather-icons'
import channelSubmitMixin from "../mixins/channelSubmitMixin";
import ChannelsGridView from "@/modules/jobs/components/ChannelsGridView.vue";
import TableEditButton from "@/components/table/buttons/TableEditButton.vue";

export default {
  name: "channel",
  components: {
    ChannelsGridView,
    ArrowUpIcon,
    InstagramIcon,
    SearchIcon,
    BookOpenIcon,
    PlayCircleIcon,
    PauseCircleIcon,
    GridIcon,
    ListIcon,
    TableEditButton,
  },
  mixins: [channelSubmitMixin],
  data() {
    return {
      channels: {},
      status: {},
      gridView: false,
      tableKey: 'channel-table'
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Status'),
          prop: 'status',
          width: 80,
          align: 'left'
        },
        {
          label: this.$t('Name'),
          prop: 'name',
          maxWidth: 200,
          sortable: true,
          align: 'left',
        },
        {
          label: this.$t('Promotion Type'),
          prop: 'promotion_type',
          minWidth: 180,
          align: 'left',
          hide: true,
        },
        {
          label: this.$t('Broker'),
          prop: 'broker',
          maxWidth: 120,
          align: 'left',
          hide: true,
        },
      ]
    }
  },
  mounted() {
    const tableSettings = this.$store.state.settings.tableSettings[this.tableKey]

    if (tableSettings) {
      this.gridView = tableSettings.gridView
    }
  },
  methods: {
    goToChannelPage(row, tab) {
      this.$router.push({ path: `/settings/channel/${row.id}`, query: { title: row.name, tab: tab } })
    },
    onTableFetchData(data) {
      this.channels = data
      data.forEach(data => {
        this.status[data.id] = data.status
      })
    },
    toggleGridView() {
      this.gridView = !this.gridView

      this.$store.commit('settings/SET_TABLE_SETTINGS', {
        table: this.tableKey,
        setting: 'gridView',
        val: this.gridView
      })
      if (!this.gridView) {
        const tableRef = this.$refs.campaignsTable
        this.$nextTick(() => {
          tableRef && tableRef.initSortable()
        })
      }
    }
  }
}
</script>
