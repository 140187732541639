<template>
  <BaseNoPermissions v-if="!$isOwner" />
  <div
      v-else
      class="mt-4"
  >
    <base-form v-bind="$attrs"
               :loading="loading"
               :save-text="$t('Save')"
               grid-classes="flex flex-col"
               layout="modal"
               @submit="submitData">
      <div class="w-full">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Your organization') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>

        <div class="flex flex-col lg:flex-row pt-6">
          <base-avatar-upload
              :name="model.name"
              :image="model.media_image_location"
              button-text="Select image"
              class="w-3/6 flex-col mr-4 md:mr-0 mt-6"
              @on-finish="updateMediaId"/>
          <div class="flex flex-col w-full justify-center my-6">
            <base-input v-model="model.name"
                        :label="$t('Organization')"
                        :placeholder="$t('Organization')"
                        :name="$t('Organization')"
                        class="w-full"
                        id="organization"
                        rules="required">
            </base-input>
            <base-input v-model="model.description"
                        type="textarea"
                        rows="5"
                        :label="$t('Description')"
                        :label-info="$t('Maximum 200 characters.')"
                        :placeholder="$t('Description')"
                        :name="$t('Description')"
                        class="w-full"
                        id="jobDescription"
                        max-length="200"
                        rules="max:200">
            </base-input>
            <base-phone-input :label="$t('Phone')"
                              v-model="model.phone"
                              :value="model.phone"
                              :phone-country="model.phone_country || ''"
                              @input-data="updatePhoneData"/>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row">
          <base-input v-model="model.street"
                      :label="$t('Address')"
                      :placeholder="$t('Address')"
                      :name="$t('Address')"
                      class="w-full"
                      id="street">
          </base-input>
          <div class="lg:w-2/6 lg:ml-4">
            <base-input v-model="model.house_no"
                        :label="$t('House number')"
                        :placeholder="$t('House number')"
                        :name="$t('House number')"
                        id="house">
            </base-input>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row">
          <base-input v-model="model.zip_code"
                      :label="$t('Zip code')"
                      :placeholder="$t('Zip code')"
                      :name="$t('Zip code')"
                      class="w-full"
                      id="zipcode">
          </base-input>
          <div class="w-full lg:ml-4">
            <base-autocomplete :inputValue="model.city"
                               :fetchDataAction="fetchCountriesData"
                               @update:modelValue="onCityFieldChange($event)"
                               @onKeydownEnter="onCityFieldChange($event)"
                               :placeholder="$t('City')"
                               :label="$t('City')"
                               :name="$t('City')"
                               classNames="w-full">
            </base-autocomplete>
          </div>
        </div>
        <base-select v-model="model.country_code"
                     :options="allCountries"
                     :label="$t('Country')"
                     :placeholder="$t('Country')"
                     :name="$t('Country')"
                     classNames="w-full"
                     name="country"
                     id="country"
                     filterable>
        </base-select>

        <base-select v-model="model.countries"
                     :options="allCountries"
                     :label="$t('Countries')"
                     :placeholder="$t('Countries')"
                     classNames="mt-4"
                     :name="$t('Countries')"
                     :label-info="$t('You can select which countries you want to advertise in and ignore channels in other countries')"
                     id="countries"
                     rules="required"
                     multiple>
        </base-select>
      </div>
    </base-form>
  </div>

</template>

<script>
import BaseAvatarUpload from "@/components/form/BaseAvatarUpload.vue";
import { get } from "lodash";
import SettingsService from "@/modules/dashboard/services/SettingsService";
import countriesMixin from "@/modules/jobs/mixins/countriesMixin";

export default {
  name: "OrganizationSettings",
  components: {
    BaseAvatarUpload
  },
  mixins: [countriesMixin],
  data() {
    return {
      model: {
        media_image_id: null,
        name: null,
        description: null,
        country_code: null,
        zip_code: null,
        street: null,
        house_no: null,
        phone: '',
        phone_country: '',
        countries: [],
      },
      loading: false
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings
    },
    countriesOptions() {
      return [
        {
          value: 'NL',
          label: 'Netherlands',
        },
        {
          value: 'BE',
          label: 'Belgium',
        },
        {
          value: 'DE',
          label: 'Germany',
        },
        {
          value: 'GB',
          label: 'United Kingdom',
        },
        {
          value: 'FR',
          label: 'France',
        },
        {
          value: 'US',
          label: 'United States',
        },
        {
          value: 'CA',
          label: 'Canada',
        },
        {
          value: 'UA',
          label: 'Ukraine',
        },
        {
          value: 'SE',
          label: 'Sweden',
        },
        {
          value: 'DK',
          label: 'Denmark',
        },
        {
          value: 'PL',
          label: 'Poland',
        },
        {
          value: 'AT',
          label: 'Austria',
        }
      ]
    }
  },
  watch: {
    settings: {
      immediate: true,
      handler() {
        this.initModel()
      }
    }
  },
  methods: {
    initModel() {
      const countries = get(this.settings, 'countries', null)
      this.model.name = this.settings.name
      this.model.description = this.settings.description
      this.model.house_no = get(this.settings, 'address.house_no', null)
      this.model.country_code = get(this.settings, 'address.country_code', null)
      this.model.street = get(this.settings, 'address.street', null)
      this.model.zip_code = get(this.settings, 'address.zip_code', null)
      this.model.city = get(this.settings, 'address.city', null)
      if (countries) {
        this.model.countries = get(this.settings, 'countries', null)
      }
      this.model.media_image_location = this.settings.media_image_location
      this.settings.phone ? this.model.phone = this.settings.phone : false
      this.settings.phone_country ? this.model.phone_country = this.settings.phone_country : false
    },
    updatePhoneData(value) {
      this.model.phone_country = get(value, 'activeCountry.iso2', '')
      this.model.phone = get(value, 'number', '')
    },
    async submitData() {
      try {
        this.loading = true
        const res = await SettingsService.saveSettings(this.model)

        if (!res) {
          return
        }

        this.$store.commit('settings/setSettings', res)
        this.$success(this.$t('Settings saved'))
      } finally {
        this.loading = false
      }
    },
    updateMediaId(value) {
      this.model.media_image_id = value
      if (!value) {
        this.model.media_image_location = null
      }
    }
  }
}
</script>
