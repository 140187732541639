<template>
  <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center mt-4">
    <p class="text-xl leading-6 font-medium text-gray-900">
      {{ $t('Media budget balance') }}
    </p>
    <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
      <span>
        {{ $formatPrice(balance, {maximumFractionDigits: 2}) }}
      </span>
      <span class="ml-3 text-xl font-medium text-gray-500">
        {{ getLocaleCurrencyText }}
      </span>
    </div>
    <div class="mt-4">
        <base-button
            variant="primary"
            class="mt-3 py-3"
            size="sm"
            :disabled="!isMediaBudgetEnabled"
            @click="$router.push('/finance/budget-create')"
        >
          <span class="whitespace-nowrap">
            {{ $t('Add media budget') }}
          </span>
        </base-button>
    </div>
  </div>
  <div class="py-4">
    <BaseDataTable :columns="columns"
                   tableColumnsKey="settings-budget-table"
                   :default-sort="defaultSort"
                   url="/media-budget">
      <template v-slot:amount="{row}">
        <span :class="[ isPositive(row.amount) ? 'text-green-500' : 'text-red-500' ]">
          {{ $formatPrice(row.amount, { maximumFractionDigits: 0 }) }}
        </span>
      </template>
      <template v-slot:created_at="{row}">
        {{ $formatDate(row.created_at) }}
      </template>
    </BaseDataTable>
  </div>
</template>

<script>
import localeCurrencyMixin from "@/modules/jobs/mixins/localeCurrencyMixin.js";
import DashboardService from "@/modules/dashboard/services/DashboardService";

export default {
  name: "BudgetSettings",
  mixins: [localeCurrencyMixin],
  data() {
    return {
      balance: null
    }
  },
  async mounted() {
    const res = await DashboardService.getDashboardKpis()
    if (!res) {
      return
    }
    this.balance = res?.media_budget_available
  },
  computed: {
    isMediaBudgetEnabled() {
      return this.$store.state.auth?.selectedClient?.payment_type === 'MEDIA-BUDGET'
    },
    defaultSort() {
      return {
          'created_at': 'desc'
        }
    },
    columns() {
      return [
        {
          label: this.$t('Created at'),
          prop: 'created_at',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Title'),
          prop: 'title',
          minWidth: 120,
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Amount'),
          prop: 'amount',
          minWidth: 120,
          sortable: true,
          align: 'left'
        }
      ]
    }
  },
  methods: {
    isPositive(amount) {
      const positive = Math.sign(amount)
      return positive && positive !== -1
    },
  }
}
</script>
