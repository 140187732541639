<template>
    <div class="-my-2 py-2 overflow-x-auto table-wrapper">
        <div class="align-middle inline-block w-full shadow-card overflow-auto table-wrapper__inner"
        >
            <table class="min-w-full table__header-wrapper">
                <thead>
                <slot name="thead-infos"/>
                <tr>
                    <th v-for="column in availableColumns"
                        :key="column.prop"
                        class="px-4 py-2 border-b border-gray-200 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider cursor-move"
                        :class="{
                          [`th-${column.prop}`]: column.prop,
                          'visible-columns__header': !column.prop,
                          [column.classes]: column.classes,
                        }"
                        :style="column.styles"
                    >
                        <div class="flex items-center th-cell"
                             :class="{
                                 'cursor-pointer select-none': column.sortable,
                                 'justify-end': column.align === 'right',
                                 'justify-start': column.align === 'left',
                                 'justify-center': column.align === 'center',
                             }"
                             @click="toggleSort(column)"
                        >
                            <ColumnHeaderRenderer v-if="!column.labelInfo" :column="column"/>
                            <BaseTooltip
                                v-else
                                :content="column.labelInfo"
                            >
                              <ColumnHeaderRenderer :column="column"/>
                            </BaseTooltip>
                            <div v-if="column.sortable"
                                 class="flex flex-col ml-1"
                            >
                                <ChevronUpIcon class="w-3 h-3 -mb-1"
                                               :stroke-width="column.sortDirection === 'asc' ? 5 : 3"
                                               :class="{'text-primary-600': column.sortDirection === 'asc'}"
                                />
                                <ChevronDownIcon class="w-3 h-3"
                                                 :stroke-width="column.sortDirection === 'desc' ? 5 : 3"
                                                 :class="{'text-primary-600': column.sortDirection === 'desc'}"
                                />
                            </div>
                        </div>
                    </th>
                </tr>
                </thead>
                <slot></slot>
                <tbody class="bg-white">
                <tr v-for="(row, index) in tableData"
                    :key="row[rowKey] || index"
                    :class="getRowClasses(row)"
                >
                    <template v-if="row.header">
                        <td :colspan="availableColumns.length"
                            class="px-4 py-3 border-b whitespace-nowrap border-gray-200 font-medium">
                            <slot name="header" :row="row"/>
                        </td>
                    </template>
                    <template v-if="!row.header && !row.footer && !row.subtotal">
                        <td v-for="column in availableColumns"
                            :key="column.prop"
                            class="whitespace-nowrap text-gray-700 td-cell"
                            :class="{
                              'text-right': column.align === 'right',
                              'text-left': column.align === 'left',
                              'text-center': column.align === 'center',
                              [`td-${column.prop}`]: column.prop,
                              'px-1 py-1': column.smallCell,
                              'px-2 py-2': !column.smallCell,
                              [column.rowClasses]: column.rowClasses,
                            }"
                            :style="column.styles"
                        >
                            <ColumnRenderer :row="row"
                                            :index="index"
                                            :column="column"/>
                        </td>
                    </template>
                    <template v-if="row.footer">
                        <td :colspan="availableColumns.length"
                            class="px-4 py-3 border-b whitespace-nowrap border-gray-200 text-gray-700 font-medium text-right">
                            <slot name="footer" :row="row"/>
                        </td>
                    </template>
                    <template v-if="row.subtotal">
                        <slot name="subtotal" :row="row"/>
                    </template>
                </tr>
                <tr v-if="data.length === 0 && !loading || data.length === 0 && loading"
                    key="empty">
                    <td :colspan="availableColumns.length"
                        class="text-center py-5 text-gray-500 text-lg td-cell no-data-cell">
                        <slot name="empty">
                                <span v-if="!loading">
                                    {{$t('No Data')}}
                                </span>
                            <span v-else-if="data.length === 0 && loading">
                                    <i class="el-icon el-icon-loading"></i>
                                    {{$t('Loading...')}}
                                </span>
                        </slot>
                    </td>
                </tr>
                <tr v-if="hasSummary" key="summary">
                    <td v-for="column in availableColumns"
                        :key="column.prop"
                        class="px-4 py-3 whitespace-nowrap border-b border-gray-200 text-gray-700 td-cell"
                        :class="{
                              'text-right': column.align === 'right',
                              'text-left': column.align === 'left',
                              'text-center': column.align === 'center',
                            }"
                        :style="column.styles"
                    >
                        <slot name="summary" :column="column"></slot>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
  import { h } from 'vue'
  import { get, cloneDeep, orderBy } from 'lodash'
  import { ChevronDownIcon, ChevronUpIcon } from '@zhuowenli/vue-feather-icons'

  function getColumnData(row, prop) {
    return get(row, `${prop}`, '')
  }

  export default {
    components: {
      ColumnRenderer: {
        props: ['row', 'column', 'index'],
        render() {
          const classList = ['cell']
          if (this.column.align === 'center') {
            classList.push('flex justify-center')
          }
          const classes = classList.join(' ')
          const props = {
            class: classes,
          }

          const title = getColumnData(this.row, this.column.prop)
          const isTitleOrNameCol = this.column?.prop?.includes('name') || this.column?.prop?.includes('title')
          const translateTitle = typeof title === 'string' && title && !isTitleOrNameCol && !parseInt(title)
          props.title = translateTitle ? this.$t(title) : title;
          if (parseFloat(props.title)) {
            props.title = parseFloat(props.title).toFixed(2)
          }

          if (this.column.$slots.default) {
            const renderedSlot = this.column.$slots.default({
              row: this.row,
              index: this.index,
            })

            return h('div', props, [renderedSlot])
          }

          return h('span', props, [props.title])
        },
      },
      ColumnHeaderRenderer: {
        props: ['column'],
        render() {
          if (this.column.$slots.header) {
            return this.column.$slots.header({
              column: this.column,
            })
          }
          return h('span', [this.column.label])
        },
      },
      ChevronUpIcon,
      ChevronDownIcon,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      rowKey: {
        type: String,
        default: 'id',
      },
      localSort: {
        type: Boolean,
        default: true,
      },
      defaultSort: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        default: true,
      },
      hasSummary: {
        type: Boolean,
        default: false,
      },
      rowClasses: {
        type: Function,
        default: () => ''
      }
    },
    provide() {
      return {
        addColumn: this.addColumn,
        removeColumn: this.removeColumn,
      }
    },
    data() {
      return {
        columns: [],
        tableData: [],
      }
    },
    computed: {
      availableColumns() {
        return this.columns.sort((a, b) => {
          if (a.prop === undefined) {
            return 1
          }
          return a.order > b.order ? 1 : -1
        })
      }
    },
    methods: {
      getRowClasses(row) {
        if (this.rowClasses && typeof this.rowClasses === 'function') {
          return this.rowClasses(row)
        }
        return ''
      },
      hasSorting() {
        return this.columns.some(col => col.sortDirection !== '')
      },
      toggleDefaultSort() {
        if (this.hasSorting() || !this.defaultSort) {
          return
        }
        Object.keys(this.defaultSort).forEach(k => {
          let column = this.columns.find(col => col.prop && col.prop.includes(k))
          if (!column || !column.sortable) {
            return
          }
          if (this.defaultSort && column?.prop.includes(k)) {
            column.toggleSort(this.defaultSort[k])
          }
        })
      },
      addColumn(item) {
        this.columns.push(item)
      },
      removeColumn(col) {
        const index = this.columns.findIndex(item => item.$.id === col.$.id)

        if (index > -1) {
          this.columns.splice(index, 1)
        }
      },
      toggleSort(column, sendEvent = true) {
        if (!column.sortable) {
          return
        }
        if (sendEvent) {
          this.$emit('sort', column, this.columns)
        }

        this.columns.forEach(col => {
          if (col.prop !== column.prop) {
            col.sortDirection = ''
          }
        })
        if (!sendEvent) {
          column.toggleSort()
        }
        if (!this.localSort) {
          return
        }
        column.toggleSort()

        const columnsToSort = this.columns.filter(c => c.sortDirection !== '')
        const columnProps = columnsToSort.map(c => c.prop)
        const columnSortOrders = columnsToSort.map(c => c.sortDirection)

        if (columnProps.length === 0) {
          this.tableData = cloneDeep(this.data)
        } else {
          this.tableData = orderBy(this.tableData, columnProps, columnSortOrders)
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.tableData = value
          this.toggleDefaultSort()
        },
      },
    },
  }
</script>
<style lang="scss">
    .thead-infos {
        @apply text-center px-4 py-3 bg-gray-50 text-xs leading-4 font-semibold text-gray-600 uppercase tracking-wider;
    }

    tr > td.subtotal {
        @apply px-4 py-3 border-b whitespace-nowrap border-gray-200 font-medium;
    }
    .table-wrapper table .actions-header,
    .table-wrapper table .table-actions {
      position: sticky;
      right: 0;
      z-index: 3;
      @apply border-l px-3;
    }
    .table-wrapper table {
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      .cell {
       @apply truncate;
      }

      tr:not(:last-child) {
        .td-cell {
          @apply border-b border-gray-200;
        }
      }
    }
    .table-wrapper table .table-actions {
      z-index: 2;
      @apply bg-white px-3 py-2;

      .cell {
        @apply flex items-center;

        .el-tooltip:last-child {
          @apply mr-0;
        }
      }
    }
</style>
