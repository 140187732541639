<template>
    <div>
        <a v-if="formattedAddress"
           :href="locationLink"
           target="_blank"
           rel="noopener"
           class="text-primary-500 hover:text-primary-900 hover:underline cursor-pointer"
        >
            <address class="address-field truncate">
                {{formattedAddress}}
            </address>
        </a>
        <span v-else>- -</span>
    </div>
</template>
<script>
  import { get } from 'lodash'
  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      formattedAddress() {
        const address = get(this.row, this.column.prop, '')
        return address.trim()
      },
      locationLink() {
        return `https://www.google.com/maps/search/?api=1&query=${this.formattedAddress}`
      }
    },
  }
</script>
<style scoped>
</style>
