<template>
  <Disclosure as="nav" class="border-b border-gray-200 bg-white sticky top-0 left-0 z-10" v-slot="{ open }">
    <div class="mx-auto w-full px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <img class="block h-8 w-auto lg:hidden"
                 src="/img/logo.png" alt="Jobmatix logo" />
            <img class="hidden h-8 w-auto lg:block"
                 src="/img/logo-square.png" alt="Jobmatix logo" />
          </div>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <router-link v-for="item in navigation"
                         :key="item.name"
                         :to="item.path"
                         class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                         :class="{
                               'border-indigo-500 text-gray-900': $route.path.includes(item.path),
                               'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': !$route.path.includes(item.path),
                               }"
                         :aria-current="$route.path.includes(item.path) ? 'page' : undefined">
              {{ item.name }}
            </router-link>
          </div>
        </div>
        <div class="ml-4 flex items-center md:ml-6">

          <div v-if="isTrial" class="text-yellow-600 text-sm font-medium">
            {{ trialDaysLeft }} {{ $t(' trial days left') }}
          </div>
          <div class="mx-3 mt-5">
            <organization-select />
          </div>
          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <button
                  @click="showDropdown = !showDropdown"
                  class="max-w-xs bg-white dark:bg-gray-900 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  id="user-menu" aria-haspopup="true">
                <span class="sr-only">{{ $t('Open user menu') }}</span>
                <base-avatar :name="userName"
                             class="h-8 w-8 rounded-full">
                </base-avatar>
              </button>
            </div>
            <transition enter-active-class="transition ease-out duration-100"
                        enter-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                        appear>
              <div
                  v-if="showDropdown"
                  class="origin-top-right absolute dropdown-menu right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5"
                  role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <router-link to="/profile"
                             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                             :class="{'opacity-30 cursor-not-allowed focus:no-underline pointer-events-none': noSubscription}"
                             role="menuitem">
                  {{ $t('Your Profile') }}
                </router-link>

                <router-link v-if="$isOwner"
                             to="/settings"
                             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                             :class="{'opacity-30 cursor-not-allowed focus:no-underline pointer-events-none': noSubscription}"
                             role="menuitem">
                  {{ $t('Company Settings') }}
                </router-link>

                <router-link v-if="$isOwner"
                             to="/finance"
                             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                             :class="{'opacity-30 cursor-not-allowed focus:no-underline pointer-events-none': noSubscription}"
                             role="menuitem">
                  {{ $t('Finance Settings') }}
                </router-link>

                <a
                    href="https://support.jobmatix.com/knowledge"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    target="_blank"
                    rel="noopener"
                >
                  {{ $t('Support') }}
                </a>

                <a @click.prevent="logout"
                   href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                  {{ $t('Sign out') }}
                </a>
              </div>
            </transition>
          </div>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
              class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Open main menu</span>
            <AlignJustifyIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pt-2 pb-3">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href"
                          :class="[$route.path.includes(item.path) ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']"
                          :aria-current="$route.path.includes(item.path) ? 'page' : undefined">
          <RouterLink :to="item.path">
            {{ item.name }}
          </RouterLink>
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { BellIcon, XIcon, AlignJustifyIcon } from '@zhuowenli/vue-feather-icons'
import OrganizationSelect from './OrganizationSelect.vue'

export default {
  components: {
    OrganizationSelect,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    XIcon,
    BellIcon,
    AlignJustifyIcon,
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    navigation() {
      const menus = [
        {
          name: this.$t('Dashboard'),
          path: '/welcome',
          icon: 'IconDashboard',
        },
        {
          name: this.$t('Jobs'),
          path: '/jobs',
          icon: 'IconJobs',
        },
        {
          name: this.$t('Campaigns'),
          path: '/campaigns',
          icon: 'IconCampaigns',
          disabled: !this.$canCreateCampaigns,
        },
        {
          name: this.$t('Reports'),
          path: '/reports',
          icon: 'IconReports',
        },
      ]
      return menus.filter(el => !el.disabled)
    },
    userName() {
      return this.user.name || 'Account'
    },
    noSubscription() {
      return !this.$store.state.auth.selectedClient?.subscription
    },
    isTrial() {
      return this.$store.getters['auth/isTrial']
    },
    trialDaysLeft() {
      return this.$store.getters['auth/trialDaysLeft']
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
      this.showDropdown = false
    },
    toggleSidebar(value) {
      this.$refs.sidebar.toggleSidebar(value)
    },
  },
  watch: {
    '$route.path'() {
      this.showDropdown = false
    },
  },
}
</script>
<style scoped>
.dropdown-menu {
  z-index: 100;
}
</style>
