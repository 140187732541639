<template>
  <div class="bg-white dark:bg-gray-900 shadow-card rounded-lg flex flex-col justify-between relative">
    <div class="px-4 py-5 sm:p-6 h-full">
      <div class="flex items-center h-full">
        <div v-if="$slots.icon" class="flex-shrink-0 bg-blueGray-600 rounded-md p-3">
          <slot name="icon">
            <!-- Heroicon name: cursor-click -->

          </slot>
        </div>
        <div
            class="flex h-full w-full justify-evenly items-center flex-wrap gap-3">
          <i v-if="loading" class="el-icon el-icon-loading w-full text-center text-xl w-full"></i>
          <slot v-else name="content">
          </slot>
        </div>
      </div>
    </div>
    <div class="footer-wrapper absolute bottom-0 left-0 right-0 w-40 m-auto flex justify-center"
         data-html2canvas-ignore="true"
         v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseStatsCard",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.footer-wrapper {
  bottom: -20px;
}
</style>
