<template>
  <label :for="label || $slots.label"
         class="block text-sm font-medium leading-5 text-gray-700">
    <slot name="label">
      {{ label }}
      <span v-if="rules && rules.includes('required')"
            class="text-gray-500">
              *
        </span>
    </slot>
    <span v-if="labelInfo" class="block text-xs text-gray-400 flex items-center mb-2">
          <info-icon size="1.3x" class="text-black mr-2"></info-icon>
        {{ labelInfo }}
      </span>
  </label>
  <base-input-error :error="errorMessage"
                    :show-tooltip="inlineErrors">
    <el-select v-bind="$attrs"
               v-model="inputValue"
               value-key="iso2"
               id="country-select"
               filterable
               placeholder="Code"
               @change="$emit('update:modelValue', $event)"
               class="select-default country-select__wrapper"
               :class="{
                 'country-select' : !showText,
                 'country-select__show-text' : showText,
                 'select-error': errorMessage
               }"
               ref="select">
      <template v-slot:prefix>
        <img class="w-10 my-auto cursor-pointer selected-flag"
             :src="inputValue?.icon"
             :alt="inputValue?.name">
      </template>
      <el-option v-for="country in allCountries"
                 class="country-option__item select-default"
                 :label="country.name"
                 :value="country"
                 :key="country.iso2">
        <div class="country-option">
          <img :src="country.icon" :alt="country.name" class="country-option__icon">
          <span class="country-option__text">{{ country.name }}</span>
        </div>
      </el-option>
    </el-select>
  </base-input-error>
</template>

<script>
import { ElSelect, ElOption } from 'element-plus'
import { useField } from "vee-validate";
import { InfoIcon } from '@zhuowenli/vue-feather-icons'

export default {
  name: "BaseCountrySelect",
  components: {
    ElSelect,
    ElOption,
    InfoIcon
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: [String, Number, Object],
    label: {
      type: String,
      default: '',
    },
    labelInfo: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    showText: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object, Array],
      default: ''
    },
    inlineErrors: {
      type: Boolean,
      default: false,
    },
    defaultCountryCode: {
      type: String,
      default: '',
    },
    allCountries: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const name = props.name || props.label
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.rules, {
      initialValue: props.modelValue,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  mounted() {
    if (this.defaultCountryCode) {
      this.inputValue = this.defaultCountry(this.defaultCountryCode)
      this.$emit('update:modelValue', this.inputValue)
    }
  },
  methods: {
    defaultCountry(countryCode = 'nl') {
      return this.allCountries.find(c => c.iso2.toLowerCase() === countryCode.toLowerCase())
    }
  },
  watch: {
    defaultCountryCode(val) {
      this.inputValue = this.defaultCountry(val)
      this.$emit('update:modelValue', this.inputValue)
    }
  }
}
</script>

<style lang="scss">
.el-select.country-select.country-select__wrapper {
  .el-input .el-input__inner {
    padding: 0 5px;

    @screen sm {
      padding: 0 7px;
    }
  }
}

.country-select__wrapper.country-select__show-text {
  .el-input .el-input__inner {
      @apply w-64;
      padding-left: 3.5rem !important;
  }
}

.country-select__wrapper .el-image {
  @apply w-20 h-20 text-center;

  @screen sm {
    @apply w-32 h-32;
  }

  @screen md {
    @apply w-60 h-60;
  }
}

.country-select__wrapper .el-input .el-input__prefix {
  @apply flex justify-center ml-1;
}

.country-select__wrapper .el-input .el-input__suffix {
  @apply hidden;
}

.country-select__wrapper.el-select.country-select {
  width: 51px;
}
</style>
