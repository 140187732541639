<template>
  <div class="flex items-center min-h-10">
    <JobTags
        :tags="get(job, 'job_tags', [])"
        :max-tags="maxTags"
        :tagInputRef="`tag-${job.id}`"
        :items-to-exclude="filterTags ? job.job_tags : []"
        :placeholder="$t('Tag name...')"
        v-bind="$attrs"
        @delete="onDeleteTag($event, job)"
        @onKeydownEnter="onAddTag($event, job)"/>
  </div>
</template>
<script>
import JobTags from "@/modules/jobs/components/JobTags.vue";
import JobService from "@/modules/jobs/services/JobService.js";
import { cloneDeep } from "lodash";

export default {
  components: {
    JobTags,
  },
  props: {
    job: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    tableKey: {
      type: String,
      default: 'undefined'
    }
  },
  computed: {
    maxTags() {
      return this.$store.state.settings?.tableSettings[this.tableKey]?.tagCount || 1
    },
    filterTags() {
      return this.$store.state.settings?.tableSettings[this.tableKey]?.filterTagAutocomplete ?? true
    },
  },
  methods: {
    async onDeleteTag(tag, row) {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Are you sure you want to delete this tag?'),
        buttonText: 'Delete'
      })

      if (!confirmed) {
        return
      }

      let payload = row.job_tags.filter(v => v !== tag);
      const res = await JobService.editTags(row.id, payload)
      this.$success(this.$t(`Tag deleted`))

      if (res) {
        row.job_tags = payload;
      } else {
        this.$error(this.$t('Something went wrong please try again.'))
      }
    },
    async onAddTag(evt, row) {
      const tag = evt

      if (row.job_tags.indexOf(tag) !== -1) {
        this.$error(this.$t('This tag already added'))
        return
      }

      if (!tag) {
        this.$error(this.$t('Tag name must be filled'))
        return
      }

      let payload = cloneDeep(row.job_tags)
      payload.push(tag)

      const res = await JobService.editTags(row.id, payload)

      if (res) {
        row.job_tags.push(tag)
        this.$success(this.$t(`Tag added`))
      } else {
        this.$error(this.$t('Something went wrong please try again.'))
      }
    }
  }
}
</script>
<style>
</style>
