import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import authModule from "@/modules/auth/store/authModule.js";
import themeModule from "@/modules/dashboard/store/themeModule.js";
import filterModule from "@/components/table/filters/filterModule.js";
import settingsModule from "@/components/table/settings/settingsModule.js";

export default createStore({
  plugins: [
    createPersistedState({
      paths: ['auth', 'theme', 'filter.savedFilters', 'filter.campaignCreateFilters', 'settings.savedColumns', 'settings.tableSettings', 'settings.env', 'settings.reportsDate', 'settings.displayedRemainingBudgetWarning'],
    })
  ],
  modules: {
    settings: settingsModule,
    filter: filterModule,
    auth: authModule,
    theme: themeModule,
  }
})
