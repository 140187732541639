<template>
  <BaseNoPermissions v-if="!isMediaBudgetEnabled" />
  <budget-change
      v-else
      v-model="media_budget"
      :save-text="$t('Deposit')"
      :cancel-text="$t('Cancel')"
      :title="$t('Deposit media budget')"
      :card-name="$t('Budget')"
      :input-name="$t('Media budget')"
      :input-placeholder="$t('Media budget')"
      @cancel="$router.push('/finance/media budget')"
      @submit="submitMediaBudget"
  />
</template>

<script>
import FinancialService from "../services/FinancialService";
import BudgetChange from "@/modules/dashboard/components/BudgetChange.vue";

export default {
  name: "budget-create",
  components: {
    BudgetChange,
  },
  data() {
    return {
      media_budget: null,
    }
  },
  computed: {
    isMediaBudgetEnabled() {
      return this.$store.state.auth?.selectedClient?.payment_type === 'MEDIA-BUDGET'
    },
  },
  methods: {
    async submitMediaBudget() {
      let checkoutRes
      const res = await FinancialService.depositMediaBudget({
        prepayment_value: this.media_budget
      })

      if (!res) {
        return
      }

      const redirectUrl = window.location.origin + `/finance/media%20budget?verify=true&token=${res.token}`
      checkoutRes = await FinancialService.checkoutMediaBudget(res.token, redirectUrl)

      if (checkoutRes.payment_url) {
        window.location.href = checkoutRes.payment_url
        return
      }

      if (checkoutRes) {
        this.$router.push('/finance/media budget')
        await this.$notifyDialog({
          title: this.$t('Payment successfull'),
          description: this.$t('You have deposited {}', {
            amount: `${this.$formatPrice(this.media_budget, {maximumFractionDigits: 0})}`
          }),
          buttonText: this.$t('Go back'),
        })
      }
    },
  }

}
</script>
