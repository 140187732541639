<template>
  <component :is="tag"
             class="p-badge"
             :class="
               [`badge-${type}`,
                pill ? `badge-pill` : ''
               ]">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'base-badge',
  props: {
    tag: {
      type: String,
      default: 'span',
      description: 'Html tag to use for the badge.'
    },
    pill: {
      type: Boolean,
      default: true,
      description: 'Whether badge is of pill type'
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger', 'default', 'check', 'inactive', 'active']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Badge type (primary|info|danger|default|warning|success)'
    }
  }
}
</script>
<style scoped>
.p-badge {
  @apply inline-flex items-center px-3 py-1 rounded-full text-xs font-semibold leading-4;
}

.p-badge.badge-default {
  @apply bg-gray-200 text-gray-600;
}

.p-badge.badge-check {
  @apply bg-gray-300 text-gray-600;
}

.p-badge.badge-inactive {
  @apply bg-yellow-200 text-yellow-600;
}

.p-badge.badge-info {
  @apply bg-blue-100 text-blue-800;
}

.p-badge.badge-success {
  @apply bg-green-200 text-green-800;
}

.p-badge.badge-active {
  @apply bg-green-200 text-green-800;
}

.p-badge.badge-danger {
  @apply bg-red-200 text-red-800;
}

</style>
