export const devHosts = {
  Dev: 'jobmatix.netlify.app',
  Local: 'local',
}

export default {
  install(Vue) {
    Vue.mixin({
        methods: {
          $isDevEnv() {
            return Object.values(devHosts).some(host => window.location.host.includes(host))
          },
        },
      },
    )
  },
}
