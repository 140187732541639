import axios from "axios";
import i18n from "@/i18n";
import { validatePhoneRequest } from "@/utils/phoneUtils.js";

export default {
  async getSettings() {
    try {
      return await axios.get('/setting');
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async addNewTeamUserSettings(payload) {
    try {
      return await axios.post('/setting/users', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async editTeamRoleSettings(userId, payload) {
    try {
      return await axios.put(`/setting/users/${userId}`, payload);
    } catch (e) {
      console.warn(e)
    }
  },

  async resendInvitationTeamMemberSettings(userId) {
    try {
      return await axios.get(`/setting/user/${userId}/resend-invite`);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async saveSettings(payload) {
    try {
      let data = {
        ...payload
      }
      data = validatePhoneRequest(data)
      return await axios.put('/setting', data);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async addFeedSettings(payload) {
    try {
      return await axios.post('/setting/feeds', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async reloadFeed(feedId) {
    return await axios.get(`/setting/feeds/${feedId}/sync`, {
      params: {
        force: true
      }
    });
  },

  async saveTargets(payload) {
    try {
      return await axios.put('/setting/targets', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async saveUtm(payload) {
    try {
      return await axios.put('/setting/settings', payload);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async createPixelToken() {
    try {
      return await axios.post('/setting/token');
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getTokens(token) {
    try {
      return await axios.get(`/setting/token/${token}`);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },

  async getTokenEvents(token) {
    try {
      return await axios.get(`/setting/token/${token}/events`);
    } catch (e) {
      window.app.$error(i18n.t('Something went wrong please try again.'))
    }
  },
}

