<template>
  <BaseNoPermissions v-if="$isReadOnly" />
  <div v-else class="mt-10 sm:mt-0 job-create-form__wrapper">
    <base-form v-bind="$attrs"
               :loading="loading"
               :editMode="editMode"
               :save-text="Object.keys(job).length ? $t('Save Job') : $t('Create Job')"
               fixed-footer
               grid-classes="flex"
               layout="modal"
               @submit="submitJobCreate">
      <div class="w-full">
        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Job basics') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>

        <div class="flex mt-2">
          <base-avatar-upload
              :image="model.media_image_id"
              button-text="Select image"
              class="w-3/6 flex-col mr-4 md:mr-0"
              :name="userFromStore.user.given_name"
              @on-finish="updateMediaId"/>
          <div class="flex flex-col w-full justify-end">
            <base-input v-model="model.organisation_name"
                        :label="$t('Organization')"
                        :placeholder="$t('Organization')"
                        :name="$t('Organization')"
                        class="w-full"
                        id="organization"
                        rules="required">
            </base-input>
            <base-select
                v-if="!editMode"
                v-model="model.status"
                :options="jobStatusesOptions"
                :label="$t('Status')"
                :placeholder="$t('Status')"
                :name="$t('Status')"
                id="jobStatus"
                rules="required"
            />
            <multi-field-input
                v-model="multiFieldsTitle"
                tag-input-ref="multiTitleRef"
                name="input1"
                wrapperClass="min-h-10"
                :placeholder="$t('Job title')"
                :add-text="$t('Add title')"
            >
              <template v-slot:default>
                <base-input v-model="model.name"
                            :label="$t('Job title')"
                            :placeholder="$t('Job title')"
                            :name="$t('Job title')"
                            class="w-full"
                            id="name"
                            rules="required|max:100">
                  <template v-slot:suffix>
                    <div v-if="multiFieldsTitle.fields?.length"
                         class="fields-length__badge">
                      {{ multiFieldsTitle.fields?.length }}
                    </div>
                    <base-tooltip :content="$t('Add multiple fields (optional)')">
                      <plus-square-icon size="1.4x" class="cursor-pointer" @click="toggleMultiShow('Title')"/>
                    </base-tooltip>
                  </template>
                </base-input>
              </template>
            </multi-field-input>
          </div>
        </div>

        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white mt-10">{{ $t('Job location') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>
        <div class="mt-3">
          <div class="lg:w-3/6">
            <base-select v-model="model.location_type"
                         :options="locationTypeOptions"
                         :label="$t('Location type')"
                         :placeholder="$t('Location type')"
                         classNames="lg:mr-2"
                         :name="$t('Location type')"
                         id="locationType"
                         rules="required"
                         @update:modelValue="resetLocation">
            </base-select>
          </div>

          <div class="flex flex-col lg:flex-row">
            <base-select v-model="model.location_country"
                         :options="allCountries"
                         :label="$t('Country')"
                         :placeholder="$t('Country')"
                         :name="$t('Country')"
                         classNames="w-full"
                         id="country"
                         rules="required"
                         filterable>
            </base-select>
            <div class="w-full lg:ml-4" v-if="model.location_type === 'FIXED'">
              <multi-field-input
                  v-model="multiFieldsCity"
                  wrapperClass="min-h-10 mt-3"
                  tag-input-ref="multiTitleRef"
                  :name="$t('city')"
                  :max-tags="20"
                  :min-length="2"
                  :placeholder="$t('City')"
                  :add-text="$t('Add city')"
                  :disable-autocomplete="false"
                  :fetchDataAction="fetchCountries"
                  :map-label="data => data?.label"
              >
                <template v-slot:default>
                  <base-autocomplete :inputValue="model.location_city"
                                     :fetchDataAction="fetchCountries"
                                     @onKeydownEnter="onCityFieldChange($event, 'location_city')"
                                     :placeholder="$t('City')"
                                     :label="$t('City')"
                                     :name="$t('City')"
                                     classNames="w-full"
                                     rules="required|min:2">
                    <template v-slot:suffix>
                      <div v-if="multiFieldsCity.fields?.length"
                           class="fields-length__badge">
                        {{ multiFieldsCity.fields?.length }}
                      </div>
                      <base-tooltip :content="$t('Add multiple fields (optional)')">
                        <plus-square-icon size="1.4x" class="cursor-pointer" @click="toggleMultiShow('City')"/>
                      </base-tooltip>
                    </template>
                  </base-autocomplete>
                </template>
              </multi-field-input>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row">
            <base-input v-if="model.location_type === 'FIXED'"
                        v-model="model.location_state"
                        :label="$t('State / Province')"
                        :placeholder="$t('State / Province')"
                        :name="$t('State / Province')"
                        class="w-full mt-2 lg:mt-0"
                        id="state">
            </base-input>
            <div v-if="model.location_type === 'FIXED'" class="w-full mt-2 lg:mt-0 lg:ml-4">
              <base-input v-model="model.location_zip_code"
                          :label="$t('Zip code')"
                          :placeholder="$t('Zip code')"
                          :name="$t('Zip code')"
                          id="zipcode">
              </base-input>
            </div>
          </div>
        </div>

        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white mt-6 lg:mt-10">{{ $t('Apply details') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>
        <div class="mt-3">
          <div class="lg:w-3/6">
            <base-select v-model="detailsType"
                         :options="detailsTypeOptions"
                         :label="$t('Apply type')"
                         :placeholder="$t('Apply type')"
                         classNames="lg:mr-2"
                         name="applyType"
                         id="applyType"
                         rules="required">
            </base-select>
          </div>
          <div class="flex flex-col w-full">
            <base-input v-model="model.apply_email"
                        :label="$t('Apply Email')"
                        :placeholder="$t('Apply Email')"
                        :name="$t('Apply Email')"
                        class="w-full"
                        id="email"
                        rules="email">
            </base-input>
            <base-input v-if="detailsType === 'OWN'"
                        v-model="model.job_url"
                        :label="$t('Job page URL')"
                        :placeholder="$t('Vacancy page URL')"
                        :name="$t('Vacancy page URL')"
                        class="w-full"
                        id="pageUrl"
                        rules="url">
            </base-input>
            <base-input v-if="detailsType === 'OWN'"
                        v-model="model.apply_url"
                        :label="$t('Job apply URL')"
                        :placeholder="$t('Job apply URL')"
                        :name="$t('Job apply URL')"
                        classNames="w-full"
                        id="applicationUrl">
            </base-input>
          </div>
        </div>

        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white mt-10">{{ $t('Job filtering') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>
        <div class="mt-3">
          <div class="flex flex-col xl:flex-row">
            <base-select v-model="model.contract"
                         :options="jobTypeOptions"
                         :label="$t('Job type')"
                         :placeholder="$t('Job type')"
                         classNames="w-full"
                         :name="$t('Job type')"
                         id="jobType"
                         rules="required"
                         :label-info="$t('Please select a job type.')">
            </base-select>
            <base-select v-model="model.education"
                         :options="educationOptions"
                         :label="$t('Education')"
                         :placeholder="$t('Education')"
                         classNames="w-full xl:mx-2"
                         :name="$t('Education')"
                         id="education"
                         rules="required"
                         multiple
                         :multipleLimit="2"
                         :label-info="$t('You can select up to 2 education types.')">
            </base-select>
            <base-select v-model="model.category"
                         :options="categoryOptions"
                         :label="$t('Category')"
                         :placeholder="$t('Category')"
                         classNames="w-full"
                         :name="$t('Category')"
                         id="category"
                         rules="required"
                         multiple
                         :multipleLimit="2"
                         :label-info="$t('You can select up to 2 categories.')">
            </base-select>
          </div>
        </div>

        <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white mt-10">{{ $t('Job texts') }}</p>
        <div class="w-full border-b border-gray-200 mt-4"></div>
        <div class="mt-3">
          <base-input v-model="model.description"
                      type="textarea"
                      rows="5"
                      :label="$t('Teaser')"
                      :label-info="$t('Teaser can be used as an introduction to the vacancy. Maximum 200 characters.')"
                      :placeholder="$t('Teaser')"
                      :name="$t('Teaser')"
                      class="w-full"
                      id="jobDescription"
                      max-length="300"
                      rules="required|max:300">
          </base-input>
          <div></div>
          <html-editor
              v-model="model.text"
              :label="$t('Job text')"
              :label-info="$t('A good vacancy text ensures more applications.')"
              :name="$t('Job text')"
              rules="required"/>
        </div>

        <div v-if="isOwnerOrMarketeer">
          <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white mt-10">{{ $t('Extra options') }}</p>
          <div class="w-full border-b border-gray-200 my-4"></div>
          <div class="flex">
            <base-switch
                v-model="model.continuous"
                class="mb-4 mr-6"
                :label="$t('Continuous job')"/>
            <base-switch
                v-model="model.feed_hide"
                v-if="job.feed_id"
                :label="$t('Don’t sync with feed')"/>
          </div>

          <div class="flex flex-col">
            <p class="mb-3 text-sm font-medium leading-5 text-gray-700">
              {{ $t('From and until date for vacancy (not mandatory)') }}</p>
            <div class="flex flex-col lg:flex-row">
              <base-date-picker v-model="model.from"
                                name="Date from"
                                class="w-full"
                                @update:modelValue="onChangeFromDate"
                                :placeholder="$t('Start date')"/>
              <base-date-picker v-if="!model.continuous"
                                v-model="model.till"
                                name="Date till"
                                class="w-full lg:ml-4"
                                :disabled-date="tillDisabledDays"
                                :placeholder="$t('End date')"/>
            </div>
          </div>
        </div>
      </div>
    </base-form>
  </div>
</template>

<script>
import BaseAutocomplete from "@/components/form/BaseAutocomplete.vue";
import BaseAvatarUpload from "@/components/form/BaseAvatarUpload.vue";
import BaseDatePicker from "@/components/form/BaseDatePicker.vue";
import JobService from "@/modules/jobs/services/JobService";
import HtmlEditor from "@/components/common/HtmlEditor.vue";
import formatSelectOptionsMixin from "@/modules/jobs/mixins/formatSelectOptionsMixin";
import countriesMixin from "@/modules/jobs/mixins/countriesMixin";
import { PlusSquareIcon } from '@zhuowenli/vue-feather-icons'
import MultiFieldInput from "@/components/form/MultiFieldInput.vue";
import { UserTypes } from '@/enum/userEnums'
import { DEFAULT_DATE_FORMAT } from "@/plugins/dateFormatPlugin.js";
import { parse } from 'date-fns'
import { JOB_STATUSES } from '@/enum/tableEnums'
const apiDateFormat = 'yyyy-MM-dd';

export default {
  name: "JobCreateForm",
  components: {
    BaseAutocomplete,
    BaseAvatarUpload,
    BaseDatePicker,
    HtmlEditor,
    PlusSquareIcon,
    MultiFieldInput
  },
  props: {
    job: {
      type: Object,
      default: () => ({})
    },
    loading: Boolean,
  },
  mixins: [formatSelectOptionsMixin, countriesMixin],
  data() {
    return {
      model: {
        status: JOB_STATUSES.ACTIVE,
        contract: null,
        media_image_id: null,
        organisation_name: null,
        name: null,
        description: null,
        text: null,
        location_country: null,
        location_city: null,
        location_state: null,
        lat: null,
        lon: null,
        location_type: 'FIXED',
        location_zip_code: null,
        apply_email: null,
        job_url: null,
        apply_url: undefined,
        education: [],
        category: [],
        from: null,
        till: null,
        continuous: false
      },
      multiFieldsTitle: {
        fields: [],
        show: false
      },
      multiFieldsCity: {
        fields: [],
        show: false
      },
      categories: null,
      educations: null,
      contracts: null,
      loading: false,
      detailsType: 'PAGE',
    }
  },
  async created() {
    this.loading = true
    const [categories, educations, contracts] = await Promise.all([
      JobService.typeAhead({
        type: "job_category"
      }),
      JobService.typeAhead({
        type: "job_education"
      }),
      JobService.typeAhead({
        type: "job_contract"
      })
    ])

    this.categories = categories
    this.educations = educations
    this.contracts = contracts

    const copyJob = this.$route.query.copy

    if (copyJob) {
      const res = await JobService.getJob(copyJob)
      this.initModel(res)
    } else if (this.editMode) {
      this.$nextTick(() => {
        this.detailsType = 'OWN'
        this.initModel()
      })
    } else {
      this.model.location_country = this.userFromStore.selectedClient.address.country_code
      this.model.media_image_id = this.userFromStore.selectedClient.image
      this.model.organisation_name = this.userFromStore.selectedClient.name
      this.model.apply_email = this.userFromStore.user.email
      this.model.from = new Date()
    }

    this.loading = false
  },
  computed: {
    jobStatusesOptions() {
      return this.formatOptionsData(JOB_STATUSES).filter(el => el.value !== JOB_STATUSES.FINISHED)
    },
    isOwnerOrMarketeer() {
      const role = this.$store.state.auth?.user?.role
      return [UserTypes.Owner, UserTypes.Marketeer].includes(role)
    },
    editMode() {
      return Object.keys(this.job).length
    },
    tillDisabledDays() {
      const app = this
      return function disabledDate(time) {
        const from = app.parseStringDate(app.model.from)
        if (!from) {
          return false
        }
        return time.getTime() < from.getTime();
      }
    },
    jobTypeOptions() {
      return this.formatOptionsData('contracts')
    },
    educationOptions() {
      return this.formatOptionsData('educations')
    },
    categoryOptions() {
      return this.formatOptionsData('categories')
    },
    locationTypeOptions() {
      return [
        {
          value: 'FIXED',
          label: 'Fixed'
        },
        {
          value: 'COUNTRY',
          label: 'Remote'
        }
      ]
    },
    detailsTypeOptions() {
      return [
        {
          value: 'OWN',
          label: 'Own details'
        },
        {
          value: 'PAGE',
          label: 'Jobmatix.com page'
        }
      ]
    },
    userFromStore() {
      return this.$store.state.auth
    }
  },
  methods: {
    resetLocation(val) {
      if(val === 'COUNTRY') {
        this.model.location_zip_code = null
        this.model.location_state = null
        this.model.location_city = null
      }
    },
    initModel(job = this.job) {
      this.model.status = job.status
      this.model.media_image_id = job.media_image_location
      this.model.organisation_name = job.organisation_name
      this.model.name = job.name
      this.model.description = job.description
      this.model.text = job.text
      this.model.location_country = job.location_country
      this.model.location_city = job.location_city
      this.model.location_state = job.location_state
      this.model.apply_email = job.apply_email
      this.model.job_url = job.job_url
      this.model.apply_url = job.apply_url
      this.model.job_tags = job.job_tags
      this.model.contract = this.get(job, 'job_segments.contract[0]', '').toString()
      this.model.education = this.get(job ,'job_segments.education', []).map(education => education.toString()) || [];
      this.model.category = this.get(job, 'job_segments.category', []).map(category => category.toString()) || [];
      this.model.from = job.from
      this.model.location_type = job.location_type
      this.model.location_zip_code = job.location_zip_code
      job.continuous ? this.model.continuous = true : this.model.continuous = false
      job.feed_hide ? this.model.feed_hide = true : this.model.feed_hide = false
      this.multiFieldsTitle.fields = job.multiplier?.titles || []
      this.multiFieldsCity.fields = job.multiplier?.cities || []

      this.multiFieldsTitle.fields?.length ? this.multiFieldsTitle.show = true : false
      this.multiFieldsCity.fields?.length ? this.multiFieldsCity.show = true : false

      if (job.till && !this.model.continuous) {
        this.model.till = job.till
      }
    },
    onChangeFromDate(val) {
      if (!val) {
        this.model.till = null
        return
      }
      this.addMothToTillDate(val)
    },
    addMothToTillDate(fromDate = new Date()) {
      let tillDate = new Date(fromDate)
      tillDate.setDate(tillDate.getDate() + 30)
      this.model.till = tillDate
    },
    parseStringDate(date) {
      let parsedDate = parse(date, apiDateFormat, new Date())
      if (!parsedDate.getTime()) {
        parsedDate = parse(date, DEFAULT_DATE_FORMAT, new Date())
      }
      return parsedDate
    },
    updateMediaId(value) {
      this.model.media_image_id = value
    },
    async submitJobCreate() {
      let res
      let jobId = this.job.id

      if (this.model.continuous) {
        delete this.model.till
      }

      try {
        this.loading = true
        if (!this.model.apply_url) {
          this.model.apply_url = undefined
        }
        const data = {
          ...this.model
        }
        data.contract = [this.model.contract]
        data.from = this.parseDate(this.model.from)
        data.till = this.parseDate(this.model.till)

        if (this.editMode) {
          res = await JobService.editJob(this.job.id, data)
        } else {
          res = await JobService.createJob(data)
          jobId = res.id
        }

      } catch (err) {
        console.log('Error saving or updating the job', err)
      } finally {
        this.loading = false
      }

      const fieldRes = await JobService.editJobField(jobId, {
        multiplier: {
          cities: this.multiFieldsCity.fields,
          titles: this.multiFieldsTitle.fields
        }
      })

      if (!res || !fieldRes && this.editMode) {
        return
      }

      this.editMode ? this.$success(this.$t('Successfully edited job!')) : this.$success(this.$t('Successfully created a job!'))
      this.$router.push({path: `/jobs/${res.id}`, query: {
          tab: 'edit',
          title: res.name
        }})
      this.$emit('changeTab', 'edit')
    },
    toggleMultiShow(field) {
      const modelName = 'multiFields' + field
      this[modelName].show = !this[modelName].show
    },
    parseDate(date) {
      if (date?.getTime) {
        return this.$formatDate(date, apiDateFormat)
      }
      return date
    },
    fetchCountries(query) {
      return this.fetchCountriesData(query, this.model.location_country)
    }
  },
}
</script>
<style lang="scss">
.job-create-form__wrapper .el-image {
  @apply w-20 h-20 text-center;

  @screen sm {
    @apply w-32 h-32;
  }

  @screen md {
    @apply w-60 h-60;
  }
}

.fields-length__badge {
  @apply w-3 h-3 rounded-full absolute bg-white flex items-center justify-center border border-primary-500 text-black;
  font-size: 8px;
  top: 7px;
  right: 7px;
}
</style>
