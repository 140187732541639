<template>
  <div class="flex flex-wrap items-center" :class="$attrs.class">
    <template v-if="tags.length > 0">
      <status-badge v-for="tag in tagsToDisplay"
                    :key="tag"
                    :status="tag.slug || tag"
                    :close-icon="!hideDeleteButton"
                    :capitalize="$attrs.capitalize"
                    @delete="$emit('delete', tag)"
                    class="flex mb-1 ml-1"
      >
      </status-badge>
      <status-badge v-if="!showMore && tags.length > maxTags"
                    class="mb-1 ml-1 cursor-pointer"
                    type="more"
                    :status="`+ ${remainingTags} ${$t('more')}`"
                    @click="showMore = true"/>
      <status-badge v-else-if="showMore && tags.length > maxTags"
                    class="mb-1 ml-1 cursor-pointer"
                    type="less"
                    :status="$t(`Show Less`)"
                    @click="showMore = false"/>
    </template>
    <div v-if="!hideAddInput"
         class="flex flex-col justify-center ml-1"
         :class="{
            'my-1' : showAddInput,
            'h-8': smallInput,
          }">
      <div v-if="showAddInput"
           :class="{'add-input-container': !smallInput}"
      >
        <base-autocomplete
            :inputValue="tagName"
            v-bind="$attrs"
            :key="inputKey"
            :ref="$attrs.tagInputRef"
            :fetchDataAction="fetchDataAction || fetchTagsData"
            :class-names="smallInput ? 'h-8 w-full': 'w-full'"
            @update:modelValue="tagName = $event"
            @onKeydownEnter="onChangeInput"
        >
          <template v-slot:prefix>
            <ArrowLeftCircleIcon class="w-4 h-4 text-gray-400 cursor-pointer" @click="showAddInput = false"/>
          </template>
        </base-autocomplete>
      </div>
      <status-badge v-else-if="!$isReadOnly"
                    class="mb-1 cursor-pointer"
                    :status="addText"
                    :capitalize="$attrs.capitalize"
                    @click="showTagInput"/>
    </div>
  </div>
</template>
<script>
import { ArrowLeftCircleIcon } from '@zhuowenli/vue-feather-icons'
import BaseAutocomplete from "@/components/form/BaseAutocomplete.vue";
import JobService from "@/modules/jobs/services/JobService.js";

export default {
  inheritAttrs: false,
  components: {
    BaseAutocomplete,
    ArrowLeftCircleIcon
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    maxTags: {
      type: Number,
      default: 1
    },
    hideAddInput: {
      type: Boolean,
      default: false
    },
    hideDeleteButton: {
      type: Boolean,
      default: false
    },
    addText: {
      type: String,
      default: 'Add tag'
    },
    fetchDataAction: {
      type: Function,
    },
    smallInput: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['delete'],
  data() {
    return {
      showMore: false,
      showAddInput: false,
      tagName: ''
    }
  },
  computed: {
    tagsToDisplay() {
      if (this.showMore) {
        return this.tags
      }
      return this.tags.slice(0, this.maxTags)
    },
    remainingTags() {
      return this.tags.length - this.maxTags
    },
    inputKey() {
      return `tagInput-${Math.random()}`
    }
  },
  methods: {
    onChangeInput() {
      this.tagName = ''
    },
    async fetchTagsData(query) {
      return await JobService.typeAhead({
        type: "tags",
        query: query
      })
    },
    showTagInput() {
      this.showAddInput = true
      this.$nextTick(() => {
        const tagInputRef = this.$refs[this.$attrs.tagInputRef]
        tagInputRef && tagInputRef.focus()
      })
    }
  }
}
</script>
<style lang="scss">
.el-autocomplete {
  .el-input__inner {
    @apply h-8;
  }

  .el-input__prefix {
    @apply flex items-center justify-center pl-1;
  }
}
</style>
<style scoped>
.add-input-container {
  min-width: 290px;
}
</style>
