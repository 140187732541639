<template>
  <div>
    <div class="flex justify-between items-end">
      <div>
        <entity-select
            v-if="!channelId"
            v-model="selectedChannel"
            :label="$t('Channel')"
            :label-format="channelLabelFormat"
            url="/channel"
            value-key="id"
            select-first-option
        />
      </div>

      <report-date-picker
          :key="dateKey"
          v-model:from="from"
          v-model:till="till"/>
    </div>

    <dashboard-reports :from="from"
                       :till="till"
                       :default-active-items="['kpi_applicants', 'kpi_spend']"
                       :extra-params="{
                         [`params[channel_id]`]: selectedChannel
                       }"
    />
    <div class="mt-4">
      <jobs-table :from="from"
                  :till="till"
                  :extra-params="{
                         [`params[channel_id]`]: selectedChannel
                       }"
      />
    </div>
    <div class="mt-4">
      <campaigns-table :from="from"
                      :till="till"
                      :extra-params="{
                         [`params[channel_id]`]: selectedChannel
                       }"
      />
    </div>
  </div>
</template>
<script>
import { subMonths } from "date-fns";
import EntitySelect from "@/components/common/EntitySelect.vue";
import ReportDatePicker from "@/components/report/ReportDatePicker.vue";
import DashboardReports from "@/modules/dashboard/components/DashboardReports.vue";
import CampaignsTable from "@/modules/reports/components/CampaignsTable.vue";
import JobsTable from "@/modules/reports/components/JobsTable.vue";

export default {
  components: {
    ReportDatePicker,
    DashboardReports,
    EntitySelect,
    JobsTable,
    CampaignsTable
  },
  props: {
    channelId: {
      type: String,
    }
  },
  data() {
    const reportsDate = this.$store.state.settings.reportsDate
    const from = reportsDate?.from ? new Date(reportsDate.from) : subMonths(new Date(), 1)
    const till = reportsDate?.till ? new Date(reportsDate.till) : new Date()
    return {
      loading: false,
      from,
      till,
      jobsData: [],
      selectedChannel: this.$route.query.channel || this.channelId || null,
      dateKey: 0
    }
  },
  methods: {
    channelLabelFormat(channel) {
      return `${channel.name} - ${channel.promotion_type.toLowerCase()}`
    }
  },
  mounted() {
    this.dateKey = Date.now()
  }
}
</script>
<style>
</style>
