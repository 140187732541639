<template>
  <BaseDataTable :columns="columns"
                 :tableColumnsKey="tableKey"
                 :initial-filters="initialFilters"
                 :actions="$canCreateCampaigns ? actions : basicUserActions"
                 :per-page="perPage"
                 :add-text="$t('New Job')"
                 url="/job"
                 entity="job"
                 @data-fetch="onTableFetchData"
                 @add="$router.push('/jobs/job-create')"
                 @view="row => goToJobPage(row, 'overview')"
                 @edit="row => goToJobPage(row, 'edit')"
                 @pagination-fetch="$emit('pagination-fetch', $event)"
                 ref="table"
  >
    <template v-slot:empty>
      <slot name="empty">
        <div class="flex flex-col justify-evenly my-10">
          {{$t('No Data found')}}
          <router-link to="/jobs/job-create" class="mt-4">
            <base-button variant="primary">
              {{ $t('Add a job') }}
            </base-button>
          </router-link>
        </div>
      </slot>
    </template>
    <template v-slot:status="{row}">
      <base-popover :parent-entity-id="`popover-status-${row.id}`"
                    :ref="`popover-status-${row.id}`">
        <template v-slot:body>
          <div class="w-32">
            <base-radio v-for="data in JOB_STATUSES"
                        v-model="status[row.id]"
                        :key="`radio-status-${row.id}`"
                        :value="data"
                        :disabled="data === JOB_STATUSES.DRAFT"
                        name="status"
                        :active="data === status[row.id]"
                        @update:modelValue="$evt => onSubmit(row, 'status', $evt)"
                        inline>
              {{ $t(data).toLowerCase() }}
            </base-radio>
          </div>
        </template>
        <template v-slot:reference>
          <base-button variant="primary-link">
            <div class="w-4 h-4 m-auto rounded-full"
                 :class="{
                      'bg-green-400' : row.status === JOB_STATUSES.ACTIVE,
                      'bg-yellow-400' : row.status === JOB_STATUSES.FINISHED,
                      'bg-gray-400' : row.status === JOB_STATUSES.DRAFT,
                    }"/>
          </base-button>
        </template>
      </base-popover>
    </template>

    <template v-slot:priority="{row}">
      <base-popover :parent-entity-id="`popover-priority-${row.id}`"
                    :ref="`popover-priority-${row.id}`">
        <template v-slot:body>
          <div class="w-48">
            <base-radio v-for="data in PRIORITIES"
                        v-model="priority[row.id]"
                        :key="`radio-priority-${row.id}`"
                        :value="data"
                        :disabled="data === PRIORITIES.VERY_LOW || data === PRIORITIES.VERY_HIGH"
                        name="priority"
                        :active="data === priority[row.id]"
                        @update:modelValue="$evt => onSubmit(row, 'priority', $evt)"
                        inline>
              {{ $t(data).toLowerCase() }}
            </base-radio>
          </div>
        </template>
        <template v-slot:reference>
          <arrow-up-circle-icon
              size="1.5x"
              :key="circleArrowKey"
              class="m-auto cursor-pointer"
              :class="{
                  'text-yellow-200' : row.priority === PRIORITIES.VERY_LOW ,
                  'text-yellow-400' : row.priority === PRIORITIES.LOW ,
                  'text-green-400' : row.priority === PRIORITIES.MEDIUM,
                  'text-red-300' : row.priority === PRIORITIES.HIGH,
                  'text-red-600' : row.priority === PRIORITIES.VERY_HIGH
                }"/>
        </template>
      </base-popover>
    </template>

    <template v-slot:boosts="{row}">
      <base-popover :parent-entity-id="`popover-boost-${row.id}`">
        <template v-slot:body>
          <div class="w-88">
            <div v-if="row.boosts.length">
              <info-card
                  v-for="(boost, index) in row.boosts"
                  :key="row.boosts.id"
                  :data="boost"
                  :fields="boostFields"
                  title="name">
                <template v-slot:extraFields>
                  <router-link :to="boostCampaignPath(row, index)" class="w-full flex justify-center py-2 hover:underline">
                    {{ $t('Go to Campaign') }}
                  </router-link>
                </template>
              </info-card>
            </div>
            <div v-else class="break-normal text-center p-2">
              {{ $t('No active boosts, please create one') }}
            </div>
          </div>
        </template>
        <template v-slot:reference>
          <div class="inline-flex rounded-md p-1 px-2 cursor-pointer"
               :class="[row.boosts.length ? 'bg-green-200' : 'bg-primary-200']"
               @click="goToCampaignCreation(row.id)">
            <p class="font-semibold"
               :class="[row.boosts.length ? 'text-green-900' : 'text-primary-700']">
              Boost
            </p>
          </div>
        </template>
      </base-popover>
    </template>
    <template v-slot:job_tags="{row}">
      <JobTagsCell :job="row" :tableKey="tableKey" />
    </template>
    <template v-slot:ctr="{row}">
      <JobPerformance :kpis="{
            applicants: get(row, 'kpis.applicants'),
            clicks: get(row, 'kpis.clicks')
       }">
      </JobPerformance>
    </template>
    <template v-slot:applicants="{row}">
      <div>{{get(row, 'kpis.applicants', '--')}}</div>
    </template>
    <template v-slot:clicks="{row}">
      <div>{{get(row, 'kpis.clicks', '--')}}</div>
    </template>
    <template v-slot:location_name="{row}">
      <span>{{ getCountryNameByLocale(row.location_name) }}</span>
      <JobTags v-if="row.multiplier?.cities?.length"
               class="mt-2"
               :tags="row.multiplier?.cities"
               :max-tags="maxTags"
               hide-delete-button
               hide-add-input/>
    </template>
    <template v-slot:name="{row}">
      <div class="truncate" :title="row.name">
        <router-link :to="{path: `/jobs/${row.id}`, query: { tab: 'overview', title: row.name}}"
                     class="hover:underline">
          <span class="text-gray-700 font-normal w-10">{{ row.name }}</span>
        </router-link>
        <JobTags v-if="row.multiplier?.titles?.length"
                 class="mt-2"
                 :tags="row.multiplier?.titles"
                 :max-tags="maxTags"
                 hide-delete-button
                 hide-add-input/>
      </div>
    </template>

    <template v-slot:extra-actions="{row}">
      <a v-if="row.job_url"
         :href="getTestJobUrl(row.job_url)"
         target="_blank"
         rel="noopener"
         tabindex="-1"
         class="table-action-button"
      >
        <base-button variant="gray-light"
                     :tabindex="0"
                     size="icon"
        >
          <ExternalLinkIcon class="w-4 h-4"/>
        </base-button>
      </a>
    </template>
  </BaseDataTable>
</template>
<script>
import JobTags from "@/modules/jobs/components/JobTags.vue";
import InfoCard from "@/components/common/InfoCard.vue";
import JobPerformance from "@/modules/jobs/components/JobPerformance.vue";
import { ArrowUpCircleIcon } from '@zhuowenli/vue-feather-icons'
import jobSubmitFieldMixin from "@/modules/jobs/mixins/jobSubmitFieldMixin.js";
import { JOB_STATUSES, PRIORITIES } from '@/enum/tableEnums'
import { ExternalLinkIcon } from '@zhuowenli/vue-feather-icons'
import JobTagsCell from "@/modules/jobs/components/JobTagsCell.vue";
import { replaceLinkVariables } from "@/utils/replaceLinkVariables.js";
import { getCountryNameByLocale } from '@/utils/localeUtils'

export default {
  name: 'JobsList',
  components: {
    InfoCard,
    JobTags,
    JobPerformance,
    JobTagsCell,
    ExternalLinkIcon,
    ArrowUpCircleIcon,
  },
  props:{
    initialFilters: {
      type: Array,
      default: [
        {
          field: 'status',
          operator: 'IS',
          value: 'ACTIVE',
        }
      ]
    },
    actions: {
      type: String,
      default: 'view,search,filters,add,edit'
    },
    createCampaignButton: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 25,
    }
  },
  mixins: [jobSubmitFieldMixin],
  data() {
    return {
      status: {},
      priority: {},
      tableKey: 'jobs-table-2',
      circleArrowKey: 0,
      PRIORITIES,
      JOB_STATUSES
    }
  },
  computed: {
    basicUserActions() {
      return 'view,search'
    },
    boostFields() {
      return {
        budget_stopper: 'Budget stopper',
      }
    },
    maxTags() {
      return this.$store.state.settings.tableSettings[this.tableKey]?.tagCount || 1
    },
    columns() {
      let columns = [
        {
          label: this.$t('From'),
          prop: 'from',
          maxWidth: 160,
          sortable: true,
          component: 'FormattedDate'
        },
        {
          label: this.$t('Title'),
          prop: 'name',
          maxWidth: 220 ,
          sortable: true,
        },
        {
          label: this.$t('Location'),
          prop: 'location_name',
          maxWidth: 180,
          sortable: true,
        },
        {
          label: this.$t('Organisation'),
          prop: 'organisation_name',
          maxWidth: 200,
          minWidth: 120,
        },
        {
          label: this.$t('Reference'),
          prop: 'reference',
          maxWidth: 100,
          sortable: true,
        },
        {
          label: this.$t('Tags'),
          prop: 'job_tags',
          maxWidth: 350,
          minWidth: 300,
        },
        {
          label: this.$t('Status'),
          prop: 'status',
          maxWidth: 70,
          sortable: true,
          align: 'left',
          hide: true,
        },
        {
          label: this.$t('Priority'),
          prop: 'priority',
          maxWidth: 70,
          hide: true,
        },
        {
          label: this.$t('Conversion Rate'),
          prop: 'ctr',
          maxWidth: 180,
          firstSort: 'desc',
          hide: true,
        },
        {
          label: this.$t('Applicants'),
          prop: 'applicants',
          maxWidth: 120,
          sortable: true,
          firstSort: 'desc',
          hide: true,
        },
        {
          label: this.$t('Clicks'),
          prop: 'clicks',
          maxWidth: 120,
          sortable: true,
          firstSort: 'desc',
          hide: true,
        },
        {
          label: this.$t('Till'),
          prop: 'till',
          maxWidth: 160,
          sortable: true,
          component: 'FormattedDate',
          hide: true,
        },
      ]
      if (!this.$canCreateCampaigns) {
        columns = columns.filter(c => c.prop !== 'priority')
      }
      return columns
    }
  },
  methods: {
    getCountryNameByLocale,
    boostCampaignPath(campaign, index) {
      return `/campaigns/${campaign.boosts[index].id}?tab=overview&title=${campaign.boosts[index].name}&job=${campaign.boosts[index].job_id}`
    },
    goToCampaignCreatePage() {
      const tableFilters = this.$refs.table?.dynamicFilters || []
      this.$store.commit('filter/SET_CAMPAIGN_CREATE_FILTERS', tableFilters)
      this.$router.push({path: '/campaigns/campaign-create'})
    },
    goToJobPage(row, tab) {
      this.$router.push({path: `/jobs/${row.id}`, query: {title: row.name, tab: tab}})
    },
    onTableFetchData(data) {
      data.forEach(data => {
        this.status[data.id] = data.status
        this.priority[data.id] = data.priority
      })
    },
    goToCampaignCreation(id) {
      if (!this.$canCreateCampaigns) {
        return
      }
      this.$router.push({
        path: '/campaigns/campaign-create',
        query: {
          job: id
        }
      })
    },
    getTestJobUrl(url) {
      return replaceLinkVariables(url, {
        campaign: 'TEST-campaign',
        channel: 'TEST-channel',
        medium: 'TEST-medium',
        type: 'TEST-type',
        reference: 'TEST-reference',
        content: 'TEST-content',
      })
    }
  }
}
</script>
