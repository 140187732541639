<template>
  <div class="mt-10 sm:mt-0 profile-update_form__wrapper">
    <base-form v-bind="$attrs"
               :title="$t('Personal Information')"
               :description="$t('Use a permanent address where you can receive mail.')"
               :save-text="$t('Update Profile')"
               :loading="loading"
               @cancel="$emit('cancel')"
               @submit="updateProfile"
    >

      <div class="col-span-6">
        <lang-switcher />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.email"
                    :label="$t('Email')"
                    :placeholder="$t('Email')"
                    :name="$t('Email')"
                    readonly
                    id="email"
                    rules="required|email">
        </base-input>
      </div>

      <div class="col-span-6 md:col-span-3">
        <base-phone-input :label="$t('Phone')"
                          v-model="model.phone"
                          :value="model.phone || ''"
                          rules="required"
                          :phone-country="model.phone_country || ''"
                          @input-data="updatePhoneData"/>
      </div>

      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.given_name"
                    :label="$t('First Name')"
                    :placeholder="$t('First Name')"
                    :name="$t('First Name')"
                    id="name"
                    rules="required">
        </base-input>
      </div>

      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.family_name"
                    :label="$t('Last Name')"
                    :name="$t('Last Name')"
                    :placeholder="$t('Last Name')"
                    id="family_name"
                    rules="required">
        </base-input>
      </div>
      <slot></slot>
    </base-form>
    <base-form class="mt-8"
               grid-classes="flex flex-col"
               :title="$t('Password Change')"
               :description="$t('Change you account password')"
               :save-text="$t('Change Password')"
               @submit="changePassword"
    >
      <base-input v-model="form.password_old"
                  class="w-full"
                  type="password"
                  :label="$t('Old Password')"
                  :placeholder="$t('Old Password')"
                  :name="$t('Old Password')"
                  id="old_password"
                  rules="required">
      </base-input>

      <base-input v-model="form.password"
                  class="w-full"
                  type="password"
                  :label="$t('New Password')"
                  :placeholder="$t('New Password')"
                  :name="$t('New Password')"
                  id="password"
                  rules="required|min:8">
      </base-input>

      <base-input v-model="form.password_confirm"
                  class="w-full"
                  type="password"
                  :label="$t('Confirm Password')"
                  :placeholder="$t('Confirm Password')"
                  :name="$t('Confirm Password')"
                  id="password_confirm"
                  :rules="`required|min:8|confirmed:@${$t('New Password')}`">
      </base-input>
    </base-form>

    <base-form class="mt-8"
               grid-classes="flex flex-col"
               :title="$t('Notifications Preferences')"
               :description="$t('Manage your notification preferences')"
               :save-text="$t('Update Preferences')"
               :loading="notificationPreferencesLoading"
               @submit="updateNotificationPreferences"
    >
      <base-checkbox v-model="notificationPreferences.monthly_report_mail"
                     class="w-full border-0"
                     type="checkbox"
                     :label="$t('Monthly report notification')"
                     :name="$t('Monthly report notification')"
                     id="monthly_report_mail">
      </base-checkbox>
    </base-form>
  </div>
</template>
<script>
import { get } from 'lodash'
import UserService from "@/modules/auth/services/UserService.js";
import BaseAvatarUpload from "@/components/form/BaseAvatarUpload.vue";
import LangSwitcher from "@/modules/profile/components/LangSwitcher.vue";

export default {
  components: {
    BaseAvatarUpload,
    LangSwitcher,
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      model: {
        name: '',
        email: '',
        photo_url: ''
      },
      form: {},
      notificationPreferencesLoading: false,
      notificationPreferences: {
        id: this.$store.state.auth.user.id,
        monthly_report_mail: false,
      }
    }
  },
  computed: {
    userName() {
      return this.data.name || ''
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        this.model = {
          ...this.model,
          ...value,
        }
        this.notificationPreferences.monthly_report_mail = value.monthly_report_mail
      }
    }
  },
  methods: {
    initPasswordForm() {
      this.form = {
        password_old: '',
        password: '',
        password_confirm: '',
      }
    },
    updatePhoneData(value) {
      this.model.phone_country = get(value, 'activeCountry.iso2', '')
      this.model.phone = get(value, 'number', '')
    },
    async updateProfile() {
      try {
        this.loading = true
        await UserService.updateProfile(this.model);
        await this.$store.dispatch('auth/refreshProfile', true)
        this.$success(this.$t('User profile updated'))
        this.$emit('save')
      } catch (err) {
        this.$error(this.$t('Could not update the user profile'))
      } finally {
        this.loading = false
      }
    },
    async changePassword() {
      try {
        await UserService.changePassword(this.form)
        this.$success(this.$t('Your password was changed'))
        this.initPasswordForm()
      } catch (e) {
        this.$error(this.$t('Something went wrong please try again.'))
      }
    },
    async getProfileData() {
      this.model = this.$store.state.auth.user
    },
    async updateNotificationPreferences() {
      try {
        this.notificationPreferencesLoading = true
        await UserService.updateNotificationPreferences(this.notificationPreferences)
        this.$success(this.$t('Your notifications were changed'))
        await this.$store.dispatch('auth/refreshProfile', true)
      } catch (e) {
        this.$error(this.$t('Something went wrong please try again.'))
      } finally {
        this.notificationPreferencesLoading = false
      }
    },
  },
  mounted() {
    this.getProfileData()
    this.initPasswordForm()
  }
}
</script>
<style lang="scss">
.profile-update_form__wrapper .el-image {
  @apply w-20 h-20 text-center mr-4;

  @screen sm {
    @apply w-32 h-32;
  }

  @screen md {
    @apply w-40 h-40;
  }
}
</style>
