<template>
  <div class="mt-10 sm:mt-0 profile-update_form__wrapper">
    <BaseDataTable :columns="columns"
                   :data="exportsData"
                   :show-actions-column="false"
                   :show-pagination="false"
                   :title="$t('Available Exports')"
                   class="mt-4 mb-6"
    >
      <template #type="{row}">
        <div class="capitalize">{{ typeLabels[row.type] ? $t(typeLabels[row.type]) : row.type }}</div>
      </template>
      <template #actions="{row}">
        <div class="capitalize">{{ row.type }}</div>
      </template>
      <template #link="{row}">
        <base-button
            :loading="row.downloading"
            :disabled="!row.ready_to_download"
            size="sm"
            @click="downloadFile(row)">
          {{ $t('Download') }}
        </base-button>
      </template>
      <template #created_at="{row}">
        {{ $formatDate(row.created_at, DEFAULT_DATE_TIME_FORMAT) }}
      </template>
    </BaseDataTable>
    <base-form
        :title="$t('Request data export')"
        :description="$t('You can request data export for your job applicants')"
        :save-text="$t('Request export')"
        :loading="loading"
        @cancel="$emit('cancel')"
        @submit="requestExport"
    >
      <div class="col-span-6 md:col-span-2">
        <base-select
            v-model="model.type"
            :options="exportEntities"
            :label="$t('Type')"
        />
      </div>

      <div class="col-span-6 md:col-span-1" v-if="!isMonthlyExport">
        <base-select
            v-model="model.format"
            :options="formatOptions"
            :label="$t('Format')"
        />
      </div>

      <div v-if="!isMonthlyExport">
        <div class="col-span-6 md:col-span-3 date-picker__wrapper">
          <label class="block text-sm font-medium leading-5 text-gray-700">
            {{ $t('Date Range') }}
          </label>
          <el-date-picker
              v-model="dateRange"
              :shortcuts="shortcuts"
              :placeholder="$t('Select Date')"
              :format="'DD-MM-YYYY'"
              type="daterange"
              popper-class="date-picker__wrapper"
          />
        </div>
        <div v-if="model.type === 'ml'"
             class="col-span-6 md:col-span-3">
          <entity-select
              v-model="model.channel_id"
              :label="$t('Channel')"
              :label-format="channelLabelFormat"
              url="/channel"
              value-key="id"
              select-first-option
          />
        </div>
      </div>

      <template #pre-extra-buttons>
        <span class="mr-2 text-sm">
          <span v-if="loading">{{ $t('Generating...') }}</span>
          <span v-else>{{ $t('Downloads are available for 3 hours') }}</span>
        </span>
      </template>
    </base-form>
  </div>
</template>
<script>
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  subWeeks,
  subDays,
  endOfDay,
  startOfWeek,
  endOfWeek,
} from "date-fns";

import axios from 'axios'
import { ElDatePicker } from 'element-plus'
import { getDateWithoutTimezone } from "@/utils/dateUtils.js";
import EntitySelect from "@/components/common/EntitySelect.vue";
import { downloadFile } from "@/plugins/exportToPng.js";
import i18n from "@/i18n.js";
import { DEFAULT_DATE_TIME_FORMAT } from "@/plugins/dateFormatPlugin.js";

let now = new Date()
let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
today = getDateWithoutTimezone(today)
now = getDateWithoutTimezone(now)

const typeValues = {
  Monthly: 'monthly-export',
  MonthlyPdf: 'monthly-pdf',
  Applicants: 'applicants',
  Clients: 'client-month',
  Jobs: 'client-jobs',
  Campaigns: 'client-campaigns',
}
const typeLabels = {
  [typeValues.Monthly]: 'Monthly Export Excel',
  [typeValues.MonthlyPdf]: 'Monthly PDF report',
  [typeValues.Applicants]: 'Applicants',
  [typeValues.Clients]: 'Clients',
  [typeValues.Jobs]: 'Jobs',
  [typeValues.Campaigns]: 'Campaigns',
}
export default {
  name: 'export-data',
  components: {
    ElDatePicker,
    EntitySelect,
  },
  data() {
    return {
      loading: false,
      typeLabels,
      DEFAULT_DATE_TIME_FORMAT,
      model: {
        type: 'applicants',
        format: 'xlsx',
        client_id: null,
        date_from: subMonths(today, 1),
        date_till: today,
        channel_id: null
      },
      exportEntities: [
        {
          label: this.$t(typeLabels[typeValues.Monthly]),
          value: typeValues.Monthly
        },
        {
          label: this.$t(typeLabels[typeValues.MonthlyPdf]),
          value: typeValues.MonthlyPdf
        },
        {
          label: this.$t(typeLabels[typeValues.Applicants]),
          value: typeValues.Applicants
        },
        {
          label: this.$t(typeLabels[typeValues.Clients]),
          value: typeValues.Clients
        },
        {
          label: this.$t(typeLabels[typeValues.Jobs]),
          value: typeValues.Jobs
        },
        {
          label: this.$t(typeLabels[typeValues.Campaigns]),
          value: typeValues.Campaigns
        },
      ],
      formatOptions: [
        {
          label: this.$t('CSV'),
          value: 'csv'
        },
        {
          label: this.$t('XLSX'),
          value: 'xlsx'
        },
        {
          label: this.$t('JSON'),
          value: 'json'
        },
      ],
      form: {}
    }
  },
  computed: {
    exportsData() {
      return this.$store.state.auth.profileObject?.exports
    },
    isMonthlyExport() {
      return [typeValues.Monthly, typeValues.MonthlyPdf].includes(this.model.type)
    },
    currentClient() {
      return this.allClients.find(c => c.active) || {}
    },
    allClients() {
      return this.$store.state.auth.allClients
    },
    columns() {
      return [
        {
          label: this.$t('Name'),
          prop: 'name',
          width: 120,
        },
        {
          label: this.$t('Type'),
          prop: 'type',
          width: 120,
        },
        {
          label: this.$t('Format'),
          prop: 'format',
          width: 120,
        },
        {
          label: this.$t('Created'),
          prop: 'created_at',
          width: 120,
        },
        {
          label: this.$t('Actions'),
          prop: 'link',
          width: 120,
        },
      ]
    },
    dateRange: {
      get() {
        if (!this.model.date_from && !this.model.date_till) {
          return []
        }
        return [this.model.date_from, this.model.date_till]
      },
      set(value) {
        this.onChangeDates(value)
      }
    },
    shortcuts() {
      const app = this
      return [
        {
          text: 'Today',
          onClick() {
            app.formatDates(today, now)
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const from = subDays(today, 1)
            const to = getDateWithoutTimezone(endOfDay(today))
            app.formatDates(from, to)
          },
        },
        {
          text: 'Last week',
          onClick() {
            let weekStart = startOfWeek(today, { weekStartsOn: 1 })
            weekStart = getDateWithoutTimezone(weekStart)
            const lastWeekStart = subWeeks(weekStart, 1)
            const lastWeekEnd = endOfWeek(lastWeekStart, { weekStartsOn: 1 })
            app.formatDates(lastWeekStart, lastWeekEnd)
          },
        },
        {
          text: 'Last 2 weeks',
          onClick() {
            let weekStart = startOfWeek(today, { weekStartsOn: 1 })
            weekStart = getDateWithoutTimezone(weekStart)
            const twoWeeksStart = subWeeks(weekStart, 2)
            const twoWeeksEnd = endOfWeek(twoWeeksStart, { weekStartsOn: 1 })
            app.formatDates(twoWeeksStart, twoWeeksEnd)
          },
        },
        {
          text: 'This month',
          onClick() {
            const thisMonth = getDateWithoutTimezone(startOfMonth(today))
            app.formatDates(thisMonth)
          },
        },
        {
          text: 'Last month',
          onClick() {
            const lastMonth = getDateWithoutTimezone(subMonths(today, 1))
            const lastMonthFirstDay = startOfMonth(lastMonth)
            const lastMonthLastDay = endOfMonth(lastMonth)
            app.formatDates(lastMonthFirstDay, lastMonthLastDay)
          },
        },
        {
          text: 'Last 3 months',
          onClick() {
            const threeMonthsAgo = getDateWithoutTimezone(subMonths(today, 3))
            const lastMonthFirstDay = startOfMonth(threeMonthsAgo)
            app.formatDates(lastMonthFirstDay, today)
          },
        }
      ]
    }
  },
  methods: {
    channelLabelFormat(channel) {
      return `${channel.name} - ${channel.promotion_type.toLowerCase()}`
    },
    formatDates(from, to) {
      this.model.date_from = from
      this.model.date_till = to
    },
    onChangeDates(value) {
      if (!value) {
        this.model.date_from = null
        this.model.date_till = null
      }
      this.model.date_from = value[0]
      this.model.date_till = value[1]
    },
    async downloadFile(row) {
      try {
        row.downloading = true
        const response = await axios.get(`export/download/${row.token}`, {
          responseType: 'blob',
        })
        downloadFile({
          fileContent: response,
          fileName: `${row.type}-export`,
          extension: row.format,
          shouldEncode: false,
        })
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not download the export file'))
      } finally {
        row.downloading = false
      }
    },
    async requestExport() {

      if (this.model.type === typeValues.Monthly) {
        this.model.format = 'xlsx'
      } else if (this.model.type === typeValues.MonthlyPdf) {
        this.model.format = 'pdf'
      }

      try {
        this.loading = true
        await axios.post(`/export/${this.model.type}/${this.model.format}`, {
          client_id: this.currentClient.id,
          date_from: this.$formatDate(this.model.date_from),
          date_till: this.$formatDate(this.model.date_till),
          channel_id: this.model.channel_id,
        })
        this.$success(this.$t('Data export requested. Once processed, your export will appear in the table above.'))
        this.$emit('save')
        setTimeout(async () => {
          await this.$store.dispatch('auth/refreshProfile', true)
          this.loading = false
        }, 5000)
      } catch (err) {
        this.loading = false
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not request data export'))
      }
    },
  },
}
</script>
<style lang="scss">
</style>
