export default {
  methods: {
    formatOptionsData(object, valueKey = 'key') {
      if (!this[object] && !object) {
        return
      }
      const values = []

      Object.entries(this[object] || object).forEach(([key, value]) => {
        values.push({
          value: valueKey === 'key' ? key : value,
          label: this.$t(value)
        })
      })

      return values
    }
  }
}
