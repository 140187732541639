<template>
  <BaseNoPermissions v-if="!$isOwner" />
  <div v-else>
    <router-view></router-view>
  </div>
</template>
<script>
export default {

}
</script>
