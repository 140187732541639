<template>
  <div class="relative inline-block text-left"
       v-click-outside="onClickOutside">
    <div @click="showDropdown = ! showDropdown">
      <slot
        name="trigger"
        :show-dropdown="showDropdown"
      />
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <div v-show="showDropdown"
           class="origin-top-right z-10 absolute right-0 mt-2 min-w-40 rounded-md shadow-card bg-white dark:bg-grey-850 ring-1 ring-black ring-opacity-5 focus:outline-none"
           :class="dropdownClass"
           role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div class="py-1" role="none">
          <slot>
            <span v-for="option in options"
                  :key="option"
                  class="block px-4 py-2 cursor-pointer text-sm text-gray-700 dark:text-grey-550 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900"
                  role="menuitem"
                  @click="onOptionClick(option)"
            >
              {{ option }}
            </span>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import clickOutside from "@/directives/click-ouside";

export default {
  directives: {
    clickOutside,
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    dropdownClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  methods: {
    onOptionClick(option) {
      this.$emit('option-click', option)
      this.showDropdown = false
    },
    onClickOutside() {
      this.closeDropdown()
    },
    closeDropdown() {
      if (this.showDropdown) {
        this.showDropdown = false
      }
    }
  }
}
</script>
<style>
</style>
