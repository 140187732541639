<template>
  <div>
    <base-button variant="danger-light"
                 :tabindex="0"
                 size="icon"
                 class="hidden md:flex table-action-button"
                 @click="$emit('click')">
      <Trash2Icon class="w-4 h-4"/>
    </base-button>
  </div>
</template>
<script>
import { Trash2Icon } from '@zhuowenli/vue-feather-icons'

export default {
  name: 'TableDeleteButton',
  components: {
    Trash2Icon,
  }
}
</script>
<style>
</style>
