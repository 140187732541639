<template>
  <div class="campaign-create-form__wrapper flex flex-col 2xl:flex-row w-full">
    <base-form v-bind="$attrs"
               :loading="loading"
               :save-text="Object.keys(campaign).length ? $t('Save Campaign') : $t('Create campaign')"
               grid-classes="flex"
               :class="queryJob ? 'w-full' : '2xl:w-3/6'"
               layout="modal"
               @submit="submitCampaignCreate">
      <div class="w-full grid grid-cols-2 gap-x-4">
        <div class="col-span-2">
          <p v-if="queryJob"
             class="text-xl font-medium leading-6 text-gray-900 dark:text-white"
             :class="{ 'mb-4' : queryJob }"
          >
            <span>{{ $t('Boost create') }}</span>
            <span>{{ $t(' for ') }}</span>
            <span class="text-primary-500">"{{ queryJob.name }}"</span>
          </p>
          <p v-if="!queryJob" class="text-xl font-medium leading-6 text-gray-900 dark:text-white">
            {{ $t('Campaign basics') }}
          </p>
          <div class="w-full border-b border-gray-200 mt-2 mb-4"></div>
        </div>
        <base-select
            v-if="!isEditing"
            v-model="model.type"
            :options="campaignTypeOptions"
            :label="$t('Campaign type')"
            :placeholder="$t('Campaign type')"
            :name="$t('Campaign type')"
            :readonly="readonlyTypeSelect"
            classNames="w-full"
            id="campaignType"
            rules="required"
        />
        <base-input
            v-model="model.name"
            :label="$t('Campaign title')"
            :placeholder="$t('Campaign title')"
            :name="$t('Campaign title')"
            id="name"
            rules="required"
        />
        <base-select
            v-model="model.status"
            :options="campaignStatusesOptions"
            :label="$t('Campaign status')"
            :placeholder="$t('Campaign status')"
            :name="$t('Campaign Status')"
            id="campaignStatus"
            rules="required"
        />
        <base-input
            v-if="false"
            v-model="model.priority"
            type="number"
            min="0"
            max="9"
            rules="required|between:0,9"
            :label="$t('Campaign priority')"
            :placeholder="$t('Campaign priority')"
            :name="$t('Campaign priority')"
            :info-text="$t('Determine in which campaign a job will be advertised when it is filtered into multiple campaigns.(0-9)')"
        />
        <div class="col-span-2">
          <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Campaign strategy') }}</p>
          <div class="w-full border-b border-gray-200 mt-2 mb-4"></div>
          <base-amount-option
              v-model="model.budget_stopper"
              :name="$t('Budget')"
              :label="$t('Budget stopper')"
              :options="amountOptions"
              :monthly-job-budget="monthlyJobBudget"
              rules="required"
              @onChangeOption="onChangeBudgetStopper"
          />
          <div class="grid grid-cols-2 gap-x-4 items-end">
            <base-input v-if="showBudgetStopper"
                        ref="stopperInput"
                        v-model="model.budget_stopper"
                        type="number"
                        :placeholder="$t('Budget stopper')"
                        :name="$t('Budget stopper')"
                        id="budgetStopper"
                        :label="$t('Budget stopper value')"
                        :min="minBudget"
                        :max="maxBudget"
                        :rules="`required|min_value:${minBudget}|max_value:${maxBudget}`"
                        :info-text="budgetStopperMessage"
                        has-suffix
                        @blur="checkBudgetStopper(model.budget_stopper, true)"
            >
              <template v-slot:prefix>
                {{ getLocaleCurrencySign }}
              </template>
            </base-input>
            <base-select
                 v-if="$client?.campaign_strategy_type"
                 v-model="model.conversion_strategy"
                 :options="conversionStrategyOptions"
                 :label="$t('Pixel target set on')"
                 :name="$t('Pixel target set on')"
                 id="conversion_strategy"
                 classNames="w-full col-span-2 md:col-span-1"
            >
            </base-select>
            <div
                v-if="model.budget_stopper_next_month"
                class="mb-4 mt-2 col-span-2 flex gap-2 items-center"
            >
              <p>
                <span class="text-gray-500">{{ `${$t('Budget stopper per')} ${getFirstDayOfNextMonth()}: ` }}</span>
                <span class="font-semibold text-primary-600">{{ `${getLocaleCurrencySign} ${model.budget_stopper_next_month}` }}</span>
              </p>
              <BaseTooltip :content="$t('Delete budget stopper for next month')">
                <XIcon
                    class="h-4 w-4 text-gray-500 hover:text-red-500 cursor-pointer"
                    @click="model.budget_stopper_next_month = null"
                />
              </BaseTooltip>
            </div>
          </div>
        </div>
        <div
            v-if="displayIndeedBudgetControl"
            class="col-span-2 grid grid-cols-3 gap-x-4 items-center"
        >
          <base-input
              :model-value="Math.round(100 - +model.indeed_percentage)"
              type="number"
              min="0"
              max="100"
              disabled
              id="other_percentage"
              rules="between:0,100"
              :label="$t('Standard channels budget (%)')"
              :placeholder="$t('Standard channels budget (%)')"
              :name="$t('Standard channels budget (%)')"
              :info-text="$t('The percentage of the budget allocation for other standard channels.')"
          />
          <base-input
              v-model="model.indeed_percentage"
              type="number"
              min="0"
              max="100"
              id="indeed_percentage"
              rules="numeric|between:0,100"
              :label="$t('Indeed budget (%)')"
              :placeholder="$t('Indeed budget (%)')"
              :name="$t('Indeed budget (%)')"
              :info-text="$t('The percentage of the budget allocation for Indeed. This designated percentage will be deducted from the total campaign budget. The remaining amount after deducting the Indeed budget will be available for spending on other job boards.')"
              @blur="notifyIndeedBudget"
          />
          <base-checkbox
              v-model="model.indeed_budget_control"
              class="w-full border-0"
              type="checkbox"
              :label="$t('Set fixed percentage')"
              :name="$t('Set fixed percentage')"
              :info-text="$t('When checking this box, the indeed percentage is fixed and wont get changed by the algorithms')"
              id="indeed_budget_control"
          />
        </div>
        <base-select v-if="!queryJob || Object.keys(campaign).length"
                     v-model="extra.strategy"
                     :options="strategyOptions"
                     :label="$t('Strategy')"
                     :placeholder="$t('Custom')"
                     :name="$t('Strategy')"
                     id="strategy"
                     ref="strategySelect"
                     clearable
                     classNames="w-full col-span-2 md:col-span-1"
        >
        </base-select>
        <template v-if="showExtraFields">
          <base-input v-model="extra.max_cpc"
                      id="maxCPC"
                      type="number"
                      step="any"
                      class="col-span-2 md:col-span-1"
                      :label="$t('Max CPC')"
                      :placeholder="$t('Max CPC')"
                      :name="$t('Max CPC')"
                      :info-text="$t('This setting will adjust the cpc for all channels')"
                      @focus="onMaxCpcFocus"
          >
            <template v-slot:prefix>
              {{ getLocaleCurrencySign }}
            </template>
          </base-input>
        </template>
        <base-input
            v-if="[Strategies.Result, Strategies.ResultPacing].includes(extra.strategy)"
            v-model="model.target_cpa"
            :label="$t('Target CPA')"
            :placeholder="$t('Target CPA')"
            :name="$t('Target CPA')"
            type="number"
            step="any"
            class="col-span-2 md:col-span-1"
            id="target_cpa"
            :rules="extra.strategy ? 'required' : ''"
        >
          <template v-slot:prefix>
            {{ getLocaleCurrencySign }}
          </template>
        </base-input>
        <base-input v-model="model.utm_content"
                    :label="$t('UTM Content')"
                    :placeholder="$t('UTM Content')"
                    :name="$t('UTM Content')"
                    :info-text="$t('UTM Content Info')"
                    class="col-span-2 md:col-span-1"
                    id="utm_content">
        </base-input>
        <base-input v-model="model.utm_term"
                    :label="$t('UTM Term')"
                    :placeholder="$t('UTM Term')"
                    :name="$t('UTM Term')"
                    :info-text="$t('UTM Term Info')"
                    class="col-span-2 md:col-span-1"
                    id="utm_term">
        </base-input>
        <div v-if="!isBoost" class="col-span-2">
          <p class="text-xl w-full font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Job Filters') }}</p>
          <div class="w-full border-b border-gray-200 mt-2 mb-4"></div>
          <CampaignJobFilters
              :initial-filters="model.filter"
              @filter-change="onJobFiltersChange"
          />
        </div>
        <template v-if="$client?.performance_rules">
          <div class="col-span-2">
            <p class="text-xl font-medium leading-6 text-gray-900 dark:text-white">{{ $t('Performance rules') }}</p>
            <div class="w-full border-b border-gray-200 mt-2 mb-4" />
          </div>

          <base-input
              v-model="model.budget_stopper_job"
              id="budget_stopper_job"
              has-suffix
              rules="min_val:10"
              :name="$t('Budget per job')"
              :label="$t('Budget per job')"
              :placeholder="$t('Budget per job')"
          >
            <template v-slot:prefix>
              {{ getLocaleCurrencySign }}
            </template>
          </base-input>
          <!--<base-input-->
          <!--    v-model="model.max_clicks"-->
          <!--    id="max_clicks"-->
          <!--    rules="min_val:10"-->
          <!--    :name="$t('Max clicks')"-->
          <!--    :label="$t('Max clicks')"-->
          <!--    :placeholder="$t('Max clicks')"-->
          <!--/>-->
          <!--<base-checkbox-->
          <!--    v-model="model.check_monthly"-->
          <!--    id="check_monthly"-->
          <!--    type="checkbox"-->
          <!--    class="w-full border-0 mt-3"-->
          <!--    :label="$t('This month (otherwise for all time)')"-->
          <!--    :name="$t('This month (otherwise for all time)')"-->
          <!--/>-->
        </template>

      </div>
    </base-form>
    <div class="2xl:ml-4 2xl:w-3/6 mt-4 2xl:mt-0 relative"
         v-if="showJobsTable">
      <div class="sticky right-0 top-0">
        <BaseDataTable
            ref="jobsTable"
            :columns="columns"
            :initial-filters="model.filter"
            :row-classes="getRowClasses"
            :excluded-rows="selectedItems"
            :draggable-columns="false"
            :show-actions-column="false"
            show-top-total-rows
            url="/job"
            entity="Job"
            @on-change-filters="onFiltersChange"
            @data-fetch="onJobsFetch"
        >
          <template #headerInfo>
            <span class="text-xl font-semibold text-gray-500">
              - {{ `${getLocaleCurrencySign} ${monthlyJobBudget} ${$t('for job / month')}` }}
            </span>
          </template>
          <template v-slot:name="{row}">
            <div class="truncate" :title="row.name">
              <router-link :to="`/jobs/${row.id}`"
                           target="_blank"
                           class="text-gray-700 font-normal">
                {{ row.name }}
              </router-link>
            </div>
          </template>
          <template v-slot:job_tags="{row}">
            <JobTagsCell :job="row" :hideAddInput="true" />
          </template>
          <template v-slot:availability="{row}">
            <div class="flex">
              <base-button v-if="!active[row.id]"
                           variant="white"
                           size="xs"
                           @click="toggleChannelAvailability(row.id)"
                           type="button"
              >
                <check-icon
                    size="1.5x"
                    class="cursor-pointer text-green-500"/>
              </base-button>
              <base-button v-else
                           variant="white"
                           @click="toggleChannelAvailability(row.id)"
                           size="xs"
                           type="button"
              >
                <x-icon
                    size="1.5x"
                    class="cursor-pointer text-red-500"/>
              </base-button>
            </div>
          </template>
        </BaseDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignService from '@/modules/jobs/services/CampaignService.js'
import formatSelectOptionsMixin from '@/modules/jobs/mixins/formatSelectOptionsMixin.js'
import localeCurrencyMixin from '@/modules/jobs/mixins/localeCurrencyMixin.js'
import axios from 'axios'
import JobService from '@/modules/jobs/services/JobService.js'
import { cloneDeep, get } from 'lodash'
import BaseAmountOption from '@/modules/jobs/components/BaseAmountOption.vue'
import { CheckIcon, XIcon } from '@zhuowenli/vue-feather-icons'
import CampaignJobFilters from '@/modules/jobs/components/CampaignJobFilters.vue'
import JobTagsCell from '@/modules/jobs/components/JobTagsCell.vue'
import {
  campaignSpentBudget,
  CampaignStrategies,
  campaignStrategyOptions,
} from '@/modules/jobs/enums/CampaignStrategies.js'
import { CAMPAIGN_STATUSES } from '@/enum/tableEnums'
import { timeout } from '@/utils/globalUtils'
import { DATE_TIME_2 } from '@/plugins/dateFormatPlugin'

const emptyJobsFilter = [
  {
    field: 'status',
    operator: 'IS',
    value: 'ACTIVE'
  }
]
export default {
  name: "CampaignCreateForm",
  components: {
    XIcon,
    CheckIcon,
    JobTagsCell,
    BaseAmountOption,
    CampaignJobFilters,
  },
  props: {
    campaign: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [formatSelectOptionsMixin, localeCurrencyMixin],
  async created() {
    const res = await axios.get('/campaign/api-fields')
    if (!res) {
      return
    }
    this.campaignTypes = res?.filters.find(field => field.field === 'type')?.options
  },
  data() {
    return {
      lastValidBudget: 0,
      totalJobs: 0,
      confirmMaxCpc: false,
      Strategies: CampaignStrategies,
      model: {
        type: 'MONTH',
        status: 'ACTIVE',
        // priority: 5,
        conversion_strategy: null,
        indeed_percentage: null,
        indeed_budget_control: null,
        budget_stopper: null,
        budget_stopper_next_month: null,
        filter: this.campaign?.filter || cloneDeep(emptyJobsFilter),
        name: null,
        utm_content: null,
        utm_term: null,
        target_cpa: null,
        // max_clicks: null,
        // check_monthly: null,
        budget_stopper_job: null,
      },
      extra: {
        strategy: null,
        max_cpc: null,
      },
      loading: false,
      showBudgetStopper: false,
      campaignTypes: null,
      job: {},
      readonlyTypeSelect: false,
      filtersInitialized: false,
      allFilteredJobs: {},
      active: {},
      lastJobsFilter: {},
      minBudget: 50,
      maxBudget: 150000,
    }
  },
  computed: {
    displayIndeedBudgetControl() {
      if(!this.campaign?.id) {
        return false
      }
      return this.campaign.indeed_control
    },
    budgetStopperMessage() {
      return this.$t('The campaign budget can not be lower than the total spent and the expected spend for the remaining 24 hours (latency clicks).')
    },
    spentBudget() {
      return campaignSpentBudget(this.campaign)
    },
    monthlyJobBudget() {
      if (!this.totalJobs) {
        return this.model.budget_stopper
      }
      const value = this.model.budget_stopper / this.totalJobs
      return value.toFixed(2)
    },
    showExtraFields() {
      if (!this.isEditing && this.isBoost) {
        return false
      }

      return !this.extra.strategy
    },
    isEditing() {
      return Object.keys(this.campaign).length
    },
    isBoost() {
      return Object.keys(this.job).length
    },
    amountOptions() {
      return [
        { label: 100 },
        { label: 500 },
        { label: 1000 },
        { label: 2500 },
        { label: 5000 },
        { label: 10000 },
        { label: this.$t('Other') },
      ]
    },
    conversionStrategyOptions() {
      return [
        {
          label: this.$t('Default (all)'),
          value: null,
        },
        {
          label: this.$t('Apply start'),
          value: 'apply_start'
        },
        {
          label: this.$t('Applicant'),
          value: 'applicant'
        }
      ]
    },
    strategyOptions() {
      return campaignStrategyOptions.map(el => {
        return {
          ...el,
          label: this.$t(el.label)
        }
      })
    },
    columns() {
      return [
        {
          label: this.$t('Title'),
          prop: 'name',
          maxWidth: 180,
          sortable: true,
        },
        {
          label: this.$t('Organization'),
          prop: 'organisation_name',
          maxWidth: 180,
          sortable: true,
        },
        {
          label: this.$t('Location'),
          prop: 'location_name',
          maxWidth: 160,
          sortable: true,
        },
        {
          label: this.$t('Reference'),
          prop: 'reference',
          maxWidth: 140,
          sortable: true,
        },
        {
          label: this.$t('Tags'),
          prop: 'job_tags',
          maxWidth: 350,
          minWidth: 300,
        },
      ]
    },
    campaignTypeOptions() {
      let types = this.formatOptionsData('campaignTypes')
      types = types.filter(t => t.value !== 'ONE-TIME')
      let boostType = types?.find(type => type.label === 'Boost')
      boostType ? boostType.disabled = true : false
      return types
    },
    campaignStatusesOptions() {
      return this.campaign?.id ? this.formatOptionsData(CAMPAIGN_STATUSES) : this.formatOptionsData(CAMPAIGN_STATUSES).filter(el => el.value !== CAMPAIGN_STATUSES.PAUSE)
    },
    queryJob() {
      if (!this.$route.query.job) {
        return false
      }
      return this.job
    },
    showJobsTable() {
      if (this.queryJob) {
        return false
      }
      if (!this.$route.params.id) {
        return this.filtersInitialized
      }
      return this.campaign.id && this.filtersInitialized
    },
    isBoostType() {
      return this.campaign.type === 'BOOST' || this.model.type === 'BOOST'
    },
    selectedItems() {
      let countExcluded = 0
      Object.values(this.active).forEach(value => {
        if (!value) {
          countExcluded++
        }
      })
      return countExcluded
    }
  },
  methods: {
    get,
    notifyIndeedBudget() {
      if(!this.displayIndeedBudgetControl) {
        return
      }
      const budget = +this.model.indeed_percentage
      if(budget === 100) {
        this.$notifyDialog({
          type: 'info',
          title: this.$t('By assigning 100% of your budget to indeed, you are choosing not to advertise on other channels.'),
          buttonText: this.$t('Continue'),
        })
      } else if(budget === 0) {
        this.$notifyDialog({
          type: 'info',
          title: this.$t('By assigning 100% of your budget to standard channels, you are choosing not to advertise on Indeed.'),
          buttonText: this.$t('Continue'),
        })
      }
    },
    async onMaxCpcFocus() {
      if (!this.isEditing || this.confirmMaxCpc) {
        return
      }
      this.confirmMaxCpc = await this.$confirm({
        buttonText: this.$t('Confirm'),
        title: this.$t('Setting the CPC for all channels'),
        description: this.$t('With this setting, the CPC will be adjusted for each channel, do you want to continue?')
      })
    },
    onJobFiltersChange(filters) {
      this.$refs.jobsTable.dynamicFilters = filters
      this.$refs.jobsTable.refresh()
      this.model.filter = filters
    },
    toggleChannelAvailability(id) {
      this.active[id] = !this.active[id]
      if (this.campaign?.id) {
        this.submitCampaignJobs()
      }
    },
    async submitCampaignJobs(campaignId = this.campaign.id) {
      let excludedJobIds = []
      let res

      Object.entries(this.active).forEach(([key, value]) => {
        if (!value) {
          excludedJobIds.push(key)
        }
      })

      if (!this.isBoostType) {
        res = await CampaignService.excludeJobFromCampaign(campaignId, {
          job_ids: excludedJobIds
        })
      }

      if (!res) {
        this.$error(this.$t('Something went wrong please try again.'))
      }
    },
    getRowClasses(row) {
      if (this.active[row.id] === false) {
        return 'bg-gray-100 outline-inner opacity-50'
      }
      return ''
    },
    onChangeBudgetStopper(val) {
      const findOption = this.amountOptions.find(o => o.label.toString() === this.model.budget_stopper?.toString())
      this.showBudgetStopper = val.label === this.$t('Other');

      if (val.label === this.$t('Other')) {
        this.$nextTick(() => {
          this.$refs.stopperInput && this.$refs.stopperInput.focus()
        })
      }

      if (val.label === this.$t('Other') && findOption?.label) {
        this.model.budget_stopper = null
      } else if (val.label !== this.$t('Other')) {
        this.model.budget_stopper = val.label
      }
    },
    async initForm() {
      if (this.campaign?.id) {
        this.initModel()
      } else {
        let filters = this.$store.state.filter.campaignCreateFilters
        if (filters?.length === 0) {
          filters = emptyJobsFilter
        }
        this.model.filter = cloneDeep(filters)
      }
      this.filtersInitialized = true

      if (!this.queryJob) {
        return
      }

      this.loading = true
      this.job = await JobService.getJob(this.$route.query.job)
      if (!this.$route.params?.id) {
        this.model.name = `Boost - ${this.job.name}`
      }
      this.loading = false
    },
    initModel() {
      if (this.isBoostType) {
        this.initBoostModel()
      } else if (!this.isBoostType && !this.campaign.strategy) {
        this.initNotBoostModel()
        this.extra.max_cpc = this.roundDecimals(this.campaign.max_cpc)
      } else {
        this.initNotBoostModel()
        this.extra.strategy = this.campaign.strategy
      }
      this.initIndeedBudget()
      // this.model.priority = this.campaign.priority
      this.model.utm_content = this.campaign?.settings?.utm_content
      this.model.utm_term = this.campaign?.settings?.utm_term

      // this.model.max_clicks = this.campaign?.settings?.max_clicks
      // this.model.check_monthly = this.campaign?.settings?.check_monthly
      this.model.budget_stopper_job = this.roundDecimals(this.campaign?.settings?.budget_stopper_job)
    },
    roundDecimals(val) {
      return val ? Number(val).toFixed(2) : val
    },
    initBoostModel() {
      this.model.status = this.campaign.status
      this.model.name = this.campaign.name
      this.model.budget_stopper = +this.campaign.budget_stopper
      this.lastValidBudget = this.model.budget_stopper
      this.model.budget_stopper_next_month = this.campaign.budget_stopper_next_month
      this.model.target_cpa = +this.campaign.target_cpa
      this.model.filter = cloneDeep(this.campaign?.filter?.length ? this.campaign.filter : emptyJobsFilter)
    },
    initNotBoostModel() {
      this.initBoostModel()
      this.model.type = this.campaign.type
    },
    onFiltersChange(val) {
      if (JSON.stringify(val) === JSON.stringify(this.model.filter)) {
        return
      }
      this.model.filter = cloneDeep(val)
    },
    onJobsFetch(data, _, pagination) {
      this.totalJobs = pagination.total
      this.allFilteredJobs = data
      data.forEach(job => {
        this.active[job.id] = true
      })

      if (!this.campaign?.jobs?.INACTIVE) {
        return
      }

      this.campaign?.jobs?.INACTIVE.forEach(job => {
        this.active[job] ? this.active[job] = false : false
      })
    },
    async confirmNoFilters() {
      return await this.$confirm({
        title: this.$t('Are you sure you want to create a campaign without job filters?'),
        buttonText: this.$t('Yes'),
        cancelText: this.$t('No')
      })
    },
    async confirmLowBudgetPerJob() {
      return await this.$confirm({
        buttonText: this.$t('Yes'),
        cancelText: this.$t('No'),
        title: this.$t('The budget per job of your campaign is less than 1 euro.'),
        description: this.$t('This campaign setting gives an increased risk of exceeding the set budget (overspend). Do you want to continue and therefore accept this risk?')
      })
    },
    async submitCampaignCreate() {
      if (this.model.filter.length === 1 && !await this.confirmNoFilters()) {
        return
      }

      await timeout(300)

      if (this.monthlyJobBudget < 1 && !await this.confirmLowBudgetPerJob()) {
        return
      }

      let query = {
        tab: 'overview'
      }
      let message = this.$t('Campaign created!')
      if (this.queryJob) {
        this.model.job_id = this.queryJob.id
        query.job = this.$route.query.job
        this.model.strategy = this.extra.strategy
      } else if (!this.queryJob && !this.extra.strategy) {
        this.model = { ...this.model, ...this.extra }
      } else {
        this.model.strategy = this.extra.strategy
      }
      // this.model.priority = +this.model.priority

      if(this.displayIndeedBudgetControl) {
        this.model.indeed_budget_control = this.model.indeed_budget_control === true ? false : null
      }

      if (!this.$client?.campaign_strategy_type) {
        this.model.conversion_strategy = null
      }

      if (!this.$client?.performance_rules) {
        this.model.budget_stopper_job = null
        this.model.max_clicks = null
      }

      if(this.displayIndeedBudgetControl) {
        this.model.indeed_budget_control = this.model.indeed_budget_control === true ? false : null
      }

      if (!this.$client?.campaign_strategy_type) {
        this.model.conversion_strategy = null
      }

      if (!this.$client?.performance_rules) {
        this.model.budget_stopper_job = null
        this.model.max_clicks = null
      }

      let res;

      try {
        this.loading = true
        if (this.isEditing) {
          message = this.$t('Campaign updated!')
          if (!this.extra.strategy) {
            this.model = { ...this.model, ...this.extra }
          }
          this.model.status = this.campaign.status
          res = await CampaignService.editCampaign(this.campaign.id, this.model)
        } else {
          res = await CampaignService.createCampaign(this.model)
          res ? await this.submitCampaignJobs(res.id) : false
        }
      } finally {
        this.loading = false
      }

      if (!res) {
        return
      }

      this.$store.commit('filter/SET_CAMPAIGN_CREATE_FILTERS', [])
      this.$success(message)

      query.title = res.name

      if (!this.isEditing) {
        await this.$router.push({ path: `/campaigns/${res.id}` })
        return
      }
      if (this.campaign.max_cpc === res.max_cpc) {
        this.$emit('updateCampaign', res)
        return
      }
      await this.setChannelsCpc({
        campaignId: res.id,
        max_cpc: res.max_cpc,
        strategy: res.strategy,
      })
      this.$emit('updateCampaign', res)
    },
    updateChannelCpc({ channels, channel, max_cpc }) {
      let cpc = +this.roundDecimals(channel.campaign_channel_max_cpc || 0);
      max_cpc = +max_cpc

      if (max_cpc <= channel.max_cpc_price && max_cpc >= channel.min_cpc_price ) {
        cpc = max_cpc
      } else if (channel.max_cpc_price <= max_cpc) {
        cpc = channel.max_cpc_price
      }
      const status = channel.campaign_channel_include

      channels[channel.id] = {
        cpc: cpc.toString(),
        status: status === true ? 't' : status === false ? 'f' : null
      }
    },
    async setChannelsCpc({ campaignId, max_cpc, strategy }) {
      if (strategy || !max_cpc) {
        return
      }

      const campaignChannels = await CampaignService.getAvailableCampaignChannels(campaignId, {
        sort: {
          limit: 1000
        }
      })
      const allChannels = campaignChannels.data;
      let channels = {}

      allChannels.forEach(channel => {
        this.updateChannelCpc({ channels, channel, max_cpc })
      })

      await CampaignService.editCampaignChannels(campaignId, { channels })
    },
    async confirmLowBudget() {
      return await this.$confirm({
        cancelText: this.$t('Cancel'),
        title: this.$t('The budget stopper is too low.'),
        buttonText: this.$t('Set the budget limit for start of the next month'),
        description: this.$t('The budget stopper cannot be set lower than {amount} (This is the total spend and the expected spend for the remaining 24 hours latency clicks). You do have the option to set a lower campaign budget for next month.', {
          amount: this.$formatPrice(this.spentBudget)
        }),
      })
    },
    isGreaterThanSpent(val) {
      return Number(val) >= this.spentBudget
    },
    getFirstDayOfNextMonth() {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      return this.$formatDate(firstDay, DATE_TIME_2)
    },
    async checkBudgetStopper(val, oldVal) {
      if ((this.showBudgetStopper && oldVal !== true) || !oldVal || !val || this.lastValidBudget === +val) {
        return
      }
      if (this.isGreaterThanSpent(+val)) {
        this.lastValidBudget = +val
        return
      }
      if (!await this.confirmLowBudget() && this.lastValidBudget) {
        this.model.budget_stopper = this.lastValidBudget
        return
      }
      if(+val < this.minBudget) {
        val = this.minBudget
        this.$error(this.$t('The budget stopper cannot be set lower than {amount}', {
          amount: this.$formatPrice(this.minBudget)
        }))
      }

      this.model.budget_stopper_next_month = +val
      this.model.budget_stopper = this.lastValidBudget
    },
    initIndeedBudget() {
      if(!this.displayIndeedBudgetControl) {
        this.model.indeed_budget_control = this.campaign.indeed_budget_control || undefined
        this.model.indeed_percentage = this.campaign.indeed_percentage || undefined
      } else {
        this.model.indeed_budget_control = this.campaign.indeed_budget_control === false ? true : null
        const percentage = this.campaign?.indeed_percentage || 0
        this.model.indeed_percentage = Math.round(percentage * 100)
      }
    },
  },
  watch: {
    async 'model.budget_stopper'(val, oldVal) {
      await this.checkBudgetStopper(val, oldVal)
    },
    '$route.query': {
      immediate: true,
      handler(query) {
        if (query.job) {
          this.readonlyTypeSelect = true
          this.model.type = 'BOOST'
        }
      }
    },
    campaign: {
      immediate: true,
      handler() {
        this.initForm()
      }
    },
    showExtraFields(val) {
      if (val) {
        this.extra.max_cpc = this.roundDecimals(this.campaign.max_cpc)
      }
    }
  }
}
</script>

<style>
#other_percentage {
  @apply bg-gray-100 text-gray-500 cursor-not-allowed;
}
</style>
