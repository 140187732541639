import ProfileLayout from "@/modules/profile/layout/ProfileLayout.vue";
import ProfileEdit from './pages/profile-edit.vue'
import ExportData from './pages/export-data.vue'
import i18n from "@/i18n";

export default [
  {
    path: '/profile',
    name: 'Profile',
    title: 'Profile',
    redirect: '/profile/edit',
    component: ProfileLayout,
    children: [
      {
        path: 'edit',
        name: 'Edit profile',
        title: 'Edit profile',
        description: 'Manage your profile data, session and avatar',
        component: ProfileEdit,
      },
      {
        path: 'export',
        name: 'Export data',
        title: 'Export data',
        description: 'Request data export for job applicants',
        component: ExportData,
      },
    ]
  }
]
