<template>
  <div class="cursor-pointer base-radio__wrapper relative border py-3 px-2"
       :class="{
              'bg-gray-100': disabled,
              'flex items-center justify-start': inline,
              'border-primary-200 bg-primary-100': active,
              'border-gray-200': !active,
     }">
    <input v-bind="$attrs"
           :id="cbId"
           class="form-radio h-4 w-4 text-gray-800 transition duration-150 ease-in-out cursor-pointer"
           type="radio"
           :disabled="disabled"
           :checked="modelValue === value"
           :value="value"
           @change="$emit('update:modelValue', $props.value)"/>
      <label :for="cbId" class="cursor-pointer w-full absolute inset-0 flex items-center">
      <span class="ml-3 font-medium text-gray-900 pl-6 capitalize"
            :class="{'text-primary-900': active}"
      >
        <slot></slot>
      </span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'BaseRadio',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    label: {
      type: [String, Number],
      description: 'Radio label'
    },
    disabled: {
      type: Boolean,
      description: 'Whether radio is disabled'
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    },
    inline: {
      type: Boolean,
      description: 'Whether radio is inline'
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cbId: ''
    }
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2)
  }
}
</script>
<style lang="scss">
.base-radio__wrapper {
  label {
    @apply m-0;
  }

  input[type='radio']:checked:hover, [type='radio']:checked:focus, [type='radio']:checked {
    @apply bg-primary-500;
  }

  input[type='radio']:disabled, input[type='radio']:disabled:hover, input[type='radio']:disabled:focus {
    @apply bg-gray-200;
  }

  input[type='radio'] {
    box-shadow: none;
  }
}
</style>
