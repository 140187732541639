import { get } from 'lodash'
import { ElLoading } from 'element-plus'
import locale from 'element-plus/lib/locale'
import lang from 'element-plus/lib/locale/lang/en'
import 'dayjs/locale/nl'
import dayjs from "dayjs";
import NotificationPlugin from '@/components/common/NotificationPlugin/index'
import ModalPlugin from '@/components/common/modal/modalPlugin'
import PermissionPlugin from '@/modules/auth/plugins/permissionsPlugin'
import DateFormatPlugin from './dateFormatPlugin'
import FormatPrice from './formatPrice'
import ExportToPng from './exportToPng'
import FormatPercent from './formatPercent'
import CopyToClipboard from './copyToClipboard.js'
import clickOutside from '@/directives/click-ouside.js'
import VeeValidate from "@/plugins/veeValidate";
import Cleave from 'cleave.js';
import UserPlugin from "@/plugins/userPlugin.js";
import envPlugin from "@/plugins/envPlugin.js";

// configure language
locale.use(lang)
dayjs.locale('nl')

export default {
  install(Vue) {
    Vue.use(ModalPlugin)
    Vue.use(NotificationPlugin)
    Vue.use(PermissionPlugin)
    Vue.use(DateFormatPlugin)
    Vue.use(FormatPrice)
    Vue.use(FormatPercent)
    Vue.use(ExportToPng)
    Vue.use(VeeValidate)
    Vue.use(CopyToClipboard)
    Vue.use(UserPlugin)
    Vue.use(envPlugin)
    Vue.directive('loading', ElLoading.directive)
    Vue.directive('cleave', {
      mounted: (el, binding) => {
        if (binding.value.disabled) {
          return
        }
        el.cleave = new Cleave(el, binding.value || {})
      },
      updated: (el) => {
        if (!el.cleave) {
          return
        }

        if (!el.cleave.properties.result) {
          return
        }

        const event = new Event('input', {bubbles: true});
        setTimeout(function () {
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
        }, 100);
      }
    })
    Vue.directive('click-outside', clickOutside)
    Vue.mixin({
      methods: {
        get,
        round(number, decimals = 2) {
          let base = 1
          for (let i = 0; i < decimals; i++) {
            base = base * 10
          }
          return Math.round(number * base) / base
        },
      },
    })
  },
}
