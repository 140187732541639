<template>
  <div>
    <div>
      <h2 class="mt-6 text-3xl leading-9 font-semibold text-gray-900 text-center">
        {{ $t('Forgot Password') }}
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w text-center">
        {{ $t('Enter your email to reset it') }}
      </p>
    </div>
    <div class="mt-4">
      <div class="mt-6 relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center text-sm leading-5">
        </div>
      </div>
      <div class="mt-6">
        <VeeForm v-slot="{ handleSubmit, errors }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <BaseInput v-model="model.email"
                       :label="$t('Email address')"
                       :placeholder="$t('Email address')"
                       :name="$t('Email address')"
                       rules="required|email"
                       id="email"
                       type="email"
            />
            <div class="flex justify-end text-sm leading-5">
              <router-link to="/login">
                {{ $t('Back to login') }}
              </router-link>
            </div>
            <div class="mt-6">
              <BaseButton block
                          type="submit"
                          :disabled="Object.keys(errors).length > 0"
                          :loading="loading">
                {{ $t('Send reset password link') }}
              </BaseButton>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from '@/modules/auth/services/AuthService.js';

export default {
  data() {
    return {
      loading: false,
      model: {
        email: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await AuthService.forgotPassword(this.model)
        this.$success(this.$t(' Please check your email!'))
      } catch (e) {
        if (e.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
