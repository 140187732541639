import { UserTypes } from "@/enum/userEnums";
import store from "@/store";

export function isOwner() {
  return store.state.auth?.user?.role === UserTypes.Owner
}

export function isMarketeer() {
  return store.state.auth?.user?.role === UserTypes.Marketeer
}

export function isReadOnly() {
  return store.state.auth?.user?.role === UserTypes.ReadOnly
}

export function isAdmin() {
  return this.$store.state.auth?.user?.admin === true
}
